/*------------------------------------*\
    #TABS
\*------------------------------------*/

.c-tabs {
  @extend .o-list-bare;
  font-size: 0;
  padding: 0 $spacing-unit-small;
  @include mq($from: md) {
    padding: 0;
  }
}

.c-tabs__item {
  display: inline-block;
  margin: 0;
  @include font-size($font-size-12);
  @include mq($from: sm) {
    @include font-size($font-size-14);
  }
  @include mq($from: md) {
    @include font-size($font-size-15);
  }
}

.c-tabs__link {
  display: inline-block;
  @include setPropFromVar(color, $var-color-text-base);;
  transition: $global-transition-all;
  @include setPropFromVar(font-family, $var-font-family-bold);
  @include setPropFromVar(font-weight, $var-font-weight-bold);
  padding: $spacing-unit $spacing-unit-small $spacing-unit-med-tiny;
  &:hover,
  &:focus,
  .c-tabs__item.is-active & {
    text-decoration: none;
    @include setPropFromVar(color, $var-color-ui);
  }
  @include mq($from: md) {
    padding: $spacing-unit-med-small $spacing-unit;
    @include setPropFromVar(background-color, $var-color-bg-3);
    .c-tabs__item.is-active & {
      @include setPropFromVar(background-color, $var-color-bg-1);
    }
  }
}
