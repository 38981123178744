/*------------------------------------*\
    #PROGRESS
\*------------------------------------*/

.c-progress {
  &--password {
    display: none;
    html.js & {
      margin: $spacing-unit-tiny 0;
    }
  }
}

.c-progress__bar {
  -webkit-appearance: none;
  margin-bottom: $spacing-unit-small;
  height: $spacing-unit-med-large;
  width: 100%;

  &::-webkit-progress-bar {
    @include setPropFromVar(background-color, $var-color-bg-2);
  }

  &::-webkit-progress-value {
    background-color: $color-green-bright;
  }

  .c-progress--password & {
    display: inline-block;
    height: $spacing-unit-tiny;
    width: 0;
    border: none;
    background-color: transparent;
    border-radius: 0;
    margin: 0;
    &--danger {
      background-color: $color-danger;
    }
    &--warning {
      background-color: $color-warning;
    }
    &--success {
      background-color: $color-success;
    }
  }
}

.c-progress__filled {
  background: $color-blue;
  height: $spacing-unit;
  width: 5px;
}

.c-progress__percentage {
  float: right;
}

.c-progress__wrapper {
  .c-progress--password & {
    display: inline-block;
    vertical-align: middle;
    width: 200px;
      border: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  }
}

.c-progress__title {
  .c-progress--password & {
    @include font-size($font-size-12);
    color: $color-gray-60;
    @include mq(sm) {
      display: inline-block;
      vertical-align: middle;
      margin-right: $spacing-unit-small;
    }
  }
}
