/*------------------------------------*\
    #BIDDER PROFILE
\*------------------------------------*/

.c-bidder-profile {}

.c-bidder-profile__section {
  margin-bottom: $spacing-unit;
  @include mq(md) {
    padding: $spacing-unit;
      border: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
    margin-bottom: $spacing-unit-large;
    background-color: $color-gray-98-blue-tint
  }
}
