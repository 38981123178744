/*------------------------------------*\
    #DRUPAL
\*------------------------------------*/

/**
 * Styles for Drupal (and Drupal contrib) elements which can't be themed
 */

// Misc
.resizable-textarea .grippie {
  background: #eee url(/misc/grippie.png) no-repeat center 2px;
  border: 1px solid #ddd;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}
// Make space for grippie before it appears
.form-textarea-wrapper.resizable:not(.textarea-processed) {
  padding-bottom: 9px;
}

.ckeditor_links {
  .c-form--inline .c-form__wrapper--auction-admin & {
    @include mq(lg) {
      margin-left: $inline-form-label-width;
    }
  }
  .c-form--inline .c-form__wrapper--auction-admin .c-form__fieldset & {
    @include mq(lg) {
      margin-left: $inline-form-label-width - $spacing-unit;
    }
  }
}

html.js .js-hide {
  display: none;
}

em.placeholder {
  font-style: inherit;
}

/**
 * Autocomplete.
 *
 * @see autocomplete.js
 */
/* Suggestion list */
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}
#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
  zoom: 1; /* IE7 */
}

/**
 * TableDrag behavior.
 *
 * @see tabledrag.js
 */
body.drag {
  cursor: move;
}
.draggable {
  td {
    @include mq($until: md) {
      padding: 0 $spacing-unit-tiny;
    }
    @include mq(md) {
      border-left: 0;
      &:first-child {
        position: relative;
        padding-left: 20px;
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          border-left: 10px solid transparent;
        }
      }
    }
  }
}
.draggable.tabledrag-handle-hover {
  cursor: move;
  @include mq(md) {
    td:first-child {
      &:before {
        @include setPropFromVar(border-color, $var-color-border);
      }
    }
  }
}
.draggable.drag {
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  td {
    @include mq(md) {
      border-top: 1px solid;
      border-bottom: 1px solid;
      @include setPropFromVar(border-color, $var-color-ui);
      &:first-child {
        border-left: 1px solid;
        @include setPropFromVar(border-color, $var-color-ui);
        &:before {
          @include setPropFromVar(border-color, $var-color-ui);
        }
      }
      &:last-child {
        border-right: 1px solid;
        @include setPropFromVar(border-color, $var-color-ui);
      }
    }
  }
}
.draggable.is-changed {
  @include mq($until: md) {
    td {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-left: 1px solid;
        @include setPropFromVar(border-left-color, $var-color-ui);
      }
    }
  }
  @include mq(md) {
    td:first-child {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-right: 1px solid;
        @include setPropFromVar(border-color, $var-color-ui);
      }
    }
  }
}

div.tree-child {
  background: url(/misc/tree.png) no-repeat 11px center; /* LTR */
}
div.tree-child-last {
  background: url(/misc/tree-bottom.png) no-repeat 11px center; /* LTR */
}
div.tree-child-horizontal {
  background: url(/misc/tree.png) no-repeat -11px center;
}
.tabledrag-toggle-weight-wrapper {
  text-align: right; /* LTR */
}
table.has-tabledrag tbody {
  border: 1px solid;
  @include setPropFromVar(box-shadow, $var-focus-shadow);
  @include setPropFromVar(border-color, $var-color-ui);
}

/**
 * TableHeader behavior.
 *
 * @see tableheader.js
 */
table.sticky-header {
  //@include setPropFromVar(background-color, $var-color-bg-3);
  //margin-top: 0;
  th {
  }
}

/**
 * Admin menu
 */
#admin-menu {
  height: auto;
}

/**
 * Hide elements visually, but keep them available for screen-readers.
 *
 * Used for information required for screen-reader users to understand and use
 * the site where visual display is undesirable. Information provided in this
 * manner should be kept concise, to avoid unnecessary burden on the user.
 * "!important" is used to prevent unintentional overrides.
 */
.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}

/**
 * The .element-focusable class extends the .element-invisible class to allow
 * the element to be focusable when navigated to via the keyboard.
 */
.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto;
}

