/*------------------------------------*\
    #ICHECK
\*------------------------------------*/

/**
 * Styles output from iCheck jQuery plugin
 */

.icheckbox,
.iradio {
  position: relative;
  cursor: pointer;
  text-align: center;
  width: 1em;
  height: 1em;
  :not(.icheck--align-top) > & {
    top: -2px;
  }
  // Don't allow the label to squeeze in on the checkbox
  flex-shrink: 0;
  @include font-size(28px);
  @include mq(md) {
    @include font-size(32px);
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -0.5em;
    width: 1em;
    height: 1em;
    @include setPropFromVar(background-color, $var-color-bg-1);
    border: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);
    border-radius: $global-radius;
    .has-warning & {
      border-color: $color-warning;
    }
    .has-error & {
      border-color: $color-danger;
    }
  }

  &.icheckbox-sm,
  &.iradio-sm {
    @include font-size($font-size-18);
    top: -1px;
    .icheck--align-top > & {
      @include mq(md) {
        top: 1px;
      }
    }
  }

  &.checked {
    $scale: 19/32;
    &:after {
      content: '';
      @include svg-icon(tick, $scale * 1em, $fill: $color-blue);
      @include setPropFromVar(filter, unquote($var-color-filter));
      position: absolute;
      left: $scale * 0.375em;
      top: 50%;
      margin-top: $scale * -0.5em;
      .has-warning & {
        filter: url(#color-warning);
      }
      .has-error & {
        filter: url(#color-danger);
      }
    }
    &.icheckbox-sm,
    &.iradio-sm {
      &:after {
        @include svg-icon(tick-thick, $scale * 1em, $fill: $color-blue);
      }
    }
  }

  html.js .is-disabled-js,
  &.is-disabled,
  fieldset:disabled & {
    cursor: default;
    pointer-events: none;
    &:before {
      @include setPropFromVar(background-color, $var-state-disabled-bg);
    }
  }

  &.is-disabled-quiet {
    cursor: default;
    pointer-events: none;
    background-color: white;
  }

  &.has-focus:before {
    @include setPropFromVar(border-color, $var-color-ui);
  }

  .is-error &:before {
    border-color: $color-form-error;
  }

  @each $name, $colors in $theme-colors {
    $bg: map-get($colors, ui);
    $hover: map-get($colors, hover);
    .icheck_#{$name} & {
      &:before {
        background-color: $bg;
        border-color: $bg;
      }
      &:hover,
      &:focus,
      &:active,
      &.active {
        &:before {
          background-color: $hover;
          border-color: $hover;
        }
      }
      &.checked:after {
        filter: brightness(0) invert(1);
      }
    }
  }

}

