/*------------------------------------*\
    #INPUTS
\*------------------------------------*/

@mixin input-focus() {
  transition: $fast-transition-all;
  &:focus {
    &:not(.is-locked) {
      outline: none;
      &:not(.has-error) {
        @include setPropFromVar(color, $var-color-text-dark);
        @include setPropFromVar(border-color, $var-color-ui);
        @include mq(sm) {
          @include setPropFromVar(box-shadow, $var-input-focus-shadow);
        }
      }
      &.has-error {
        border-color: $color-danger;
      }
    }
  }
}

// Placeholder text
@mixin placeholder() {
  &::placeholder {
    @include setPropFromVar(color, $var-color-text-placeholder);
    opacity: 1;
    line-height: normal !important;
  }
}

.c-input {
  display: block;
  width: 100%;
  @include setPropFromVar(color, $var-color-text-base);
  @include setPropFromVar(font-family, $var-font-family-default);
  @include setPropFromVar(background-color, $var-color-bg-1);
  background-image: none;
  border: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  border-radius: $global-radius;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  // Focus styles
  &:not(.c-input--ai-search) {
    @include input-focus;
  }

  // Placeholder
  @include placeholder;

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    border: 0;
    background-color: transparent;
  }

  &.is-error,
  &.has-error {
    border-color: $color-danger;
  }

  &.received-ajax-update,
  .received-ajax-update & {
    @include setPropFromVar(border-color, $var-color-ui);
    @include setPropFromVar(box-shadow, $var-input-focus-shadow);
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  html.js &.is-disabled-js,
  &[disabled],
  &.is-disabled,
  &[readonly],
  fieldset[disabled] &,
  fieldset.is-disabled & {
    &:not(.is-locked) {
      @include setPropFromVar(background-color, $var-state-disabled-bg);
      opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
      pointer-events: none !important;
    }
  }

  &[disabled],
  &.is-disabled,
  html.js &.is-disabled-js,
  fieldset[disabled] &,
  fieldset.is-disabled & {
    &:not(.is-locked) {
      cursor: not-allowed !important;;
    }
  }

  &--textarea {
    @include input-sizing($include-height: false);
  }

  &--checkbox {
    float: left;
    width: auto;
    margin-right: $spacing-unit;
  }

  &--number {
    @include mq(md) {
      max-width: 200px;
    }
  }

}

::-webkit-color-swatch-wrapper {
  padding: 0;
}
::-webkit-color-swatch {
  border: none;
}
