/*------------------------------------*\
    #Colors
\*------------------------------------*/

$color-white:                       #FFFFFF;
$color-blue:                        #49BEEA;
$color-blue-shadow:                 #33ACD1;
$color-blue-dark:                   #3DAFD3;
$color-blue-v-dark:                 #42ABCC;
$color-blue-vv-dark:                #142736;
$color-blue-light:                  #4ECBFA;
$color-blue-v-light:                #6FD1EF;
$color-blue-vv-light:               #75DBFA;
$color-teal:                        #347C8C;
$color-green-bright:                #39B54A;
$color-green:                       #84C7A8;
$color-green-dark:                  #6AAA8A;
$color-green-shadow:                #C3DCD2;
$color-red:                         #EF8E8E;
$color-red-bright:                  #EF524B;
$color-red-dark:                    #D87373;
$color-red-rich:                    #E01B1B;
$color-red-shadow:                  #D32626;
$color-yellow:                      #FFBA55;
$color-yellow-light:                #FFDDAB;
$color-yellow-dark:                 #D09A47;
$color-gold:                        #D9B730;

$color-blue-gray-1:                 #20363D;
$color-blue-gray-2:                 #355159;
$color-blue-gray-3:                 #395661;
$color-blue-gray-4:                 #405D66;

$color-gray-20:                     #333333;
$color-gray-30:                     #4D4D4D;
$color-gray-35-blue-tint:           #58595B;
$color-gray-40:                     #666666;
$color-gray-44-blue-tint:           #6D6E71;
$color-gray-60:                     #999999;
$color-gray-69:                     #AFAFAF;
$color-gray-70:                     #B3B3B3;
$color-gray-80:                     #CCCCCC;
$color-gray-87-blue-tint:           #DBDCDD;
$color-gray-89-blue-tint:           #B9D7E3;
$color-gray-90-blue-tint:           #E2E3E8;
$color-gray-91-blue-tint:           #DFE2EA;
$color-gray-93-blue-tint:           #E8EAED;
$color-gray-95:                     #F2F2F2;
$color-gray-96-blue-tint:           #F2F3F6;
$color-gray-97-green-tint:          #F2F9F5;
$color-gray-98-blue-tint:           #F8F9FA;

$color-twitter:                     #78CBEF;
$color-facebook:                    #547BBC;

$color-gray-ui:                     $color-gray-93-blue-tint;
$color-tint:                        $color-gray-96-blue-tint;

$color-success:                     $color-green-bright;
$state-success-text:                white;
$state-success-bg:                  $color-success;
$state-success-bg-hover:            $color-green-bright;
$state-success-border:              $color-green-dark;

$color-info:                        #d9edf7;
$state-info-text:                   #31708f;
$state-info-bg:                     $color-info;
$state-info-border:                 darken(adjust-hue($state-info-bg, -10), 7%);

$color-warning:                     $color-yellow;
$state-warning-text:                $color-gray-44-blue-tint;
$state-warning-bg:                  $color-gray-96-blue-tint;
$state-warning-border:              $color-warning;

$color-danger:                      $color-red-bright;
$state-danger-text:                 white;
$state-danger-bg:                   $color-danger;
$state-danger-bg-hover:             $color-red-bright;
$state-danger-border:               $color-red-dark;

$color-form-error:                  $color-red-dark;

$state-disabled-text:               $color-gray-69;
$state-disabled-bg:                 $color-gray-93-blue-tint;
$state-disabled-border:             $color-gray-87-blue-tint;

$status_colors: (
  '': $color-green-bright,
  bidding: $color-blue,
  below-reserve: $color-blue,
  live-reserve-not-met: $color-blue,
  ended: $color-blue,
  winning: $color-green-bright,
  won: $color-green-bright,
  losing: $color-red-bright,
  lost: $color-red-bright,
  expired-reserve-not-met: $color-gray-80,
  superseded: $color-gray-69
);

$theme-colors: (
  blue: (ui: $color-blue, focus: $color-blue-light),
  teal: (ui: $color-teal, focus: lighten($color-teal, 10)),
  green: (ui: $color-green, focus: $color-green-bright),
  black: (ui: black, focus: $color-blue-gray-4),
  gold: (ui: $color-gold, focus: lighten($color-gold, 5)),
  dark: (ui: $color-gray-87-blue-tint),
);

