/*------------------------------------*\
    #CONTENT LIST
\*------------------------------------*/

$number-padding: $spacing-unit + $spacing-unit-small;
$vertical-margin: $spacing-unit;

.c-content-list {
  margin-bottom: $spacing-unit-large;
  > li {
    margin-bottom: $vertical-margin;
  }

  &--legal {
    list-style: none;
    counter-reset: list;
    margin: 0;
    > li {
      counter-increment: list;
      margin: 0;
      > .c-heading--beta {
        margin: $spacing-unit-large 0 $spacing-unit;
        &:before {
          content: counter(list) ". ";
        }
      }
      > ul {
        > li {
          margin-bottom: $vertical-margin;
        }
      }
      > ol {
        list-style: none;
        counter-reset: list;
        margin-left: 0;
        > li {
          counter-increment: list;
          padding-left: $number-padding;
          margin: $vertical-margin 0;
          position: relative;
          &:before {
            position: absolute;
            left: 0;
            content: "(" counter(list, lower-alpha) ")";
          }
          > ol {
            list-style: none;
            counter-reset: list;
            margin: $vertical-margin 0;
            > li {
              counter-increment: list;
              padding-left: $number-padding;
              margin: $vertical-margin / 2 0;
              position: relative;
              &:before {
                position: absolute;
                left: 0;
                content: "(" counter(list, lower-roman) ")";
              }
              > ol {
                list-style: none;
                counter-reset: list;
                margin: $vertical-margin / 2 0;
                > li {
                  counter-increment: list;
                  padding-left: $number-padding;
                  margin: $vertical-margin / 4 0;
                  position: relative;
                  &:before {
                    position: absolute;
                    left: 0;
                    content: counter(list) ". ";
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}
