/*------------------------------------*\
    #CKEDITOR DIALOG
\*------------------------------------*/

.cke_dialog {
  width: auto;
  //.c-ckeditor-dialog--token-insert & {
  //  @include mq(md) {
  //    max-width: 600px;
  //  }
  //}
}