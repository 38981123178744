/*------------------------------------*\
    #AUTOCOMPLETE DELUXE
\*------------------------------------*/

a.autocomplete-deluxe-single:hover {
  text-decoration: none;
}

.ui-autocomplete .ui-state-hover {
  padding: 0;
  margin: 0;
}

.autocomplete-deluxe-container {
  clear: both;
  position: relative;
  padding: 0 10px !important;
  border: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  background-color: #fff;
  @include mq(lg) {
    clear: none;
  }
}

.autocomplete-deluxe-container input.autocomplete-deluxe-form,
.autocomplete-deluxe-item {
  border: 1px solid transparent;
  @include font-size($font-size-12, 1);
  margin: 6px 6px 6px 0;
  padding: 8px;
  height: calc(16px + 1em);
  @include mq(sm) {
    margin-top: 7px;
  }
  @include mq(md) {
    @include font-size($font-size-16, 1);
    margin-top: 10px;
    padding: 10px;
    height: calc(20px + 1em);
  }
}

.autocomplete-deluxe-container input.autocomplete-deluxe-form {
  display: inline-block;
  width: 20px;
  float: left;
  display: inline-block;
  border-radius: 0;
  @include attention {
    @include setPropFromVar(border-color, $var-color-border);
    box-shadow: none !important;
  }
}

.autocomplete-deluxe-value-container {
  display: none;
}

div.autocomplete-deluxe-container input.autocomplete-deluxe-form-single  {
  width: 90%;
  border: none;
}

.autocomplete-deluxe-search {
  margin: 0px 0px 4px 0px;
  padding: 3px 4px;
  position: relative;
  white-space: nowrap;
  z-index: 1010;
}

span.autocomplete-deluxe-value-delete {
  display: inline-block;
  float: right;
  width: 18px;
  position: relative;
  &:before {
    position: relative;
    vertical-align: middle;
    top: -2px;
    content: '';
    @include svg-icon(cross, 0.75em);
  }
  user-select: none;
}

.autocomplete-deluxe-single-open {
  border: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  @include setPropFromVar(background-color, $var-color-bg-4);
}

div.autocomplete-deluxe-multiple {
  border-radius: $global-radius;
  position: relative;
  cursor: text;
  margin: 0;
  padding: 0;
  overflow: hidden;
  cursor: text;
}

div.autocomplete-deluxe-throbber {
  @include spinner(medium, blue, right);
  visibility: hidden;
  &.autocomplete-deluxe-open {
    @include delayed-visibility;
  }
}

.autocomplete-deluxe-item {
  float: left;
  @include setPropFromVar(color, $var-color-text-base);;
  cursor: default;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid $color-gray-93-blue-tint;
  @include mq(md) {
    //padding-left: 20px;
    //padding-right: 20px;
  }
}

.autocomplete-deluxe-item-delete {
  display: block;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  font-size: 0.4em;
  border: 1px solid transparent;
  transition: $global-transition-all;
  &:after {
    content: '';
    @include svg-icon(cross, $fill: $color-danger);
    position: absolute;
    right: 2px;
    top: 2px;
    opacity: 0;
    transition: $global-transition-all;
  }
  @include attention {
    @include setPropFromVar(border-color, $var-color-border);
    background-color: rgba(0,0,0,.05);
    &:after {
      opacity: 1;
    }
  }
}

.autocomplete-deluxe-item-focus {
  background: none repeat scroll 0 0 #D4D4D4;
}

.autocomplete-deluxe-highlight-char {
  color: $color-blue;
  font-weight: bold;
}
