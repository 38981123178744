/*------------------------------------*\
    #ICON BACKGROUND
\*------------------------------------*/

$overflow: $spacing-unit;

.c-icon-bg {
  position: relative;
  overflow: hidden;
}

.c-icon-bg__container {
  position: absolute !important;
  top: -$overflow;
  bottom: -$overflow;
  left: -$overflow;
  right: -$overflow;
  font-size: 6vw;
  @include mq(md) {
    font-size: 4vw;
  }
}

.c-icon-bg__icon {
  color: $color-blue-v-light;
  transition: $slow-transition-all;
  .c-icon-bg--hover & {
    &:hover {
      color: $color-blue-vv-light;
      transform: scale(1.05);
    }
  }
}