/*------------------------------------*\
    #AUCTION ITEM NODES
\*------------------------------------*/

.c-node-ai {
  position: relative;
  width: 100%;

  &--teaser {
    max-width: 300px;
    @include mq($from: md) {
      max-width: none;
    }
  }

  &--teaser,
  &--small-teaser {
    margin: 0 auto;
    border: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);
    html:not([data-theme="photographic"]):not([data-theme="dark"][data-ui="black"]) & {
      border-top: none;
    }
    @include setPropFromVar(background-color, $var-color-bg-1);
    height: 100%;
  }


  &--listing {
    margin: 0 auto;
    padding-left: 10px;
    border-bottom: 1px solid;
    @include setPropFromVar(border-color, $var-color-border-light);;
    @include setPropFromVar(background-color, $var-color-bg-1);
    :last-child > & {
      border-bottom: none;
    }
    @extend .o-wrapper--tight-1;
  }

  &--search-result {
    margin-bottom: -1px;
  }

  &--print {
    break-after: page;
    margin-top: $spacing-unit;
  }
}

.c-node-ai__wrapper {
  .c-node-ai--print & {
    border: 1px solid $color-gray-87-blue-tint;
    margin: 0 auto;
    padding: $spacing-unit $spacing-unit-med-large;
  }
}

.c-node-ai__offline-preview-wrapper {
  margin-top: $spacing-unit;
}

.c-node-ai__preview-mode-wrapper {
  text-align: center;
  margin: 0 $spacing-unit-small;
  padding-top: $spacing-unit-small;
  @include mq($from: md) {
    margin: 0;
  }
  @include mq($from: mlg) {
    padding: 0;
  }
}

.c-node-ai__preview-mode {
  display: inline-block;
  padding: $spacing-unit-small $spacing-unit;
  border: 1px solid $color-warning;
  @include setPropFromVar(color, $var-color-ai-header-text);
  @include font-size($font-size-12, 1.5);
  @include mq($from: md) {
    @include font-size($font-size-14);
  }
}

.c-node-ai__ajax-updating-wrapper {
  opacity: 1;
  transition: opacity $global-transition;
  .is-ajax-updating & {
    opacity: $opacity-updating;
  }
}

.c-node-ai__wrapping-link {
  .c-node-ai--search-result & {
    display: block;
    padding: $spacing-unit-tiny $spacing-unit-small;
    @include setPropFromVar(background-color, $var-color-bg-3);
    position: relative;
    border: 1px solid transparent;
    @at-root .c-node-ai[data-current]#{&},
    &:hover,
    &:focus {
      @include setPropFromVar(background-color, $var-color-bg-1);
      text-decoration: none;
      outline: none;
      @include setPropFromVar(border-color, $var-color-ui);
      z-index: 1;
    }
  }
}

.c-node-ai__edit-link {
}

.c-node-ai__header {
  .c-node-ai--full &,
  .c-node-ai--monitor & {
    @include setPropFromVar(background-color, $var-color-ai-header-bg-1);
  }
  .c-node-ai--listing & {
    display: flex;
    align-items: center;
  }
}

.c-node-ai__banner-image {
  text-align: center;
  padding-top: $spacing-unit;
  display: grid;
  > * {
    grid-area: 1/1/1/1;
  }
  .c-node-ai--print & {
    padding-top: 0;
  }
}

.c-node-ai__image {
  .c-node-ai--teaser &,
  .c-node-ai--small-teaser &,
  .c-node-ai--listing & {
    display: grid;
    position: relative;
    > a,
    > img {
      display: flex;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  .c-node-ai--search-result & {
    height: 50px;
    width: 50px;
    > img {
      display: flex;
      height: 100%;
      max-height: 100%;
    }
  }
  > a {
    display: block;
  }
  .c-node-ai--listing & {
    width: 75px;
    height: 75px;
    flex-shrink: 0;
    @include mq($from: mmd) {
      width: 96px;
      height: 96px;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.c-node-ai__status {
  .c-node-ai--full &,
  .c-node-ai--monitor & {
    height: 10px;
    width: 100%;
  }
  .c-node-ai--teaser & {
    height: 10px;
  }
  .c-node-ai--small-teaser & {
    height: 8px;
  }
  .c-node-ai--listing & {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
  .c-node-ai--listing & {
    width: 10px;
  }

  $status_colors: (
    offline: $color-gray-87-blue-tint,
    not-started: $color-gray-87-blue-tint,
    no-bids: ui,
    live-reserve-not-met: ui,
    bidding: ui,
    unavailable: $color-gray-80,
    ended: $color-gray-80,
    ended-no-bids: $color-gray-80,
    online-ended: $color-gray-80,
    winning: $color-green-bright,
    processing: $color-gray-80,
    expired-reserve-not-met: $color-gray-80,
    won: $color-green-bright,
    online-won: $color-green-bright,
    losing: $color-red-bright,
    lost: $color-red-bright,
    online-lost: $color-red-bright,
  );
  @each $variant in (full, monitor, teaser, small-teaser, listing) {
    @each $status, $color in $status_colors {
      $ai-variant-selector: '.c-node-ai--#{$variant}';
      $attr-selector: '[data-status="#{$status}"]';
      #{$ai-variant-selector}#{$attr-selector} &--#{$variant} {
        @if ($color == ui) {
          @include setPropFromVar(background-color, $var-color-ui);
          // Don't use the green ui as a status color
          html[data-ui="green"] & {
            background-color: $color-blue;
          }
        }
        @else {
          background-color: $color;
        }
      }
    }
  }

}

.c-node-ai__title {
  overflow: hidden;
  word-break: break-word;
  .c-node-ai--full .c-node-ai__header & {
    text-align: center;
    margin: 0;
    padding: $spacing-unit;
    @include setPropFromVar(color, $var-color-ai-header-text);
    @include mq(md) {
      html:not([data-theme="photographic"]) & {
        padding: $spacing-unit $spacing-unit-huge $spacing-unit-large;
      }
    }
  }
  .c-node-ai--monitor .c-node-ai__header & {
    text-align: center;
    margin: 0;
    padding-bottom: $spacing-unit;
    @include setPropFromVar(color, $var-color-ai-header-text);
    @include mq(md) {
      //html:not([data-theme="photographic"]) & {
      //  padding: $spacing-unit $spacing-unit-huge $spacing-unit-large;
      //}
    }
  }
  .c-node-ai--offline & {
    margin: 0;
  }
  .c-node-ai--teaser-view & {
    @extend .c-heading--gamma;
    html[data-theme="dark"] & a {
      color: white;
    }
  }
  .c-node-ai--teaser &,
  .c-node-ai--small-teaser & {
    flex-grow: 1;
  }
  .c-node-ai--small-teaser & {
    margin-bottom: $spacing-unit-tiny;
    @include font-size($font-size-14, 1.5);
    @include mq(sm) {
      @include font-size($font-size-15, 1.4);
    }
    @include mq(md) {
      @include font-size($font-size-16, 1.5);
      margin-bottom: $spacing-unit-small;
    }
    @include mq(lg) {
      @include font-size($font-size-18, 1.4);
    }
  }
  .c-node-ai--listing & {
    padding: $spacing-unit-tiny 0;
    margin: 0 $spacing-unit-small 0;
    @include mq($until: sm) {
      @include font-size($font-size-15, 1.4);
    }
    @include mq($from: mmd) {
      padding: $spacing-unit-small 0;
    }
  }
  .c-node-ai--print & {
    margin-bottom: $spacing-unit;
  }
}

.c-node-ai__summary-wrap {
  @include setPropFromVar(background-color, $var-color-ai-header-bg-2);
  @include setPropFromVar(color, $var-color-ai-header-text);
  margin-bottom: $spacing-unit;
  @include mq(md) {
    @include font-size($font-size-18);
    margin: 0;
    display: flex;
    justify-content: space-between;
  }
  a:not(.c-button) {
    @include setPropFromVar(color, $var-color-ai-header-link);
  }
}

.c-node-ai__summary-item {
  padding: $spacing-unit;
  border-bottom: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  text-align: center;

  &--timer {
    html.no-js & {
      display: none;
    }
  }
  &--price {
    .c-node-ai.is-unavailable & {
      border-bottom: none;
    }
  }
  &--buy-now {
    .c-node-ai[data-status="not-started"] & {
      visibility: hidden;
      position: absolute;
    }
  }
  &--bid {
    border-bottom: none;
    &--anon {
      align-items: center;
    }
    .c-node-ai[data-status="not-started"] & {
      visibility: hidden;
      position: absolute;
    }
  }
  &--sharing {
    display: none;
  }
  @include mq(md) {
    flex: 1 1 auto;
    padding: $spacing-unit-med-large;
    border-bottom: none;
    &--bid {
      max-width: 50%;
    }
    &--timer,
    &--price {
      border-right-width: 1px;
      border-right-style: solid;
    }
    &--price {
      min-width: 210px;
    }
    &--buy-now {
      [data-uid="0"] &,
      &.c-node-ai__summary-item--buy-now-awaiting-checkout {
        display: flex;
        align-items: center;
      }
    }
  }
  @include mq(mdl) {
    padding: $spacing-unit-med-large $spacing-unit;
    html[data-theme="photographic"] & {
      padding: $spacing-unit-med-large $spacing-unit;
    }
  }
  @include mq(mlg) {
    padding: $spacing-unit-med-large;
    &--buy-now,
    &--bid {
      border-right-width: 1px;
      border-right-style: solid;
    }
    &--sharing {
      display: flex;
      justify-content: center;
      padding: $spacing-unit-med-large $spacing-unit;
    }
  }
  @include mq(lg) {
    padding: $spacing-unit-med-large $spacing-unit-large;
    &--sharing {
      padding: $spacing-unit-med-large $spacing-unit;
    }
  }

  .c-node-ai--monitor & {
    @include mq(md) {
      padding: $spacing-unit;
    }
    @include mq(mdl) {
      padding: $spacing-unit;
    }
    @include mq(mlg) {
      padding: $spacing-unit;
    }
    @include mq(lg) {
      padding: $spacing-unit;
    }
  }

  &:last-child {
    border-right: none !important;
  }
}

.c-node-ai__sharing {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-node-ai__details-wrap {
  padding: $spacing-unit-small $spacing-unit-small 0 $spacing-unit;
  .c-node-ai--rtl & {
    direction: rtl;
  }
  @include mq(md) {
    padding: $spacing-unit $spacing-unit 0 $spacing-unit;
    @include setPropFromVar(background-color, $var-color-bg-1);
    html[data-theme="photographic"] & {
      padding-top: $spacing-unit-large;
    }
  }
  .c-node-ai--full > &,
  .c-node-ai--monitor > & {
    &:after {
      content: '';
      display: block;
      margin-top: $spacing-unit-small;
      border-bottom: 1px solid;
      @include setPropFromVar(border-color, $var-color-border);
    }
  }
  .c-node-ai--teaser &,
  .c-node-ai--small-teaser & {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .c-node-ai--teaser & {
    padding: $spacing-unit;
  }
  .c-node-ai--small-teaser & {
    padding: $spacing-unit-small;
    @include mq(md) {
      padding: $spacing-unit-med-small;
    }
  }

  .c-node-ai--monitor & {
    margin-top: 0;
    padding-bottom: $spacing-unit;
    @include setPropFromVar(background-color, $var-color-bg-2);
  }

  .c-node-ai--print & {
    padding: 0 !important;
  }
}

.c-node-ai__winners-info {
  @extend .o-box;
  margin-bottom: $spacing-unit;
  &--success {
    @extend .o-box--green-bright;
  }
  &--warning {
    @extend .o-box--warning;
  }
}

.c-node-ai__winners-info-heading {
  .c-node-ai__winners-info--success & {
    color: $color-white;
  }
}

.c-node-ai__admin-info {
  margin-bottom: $spacing-unit-small;
}

.c-node-ai__admin-info-element {
  margin-bottom: $spacing-unit-small;
  @extend .o-box;
  &--success {
    @extend .o-box--success;
  }
  &--warning {
    @extend .o-box--warning;
  }
}

.c-node-ai__details {
  .c-node-ai--listing & {
    position: relative;
    padding: $spacing-unit-tiny $spacing-unit-small;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: -1px;
      border-top: 1px solid $color-gray-96-blue-tint;
      margin: 0 0 0 75px;
    }
    @include font-size($font-size-10, 1.5);
    @include setPropFromVar(color, $var-color-text-dark);
    @include mq(mmd) {
      @include font-size($font-size-16);
      &:before {
        display: none;
      }
    }
    @include mq($from: mmd) {
      flex: 0 0 50%;
      margin-left: auto;
    }
  }
}

.c-node-ai__detail-title {
  @include setPropFromVar(color, $var-color-text-base);
  @include setPropFromVar(font-family, $var-font-family-normal);
  font-weight: normal;
  margin-bottom: 0;
}

.c-node-ai__detail-data {
  vertical-align: middle;
  @include font-size($font-size-16);
}

.c-node-ai__slideshow {
  margin-bottom: $spacing-unit;
  .c-node-ai--monitor & {
    margin-bottom: 0;
    padding-top: $spacing-unit;
    @include setPropFromVar(background-color, $var-color-bg-2);
  }
  .c-node-ai--print & {
    position: relative;
    margin-bottom: 0px;
    padding-bottom: $spacing-unit;
  }
}

.c-node-ai__bidding-title {
  @include setPropFromVar(color, $var-color-ai-header-text);
}

.c-node-ai__bidding-details {
  .c-node-ai--teaser &,
  .c-node-ai--small-teaser & {
    margin-top: auto;
  }
}

.c-node-ai__timer,
.c-node-ai__price {
  .c-node-ai--teaser & {
    @include font-size($font-size-14, 1.5);
    @include mq(md) {
      @include font-size($font-size-15, 1.5);
    }
  }

  .c-node-ai--small-teaser & {
    @include font-size($font-size-12, 1.5);
    @include mq(md) {
      @include font-size($font-size-14, 1.5);
    }
  }

  .c-node-ai--listing & {
    vertical-align: middle;
    @include font-size($font-size-14);
    @include mq(md) {
      @include font-size($font-size-16);
    }
  }
}

.c-node-ai__timer-title--buy-now-exercised {
  .c-node-ai__summary-item & {
    @extend %c-heading--gamma;
    @include setPropFromVar(color, $var-color-ai-header-text);
  }
}

.c-node-ai__price,
.c-node-ai__bid-form {
  .c-node-ai--full .c-node-ai__summary-wrap &,
  .c-node-ai--monitor .c-node-ai__summary-wrap & {
    max-width: 290px;
    margin: 0 auto;
    @include mq($from: mmd) {
      max-width: none;
      margin: 0;
    }
  }
}

.c-node-ai__price {
  .c-node-ai--small-teaser & {
    display: inline-block;
  }
}

.c-node-ai__bidding {
  @include mq($from: md) {
    max-width: 500px;
    margin: 0 auto;
  }
}

.c-node-ai__more-link {
  .c-node-ai--listing & {
    @include mq(mmd) {
      top: 5px;
    }
  }
}

.c-node-ai__actions {
  position: absolute;

  .c-node-ai--full &,
  .c-node-ai--monitor & {
    right: 0;
    top: -26px;
    @include mq(md) {
      top: -29px;
    }
  }
  .c-node-ai--listing & {
    right: -1px;
    top: -1px;
  }
}

.c-node-ai__additional-wrap {
  padding: $spacing-unit;
  @include mq($from: md) {
    @include setPropFromVar(background-color, $var-color-bg-1);
  }
}

.c-node-ai__description {
  border-bottom: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  padding-bottom: $spacing-unit;
  margin-bottom: $spacing-unit;
  h1 {
    @extend .c-heading--alpha;
    margin-bottom: $spacing-unit;
  }
  h2 {
    @extend .c-heading--beta;
    margin-bottom: $spacing-unit;
    @include setPropFromVar(color, $var-color-text-base);;
  }
  h3 {
    @extend .c-heading--gamma;
  }
  h4 {
    @extend .c-heading--delta;
  }
  h5 {
    @extend .c-heading--epsilon;
  }
}

.c-node-ai__update {
  border-bottom: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  margin-bottom: $spacing-unit;
}

.c-node-ai__update-time {
  color: $color-danger;
  @include setPropFromVar(font-family, $var-font-family-bold);
  @include setPropFromVar(font-weight, $var-font-weight-bold);
  @include font-size($font-size-12);
  @include mq(sm) {
    @include font-size($font-size-16);
  }
}

.c-node-ai__buyers-premium {
  border-bottom: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  margin-bottom: $spacing-unit;
  padding-bottom: $spacing-unit;
}

.c-node-ai__bid-now {
  display: block;
  margin: $spacing-unit 0;
}

.c-node-ai__end-time {
  display: inline-block;
  .c-node-ai--full .c-node-ai__summary-wrap &,
  .c-node-ai--monitor .c-node-ai__summary-wrap & {
    padding-bottom: $spacing-unit;
  }

  .c-node-ai__about & {
    margin-bottom: $spacing-unit-small;
    &.c-node-ai__end-time--with-extension {
      margin-bottom: 0;
      @include mq(md) {
      }
    }
  }
}

.c-node-ai__start-time-time,
.c-node-ai__end-time-time {
  @include setPropFromVar(font-family, $var-font-family-bold);
  @include setPropFromVar(font-weight, $var-font-weight-bold);
  display: block;
  @include mq(sm) {
    display: none;
  }
  &--long {
    display: none;
    @include mq(sm) {
      display: inline;
    }
  }
}

.c-node-ai__end-time-timezone {
  text-align: right;
  @include setPropFromVar(font-family, $var-font-family-default);
  @include setPropFromVar(color, $var-color-text-base);;
  @include font-size($font-size-14, 1.5);
  @include mq(sm) {
    display: block;
    .c-node-ai__end-time--with-extension & {
      padding-right: 8px;
    }
  }
}

.c-node-ai__footnote {
  display: flex;
  @include setPropFromVar(font-family, $var-font-family-default);
  @include setPropFromVar(color, $var-color-text-base);;
  margin-top: $spacing-unit-tiny;
  @include font-size($font-size-12, 1.5);
  &--currency {
    @include setPropFromVar(font-family, $var-font-family-bold);
    @include setPropFromVar(font-weight, $var-font-weight-bold);
  }
}

.c-node-ai__footnote-marker {
  padding-right: 2px;
  flex: 0 1 auto;
}

.c-node-ai__footnote-text {
  flex: 1 1 auto;
}

.c-node-ai__buy-now {
  margin: $spacing-unit auto;
  &--awaiting-checkout {
    border-color: $color-warning;
  }
  .c-node-ai__summary-item & {
    margin: 0 auto;
    max-width: 540px;
    @include mq($from: md) {
      max-width: 340px;
    }
  }
}

.c-node-ai__buy-now-title {
  .c-node-ai__summary-item & {
    @extend %c-heading--gamma;
    @include setPropFromVar(color, $var-color-ai-header-text);
  }
}

.c-node-ai__buy-now-button {
  margin-top: $spacing-unit-small;
  .c-node-ai__summary-item & {
    margin-top: 0;
    margin-bottom: $spacing-unit-small;
  }
}

.c-node-ai__buy-now-extra {
  display: none;
  @include mq($from: sm) {
    display: inline;
  }
  @include mq($from: md) {
    display: none;
  }
  @include mq($from: mlg) {
    display: inline;
  }
}

.c-node-ai__related {
  margin-top: $spacing-unit-large;
  padding-top: $spacing-unit;
  border-top: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  @include mq(mlg) {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }
}

.c-node-ai__content {
  transitiion: $fade-transition;
  opacity: 1;
  .is-progress-disabled &,
  .is-ajax-loading & {
    opacity: $opacity-updating;
  }
  .c-node-ai--teaser &,
  .c-node-ai--small-teaser & {
    display: flex;
    flex-direction: column;
    height: 100%;
    @include font-size($font-size-15);
  }
  .c-node-ai--listing & {
    @include font-size($font-size-12);
    @include mq(mmd) {
      display: flex;
      align-items: center;
      @include font-size($font-size-15);
    }
  }
  .c-node-ai--search-result & {
    @include font-size($font-size-12, 1.5);
    @include mq(lg) {
      @include font-size($font-size-14, 1.5);
    }
  }
}

.c-node-ai__bid-list {
  &--empty {
    .c-node-ai--monitor & {
      padding-top: $spacing-unit;
    }
  }
}

.c-node-ai__footer {
  display: none;
  @media (min-height: 60em) {
    display: block;
  }
}

.c-node-ai__mousetrap-hint {
  position: absolute;
  .c-node-ai--search-result &,
  .c-node-ai__details-wrap &,
  .c-node-ai__page-nav--footer & {
    top: 6px;
    right: 6px;
  }
  .c-node-ai__page-nav--header & {
    top: 4px;
    right: 4px;
  }
}



































