/*------------------------------------*\
    #AUCTION PAGE NODES
\*------------------------------------*/

.c-node-ap {
  &--admin-teaser {
    position: relative;
    margin-bottom: $spacing-unit;
    background-color: white;
    padding: $spacing-unit-med-small;
    @include mq(md) {
      padding: $spacing-unit;
    }
  }

  &--admin-overview {
    background-color: white;
  }

  &--bidder-view {
    position: relative;
    background-color: #fff;
    padding: $spacing-unit;
    margin-bottom: $spacing-unit;
  }
}

.c-node-ap__header {
  @include clearfix;

  .c-node-ap--admin-teaser & {
    margin-bottom: $spacing-unit-small;
    @include mq(sm) {
      padding: $spacing-unit-tiny $spacing-unit-small 0;
    }
    @include mq(md) {
      border-bottom: 1px solid;
      @include setPropFromVar(border-color, $var-color-border);
      padding: $spacing-unit-med-large $spacing-unit $spacing-unit-small $spacing-unit;
    }
  }

  .c-node-ap--offline & {
    margin-top: $spacing-unit;
  }

  .c-node-ap--monitor & {
    display: none;
  }
}

.c-node-ap__offline-preview-wrapper {
  margin-top: $spacing-unit;
}

.c-node-ap__preview-mode-wrapper {
  text-align: center;
  margin-bottom: $spacing-unit;
}

.c-node-ap__preview-mode {
  display: inline-block;
  margin-top: $spacing-unit-small;
  padding: $spacing-unit-small $spacing-unit;
  border: 1px solid $color-warning;
  @include font-size($font-size-12, 1.5);
  @include mq($from: md) {
    @include font-size($font-size-14);
  }
}

.c-node-ap__banner {
  text-align: center;
  clear: both;
  img {
    width: 100%;
  }
  .c-node-ap--full &,
  .c-node-ap--offline & {
    margin-bottom: $spacing-unit;
    @include mq(md) {
      margin-bottom: $spacing-unit-med-large;
    }
  }
}

.c-node-ap__teaser-image {
  &--top {
    margin-bottom: $spacing-unit-med-large;
    @include mq(md) {
      display: none;
    }
  }
}

.c-node-ap__view-links {
  float: right;
  padding: 0 0 $spacing-unit-small $spacing-unit-small;
  margin: -#{$spacing-unit-tiny} -#{$spacing-unit-small} 0 0;
  @include mq(sm) {
    margin: 0;
  }
}

.c-node-ap__view-link {
  @include mq($until: sm) {
    display: none;
    &:nth-child(2) {
      display: inline;
      border: none;
      background-color: transparent;
      @include font-size($font-size-18);
      @include attention {
        color: inherit;
      }
    }
  }
}

.c-node-ap__title {
  clear: both;
  margin-bottom: $spacing-unit;
  @include mq(md) {
    clear: none;
  }
  .c-node-ap--rtl & {
    direction: rtl;
  }
  .c-node-ap--admin-teaser & {
    clear: none;
    margin-bottom: $spacing-unit-small;
    @include mq(md) {
      padding-top: $spacing-unit-small;
      margin-bottom: $spacing-unit;
    }
  }
}

.c-node-ap__intro {
  margin-bottom: $spacing-unit;
  @include mq(md) {
    margin-bottom: $spacing-unit-large;
  }
}

.c-node-ap__contact {
  margin-bottom: $spacing-unit;
}

.c-node-ap__terms {
  margin-bottom: $spacing-unit;
}

.c-node-ap__extra-content {
  margin-bottom: $spacing-unit;
}

.c-node-ap__intro,
.c-node-ap__terms,
.c-node-ap__extra-content,
.c-node-ap__sharing-text {
  .c-node-ap--rtl & {
    direction: rtl;
  }
  h1 {
    @extend .c-heading--alpha;
    margin-bottom: $spacing-unit;
  }
  h2 {
    @extend .c-heading--beta;
    margin-bottom: $spacing-unit;
    @include setPropFromVar(color, $var-color-text-base);;
  }
  h3 {
    @extend .c-heading--gamma;
  }
  h4 {
    @extend .c-heading--delta;
  }
  h5 {
    @extend .c-heading--epsilon;
  }
}

.c-node-ap__admin-details {
  @include font-size($font-size-14);
  @include mq(sm) {
    margin-bottom: $spacing-unit-small;
    @include font-size($font-size-15);
  }
  @include mq(lg) {
    @include font-size($font-size-16);
  }
}

.c-node-ap__item-details,
.c-node-ap__settings-overview {
  margin-bottom: $spacing-unit;
  table {
    margin-bottom: 0;
  }
  th:last-child,
  td:last-child {
    text-align: right;
  }
}

.c-node-ap__more-link {
  display: block;
  margin-bottom: $spacing-unit;
  @include mq(md) {
    margin-bottom: 0;
  }
  .c-node-ap--admin-teaser & {
    @include mq(sm) {
      float: right;
      position: relative;
      top: -$spacing-unit-small;
    }
  }
}

.c-node-ap__timers {
  text-align: center;
  .c-node-ap--admin-overview & {
    margin-top: $spacing-unit;
  }
  .c-node-ap--admin-teaser & {
    display: none;
    @include mq(md) {
      display: inline-block;
    }
  }
}

.c-node-ap__auction-summary {
  display: flex;
}

.c-node-ap__timer-wrapper {
  .c-node-ap--full & {
    margin-bottom: $spacing-unit;
  }
}

.c-node-ap__timer {
  @include font-size($font-size-18);
  .c-node-ap--admin-teaser & {
    display: inline-block;
    margin-bottom: $spacing-unit;
  }
  .c-node-ap--admin-overview & {
    margin: $spacing-unit 0;
    display: inline-block;
    @include mq(md) {
      margin-bottom: $spacing-unit-large;
      margin-top: 0;
      display: block;
    }
  }
  &--auction-summary,
  &--monitor-view {
    @extend .o-box;
    @extend .o-box--bordered;
    padding-bottom: $spacing-unit-tiny;
  }
  .c-node-ap__auction-summary--monitor & {
    padding-top: $spacing-unit-small;
  }
}

.c-node-ap__toggle {
  .c-node-ap--admin-teaser & {
    margin: 0 0 $spacing-unit-small 0;
    @include mq(md) {
      margin: $spacing-unit-small 0;
    }
  }
}

.c-node-ap__item-count {
  text-align: center;
  @include mq(lg) {
    text-align: left;
  }

  .c-node-ap--admin-teaser & {
    display: none;
    @include mq(md) {
      display: block;
      margin-top: $spacing-unit;
    }
  }
}

.c-node-ap__sidebar-image {
  margin-bottom: $spacing-unit;
  img {
    width: 100%;
  }
}

.c-node-ap__recent-bids {
  border-top: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  padding-top: $spacing-unit;
  margin-top: $spacing-unit;
}

.c-node-ap__donate-now {
  margin-bottom: $spacing-unit;
  &--top,
  &--bottom {
  }
}

.c-node-ap__sharing {
  &--top,
  &--bottom {
    margin-bottom: $spacing-unit;
  }
  .c-node-ap__donate-now + &--sidebar {
    margin-top: -1px
  }
}

.c-node-ap__notes {
}

.c-node-ap__note {
  @include font-size($font-size-12, 1.5);
  margin-bottom: $spacing-unit-med-tiny;
}

.c-node-ap__mousetrap-hint {
  position: absolute;
  .c-node-ap--admin-teaser & {
    top: $spacing-unit-small;
  }
}
.c-node-ap__auction-results {
  @extend .o-box;
  @extend .o-box--bordered;
  display: inline-block;
  //.c-node-ap--monitor & {
  //  float: right;
  //  text-align: right;
  //  margin: 0 0 $spacing-unit $spacing-unit;
  //}
  .c-node-ap--full & {
    margin-bottom: $spacing-unit;
  }
  .c-node-ap__auction-summary & {
    margin-right: $spacing-unit-med-large;
    //padding: $spacing-unit-small $spacing-unit;
    //display: flex;
    //align-items: center;
  }
  .c-node-ap__auction-summary--monitor & {
    padding-top: $spacing-unit-small;
    padding-bottom: $spacing-unit-small;
  }
}

.c-node-ap__auction-total {
  .c-node-ap--auction-results & {
    @extend .o-box;
    @extend .o-box--bordered;
    display: inline-block;
    float: right;
    text-align: right;
    margin: 0 0 $spacing-unit $spacing-unit;
  }
}

.c-node-ap__auction-total-wrapper {
  .c-node-ap__auction-results--monitor-view & {
    padding: 0 $spacing-unit-small;
    align-items: baseline;
  }
}

.c-node-ap__auction-total-amount-wrapper {
  .c-node-ap__auction-summary--monitor & {
    display: flex;
    align-items: baseline;
    text-align: center;
    > * {
      //margin: 0 $spacing-unit-small;
    }
  }
}

.c-node-ap__fundraising-target {
  margin-bottom: $spacing-unit-small;
}

.c-node-ap__totalizer {
  margin-top: $spacing-unit-small;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance: none;
  height: $spacing-unit-med-small;
  border-radius: $spacing-unit-small;
  border: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  &::-webkit-progress-bar {
    overflow: hidden;
    border-radius: $spacing-unit-small;
    @include setPropFromVar(background-color, $var-color-bg-1);
  }
  &::-webkit-progress-value {
    border-top-left-radius: $spacing-unit-small;
    border-bottom-left-radius: $spacing-unit-small;
    @include setPropFromVar(background-color, $var-color-ui);
  }
  &::-moz-progress-bar {
    @include setPropFromVar(background-color, $var-color-ui);
  }
}

.c-node-ap__auction-total-components {
  .c-node-ap__auction-summary--monitor & {
    display: flex;
    align-items: baseline;
    justify-content: center;
  }
}

.c-node-ap__auction-total-component-donations {
  .c-node-ap__auction-summary--monitor & {
    margin-left: $spacing-unit;
  }
}

.c-node-ap__auction-total-label {
  @extend .c-heading--delta-l;
  margin-bottom: $spacing-unit-small;
}

.c-node-ap__auction-total-amount {
  @include setPropFromVar(font-family, $var-font-family-bold);
  @include setPropFromVar(font-weight, $var-font-weight-bold);
  @include font-size(24px, 1.5);
  .c-node-ap--full & {
    @include setPropFromVar(color, $var-color-heading-gamma);
    @include mq($from: md) {
      @include font-size(32px, 1.5);
    }
  }
  .c-node-ap__auction-summary--monitor & {
    @include setPropFromVar(color, $var-color-heading-delta);
  }
}

.c-node-ap__auction-total-component-amount {
  display: inline;
  @include setPropFromVar(font-family, $var-font-family-bold);
  @include setPropFromVar(font-weight, $var-font-weight-bold);
}

.c-node-ap__donations-monitor {
  .c-node-ap__auction-total + & {
    margin-top: $spacing-unit-small;
    padding: $spacing-unit-small $spacing-unit-tiny $spacing-unit-tiny;
    @include setPropFromVar(background-color, $var-color-bg-1);
  }
}







































