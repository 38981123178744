/* ==========================================================================
   #BOX
   ========================================================================== */

/**
 * The box object simply boxes off content. Extend with cosmetic styles in the
 * Components layer.
 *
 * 1. So we can apply the `.o-box` class to naturally-inline elements.
 */

@mixin o-box {
  @include inuit-clearfix();
  display: block; /* [1] */
  padding: $spacing-unit;
  break-inside: avoid;

  > :last-child {
    margin-bottom: 0;
  }
}

.o-box {
  @include o-box;
}


/* Size variants
   ========================================================================== */

.o-box--flush {
  padding: 0;
}

.o-box--tiny {
  padding: $spacing-unit-tiny;
}

.o-box--small {
  padding: $spacing-unit-small;
}

.o-box--med-large {
  padding: $spacing-unit-med-large;
}

.o-box--large {
  padding: $spacing-unit-med-large $spacing-unit-large $spacing-unit-large;
}

.o-box--huge {
  padding: $spacing-unit-large $spacing-unit-huge;
}

.o-box--v-huge {
  padding: $spacing-unit-huge $spacing-unit-v-huge $spacing-unit-v-huge;
}


/* Responsive
   ========================================================================== */
.o-box\@sm {
  @include mq(sm) {
    @include o-box;
    padding: $spacing-unit;
  }
}
.o-box\@md {
  @include mq(md) {
    @include o-box;
    padding: $spacing-unit;
  }
}
.o-box--med-large\@md {
  @include mq(md) {
    @include o-box;
    padding: $spacing-unit-med-large;
  }
}
.o-box--large\@md {
  @include mq(md) {
    @include o-box;
    padding: $spacing-unit-med-large $spacing-unit-large $spacing-unit-large;
  }
}
.o-box--huge\@md {
  @include mq(md) {
    @include o-box;
    padding: $spacing-unit-large $spacing-unit-huge $spacing-unit-huge;
  }
}

/* Styles
   ========================================================================== */
@mixin o-box--inline {
  display: inline-block;
  padding: $spacing-unit-tiny $spacing-unit-small;
  background-color: white;
  border: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  margin: 0 $spacing-unit-small;
}

.o-box--inline {
  @include o-box--inline;
  &\@md {
    @include mq(md) {
      @include o-box--inline;
    }
  }
}

@mixin o-box--bg {
  position: relative;
  margin: 0 $spacing-unit-small;
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: -$spacing-unit-small;
    bottom: -$spacing-unit-small;
    left: -$spacing-unit-small;
    right: -$spacing-unit-small;
    background-color: white;
    border: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);
  }
}

.o-box--bg {
  @include o-box--bg;
  &\@md {
    @include mq(md) {
      @include o-box--bg;
    }
  }
}

@mixin o-box--bordered {
  border: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  border-radius: $global-radius;
  @include setPropFromVar(background-color, $var-color-bg-3);
}
.o-box--bordered {
  @include o-box--bordered;
  &\@sm {
    @include mq(sm) {
      @include o-box--bordered;
    }
  }
  &\@lmd {
    @include mq($until: md) {
      @include o-box--bordered;
    }
  }
}

@mixin o-box--green-bright {
  background-color: $color-green-bright;
  color: white;
  a {
    color: white;
    font-family: "AvenirLTPro-Heavy", sans-serif;
  }
}
.o-box--success,
.o-box--green-bright {
  @include o-box--green-bright;
  &\@md {
    @include mq(md) {
      @include o-box--green-bright;
    }
  }
}

@mixin o-box--warning {
  @include setPropFromVar(background-color, $var-color-bg-3);
  border: 1px solid $color-warning;
}
.o-box--warning {
  @include o-box--warning;
  &\@md {
    @include mq(md) {
      @include o-box--warning;
    }
  }
}
