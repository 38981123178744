/*------------------------------------*\
    #HYBRID AUTH WIDGET
\*------------------------------------*/

.c-hybridauth {
  &--connect {
    margin-bottom: $spacing-unit;
  }
  &--disconnect {
    display: inline-block;
  }
  @include mq($until: mmd) {
    &--disconnect,
    &--new-connection {
      margin-bottom: $spacing-unit-small;
    }
  }
}

.c-hybridauth__list {
  list-style: none;
  margin: 0;
  text-align: left;
  position: relative;
}

.c-hybridauth__item {
  margin-bottom: $spacing-unit-tiny;
  @include mq(md) {
    margin-bottom: $spacing-unit-small;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.c-hybridauth__link {
  @extend .c-button;
  text-align: left;
  .c-hybridauth:not(.c-hybridauth--connect) & {
    @extend .c-button--small;
    padding-left: $spacing-unit-tiny;
  }
  .c-hybridauth--new-connection & {
    @extend .c-button--primary;
    padding-left: $spacing-unit-tiny;

  }
  .c-hybridauth--connect & {
    display: block;
    padding-left: $spacing-unit-tiny;
    @include mq(md) {
      padding-left: $spacing-unit-small;
    }
  }
  .c-hybridauth--disconnect & {
    display: inline-block;
    @include attention {
      @extend .c-button--cancel;
    }
  }

  min-width: calc(100vw - #{$spacing-unit-large} - #{$spacing-unit-tiny});
  @include mq(sm) {
    min-width: 280px;
  }
}

.c-hybridauth__link-text {
  padding-left: $spacing-unit;
  @include mq($from: md) {
    padding-left: 32px;
  }
  .c-hybridauth--connect & {
    @include mq($from: sm) {
      @include font-size($font-size-16, 1.2);
    }
    @include mq($from: md) {
      @include font-size($font-size-18);
    }
  }
}

.c-hybridauth__link-span-hover {
  display: none;
  .c-hybridauth__link:hover &,
  .c-hybridauth__link:focus & {
    display: initial;
  }
}

.c-hybridauth__link-span-no-hover {
  .c-hybridauth__link:hover &,
  .c-hybridauth__link:focus & {
    display: none;
  }
}

.c-hybridauth__label {
  @include mq($until: sm) {
    padding-left: 1em;
  }
}

.c-hybridauth__icon-wrapper {
  background-color: white;
  padding: 4px;
  .c-hybridauth--connect & {
    margin-top: -1px;
    @include mq(md) {
      padding: $spacing-unit-tiny;
    }
  }
}

.c-hybridauth__icon {
  vertical-align: middle;
  text-align: center;
  @include font-size(16px, 1);
  @include mq($from: md) {
    @include font-size(20px, 1);
    .c-hybridauth--connect & {
      @include font-size(24px, 1);
    }
  }
}
