/*------------------------------------*\
    #DEFINITION_LIST
\*------------------------------------*/


.o-def-list {
  margin: 0;
  padding: 0;
  dt {
    margin: 0;
    padding: 0;
    .o-def-list:not(.o-def-list--no-colon) &:after {
      display: inline;
      content: ':';
    }
  }
  dd {
    margin: 0;
    padding: 0;
  }

  /**
   * Inline dl - takes effect at sm only
   */
  &--inline {
    @include inuit-clearfix;

    dt {
      @extend .c-heading--eta;
    }
    dd {
      margin-bottom: $spacing-unit-small;
    }

    @include mq(sm) {
      dt {
        float: left;
        clear: left;
        width: 45%;
      }
      dd {
        float: left;
        width: 55%;
        margin-bottom: 0;
      }
    }

    @include mq($from: lg) {
      dt {
        width: 30%;
      }
      dd {
        width: 70%;
      }
    }
  }

  /**
   * Table style - takes effect at sm only
   */
  &--tabular {
    @extend .o-def-list--inline;
    @include font-size($font-size-15, 1.5);
    @include mq(sm) {
      @include font-size($font-size-16);
      position: relative;
      dt {
        padding-bottom: $spacing-unit;
      }
      dd {
        padding-bottom: $spacing-unit;
      }
    }
  }

}

.o-def-list__term,
.o-def-list__desc {
  &--strong {
    @include setPropFromVar(font-family, $var-font-family-bold);
    @include setPropFromVar(font-weight, $var-font-weight-bold);
  }
}
