/* ==========================================================================
   #SPACING
   ========================================================================== */

/**
 * Utility classes to put specific spacing values onto elements. The below loop
 * will generate us a suite of classes like:
 *
 *   .u-margin-top {}
 *   .u-padding-left-large {}
 *   .u-margin-right-small {}
 *   .u-padding {}
 *   .u-padding-right-none {}
 */

/* stylelint-disable string-quotes */

$inuit-spacing-directions: (
        null,
        '-top',
        '-right',
        '-bottom',
        '-left',
);

$inuit-spacing-properties: (
        'padding',
        'margin'
);

$inuit-spacing-sizes: (
        null: $spacing-unit,
        '-tiny': $spacing-unit-tiny,
        '-small': $spacing-unit-small,
        '-med-large': $spacing-unit-med-large,
        '-large': $spacing-unit-large,
        '-huge': $spacing-unit-huge,
        '-none': 0
) !default;


@each $property in $inuit-spacing-properties {

  @each $direction in $inuit-spacing-directions {

    @each $size, $value in $inuit-spacing-sizes {

      .u-#{$property}#{$direction}#{$size} {
        #{$property}#{$direction}: $value !important;
      }

    }

  }

}

/* stylelint-enable string-quotes */

