/*------------------------------------*\
    #HEADER
\*------------------------------------*/

.c-header {
  @include use-default-fonts;
  @include setPropFromVar(background-color, $var-color-bg-1);
  border-top: 2px solid;
  @include setPropFromVar(border-color, $var-color-ui);

  @include mq(md) {
    text-align: left;
    border-top-width: 4px;
  }

  &--ai-full {
    padding-bottom: 0;
  }

  &.is-stuck {
    border-bottom: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);
    @include setPropFromVar(background-color, $var-color-bg-3);
  }
}

// Header top
.c-header__top {
  width: 100%;
  .c-header:not(.c-header--info) & {
    @include mq($until: md) {
      @include setPropFromVar(background-color, $var-color-bg-2);
      border-bottom: 1px solid;
      @include setPropFromVar(border-color, $var-color-border);;
    }
  }
  .c-header--ap-full &:last-child {
    margin-bottom: $spacing-unit-med-large;
  }
}

.c-header__top-inner,
.c-header__bottom-inner {
  padding: 0 $spacing-unit-small;
  @include mq(md) {
    padding-right: 0;
    @include clearfix;
  }
  //@include mq(xl) {
  //  margin-left: -$spacing-unit * 2;
  //  margin-right: -$spacing-unit * 2;
  //  padding: 0 $spacing-unit * 2.5;
  //}
}

// Flex container
.c-header__top-inner {
  border-bottom: 1px solid;
  @include setPropFromVar(border-color, $var-color-border-light);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq($from: md) {
    align-items: flex-start;
  }
  > * {
    flex: 1 1 0;
  }
}
// Flex item
.c-header__nav-toggle {
  text-align: left;
  @include mq($from: md) {
    display: none;
  }
}
// Flex item
.c-header__branding {
  flex-grow: 0;
  margin: $spacing-unit-med-large $spacing-unit $spacing-unit-med-large 0;
  @include mq($until: md) {
    margin: $spacing-unit-small auto !important;
  }
}
// Flex item
.c-header__main-nav {
  text-align: right;
  @include mq($from: md) {
    flex: 1 0 auto;
  }
}

.c-header__bottom {
  .c-header--ap-full & {
    @include mq($from: md) {
      padding-bottom: $spacing-unit-med-large;
    }
  }
}

.c-header__bottom-inner {
  .c-header__bottom--breadcrumbs & {
    display: flex;
    align-content: space-between;
    @include mq(md) {
      border-bottom: 1px solid;
      @include setPropFromVar(border-color, $var-color-border-light);
    }
  }
}

.c-header__breadcrumbs {
  text-align: left;
}

.c-header__bottom-link {
  text-align: right;
  padding: $spacing-unit-tiny 0;
  margin: auto 0 auto auto;
  @include mq(sm) {
    padding: $spacing-unit-small 0;
  }
}
