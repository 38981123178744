/*------------------------------------*\
    #CONTENT
\*------------------------------------*/

.c-content {
  position: relative;
  @include mq(md) {
    @include setPropFromVar(background-color, $var-color-bg-1);
  }
  &--legal {
    @include mq(md) {
      @include setPropFromVar(background-color, $var-color-bg-2);
      padding: $spacing-unit 0 $spacing-unit-huge;
    }
  }
  &--new-auction,
  &--new-auction-item {
    @include green-theme;
  }
  &--pricing-and-features {
    text-align: center;
  }
  &--print {
    width: 840px !important;
    margin: $spacing-unit-large auto 0;
    @media print {
      margin: 0 auto;
    }
  }
}

@mixin content-box {
  @include mq(sm) {
    @include setPropFromVar(background-color, $var-color-bg-1);
    margin-top: $spacing-unit;
    margin-bottom: $spacing-unit;
    padding-top: $spacing-unit;
    padding-bottom: $spacing-unit;
  }
  @include mq(md) {
    margin-top: $spacing-unit-large;
    margin-bottom: $spacing-unit-large;
    html[data-theme="dark"] & {
      border: 1px solid;
      @include setPropFromVar(border-color, $var-color-border);
    }
  }
  @include mq(lg) {
    margin-top: $spacing-unit-huge;
    margin-bottom: $spacing-unit-huge;
  }
}
.c-content--boxed {
  @extend .o-wrapper\@sm;
  @include content-box;
}

@each $i, $tight-wrapper in $tight-wrappers {
  .c-content--boxed-#{$i} {
    @extend .o-wrapper--tight-#{$i}\@sm;
    @include content-box;
  }
}

.c-content__tabs {
  border-bottom: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  margin-bottom: $spacing-unit;
  @include mq($from: md) {
    border: none;
    @include setPropFromVar(background-color, $var-color-bg-2);
    margin: -#{$spacing-unit} -#{$spacing-unit} $spacing-unit-med-large -#{$spacing-unit};
    position: relative;
    z-index: 1;
    html[data-theme="dark"] & {
      border-bottom: 1px solid;
      @include setPropFromVar(border-color, $var-color-border);
    }
  }
}

.c-content__title {
  clear: both;
  @include setPropFromVar(background-color, $var-color-bg-1);
  padding-top: $spacing-unit;

  @include mq(md) {
    clear: none;
    padding-top: $spacing-unit-med-large;
  }

  @each $i, $tight-wrapper in $tight-wrappers {
    .c-content--boxed-#{$i} & {
      @include mq($from: sm) {
        padding-top: 0;
      }
      @include mq($from: md) {
        padding-top: $spacing-unit;
      }
    }
  }

  .c-content--with-tabs & {
    padding-top: 0;
    @include mq($from: md) {
      padding-top: 0;
    }
  }

  .c-content--auction-admin:not([class*="c-content--boxed"]) & {
    @include mq(md) {
      .c-content:not([class*="c-content--boxed"]) & {
        padding: $spacing-unit-med-large $spacing-unit 0;
      }
    }
  }

  .c-content--my-auctions & {
    @include mq($until: md) {
      padding-top: $spacing-unit;
    }
  }

  .c-content--ai-full & {
    padding-top: $spacing-unit;
    margin-left: -$spacing-unit-small;
    @include mq(md) {
      padding-bottom: $spacing-unit;
      margin-left: 0;
    }
  }

  .c-content--print & {
    padding-top: 0;
  }

}

.c-content__messages {
  padding-top: $spacing-unit;
  margin-bottom: $spacing-unit-small;
  @extend .o-wrapper;
  .c-content:not([class*="c-content--boxed"]) & {
    @extend .o-wrapper--tight-4;
  }
  @each $i, $tight-wrapper in $tight-wrappers {
    .c-content--boxed-#{$i} & {
      @include mq(sm) {
        padding-top: $spacing-unit-small;
        margin-bottom: $spacing-unit;
      }
    }
  }
  .c-content:not([class*="c-content--boxed"]) .c-content__form-container & {
    padding: 0;
    margin-bottom: $spacing-unit;
  }

  .c-content--with-tabs & {
    padding-top: 0;
  }

  .c-content--home &,
  .c-content--ai-full & {
    @include mq($from: md) {
      margin-bottom: $spacing-unit;
    }
  }

  .c-content--ap-full & {
    margin-bottom: $spacing-unit;
    @include mq($from: lg) {
      margin-bottom: $spacing-unit-med-large;
    }
  }

  .c-content .c-content__main--checkout & {
    padding: 0;
    margin-bottom: $spacing-unit;
  }

  &--unpadded {
    padding: 0;
  }
  &--views-footer {
    margin-top: $spacing-unit;
    margin-bottom: 0;
  }
}

.c-content__top {
  @include mq($from: md) {
    padding-bottom: $spacing-unit-med-large;
  }
  .c-content--auction-admin & {
    padding-bottom: 0;
    @include mq(md) {
      padding-bottom: $spacing-unit-small;
    }
  }
  .c-content--admin:not([class*="c-content--boxed"]) & {
    @include mq(md) {
      @include setPropFromVar(background-color, $var-color-bg-2);
    }
  }
  .c-content--account-admin & {
    @include mq($until: md) {
      margin-bottom: $spacing-unit-small;
    }
  }
  .c-content--monitor & {
    padding-bottom: 0;
  }
  .c-content--print & {
    padding-bottom: 0;
  }
}

.c-content__main {

  .c-content:not(.c-content--standalone-form) & {
    padding-bottom: $spacing-unit;
    @include mq(lg) {
      padding-bottom: $spacing-unit-large;
    }
  }

  .c-content--info & {
    position: relative;
    z-index: 0;
    padding-bottom: 0;
  }

  .c-content--legal & {
    padding-top: $spacing-unit-large;
    @extend .o-wrapper--tight-4;
  }

  &--checkout,
  .c-content--admin:not([class*="c-content--boxed"]) & {
    @include mq(md) {
      @include setPropFromVar(background-color, $var-color-bg-2);
    }
  }

  &--my-home {
    @include setPropFromVar(background-color, $var-color-bg-2);
    padding-top: $spacing-unit;
    @include mq(md) {
      padding-top: $spacing-unit-med-large;
    }
  }

  &--my-auctions {
    @include setPropFromVar(background-color, $var-color-bg-2);
    padding: $spacing-unit 0;
    @include mq(md) {
      padding: $spacing-unit-large 0 0;
    }
  }

  &--auction-page-admin {
    padding-top: $spacing-unit-small;
    .c-content:not(.c-content--standalone-form) & {
      @include mq(md) {
        padding-bottom: $spacing-unit-large;
      }
      @include mq($from: mlg) {
        padding-bottom: $spacing-unit * 12;
      }
    }
  }

  &--auction-item-admin {
    @include mq(md) {
      padding-bottom: $spacing-unit-large;
    }
    @include mq($from: mlg) {
      padding-bottom: $spacing-unit * 8;
    }
  }

  &--auction-page-admin:not(&--items-admin),
  &--auction-item-admin {
    @include green-theme;
  }

  &--new-auction-item {
    padding-top: $spacing-unit;
  }

  &--items-admin {
    @include mq(md) {
      @include setPropFromVar(background-color, $var-color-bg-2);
    }
  }

  &--bidder-profile {
    @include mq(md) {
      padding-top: $spacing-unit-large;
    }
  }

  &--checkout,
  &--orders {
    @include mq(md) {
      padding-top: $spacing-unit-large;
    }
  }

  &--auction-page {
    padding-top: $spacing-unit-small;
    @include mq(md) {
      padding: 0 0 $spacing-unit-med-large 0;
    }
  }

  &--ai-full,
  &--incident-form {
    @include mq(md) {
      padding: $spacing-unit-med-large 0;
      @include setPropFromVar(background-color, $var-color-bg-2);
    }
  }

  .c-content--status & {
    @include mq(md) {
      padding: $spacing-unit 0;
      @include setPropFromVar(background-color, $var-color-bg-2);
    }
  }

  .c-content--print & {
    padding-bottom: 0 !important;
  }

}

.c-content__sidebar {
  .c-content--auction-item-admin & {
    @include mq(md) {
      margin-top: $spacing-unit-small;
    }
  }
  .c-content:not(.c-content--auction-item-admin) & {
    @include mq($from: md) {
      @include setPropFromVar(background-color, $var-color-bg-3);
    }
  }
}

.c-content__bottom {
  @include setPropFromVar(background-color, $var-color-bg-2);
  .c-content--print & {
    background-color: transparent;
  }
}

.c-content__fancybox {
  @include mq($from: md) {
    max-height: 80vh;
    overflow-y: scroll;
    min-width: 440px;
  }
  @include mq($from: mlg) {
    min-width: 600px;
  }
  @extend .o-box;
  @extend .o-box--med-large\@md;

  &--wrapper-2 {
    max-width: map-get($tight-wrappers, 2);
  }

  &--wrapper-3 {
    max-width: map-get($tight-wrappers, 3);
  }

  &--wrapper-4 {
    max-width: map-get($tight-wrappers, 4);
  }

  &--wrapper-5 {
    max-width: map-get($tight-wrappers, 5);
  }

  &--wrapper-6 {
    max-width: map-get($tight-wrappers, 6);
  }

  &--wrapper-7 {
    max-width: map-get($tight-wrappers, 7);
  }

  &--green {
    @include green-theme;
  }
  &--overflowing {
    overflow: visible !important;
  }

}

.c-content__section {
  .c-content--home &--banner {
    background-color: $color-blue;
    padding: 0 0 $spacing-unit-large;
    @include mq(md) {
      padding: $spacing-unit-large 0 $spacing-unit-huge;
    }
  }
  .c-content--home &--who {
    background-color: $color-blue-gray-3;
    text-align: center;
    padding: $spacing-unit-large 0 $spacing-unit;
    @include mq(md) {
      padding: $spacing-unit-v-huge 0 $spacing-unit-huge;
    }
  }
  .c-content--home &--how {
    @include blue-theme;
    padding: $spacing-unit-large 0 $spacing-unit;
    overflow: hidden;
    @include mq(md) {
      padding: $spacing-unit-huge 0 $spacing-unit-large;
    }
  }

  .c-content--how-it-works &--summary,
  .c-content--pricing-and-features &--pricing {
    padding: $spacing-unit 0;
    @include mq(sm) {
      padding: $spacing-unit-large 0;
    }
    @include mq(md) {
      padding: $spacing-unit-huge 0 $spacing-unit-large;
    }
  }

  .c-content--how-it-works &--steps {
    list-style: none;
    margin: 0;
  }

  .c-content--how-it-works &--bottom {
    text-align: center;
    max-width: 500px;
    margin: $spacing-unit auto $spacing-unit-large;
    @include mq($from: mmd) {
      margin: $spacing-unit auto;

    }
    @include mq(md) {
      max-width: none;
      position: relative;
    }
  }

  .c-content--pricing-and-features &--pricing-details {
    background-color: $color-blue;
    padding: $spacing-unit-large 0;
    @include mq(md) {
      padding: $spacing-unit-huge 0;
    }
  }

  .c-content--pricing-and-features &--one-price-all-the-features,
  .c-content--pricing-and-features &--features-detail {
    @include setPropFromVar(background-color, $var-color-bg-2);
    padding: $spacing-unit-large 0;
    @include mq(md) {
      padding: $spacing-unit-huge 0;
    }
  }

  .c-content--my-home & {
    @extend .o-box;
    @extend .o-box--large\@md;
    @include font-size($font-size-14);
    @include setPropFromVar(background-color, $var-color-bg-1);
    border: 1px solid;
    @include setPropFromVar(border-color, $var-color-border-light);
    margin-bottom: $spacing-unit;
    @include mq(md) {
      @include font-size($font-size-18, 1.6);
    }
    @include mq(lg) {
      margin-bottom: $spacing-unit-large;
    }
  }
  &--ai-edit-warning {
    @include font-size($font-size-12, 1.5);
    border: 1px solid $color-warning;
    padding: $spacing-unit-small;
    background-color: #fff;
    margin-bottom: $spacing-unit-small;
    @include mq($from: md) {
      margin-bottom: $spacing-unit;
      padding: $spacing-unit;
      @include font-size($font-size-15);
    }
  }
  &--collapse-wrapper {
    padding: 0 $spacing-unit-small;
    border-top: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);
    border-bottom: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);
    margin-bottom: -1px;
    &:last-child {
      &.is-toggled {
        padding-bottom: 1px; // Prevents collapsing
      }
    }
    @include mq($until: md) {
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      &.is-toggled {
        @include setPropFromVar(border-left-color, $var-color-border);;
        @include setPropFromVar(border-right-color, $var-color-border);;
        @include setPropFromVar(background-color, $var-color-bg-3);;
      }
    }
    @include mq($from: md) {
      padding: 0 $spacing-unit-med-large;
    }
  }
}

.c-content__subsection {
  &--reverse {
    color: white;
  }

  .c-content__section--who & {
    list-style: none;
    margin: $spacing-unit-small 0 0 15px;
    @supports (display: grid) {
      @include mq(md) {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        margin: 0 15px 0 0;
      }
    }
  }

  .c-content--how-it-works .c-content__section--summary & {
    text-align: center;
    @include mq(md) {
      text-align: left;
      columns: 2;
      column-gap: $spacing-unit-large;
    }
  }
  .c-content--how-it-works .c-content__section--steps & {
    text-align: center;
    padding: $spacing-unit-large 0;
    @include mq(md) {
      padding: $spacing-unit-huge 0;
    }
    &:nth-child(1) {
      background-color: $color-blue-gray-3;
    }
    &:nth-child(2) {
      background-color: $color-blue;
    }
    &:nth-child(3) {
      @include setPropFromVar(background-color, $var-color-bg-2);
    }
    @include mq(md) {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: calc(50% - #{$spacing-unit-v-huge});
        top: 0;
        bottom: 0;
        border-right: 1px solid white;
      }
      &:nth-child(1) {
        &:after {
          top: $spacing-unit-huge;
        }
      }
      &:nth-child(3) {
        &:after {
          border-color: $color-gray-91-blue-tint;
        }
      }
    }
  }

  .c-content__section--pricing & {
    text-align: center;
    margin-bottom: $spacing-unit-small;
  }

  .c-content--pricing-and-features .c-content__section--features-detail & {
    list-style: none;
    margin: 0;
    @include mq(md) {
      columns: 2;
      column-gap: $spacing-unit-med-huge;
    }
  }

  .c-content--my-home & {
    @include font-size($font-size-14);
    @include mq(md) {
      @include font-size($font-size-18, 1.6);
    }
  }
}

.c-content__heading {
  .c-content__section--reverse &,
  .c-content__subsection--reverse & {
    color: white;
  }

  .c-content--home .c-content__section--banner & {
    margin-top: $spacing-unit-large;
  }
  .c-content--home .c-content__section--who & {
    margin-bottom: $spacing-unit-med-large;
    @include mq(md) {
      margin-bottom: $spacing-unit-large;
    }
  }
  .c-content--home .c-content__section--how & {
    margin-bottom: $spacing-unit-med-large;
    text-align: center;
    @include mq(md) {
      margin-bottom: $spacing-unit-large;
    }
  }

  .c-content--how-it-works .c-content__section--summary & {
    text-align: center;
  }
  .c-content--how-it-works .c-content__subsection & {
    margin-bottom: $spacing-unit;
    @include mq(md) {
      position: relative;
      z-index: 1;
      margin-bottom: $spacing-unit-med-huge;
    }
  }

  .c-content--pricing-and-features .c-content__section--features-detail & {
    color: $color-blue;
  }

  .c-content--auction-item-admin & {
    margin-top: $spacing-unit-small;
    overflow: hidden;
    word-break: break-word;
  }
}

.c-content__subheading {
  .c-content--how-it-works .c-content__section--steps & {
    margin-bottom: 0;
  }
  .c-content__section--collapse-wrapper & {
    @extend .c-heading--gamma-l;
  }
  .c-content--auction-admin & {
    @extend .c-heading--beta;
    margin-bottom: $spacing-unit-small;
    clear: none;
  }
  .c-content--auction-page-admin & {
    @include mq(md) {
      margin-bottom: $spacing-unit;
    }
  }
  .c-content--auction-item-admin & {
    margin-bottom: 0;
  }
  .c-content--pricing-and-features .c-content__section--pricing & {
    @include setPropFromVar(font-family, $var-font-family-bolder);
    @include setPropFromVar(font-weight, $var-font-weight-bolder);
    margin-bottom: $spacing-unit;
    color: $color-blue;
    @include font-size(30px, 1.4);
    @include mq(md) {
      @include font-size(38px, 1.4);
    }
  }
  .c-content__section--pricing-details & {
    color: $color-blue-gray-2;
  }
}

.c-content__heading-bg {
  .c-content--how-it-works .c-content__subsection:nth-child(1) & {
    @include heading-bg($color-blue-gray-4, false);
  }
  .c-content--how-it-works .c-content__subsection:nth-child(2) & {
    @include heading-bg($color-blue-v-light, false);
  }
  .c-content--how-it-works .c-content__subsection:nth-child(3) & {
    color: $color-blue;
    @include heading-bg(white, false);
  }
}

.c-content__lead {
  .c-content--home .c-content__section--banner & {
    color: white;
    margin-bottom: $spacing-unit-large;
  }
  .c-content--pricing-and-features .c-content__section--trial & {
    color: white;
    @include mq(md) {
      position: relative;
      background-color: $color-blue-v-light;
      box-shadow: -15px 15px 0 $color-blue-shadow;
      padding: $spacing-unit-large $spacing-unit-v-huge $spacing-unit-med-huge;
      margin-bottom: $spacing-unit-med-large;
    }
  }
}

.c-content__cta {
  .c-content--pricing-and-features .c-content__section--trial & {
    margin-top: $spacing-unit-large;
    @include mq(md) {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -$spacing-unit;
    }
  }
  .c-content__main--account-admin & {
    @include mq(md) {
      position: absolute;
      right: $spacing-unit;
      top: $spacing-unit;
    }
  }
}

.c-content__lead {
  .c-content--home .c-content__section--banner & {
    color: white;
    margin-bottom: $spacing-unit-large;
  }
  .c-content--pricing-and-features .c-content__section--trial & {
    color: white;
    margin-bottom: $spacing-unit-large;
    @extend .o-box--huge\@md;
    @include mq(md) {
      background-color: $color-blue-v-light;
      box-shadow: -15px 15px 0 $color-blue-shadow;
      margin-bottom: 0;
    }
  }
}

.c-content__cta {
  .c-content--pricing-and-features .c-content__section--trial & {
    @include mq(md) {
      margin-top: -$spacing-unit-med-large;
    }
  }
}

.c-content__hidden-link {
  position: absolute;
  &--print-monitor-view,
  &--hide-image {
    right: $spacing-unit-tiny;
    top: -$spacing-unit;
  }
  &, a {
    font-size: $font-size-12;
    visibility: hidden;
    .c-content__main:hover &,
    .c-content__bottom:hover &,
    &:focus,
    &:hover {
      visibility: visible;
      text-decoration: none;
    }
    &:focus,
    &:hover {
      visibility: visible;
      text-decoration: underline;
    }
  }
}







































