/*------------------------------------*\
    #Links
\*------------------------------------*/

.c-link {
  &--quiet {
    @include attention(true) {
      color: inherit;
      @include setPropFromVar(font-family, $var-font-family-bold);
      @include setPropFromVar(font-weight, $var-font-weight-bold);
    }
  }
  &--no-underline {
    @include attention(true) {
      text-decoration: none;
    }
  }
}
