/*------------------------------------*\
    #TOGGLES
\*------------------------------------*/

$toggle-font-size-sm: $font-size-12;
$toggle-font-size-md: $font-size-14;
$toggle-height-sm: 2.5em;
$toggle-height-md: 46/14 * 1em;
$indicator-size-sm: 0.6 * $toggle-height-sm;
$indicator-size-md: 20/46 * $toggle-height-md;
$indicator-bg-height-md: 26/46 * $toggle-height-md;
$indicator-bg-width-md: $indicator-size-md * 2.5;
$indicator-spacing-sm: ($toggle-height-sm - $indicator-size-sm) / 2;
$indicator-spacing-md: ($indicator-bg-height-md - $indicator-size-md) / 2;

.c-toggle {
  display: inline-block;
  &--air-auction-page {
    margin-bottom: $spacing-unit-small;
  }
  @include mq(md) {
    width: 22em;
  }
}

.c-toggle__container {
  display: inline-block;
  position: relative;
  @include setPropFromVar(background-color, $var-color-bg-2);
  border-radius: $large-radius;
    border: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);;
  @include font-size($toggle-font-size-sm);
  line-height: $toggle-height-sm;
  padding: 2px $toggle-height-sm 0 0;
  margin: 0;
  .c-toggle--air-auction-item & {
    background-color: white;
  }
  @include mq(md) {
    padding: 0;
    border-radius: $global-radius;
      border: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);;
    width: 100%;
    @include font-size($toggle-font-size-md);
    line-height: $toggle-height-md;
    &--enabled {
      padding: 0;
    }
  }

}

.c-toggle__link {
  color: $color-gray-40;
  @include setPropFromVar(font-family, $var-font-family-normal);
  @include font-size($toggle-font-size-sm);
  line-height: $toggle-height-sm;
  padding: 0 $spacing-unit-small;

  @include mq(md) {
    padding: 0 $spacing-unit;
    height: $toggle-height-md;
    @include setPropFromVar(font-family, $var-font-family-bolder);
    @include setPropFromVar(font-weight, $var-font-weight-bolder);
    @include font-size($toggle-font-size-md);
    position: relative;
    width: 50%;
  }


  &.is-progress-disabled {
    &,
    &:hover,
    &:focus,
    &:active {
      color: $state-disabled-text;
    }
  }

  &--disable {
    display: none;

    &:hover,
    &:focus,
    &:active,
    &[disabled] {
      color: $color-gray-40;
    }

    @include mq(md) {
      display: inline-block;
      float: left;
      text-align: right;
      padding-right: $indicator-bg-width-md;
    }

    @at-root .c-toggle--disabled & {
      display: inline-block;
      color: $color-gray-40;

      &.is-progress-disabled {
        color: $state-disabled-text;
        pointer-events: none;
      }
    }

  }

  &--enable {
    color: $color-green;

    @include mq(md) {
      display: inline-block;
      float: right;
      text-align: left;
      padding-left: $indicator-bg-width-md;
    }

    &:hover,
    &:focus,
    &:active {
      color: $color-green-bright;
    }

    @at-root .c-toggle--disabled & {
      display: none;

      @include mq(md) {
        display: inline-block;
      }

    }
  }

  .c-ajax-progress {
    display: none;
  }

}

.c-toggle__button {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  position: absolute;
  border-radius: $indicator-size-sm / 2;
  top: 50%;
  margin-top: -$indicator-size-sm / 2;
  right: $indicator-spacing-sm;
  @include font-size($toggle-font-size-sm);
  line-height: 1;

  .c-ajax-progress--icon,
  .js-throbber {
    display: none;
  }

  @include mq(md) {
    @include font-size($toggle-font-size-md);
    line-height: 1;
    @include setPropFromVar(background-color, $var-color-bg-4);
    border: 1px solid;
    @include setPropFromVar(border-color, $var-color-border-heavy);
    width: $indicator-bg-width-md;
    height: $indicator-bg-height-md;
    border-radius: $indicator-bg-height-md / 2;
    left: 50%;
    top: 50%;
    margin-left: -$indicator-bg-width-md / 2;
    margin-top: -$indicator-bg-height-md / 2;

    &:active,
    &:hover,
    &:focus,
    &.active,
    &[disabled] {
      border-color: $color-green-dark;
      background-color: $color-green;
    }
    &[disabled] {
      &:hover,
      &:focus {
        background-color: $color-green !important;
        border-color: $color-green-dark !important;
      }
    }
  }

  @at-root .c-toggle--enabled & {
    @include mq(md) {
      background-color: $color-green;
      border: 1px solid $color-green-dark;
      left: 50%;
      right: auto;
      @include attention {
        background-color: $color-green-bright;
        border-color: $color-green;
      }
    }
  }

  .c-ajax-progress {
    display: none;
  }

}

.c-toggle__indicator {
  display: inline-block;
  vertical-align: middle;
  width: $indicator-size-sm;
  height: $indicator-size-sm;
  border-radius: $indicator-size-sm / 2;
  background-color: white;
    border: 1px solid;
  @include setPropFromVar(border-color, $var-color-border-heavy);;

  @include mq(md) {
    position: absolute;
    left: $indicator-spacing-md;
    top: 50%;
    width: $indicator-size-md;
    height: $indicator-size-md;
    border-radius: $indicator-size-md / 2;
    margin-top: -$indicator-size-md / 2;
    background-color: white;

    @at-root .c-toggle__button.is-progress-disabled & {
      pointer-events: none;
      border-top: 1px solid $color-green-dark;
      @include spinning(cw);
    }

  }

  @at-root .c-toggle--enabled & {
    background-color: $color-green;

    @include mq(md) {
      left: auto;
      right: $indicator-spacing-md;
      background-color: white;
    }
  }
}

.c-toggle__mousetrap-hint {
  position: absolute;
  top: -6px;
  right: -145px;
}









