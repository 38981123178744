/*------------------------------------*\
    #STRIPE
\*------------------------------------*/

/**
 * Styles the Stripe card element
 */

.StripeElement {
  padding: $input-padding-vertical-normal $input-padding-horizontal-normal;
  display: block;
  width: 100%;
  @include setPropFromVar(font-family, $var-font-family-default);
  @include setPropFromVar(color, $var-color-text-dark);
  @include setPropFromVar(background-color, $var-color-bg-1);
  background-image: none;
  border: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  border-radius: $global-radius;
  -webkit-appearance: none;
  font-size: 28px;
}

.StripeElement--focus {
  outline: none;
  @include setPropFromVar(box-shadow, $var-input-focus-shadow);
}

.StripeElement--invalid {
  border-color: $color-form-error;
}

.StripeElement--webkit-autofill {
  background-color: $color-gray-96-blue-tint !important;
}

