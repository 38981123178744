/*------------------------------------*\
    #COLLAPSE
\*------------------------------------*/

.c-collapse {
  position: relative;
  &--ai-filters {
    border-radius: $global-radius;
  }
  &--ai-finder {
    margin: $spacing-unit-tiny;
  }
}

.c-collapse__toggle {
  cursor: pointer;

  &--boxed {
    display: block;
    width: 100%;
    text-align: left;
    transition: $global-transition-all;
    @include setPropFromVar(background-color, $var-color-bg-1);
    @include input-sizing(small);
    @include mq($until: sm) {
      padding-right: $spacing-unit-med-tiny;
    }
      border: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);;
    border-radius: $global-radius;
    &.is-toggled {
      @include hide-border(bottom);
    }

    &:focus,
    &:hover {
      @include setPropFromVar(border-color, $var-color-ui);
      @include setPropFromVar(background-color, $var-color-bg-1);
    }
    &.is-toggled {
      @include setPropFromVar(border-color, $var-color-ui);
    }
  }

}

.c-collapse__target {
  html.js & {
    display: none;
  }
  html.js &.c-collapse__target--init-expanded {
    display: block;
    &\@md {
      @include mq($from: md) {
        display: block;
      }
    }
  }
  &--boxed {
    width: 100%;
    padding: $spacing-unit-tiny $spacing-unit-small 0;
    @include setPropFromVar(background-color, $var-color-bg-1);
    border: 1px solid;
    @include setPropFromVar(border-color, $var-color-ui);
    border-top: none;
    border-bottom-left-radius: $global-radius;
    border-bottom-right-radius: $global-radius;
    @include font-size($font-size-14);
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }

  .c-collapse--bef > & {
    position: absolute;
    z-index: 9999999;
  }
}

.c-collapse__toggle,
.c-collapse__target {
  .c-collapse--bef > & {
    @include mq(sm) {
      min-width: 190px;
    }
    @include mq(mlg) {
      min-width: 240px;
    }
  }
}

.c-collapse__indicator {
  @include font-size($font-size-15, 1);
  vertical-align: middle;
  .c-collapse__toggle--boxed > & {
    @include font-size($font-size-12, 1);
  }
  .c-collapse__toggle.is-toggled &,
  .c-collapse__toggle--reverse:not(.collapsed) & {
    transform: scaleY(-1);
  }
}
