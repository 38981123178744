///*------------------------------------*\
//    #ICONS
//\*------------------------------------*/

/**
 * Thanks https://codepen.io/tigt/post/optimizing-svgs-in-data-uris
 */

@mixin aspect-ratio($arglist... /*$width/$ratio, $height*/){
  $map : keywords($arglist);
  $height: map-get($map, height) or nth-or-null($arglist, 2);
  $width:  map-get($map, width)  or nth-or-null($arglist, 1);
  $ratio:  map-get($map, ratio)  or if($width and $height, $width/$height, nth-or-null($arglist, 1)) or 1;
  $padding: 1/$ratio * 100%;
  &:before { content: ''; float:left; padding-bottom: $padding; }
  &:after  { content: ''; display:table; clear: both; }
}

// Helper function
// Return null rather than throwing an error if index is outside list range.
@function nth-or-null($list, $index) {
  @return if(length($list) >= $index, nth($list, $index), null);
}


//
//  Function to replace characters in a string
//
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
        str-replace(str-slice($string, $index +
        str-length($search)), $search, $replace);
  }
  @return $string;
}

//
//  Function to create an optimized svg url
//  (may need a few extra replacements)
//
@function old_svg-url($svg){
  $svg: str-replace($svg,'"','\'');
  $svg: str-replace($svg,'<','%3C');
  $svg: str-replace($svg,'>','%3E');
  $svg: str-replace($svg,'&','%26');
  $svg: str-replace($svg,'#','%23');
  @return url("data:image/svg+xml;charset=utf8,#{$svg}");
}
@function svg-url($svg){
  //
  // Chunk up string in order to avoid
  // "SystemStackError: stack level too deep"
  //
  $encoded:'';
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg)/$slice);
  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    $chunk: str-replace($chunk,'"','\'');
    $chunk: str-replace($chunk,'<','%3C');
    $chunk: str-replace($chunk,'>','%3E');
    $chunk: str-replace($chunk,'&','%26');
    $chunk: str-replace($chunk,'#','%23');
    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }
  @return url("data:image/svg+xml;charset=utf8,#{$encoded}");
}

@mixin svg-icon($name, $width: 1em, $height: $width, $fill: currentColor) {
  display: inline-block;
  width: $width;
  height: $height;
  $svg: '';
  @if $name == tick {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 32"><path d="M14.314 31.656c0.217 0.213 0.515 0.344 0.843 0.344 0.006 0 0.012-0 0.018-0h-0.001c0.361-0.001 0.689-0.145 0.929-0.379l-0 0 27.768-29.454c0.229-0.235 0.37-0.557 0.37-0.912s-0.141-0.677-0.371-0.912l0 0c-0.235-0.229-0.557-0.37-0.912-0.37s-0.677 0.141-0.912 0.371l0-0-26.942 28.594-12.938-11.733c-0.242-0.308-0.614-0.505-1.032-0.505-0.722 0-1.308 0.585-1.308 1.308 0 0.453 0.23 0.852 0.58 1.087l0.005 0.003z" fill="#{$fill}"/></svg>';
  }
  @else if $name == tick-thick {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 32"><path d="M42.252 0.621c-0.932-0.932-2.796-0.932-3.728 0l-23.301 24.854-10.252-9.32c-0.932-1.243-2.796-1.553-4.039-0.621-0.311 0.621-0.932 1.243-0.932 1.864s0 1.553 0.621 2.175c0.311 0.311 0.311 0.311 0.311 0.311l12.427 11.184c0.621 0.311 1.243 0.621 1.864 0.621s1.553-0.311 1.864-0.932l25.165-26.718c1.243-0.621 1.243-2.175 0-3.417z" fill="#{$fill}"/></svg>';
  }
  @else if $name == arrow-left {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15"><path d="M4.5,7.8l5,5c0.2,0.2,0.5,0.2,0.7,0s0.2-0.5,0-0.7L5.6,7.5l4.6-4.6c0.2-0.2,0.2-0.5,0-0.7C10,2,9.7,2,9.5,2.1l-5,5 C4.4,7.2,4.4,7.3,4.4,7.5C4.3,7.6,4.3,7.7,4.5,7.8z" fill="#{$fill}"/></svg>';
  }
  @else if $name == arrow-right {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15"><path d="M10.2 7.2l-5-5c-0.2-0.2-0.5-0.2-0.7 0s-0.2 0.5 0 0.7l4.6 4.6-4.6 4.6c-0.2 0.2-0.2 0.5 0 0.7s0.5 0.2 0.7 0.1l5-5c0.1-0.1 0.1-0.2 0.1-0.4 0-0.1 0-0.2-0.1-0.3z" fill="#{$fill}"/></svg>';
  }
  @else if $name == arrow-down {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15"><path d="M7.5,11.4c-0.1,0-0.3,0-0.3-0.1l-7-6.8C0,4.3-0.1,3.9,0.1,3.7c0.2-0.2,0.5-0.2,0.7,0l6.6,6.5l6.7-6.5c0.2-0.2,0.5-0.2,0.7,0 c0.2,0.2,0.2,0.5,0,0.7l-7,6.8C7.7,11.4,7.6,11.4,7.5,11.4z" fill="#{$fill}"/></svg>';
  }
  @else if $name == arrow-up {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15"><path d="M7.5,3.6c0.1,0,0.3,0,0.3,0.1l7,6.8c0.2,0.2,0.2,0.5,0,0.7c-0.2,0.2-0.5,0.2-0.7,0L7.5,4.8l-6.7,6.5c-0.2,0.2-0.5,0.2-0.7,0 c-0.2-0.2-0.2-0.5,0-0.7l7-6.8C7.3,3.6,7.4,3.6,7.5,3.6z" fill="#{$fill}"/></svg>';
  }
  @else if $name == refresh {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M18.053 21.333l3.92 3.92c-1.992 1.421-4.187 2.133-6.587 2.133-3.147 0-5.832-1.112-8.053-3.333s-3.333-4.907-3.333-8.053h-4c0 2.099 0.405 4.093 1.213 5.987s1.901 3.528 3.28 4.907c1.379 1.379 3.013 2.472 4.907 3.28s3.888 1.213 5.987 1.213c3.52 0 6.675-1.085 9.467-3.253l3.867 3.867v-10.667h-10.667zM12.72 10.667l-3.92-3.92c1.992-1.421 4.187-2.133 6.587-2.133 3.147 0 5.832 1.112 8.053 3.333s3.333 4.907 3.333 8.053h4c0-2.099-0.405-4.093-1.213-5.987s-1.901-3.528-3.28-4.907c-1.379-1.379-3.013-2.472-4.907-3.28s-3.888-1.213-5.987-1.213c-3.52 0-6.675 1.085-9.467 3.253l-3.867-3.867v10.667h10.667z" fill="#{$fill}"/></svg>';
  }
  @else if $name == cross {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15"><path d="M8.208 7.5l6.645-6.645c0.196-0.196 0.196-0.513 0-0.708s-0.513-0.196-0.708 0l-6.645 6.645-6.645-6.645c-0.196-0.196-0.513-0.196-0.708 0s-0.196 0.513 0 0.708l6.645 6.645-6.645 6.645c-0.196 0.196-0.196 0.513 0 0.708 0.098 0.098 0.226 0.147 0.354 0.147s0.256-0.049 0.354-0.147l6.645-6.645 6.645 6.645c0.098 0.098 0.226 0.147 0.354 0.147s0.256-0.049 0.354-0.147c0.196-0.196 0.196-0.513 0-0.708l-6.645-6.645z" fill="#{$fill}"/></svg>';
  }
  @else if $name == info {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15"><path d="M7.5 0c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5zM14 7.5c0 3.6-2.9 6.5-6.5 6.5s-6.5-2.9-6.5-6.5c0-3.6 2.9-6.5 6.5-6.5s6.5 2.9 6.5 6.5z" fill="#{$fill}"/><path d="M7.5 6c-0.4 0-0.7 0.3-0.7 0.6v4c0 0.4 0.3 0.7 0.7 0.7s0.7-0.3 0.7-0.7v-4c0-0.3-0.3-0.6-0.7-0.6z" fill="#{$fill}"/><path d="M7.5 5.2c0.4 0 0.8-0.4 0.8-0.8s-0.4-0.8-0.8-0.8c-0.4 0-0.8 0.4-0.8 0.8 0 0.5 0.4 0.8 0.8 0.8z" fill="#{$fill}"/></svg>';
  }
  @else if $name == question {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15"><path d="M7.5 15c-4.135 0-7.5-3.365-7.5-7.5s3.364-7.5 7.5-7.5c4.136 0 7.5 3.364 7.5 7.5s-3.365 7.5-7.5 7.5zM7.5 1.011c-3.578 0-6.489 2.911-6.489 6.489s2.911 6.489 6.489 6.489 6.489-2.911 6.489-6.489-2.911-6.489-6.489-6.489z" fill="#{$fill}"/><path d="M7.503 10.076c-0.339 0-0.614 0.275-0.614 0.614s0.275 0.613 0.614 0.613c0.338 0 0.614-0.275 0.614-0.613 0-0.339-0.275-0.614-0.614-0.614z" fill="#{$fill}"/><path d="M8.975 4.215c-0.411-0.334-0.935-0.518-1.472-0.518-1.032 0-1.947 0.666-2.174 1.573-0.037 0.125-0.019 0.265 0.048 0.383s0.179 0.204 0.304 0.235c0.128 0.033 0.265 0.008 0.381-0.065 0.115-0.073 0.196-0.188 0.219-0.304 0.101-0.405 0.616-0.838 1.221-0.838 0.308 0 0.629 0.111 0.859 0.297 0.157 0.127 0.344 0.346 0.344 0.666 0 0.373-0.079 0.525-0.164 0.635-0.092 0.118-0.235 0.219-0.416 0.348l-0.058 0.041c-0.248 0.176-0.528 0.407-0.732 0.786-0.219 0.407-0.326 0.926-0.326 1.585 0 0.268 0.226 0.493 0.493 0.493s0.493-0.226 0.493-0.493c0-0.495 0.067-0.86 0.205-1.116 0.113-0.211 0.266-0.331 0.438-0.454l0.102-0.072c0.187-0.129 0.398-0.276 0.582-0.512 0.243-0.313 0.367-0.73 0.367-1.241 0-0.541-0.261-1.062-0.715-1.429z" fill="#{$fill}"/></svg>';
  }
  @else if $name == pen {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M29.079 8.379l-6.017-6.017c-0.201-0.1-0.301-0.201-0.502-0.201s-0.301 0.1-0.401 0.201l-15.242 15.141c-0.1 0.1-0.1 0.201-0.201 0.301v0l-1.705 7.821v0.1c0 0.201 0.1 0.301 0.201 0.401v0c0.1 0.1 0.201 0.201 0.401 0.201h0.1l7.921-1.905c0.1 0 0.201-0.1 0.301-0.201l15.141-15.141c0.1-0.1 0.201-0.201 0.201-0.401v0c-0.1-0.1-0.1-0.201-0.201-0.301v0 0zM13.136 23.421l-6.919 1.705 1.805-6.919 14.54-14.64 5.214 5.214-14.64 14.64z" fill="#{$fill}"/></svg>';
  }
  @else if $name == pen-paper {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M0 28.735h31.988v1.103h-31.988v-1.103z" fill="#{$fill}"/><path d="M29.079 8.379l-6.017-6.017c-0.201-0.1-0.301-0.201-0.502-0.201s-0.301 0.1-0.401 0.201l-15.242 15.141c-0.1 0.1-0.1 0.201-0.201 0.301v0l-1.705 7.821v0.1c0 0.201 0.1 0.301 0.201 0.401v0c0.1 0.1 0.201 0.201 0.401 0.201h0.1l7.921-1.905c0.1 0 0.201-0.1 0.301-0.201l15.141-15.141c0.1-0.1 0.201-0.201 0.201-0.401v0c-0.1-0.1-0.1-0.201-0.201-0.301v0 0zM13.136 23.421l-6.919 1.705 1.805-6.919 14.54-14.64 5.214 5.214-14.64 14.64z" fill="#{$fill}"/></svg>';
  }
  @else if $name == up-down {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M7.227 27.866v-26.768c0-0.606 0.492-1.098 1.098-1.098s1.098 0.491 1.098 1.098v0 26.511l4.672-4.672c0.203-0.256 0.514-0.419 0.863-0.419 0.606 0 1.098 0.491 1.098 1.098 0 0.349-0.163 0.66-0.417 0.861l-0.002 0.002-5.793 5.886-1.542 1.542-1.682-1.542-5.793-5.793c-0.256-0.203-0.419-0.514-0.419-0.863 0-0.606 0.491-1.098 1.098-1.098 0.349 0 0.66 0.163 0.861 0.417l0.002 0.002z" fill="#{$fill}"/><path d="M23.087 4.251v26.651c0 0.606 0.491 1.098 1.098 1.098s1.098-0.491 1.098-1.098v0-26.651l4.672 4.672c0.203 0.256 0.514 0.419 0.863 0.419 0.606 0 1.098-0.492 1.098-1.098 0-0.349-0.163-0.66-0.417-0.861l-5.771-5.771-1.542-1.612-1.542 1.542-5.793 5.886c-0.256 0.203-0.419 0.514-0.419 0.863 0 0.606 0.492 1.098 1.098 1.098 0.349 0 0.66-0.163 0.861-0.417l0.002-0.002z" fill="#{$fill}"/></svg>';
  }

  background: transparent svg-url('#{$svg}') 50% 50% no-repeat;
}
