/*------------------------------------*\
    #LOGO
\*------------------------------------*/

@mixin logo($size: small, $filtered: '') {

  background: transparent url("#{$image_path}logo/logo.svg") no-repeat 50% 50%;

  @if $filtered == filtered {
    @supports ( (--a: 0)) {
      background: transparent url("#{$image_path}logo/logo-bw.svg") no-repeat 50% 50%;
      @include setPropFromVar(filter, $var-logo-filter);
    }
    @supports (-ms-ime-align:auto) {
      background: transparent url("#{$image_path}logo/logo.svg") no-repeat 50% 50%;
      filter: none;
    }
  }

  display: inline-block;
  vertical-align: middle;
  $width: 240px;
  $height: 48px;

  @if $size == small {
    $width: 130px;
    $height: 26px;
  } @else if $size == medium {
    $width: 160px;
    $height: 32px;
  } @else if $size == medium-large {
    $width: 210px;
    $height: 42px;
  } @else if $size == large {
    $width: 240px;
    $height: 48px;
  }
  background-size: $width $height;
  width: $width;
  height: $height;
}

.c-logo {

  &--app {
    @include logo(medium, filtered);
    @include mq(md) {
      @include logo(large, filtered);
    }
  }

  &--info {
    @include logo(medium);
    @include mq(md) {
      @include logo(medium-large);
    }
    @include mq(mlg) {
      @include logo(large);
    }
  }

  &--footer-info {
    filter: none;
    @include logo();
    @include mq(mlg) {
      @include logo(medium-large);
    }
  }

  &--footer-app {
    filter: none;
    @include logo(small);
    display: block;
    margin: 0 auto;
  }

  &--custom {
    max-width: 160px;
    height: auto;
    @include mq($from: md) {
      max-width: 250px;
    }
  }
}

