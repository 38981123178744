/*------------------------------------*\
    #FIELD HELP
\*------------------------------------*/

.c-field-help {
  html.js body > :not(.fancybox-container) & {
    display: none;
  }
}

.c-field-help__wrapper {
  @include mq(mlg) {
    html.js body > :not(.fancybox-container) & {
      position: relative;
      padding: $spacing-unit;
      border: 1px solid $color-green;
      background-color: $color-gray-97-green-tint;
      border-radius: $global-radius;
      @include setPropFromVar(color, $var-color-text-base);
      &:before,
      &:after {
        content: '';
        position: absolute;
        right: 100%;
        top: $spacing-unit;
        margin-right: -8px;
        transform: rotate(-90deg);
      }
      &:before {
        @include triangle(16px, $color-green);
        z-index: 1;
      }
      &:after {
        @include triangle(15px, $color-gray-97-green-tint);
        z-index: 2;
      }
    }
  }
}

.c-field-help__icon {
  display: none;
  @include mq(mlg) {
    html.js body > :not(.fancybox-container) & {
      display: block;
      font-size: 2em;
      margin-bottom: $spacing-unit;
      color: $color-green;
    }
  }
}

.c-field-help__text {
  a {
    color: $color-green;
  }
}

.c-field-help__dismiss {
  display: none;
  @include mq(mlg) {
    html.js body > :not(.fancybox-container) & {
      display: block;
      margin-top: $spacing-unit;
      &:focus {
        outline: none;
      }
    }
  }
}
