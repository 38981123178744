/*------------------------------------*\
    #ACCORDION
\*------------------------------------*/

.c-accordion {
  list-style: none;
  margin: 0;
  text-align: left;
  position: relative;
}

.c-accordion__item {
  overflow: hidden;
}

.c-accordion__toggle {
  position: relative;
  @include setPropFromVar(font-family, $var-font-family-bolder);
  @include setPropFromVar(font-weight, $var-font-weight-bolder);
  display: block;
  padding: $spacing-unit-med-small $spacing-unit-med-huge;
  margin: 0;
  cursor: pointer;
  transition: $fast-transition-all;
  @include font-size(14px, 1.5);
  @include mq(md) {
    @include font-size(26px, 1.5);
    background-color: transparent;
    width: calc(50% - #{$spacing-unit-v-huge});
    padding: $spacing-unit-med-small $spacing-unit $spacing-unit-med-small $spacing-unit-huge;
    .c-accordion__item.is-toggled & {
      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        right: -2px;
        top: 0;
        bottom: 0;
        width: 3px;
        border-radius: 2px;
        background-color: #fff;
        .c-accordion--grey-bg & {
          background-color: $color-blue;
        }
      }
    }
  }

  // Background color at lmd
  @include mq($until: md) {
    .c-accordion--dark-blue-bg & {
      background-color: $color-blue-gray-4;
    }
    .c-accordion--blue-bg & {
      background-color: $color-blue-v-light;
    }
    .c-accordion--grey-bg & {
      background-color: white;
    }
  }

  @include attention {
    text-decoration: none;
  }

  // Un-toggled color
  .c-accordion--dark-blue-bg & {
    color: white;
    @include mq(md) {
      color: $color-teal;
    }
  }
  .c-accordion--blue-bg & {
    color: white;
    @include mq(md) {
      color: $color-teal;
    }
  }
  .c-accordion--grey-bg & {
    color: $color-blue;
    @include mq(md) {
      color: $color-gray-60;
    }
  }

  // Toggled and attention color
  .c-accordion--dark-blue-bg &,
  .c-accordion--blue-bg & {
    @include attention {
      color: white;
    }
  }
  .c-accordion--dark-blue-bg .c-accordion__item.is-toggled &,
  .c-accordion--blue-bg .c-accordion__item.is-toggled & {
    color: white;
  }

  .c-accordion--grey-bg & {
    @include attention {
      color: $color-blue;
    }
  }
  .c-accordion--grey-bg .c-accordion__item.is-toggled & {
    color: $color-blue;
  }

}

.c-accordion__icon {
  font-size: 1.5em;
  position: absolute;
  left: $spacing-unit-med-small;
  bottom: auto;
  @include mq(md) {
    font-size: 1.25em;
  }
}

.c-accordion__indicator {
  position: absolute;
  right: $spacing-unit;
  top: 50%;
  margin-top: -0.5em;
  .c-accordion__item.is-toggled & {
    transform: scaleY(-1);
  }
  @include mq(md) {
    display: none;
  }
}

.c-accordion__target {
  max-height: 0;
  transition: $global-transition-all;
  .c-accordion__item.is-toggled & {
    max-height: 300px;
  }
  @include mq(md) {
    max-height: none;
    opacity: 0;
    position: absolute;
    right: 0;
    left: calc(50% - #{$spacing-unit-v-huge});
    top: $spacing-unit;
    padding-left: $spacing-unit-huge;
    .c-accordion__item.is-toggled & {
      max-height: none;
      opacity: 1;
    }
  }
}

.c-accordion__content {
  @include mq($until: md) {
    padding: $spacing-unit;
    .c-accordion--dark-blue-bg & {
      background-color: $color-blue-gray-2;
    }
    .c-accordion--blue-bg & {
      background-color: $color-blue-v-dark;
    }
    .c-accordion--grey-bg & {
      background-color: $color-gray-90-blue-tint;
    }
    @include font-size(11px, 1.5);
    > :last-child {
      margin-bottom: 0;
    }
  }
  @include mq(md) {
    @include font-size(21px, 1.5);
  }
}
