/*------------------------------------*\
    #TOKENS
\*------------------------------------*/

.c-tokens {
  @include setPropFromVar(background-color, $var-color-bg-3);
    border: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  padding: $spacing-unit;
}

.c-tokens__table {
  > thead th {
      border-bottom: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  }
}

.c-tokens__group {
  > td {
    padding-top: $spacing-unit-small;
    @include setPropFromVar(font-family, $var-font-family-normal);
  }
}
