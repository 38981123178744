/*------------------------------------*\
    #ZENDESK
\*------------------------------------*/

iframe#launcher {
  margin: $spacing-unit-tiny !important;
  min-height: 0 !important;
  max-height: 24px !important;
  width: 30px !important;
  @include mq($from: mmd) {
    width: 120px !important;
    height: 42px !important;;
    margin: $spacing-unit-small !important;
    max-height: none !important;
    .c-page--info & {
      width: 200px !important;
    }
  }
}
