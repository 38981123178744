/*------------------------------------*\
    #STEPS
\*------------------------------------*/
$disk-font-size: 30px;
$disk-radius: 0.9; // em
$disk-width: 2 * $disk-radius * $disk-font-size;
$disk-md-scale: 2;

.c-steps {
  @include setVarCss(disk-font-size, 30px);
  list-style: none;
  margin: 0;
  counter-reset: c;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: -100px;
    left: 0.5 * $disk-width;
    border-left: 2px solid $color-gray-96-blue-tint;
  }
  @include mq(md) {
    margin-left: 50%;
    &:before {
      left: 0;
    }
  }
}

.c-steps__step {
  counter-increment: c;
  position: relative;
  padding-left: $disk-width + $spacing-unit;
  text-align: left;
  &:before,
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    @include font-size($disk-font-size, 2);
    width: $disk-radius * 2em;
    height: $disk-radius * 2em;
    border-radius: $disk-radius * 1em;
  }
  &:before {
    content: '';
    background-color: $color-gray-87-blue-tint;
    position: absolute;
    margin-top: 0.2em;
    margin-left: -0.2em;
  }
  &:after {
    vertical-align: middle;
    content: counter(c);
    @include setPropFromVar(font-family, $var-font-family-bolder);
    @include setPropFromVar(font-weight, $var-font-weight-bolder);
    @include setPropFromVar(color, $var-color-ui);
    @include setPropFromVar(background-color, $var-color-bg-2);
    text-align: center;
  }
  @include mq(md) {
    padding-left: 0;
    width: 200%;
    font-size: 0;
    left: -0.5 * $disk-width * $disk-md-scale;
    margin-bottom: $spacing-unit-med-huge;
    &:before,
    &:after {
      @include font-size($disk-font-size * $disk-md-scale, 2);
    }
    &:nth-child(even) {
      direction: rtl;
    }
  }
}

.c-steps__image,
.c-steps__body {
  @include mq(md) {
    .c-steps__step:nth-child(1) & {
      padding-top: 150px;
    }
    .c-steps__step:nth-child(2) & {
      padding-top: $spacing-unit-large;
    }
    .c-steps__step:nth-child(3) & {
      padding-top: 150px;
    }
  }
}

.c-steps__image {
  display: none;
  @include mq(md) {
    display: inline-block;
    vertical-align: top;
    .c-steps__step:nth-child(1) & {
      margin-left: -42%;
      width: 45%;
    }
    .c-steps__step:nth-child(2) & {
      text-align: right;
      width: 35%;
      margin-top: $spacing-unit;
    }
    .c-steps__step:nth-child(3) & {
      margin-left: -45%;
      width: 55%;
    }

  }
}

.c-steps__body {
  direction: ltr;
  @include mq(md) {
    display: inline-block;
    width: 40%;
    @include font-size($font-size-20, 1.5);
    color: $color-gray-60;
    .c-steps__step:nth-child(1) & {
      margin-left: 90px;
    }
    .c-steps__step:nth-child(2) & {
      margin-left: -45%;
      text-align: right;
      margin-right: 1.5 * $spacing-unit-v-huge;
    }
    .c-steps__step:nth-child(3) & {
      margin-left: 60px;
      padding-top: 100px;
    }
  }
}

.c-steps__icon {
  @include setPropFromVar(color, $var-color-ui);
  @include font-size(40px);
  margin-bottom: $spacing-unit-tiny;
}
