///*------------------------------------*\
//    #ANIMATIONS
//\*------------------------------------*/


@mixin spinning($dir: cw) {
  @if $dir == ccw {
    animation: spinning-ccw 0.8s linear infinite;
  }
  @else {
    animation: spinning-cw 0.8s linear infinite;
  }
}

@keyframes spinning-cw {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes  spinning-ccw {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@mixin delayed-visibility($delay: 0.2s) {
  animation: $delay delayInVisibility;
  animation-fill-mode: forwards;
  visibility: hidden;
}
@keyframes delayInVisibility {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

@mixin delayed-invisibility($delay: 0.2s) {
  animation: $delay delayInInvisibility;
  animation-fill-mode: forwards;
  visibility: visible;
}
@keyframes delayInInvisibility {
  99% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}

@mixin delayed-transparency($delay: 0.2s) {
  animation: $delay delayInTransparency;
  animation-fill-mode: forwards;
  opacity: 1;
}
@keyframes delayInTransparency {
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@mixin anim-highlight($tc: $color-green-bright, $duration: 2s) {
  $animation-name: highlight-#{unquote("\\")}#{$tc}-#{$duration};
  animation: $duration $animation-name;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  @keyframes #{$animation-name} {
    5% {
      color: $tc;
    }
    90% {
      color: $tc;
    }
  }
}

@mixin anim-highlight-bg($bg: $color-green-bright,  $tc: #ffffff, $duration: 2s) {
  &,
  a,
  [class*="c-views-table__me"],
  [class*="c-bid-status"] {
    $animation-name: highlight-bg-#{unquote("\\")}#{$bg}-#{unquote("\\")}#{$tc}-#{$duration};
    animation: $duration $animation-name;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    @keyframes #{$animation-name} {
      5% {
        background-color: $bg;
        color: $tc;
        border-width: 0;
      }
      90% {
        background-color: $bg;
        color: $tc;
        border-width: 0;
      }
    }
  }
}

@mixin anim-ellipsis {
  position: relative;
  &:after {
    content: "\2026";
    position: absolute;
    display: inline-block;
    width: 0;
    overflow: hidden;
    animation: ellipsis 1.5s infinite;
  }
}
@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@mixin tick() {
  content: '';
  display: block;
  position: absolute;
  left: 32px;
  bottom: 50%;
  width: 0;
  height: 0;
  opacity: 1;
  transform: rotate(37deg) translate(100%, 0) skew(-2deg);
  transform-origin: 100% 100%;
  animation: tick 2s ease-in forwards;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
}

@keyframes tick {
  0% {
    opacity: 1;
    border-color: white;
  }
  3% {
    width: 0.67em;
    height: 0;
  }
  6% {
    width: 0.67em;
    height: 1.33em;
  }
  95% {
    opacity: 1;
  }
  100% {
    border-color: white;
    width: 0.67em;
    height: 1.33em;
    opacity: 0;
  }
}



