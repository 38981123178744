/*------------------------------------*\
    #Custom properties
\*------------------------------------*/

// SCSS
$rootVars: ();

@mixin setVarSass($varName, $value){
    $rootVars: map-merge($rootVars, (
            #{$varName}: $value
    )) !global;
}

@function getVarSass($varName){
    @return map-get($rootVars, #{$varName});
}

// CSS
@mixin setRootVarCss($varName, $value){
    @supports ( (--a: 0)) {
        // Custom properties are supported in the browser
        $varNameLocal: unquote($varName);
        :root {
            #{--#{$varNameLocal}}: $value;
        }
    }
}

@mixin setVarCss($varName, $value){
    @supports ( (--a: 0)) {
        // Custom properties are supported in the browser
        $varNameLocal: unquote($varName);
        #{--#{$varNameLocal}}: $value;
    }
}

@function getVarCss($varName){
    $varNameLocal: unquote($varName);
    @return #{var(--#{$varNameLocal}, map-get($rootVars, #{$varName}))};
}

// Common
@mixin setVar($varName, $value){
    @include setVarSass($varName, $value);
    @include setRootVarCss($varName, $value);
}

@mixin setPropFromVar($propName, $varName){
    // Set default value when custom properties are not supported in the browser
    #{$propName}: getVarSass(unquote($varName));
    // Set custom value when custom properties are supported in the browser
    @supports ( (--a: 0)) {
        #{$propName}: getVarCss(unquote($varName));
    }
}

// Use variables for CSS variable names, to allow cross-referencing and error-checking
// Customisable by through admin UI - colour
$var-color-bg-1: color-bg-1;
$var-color-bg-2: color-bg-2;
$var-color-bg-3: color-bg-3;
$var-color-bg-4: color-bg-4;
$var-color-text-base: color-text-base;
$var-color-text-dark: color-text-dark;
$var-color-text-v-dark: color-text-v-dark;
$var-color-text-light: color-text-light;
$var-color-text-placeholder: color-text-placeholder;
$var-color-highlight: color-highlight;
$var-color-highlight-contrast: color-highlight-contrast;
$var-color-heading-alpha: color-heading-alpha;
$var-color-heading-beta: color-heading-beta;
$var-color-heading-gamma: color-heading-gamma;
$var-color-heading-delta: color-heading-delta;
$var-color-link: color-link;
$var-color-link-focus: color-link-focus;
$var-color-button-bg: color-button-bg;
$var-color-button-text: color-button-text;
$var-color-button-focus: color-button-focus;
$var-color-button-focus-text: color-button-focus-text;
$var-color-button-primary: color-button-primary;
$var-color-button-primary-focus: color-button-primary-focus;
$var-color-border: color-border;
$var-color-border-heavy: color-border-heavy;
$var-color-border-light: color-border-light;
$var-color-nav: color-nav;
$var-color-nav-inactive: color-nav-inactive;
$var-color-ui: color-ui;
$var-state-disabled-text: state-disabled-text;
$var-state-disabled-bg: state-disabled-bg;
$var-state-disabled-br: state-disabled-br;
$var-color-ai-header-text: color-ai-header-text;
$var-color-ai-header-link: color-ai-header-link;
$var-color-ai-header-bg-1: color-ai-header-bg-1;
$var-color-ai-header-bg-2: color-ai-header-bg-2;
$var-page-nav-1: page-nav-1;
$var-page-nav-2: page-nav-2;
$var-page-nav-3: page-nav-3;
$var-page-nav-4: page-nav-4;

// Customisable by through admin UI - complex properties
$var-focus-shadow: focus-shadow;
$var-input-focus-shadow: input-focus-shadow;
$var-inset-shadow: inset-shadow;
$var-outset-shadow: outset-shadow;
$var-color-filter: color-filter;
$var-logo-filter: logo-filter;

// Not customisable
$var-font-family-default: font-family-default;
$var-font-family-lighter: font-family-lighter;
$var-font-family-normal: font-family-normal;
$var-font-family-bold: font-family-bold;
$var-font-family-bolder: font-family-bolder;
$var-font-weight-default: font-weight-default;
$var-font-weight-lighter: font-weight-lighter;
$var-font-weight-normal: font-weight-normal;
$var-font-weight-bold: font-weight-bold;
$var-font-weight-bolder: font-weight-bolder;

// Define custom properties and set root-level defaults values
@mixin default-theme {
    @include setVar($var-color-bg-1, $color-white);
    @include setVar($var-color-bg-2, $color-gray-96-blue-tint);
    @include setVar($var-color-bg-3, $color-gray-98-blue-tint);
    @include setVar($var-color-bg-4, $color-gray-87-blue-tint);
    @include setVar($var-color-text-base, $color-gray-44-blue-tint);
    @include setVar($var-color-text-dark, $color-gray-35-blue-tint);
    @include setVar($var-color-text-v-dark, $color-blue-gray-2);
    @include setVar($var-color-text-light, $color-gray-60);
    @include setVar($var-color-text-placeholder, $color-gray-70);
    @include setVar($var-color-highlight, $color-gray-80);
    @include setVar($var-color-highlight-contrast, $color-white);
    @include setVar($var-color-heading-alpha, $color-blue-gray-2);
    @include setVar($var-color-heading-beta, $color-blue-gray-3);
    @include setVar($var-color-heading-gamma, $color-blue);
    @include setVar($var-color-heading-delta, $color-blue-gray-2);
    @include setVar($var-color-link, $color-blue);
    @include setVar($var-color-link-focus, $color-blue-dark);
    @include setVar($var-color-button-bg, $color-blue);
    @include setVar($var-color-button-text, $color-white);
    @include setVar($var-color-button-focus, $color-blue-light);
    @include setVar($var-color-button-focus-text, $color-white);
    @include setVar($var-color-button-primary, $color-green);
    @include setVar($var-color-button-primary-focus, $color-green-bright);
    @include setVar($var-color-border, $color-gray-87-blue-tint);
    @include setVar($var-color-border-heavy, $color-gray-80);
    @include setVar($var-color-border-light, $color-gray-93-blue-tint);
    @include setVar($var-color-nav, $color-blue);
    @include setVar($var-color-nav-inactive, $color-gray-40);
    @include setVar($var-color-ui, $color-blue);
    @include setVar($var-state-disabled-text, $color-gray-60);
    @include setVar($var-state-disabled-bg, $color-gray-93-blue-tint);
    @include setVar($var-state-disabled-br, $color-gray-87-blue-tint);
    @include setVar($var-focus-shadow, $focus-shadow-px $color-blue);
    @include setVar($var-input-focus-shadow, $input-shadow-px $color-blue);
    @include setVar($var-inset-shadow, $inset-shadow-px $color-blue);
    @include setVar($var-outset-shadow, $outset-shadow-px $color-blue);
    @include setVar($var-color-filter, url(#color-blue));
    @include setVar($var-logo-filter, url(#logo-blue));
    @include setVar($var-color-ai-header-text, $color-white);
    @include setVar($var-color-ai-header-link, $color-blue);
    @include setVar($var-color-ai-header-bg-1, $color-blue-gray-2);
    @include setVar($var-color-ai-header-bg-2, $color-gray-40);
    @include setVar($var-page-nav-1, $color-white);
    @include setVar($var-page-nav-2, $color-blue);
    @include setVar($var-page-nav-3, $color-blue);
    @include setVar($var-page-nav-4, $color-gray-40);
    @include setVar($var-font-family-default, #{map-get($default-fonts, default), sans-serif});
    @include setVar($var-font-family-lighter, #{map-get($default-fonts, lighter), sans-serif});
    @include setVar($var-font-family-normal, #{map-get($default-fonts, normal), sans-serif});
    @include setVar($var-font-family-bold, #{map-get($default-fonts, bold), sans-serif});
    @include setVar($var-font-family-bolder, #{map-get($default-fonts, bolder), sans-serif});
    @include setVar($var-font-weight-default, normal);
    @include setVar($var-font-weight-lighter, normal);
    @include setVar($var-font-weight-normal, normal);
    @include setVar($var-font-weight-bold, normal);
    @include setVar($var-font-weight-bolder, normal);
}

@mixin green-theme {
    @include setVarCss($var-color-heading-gamma, $color-green);
    @include setVarCss($var-color-link, $color-green);
    @include setVarCss($var-color-link-focus, $color-green-dark);
    @include setVarCss($var-color-button-bg, $color-green);
    @include setVarCss($var-color-button-focus, $color-green-bright);
    @include setVarCss($var-color-nav, $color-blue);
    @include setVarCss($var-color-ui, $color-green);
    @include setVarCss($var-focus-shadow, $focus-shadow-px $color-green);
    @include setVarCss($var-input-focus-shadow, $input-shadow-px $color-green);
    @include setVarCss($var-inset-shadow, $inset-shadow-px $color-green);
    @include setVarCss($var-outset-shadow, $outset-shadow-px $color-green);
    @include setVarCss($var-color-filter, url(#color-green));
}

@mixin blue-theme {
    @include setVarCss($var-color-heading-beta, $color-blue);
    @include setVarCss($var-color-heading-gamma, $color-blue);
    @include setVarCss($var-color-link, $color-blue);
    @include setVarCss($var-color-link-focus, $color-blue-dark);
    @include setVarCss($var-color-button-bg, $color-blue);
    @include setVarCss($var-color-button-focus, $color-blue-light);
    @include setVarCss($var-color-nav, $color-blue);
    @include setVarCss($var-color-ui, $color-blue);
    @include setVarCss($var-focus-shadow, $focus-shadow-px $color-blue);
    @include setVarCss($var-input-focus-shadow, $input-shadow-px $color-blue);
    @include setVarCss($var-inset-shadow, $inset-shadow-px $color-blue);
    @include setVarCss($var-outset-shadow, $outset-shadow-px $color-blue);
    @include setVarCss($var-color-filter, url(#color-blue));
}

@include default-theme;

html[data-theme="photographic"]:not([data-ui="custom_complete"]) {
  @include setVarCss($var-color-ai-header-text, $color-blue-gray-3);
  @include setVarCss($var-color-ai-header-bg-1, white);
  @include setVarCss($var-color-ai-header-bg-2, $color-gray-98-blue-tint);
  @include setVarCss($var-page-nav-1, $color-blue-gray-3);
  @include setVarCss($var-page-nav-2, white);
  @include setVarCss($var-page-nav-3, $color-blue-gray-3);
  @include setVarCss($var-page-nav-4, white);
  @include setVarCss($var-color-border, $color-gray-93-blue-tint);
  @include setVarCss($var-color-border-light, transparent);
  @include setVarCss($var-color-border-heavy, $color-gray-87-blue-tint);
  @include setVarCss($var-state-disabled-br, $color-gray-93-blue-tint);
}

html[data-theme="dark"]:not([data-ui="custom_complete"]) {
  $dark-theme-ui: map-get(map-get($theme-colors, dark), ui);
  @include setVarCss($var-color-bg-1, black);
  @include setVarCss($var-color-bg-2, black);
  @include setVarCss($var-color-bg-3, black);
  @include setVarCss($var-color-text-base, $dark-theme-ui);
  @include setVarCss($var-color-text-dark, $dark-theme-ui);
  @include setVarCss($var-color-text-v-dark, white);
  @include setVarCss($var-color-text-placeholder, $color-gray-44-blue-tint);
  @include setVarCss($var-color-highlight, $color-gray-20);
  @include setVarCss($var-color-highlight-contrast, $color-gray-20);
  @include setVarCss($var-color-ai-header-text, $dark-theme-ui);
  @include setVarCss($var-color-ai-header-link, $color-blue);
  @include setVarCss($var-color-ai-header-bg-1, black);
  @include setVarCss($var-color-ai-header-bg-2, black);
  @include setVarCss($var-color-text-base, $dark-theme-ui);
  @include setVarCss($var-color-button-text, white);
  @include setVarCss($var-color-button-focus-text, white);
  @include setVarCss($var-color-border, $color-blue-gray-2);
  @include setVarCss($var-color-border-heavy, $color-blue-gray-3);
  @include setVarCss($var-color-border-light, $color-blue-gray-1);
  @include setVarCss($var-color-heading-alpha, white);
  @include setVarCss($var-color-heading-beta, white);
  @include setVarCss($var-color-heading-gamma, white);
  @include setVarCss($var-color-heading-delta, white);
  @include setVarCss($var-color-link, $color-blue);
  @include setVarCss($var-color-link-focus, white);
  @include setVarCss($var-color-nav, white);
  @include setVarCss($var-color-nav-inactive, $color-gray-60);
  @include setVarCss($var-page-nav-1, $dark-theme-ui);
  @include setVarCss($var-page-nav-2, transparent);
  @include setVarCss($var-page-nav-3, $dark-theme-ui);
  @include setVarCss($var-page-nav-4, transparent);
  @include setVarCss($var-page-nav-4, transparent);
  @include setVarCss($var-page-nav-4, transparent);
  @include setVarCss($var-state-disabled-text, $dark-theme-ui);
  @include setVarCss($var-state-disabled-bg, $color-gray-20);
  @include setVarCss($var-state-disabled-br, $dark-theme-ui);
  @include setVarCss($var-logo-filter, url(#logo-white-blue));
}

@each $name, $colors in $theme-colors {
  $ui: map-get($colors, ui);
  $focus: map-get($colors, focus);
  $filter-color: unquote('url(#color-#{$name})');
  $filter-logo: unquote('url(#logo-#{$name})');
  $link: $ui;
  $link-focus: $focus;
  html[data-ui="#{$name}"] {
    @include setVarCss($var-color-heading-gamma, $ui);
    @include setVarCss($var-color-link, $link);
    @include setVarCss($var-color-link-focus, $link-focus);
    @include setVarCss($var-color-button-bg, $ui);
    @include setVarCss($var-color-button-focus, $focus);
    @include setVarCss($var-color-nav, $ui);
    @include setVarCss($var-color-ui, $ui);
    @include setVarCss($var-focus-shadow, $focus-shadow-px $ui);
    @include setVarCss($var-input-focus-shadow, $input-shadow-px $ui);
    @include setVarCss($var-color-filter, $filter-color);
    @include setVarCss($var-logo-filter, $filter-logo);
    @include setVarCss($var-color-ai-header-link, $link);
  }
}
html[data-ui="gold"]:not([data-theme="dark"]) {
  $colors: map-get($theme-colors, gold);
  $ui: map-get($colors, ui);
  @include setVarCss($var-color-link, black);
  @include setVarCss($var-color-link-focus, $ui);
}
html[data-theme="standard"] {
  &[data-ui="gold"] {
    @include setVarCss($var-color-ai-header-link, $color-gold);
  }
  &[data-ui="teal"],
  &[data-ui="black"] {
    @include setVarCss($var-color-ai-header-link, $color-blue);
  }
}

$custom-fonts: (
  open-sans: 'Open Sans',
  dosis: Dosis,
  roboto: Roboto,
  roboto-slab: 'Roboto Slab',
  nunito: Nunito
);
@each $name, $font in $custom-fonts {
  html[data-font="#{$name}"] {
    @each $weight in (default, lighter, normal, bold, bolder) {
      @include setVarCss(font-family-#{$weight}, #{$font, sans-serif});
      @include setVarCss(font-weight-#{$weight}, $weight);
    }
  }
}
@mixin use-default-fonts {
  @each $weight in (default, lighter, normal, bold, bolder) {
    @include setVarCss(font-family-#{$weight}, #{map-get($default-fonts, $weight), sans-serif});
    @include setVarCss(font-weight-#{$weight}, #{map-get($default-font-weights, $weight)});
  }
}

