/*------------------------------------*\
    #LISTS
\*------------------------------------*/

.c-list {
  list-style: none;
  margin: 0;
}

.c-list__item {
  position: relative;
  padding-left: calc(1em + #{$spacing-unit-small});
  margin: 0 0 $spacing-unit-small;
  &:before {
    content: '-';
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
  }

  .c-list--ticks & {
    &:before {
      content: '';
      @include svg-icon('tick', $fill: getVarSass(color-ui));
      top: 0.1em;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
