/*------------------------------------*\
    #Bid status
\*------------------------------------*/

.c-bid-status {
  &--winning,
  &--leader_online,
  &--won {
    color: $color-success;
  }
  &--below_reserve {
    color: $color-blue;
  }
  &--losing,
  &--outbid_online,
  &--lost {
    color: $color-danger;
  }
}
