/* ==========================================================================
   #FLEX
   ========================================================================== */

@mixin o-flex {
  display: flex;
}
@mixin o-flex--inline {
  display: inline-flex;
}

.o-flex {
  @include o-flex;
  &\@sm {
    @include mq($from: sm) {
      @include o-flex;
    }
  }
  &\@md {
    @include mq($from: md) {
      @include o-flex;
    }
  }
  &--just-sa {
    @include o-flex;
    justify-content: space-around;
  }
  &--just-sb {
    @include o-flex;
    justify-content: space-between;
  }
  &--space-evenly {
    @include o-flex;
    justify-content: space-evenly;
  }
  &--space-between {
    @include o-flex;
    justify-content: space-between;
  }
  &--align-base {
    @include o-flex;
    align-items: baseline;
  }
  &--align-c {
    @include o-flex;
    align-items: center;
  }
  &--align-c--inline {
    @include o-flex--inline;
    align-items: center;
  }
  &--align-c--just-c {
    @include o-flex;
    align-items: center;
    justify-content: center;
  }
  &--align-fe {
    @include o-flex;
    align-items: flex-end;
  }
}

