/*------------------------------------*\
    #IMAGE WIDGET
\*------------------------------------*/

.c-image-widget {
  margin-bottom: $spacing-unit-tiny;
  @include mq(md) {
    margin-bottom: 0;
  }
}

.c-image-widget__preview {
  padding: $spacing-unit-small 0;
  .c-image-widget--field-ai-image & {
    max-width: 210px;
  }
  .c-image-widget--field-ap-logo &,
  .c-image-widget--field-ap-sidebar-image & {
    max-width: 310px;
  }
  > img {
    width: 100%;
    height: auto;
  }
}

.c-image-widget__data {
  clear: both;
}

.c-image-widget__file-size {
  margin-right: $spacing-unit;
  font-size: 0.67em;
  color: $color-gray-60;
}

.c-image-widget__upload-button {
  html.js & {
    display: none;
  }
  .c-image-widget.has-file & {
    display: block;
  }
}
