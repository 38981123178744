/*------------------------------------*\
    #SVG IMAGES
\*------------------------------------*/

.c-svg {
  display: inline-block;
  position: relative;
  vertical-align: baseline;
  width: 1em;
  height: 1em;
}

.c-svg--layout {
  width: 1.309em;
  font-size: 60px;
  @include mq(sm) {
    font-size: 95px;
  }
}
