/*------------------------------------*\
    #AUCTION ITEM DISPLAY
\*------------------------------------*/

.c-ai-display {
}

.c-ai-display__listing-header {
  z-index: 4;
  .c-ai-display--full & {
    @include setPropFromVar(background-color, $var-color-bg-2);
    &.is-stuck {
      @include hr-bottom();
    }
  }
  .c-ai-display--admin & {
    @include setPropFromVar(background-color, $var-color-bg-2);
    @include mq($until: md) {
      background-color: white;
      &.is-stuck {
        @include hr-bottom($extend: $spacing-unit);
      }
    }
  }
}

.c-ai-display__items {
  clear: both;
  .c-ai-display--admin & {
    @include mq(md) {
      @include setPropFromVar(background-color, $var-color-bg-3);
    }
  }
}

.c-ai-display__footer {
  margin: $spacing-unit 0;
  @include font-size($font-size-12, 1.5);
  @include mq(md) {
    @include font-size($font-size-14, 1.5);
  }
}
