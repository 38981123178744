/*------------------------------------*\
    #PAGER
\*------------------------------------*/

.c-pager {
  @include clearfix;
  @include font-size($font-size-12);
  @include mq(md) {
    @include font-size($font-size-15);
    text-align: right;
  }
}

.c-pager__list {
  list-style: none;
  margin: 0;
}

.c-pager__item {
  display: block;
  float: left;
  margin: 0;
}

.c-pager__link,
.c-pager__current,
.c-pager__ellipsis {
  display: inline-block;
  line-height: 1;
  padding: 0.67em;
}

.c-pager__link {
  position: relative;
  @include attention {
    color: white;
    @include setPropFromVar(background-color, $var-color-ui);
  }
  &.is-progress-disabled {
   background-color: $state-disabled-bg;
   color: $state-disabled-text;
  }
}

.c-pager__current {
  color: white;
  @include setPropFromVar(background-color, $var-color-ui);
}

.c-pager__text {
  display: inline;
  .c-pager__item--first &,
  .c-pager__item--previous & {
    margin-left: 0.5em;
  }
  .c-pager__item--next &,
  .c-pager__item--last & {
    margin-right: 0.5em;
  }
}

.c-pager__icon {
  vertical-align: middle;
  font-size: 0.5em;
  .c-pager__item--first &,
  .c-pager__item--previous & {
    transform: scaleX(-1);
  }
}
