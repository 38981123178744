/* ==========================================================================
   #STICKY
   ========================================================================== */

@mixin o-sticky {
  position: sticky;
  top: 0;
}

@supports(position: sticky) {
  .o-sticky {
    @include o-sticky;
    z-index: 1;
  }
}

