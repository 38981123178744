/*------------------------------------*\
    #SHARE LINKS
\*------------------------------------*/

.c-share {
  &--ai {
    //position: absolute;
    //top: 50%;
    //transform: translateY(-50%);
  }
  &--ap {
    text-align: center;
      border: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);;
    padding: $spacing-unit;
    &.c-share--sidebar {
      @include setPropFromVar(background-color, $var-color-bg-2);
      @include mq(mmd) {
        padding: $spacing-unit-med-large;
      }
    }
  }
}

.c-share__title {
  display: inline-block;
  margin: 0 $spacing-unit 0 0;
  vertical-align: bottom;
}

.c-share__list {
  font-size: 0;
}

.c-share__item {
  .c-share--ai & {
    @include mq(md) {
      margin-bottom: 0;
    }
  }
  .c-share--ap & {
    display: inline-block;
    vertical-align: top;
  }
}

.c-share__link {
  $icon-size: 32px;
  @include font-size($icon-size, 1);
  transition: $global-transition-all;
  border-radius: $icon-size * 0.5;

  @include attention {
    color: $color-blue-light;
  }

  .c-share--ap & {
    display: inline-block;
    vertical-align: top;
    margin-right: $spacing-unit-tiny;
    .c-share__item:last-child & {
      margin-right: 0;
    }
  }
  .c-share--ai & {
    position: relative;
    display: block;
    &:before {
      content: '';
      position: absolute;
      top: 1px;
      bottom: 1px;
      width: $icon-size - 2px;
      height: $icon-size - 2px;
      background-color: white;
      border-radius: $icon-size * 0.5;
    }
  }

  &--link {
    .c-share--ap & {
      font-size: $font-size-15;
      line-height: $icon-size;
      @include setPropFromVar(background-color, $var-color-ui);
      color: white;
      padding: 0 $spacing-unit;
      @include attention {
        color: white;
        text-decoration: none;
        background-color: $color-blue-light;
      }
    }
  }
  &--link {
    color: $color-blue-gray-3;
    @include attention {
      color: $color-blue;
    }
  }
  &--twitter {
    color: $color-twitter;
    @include attention {
      color: $color-blue-dark;
    }
  }
  &--facebook {
    color: $color-facebook;
  }

}

.c-share__link-text {
  .c-share--ai & {
    display: none;
  }
}

.c-share__icon {
  .c-share--ap .c-share__link--link & {
    display: none;
  }
}
