/*------------------------------------*\
    #TIMERS
\*------------------------------------*/

.c-timer {
  &--ap & {
    text-align: center;
  }
  &--bidder-overview {
    @include mq($until: sm) {
      display: grid;
      grid-template-columns: auto auto;
    }
  }
}

.c-timer__title {
  margin-bottom: $spacing-unit-small;
  .c-timer--complete & {
    margin-bottom: 0;
  }
  .c-timer--block & {
    @extend %c-heading--gamma;
    @include setPropFromVar(color, $var-color-ai-header-text);
  }
  .c-timer--inline & {
    display: inline;
    margin-bottom: 0;
  }

  .c-timer--small-block & {
    margin-bottom: $spacing-unit-tiny;
  }

  .c-timer--next-expiry & {
    @extend .c-heading--delta-l;
    @include setPropFromVar(color, $var-color-heading-delta);
    margin-bottom: $spacing-unit-small;
  }

  .c-timer--bidder-overview & {
    @include mq($until: sm) {
      margin: $spacing-unit-small $spacing-unit 0 0;
    }
  }

  .c-timer--small & {
    @include setPropFromVar(font-family, $var-font-family-normal);
    font-weight: normal;
    margin-bottom: 0;
  }

}

.c-timer__complete-message {
  .c-timer--small-block & {
    margin-left: $spacing-unit-tiny;
  }
  &:after {
    display: none;
  }
}

.c-timer__body  {
  text-align: left;

  .c-timer--complete & {
    display: none;
  }

  .c-timer--inline & {
    display: inline;
  }

  .c-timer--block & {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
  }

  .c-timer--small-block & {
    margin-left: -$spacing-unit-tiny;
  }

  .c-timer--next-expiry & {
    display: block;
  }
}

.c-timer__element {
  .c-timer--block & {
    display: inline-block;
    white-space: nowrap;
    margin: 0 $spacing-unit-tiny;

    @include mq($from: sm) {
      margin: 0 $spacing-unit-small;
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  .c-timer--block &,
  .c-timer--small-block & {
    display: inline-block;
    text-align: center;
    max-width: 33.33%;
  }
  .c-timer--small .is-within-threshold &,
  .c-timer--small .is-expired & {
    color: $color-red-bright;
  }
  .c-timer--inline .is-expired & {
    display: none;
  }
}

.c-timer__value {
  transition: border-color background-color $global-transition;
  .c-timer--block & {
    @include input-sizing(large);
    @include setPropFromVar(background-color, $var-color-bg-1);
    display: inline-block;
    border-radius: $small-round;
    border: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);;
    position: relative;
    @include setPropFromVar(color, $var-color-text-dark);
    min-width: 3em;

    @include mq(md) {
      min-width: 3.5em;
    }
  }

  .c-timer--small-block & {
    display: inline-block;
    border-radius: $small-round;
    border: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);;
    padding: 7px 0 5px;
    margin: 0 3px;
    position: relative;
    max-width: 100%;
    width: 2.5em;
    @include font-size($font-size-14);
    @include mq(sm) {
      @include font-size($font-size-16);
    }
    @include mq(lg) {
      width: 3em;
    }
  }

  .c-timer--next-expiry & {
    @include setPropFromVar(background-color, $var-color-bg-1);
    margin: 0 5px;
  }

  .c-timer--monitor & {
    @extend %c-heading--delta-l;
    @include font-size($font-size-20);
    width: 2.5em;
  }

  .is-within-threshold & {
    border-color: $color-red-bright;
  }
  .c-timer--block .is-expired &,
  .c-timer--small-block .is-expired & {
    border-color: $color-red-bright;
    background-color: $color-red-bright;
    color: white;
  }
  .c-timer--inline .is-within-threshold &,
  .c-timer--inline .is-expired & {
    color: $color-red-bright;
  }

  .c-timer--block .is-changed &,
  .c-timer--small-block .is-changed & {
    border-color: $color-green-bright;
    background-color: $color-green-bright;
    color: white;
  }
  [data-status="winning"] .c-timer--block .is-changed & {
    border-color: $color-yellow;
    background-color: $color-yellow;
    color: white;
  }
  .c-timer--inline .is-changed & {
    color: $color-green-bright;
  }
}

.c-timer__period {

  .c-timer--block & {
    display: block;
    @include setPropFromVar(color, $var-color-ai-header-text);
    @include font-size($font-size-14);
    padding-top: $spacing-unit-med-tiny;
    line-height: 1;
    @include mq(md) {
      @include font-size($font-size-15, 1);
    }
  }

  .c-timer--small & {
    @include font-size($font-size-14);
    @include mq($from: md) {
      @include font-size($font-size-15);
    }
  }

  .c-timer--small-block & {
    display: block;
    color: $color-gray-70;
    line-height: 1;
    margin: 0 $spacing-unit-tiny;
    @include font-size($font-size-12);
    @include mq(sm) {
      margin: $spacing-unit-tiny $spacing-unit-tiny 0;
      @include font-size($font-size-15);
    }
  }

  .c-timer--next-expiry & {
    @include setPropFromVar(color, $var-color-heading-delta);
    @include font-size($font-size-13);
    margin-top: $spacing-unit-min;
  }

  .c-timer--inline .is-within-threshold &,
  .c-timer--inline .is-expired & {
    color: $color-red-bright;
  }

  .c-timer--inline .is-changed & {
    color: $color-green-bright;
  }


}

.c-timer__extension-note {
  display: block;
  padding-top: $spacing-unit-tiny;
  @include font-size($font-size-14);
  @include mq(md) {
    @include font-size($font-size-15);
  }
}

