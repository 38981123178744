/*------------------------------------*\
    #UTILITIES
\*------------------------------------*/

.u-invisible {
  @mixin invisible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
  }
  @include invisible;
  &-until-focused {
    &:not(:focus) {
      @include invisible;
    }
  }
}

.u-rtl {
  direction: rtl !important;
}

.u-hide {
  display: none !important;
  &--casual {
    display: none;
  }
  &-js {
    html.js &:not(.in) {
      display: none !important;
      &\@lmd {
        @include mq($until: md) {
          display: none !important;
        }
      }
    }
  }
  &-no-js {
    @at-root html.no-js &:not(.in) {
      display: none !important;
    }
  }
  &-on-ajax-success {
    .has-ajax-success &,
    .has-ajax-success-post & {
      display: none !important;
    }
  }
  &-outside-fancybox {
    body > *:not(.fancybox-container) & {
      display: none !important;
    }
  }
  &-mac-os {
    html.is-mac-os & {
      display: none !important;
    }
  }
  &-no-keyboard {
    html:not(.has-keyboard) & {
      display: none !important;
    }
  }
  &-toggled {
    .is-toggled > & {
      display: none !important;
    }
  }
  &-untoggled {
    display: none !important;
    .is-toggled > & {
      display: inline-block !important;
    }
  }
  &-expanded {
    [aria-expanded="true"] & {
      display: none !important;
    }
  }
  &-collapsed {
    [aria-expanded="false"] & {
      display: none !important;
    }
  }
  &-has-file {
    .has-file & {
      display: none !important;
    }
  }
  &-not-has-file {
    display: none !important;
    .has-file & {
      display: inline-block !important;
    }
  }
}

.u-hidden {
  visibility: hidden !important;
}

.u-h_1 {
  height: 1px;
}

.u-show {
  &-only-mac-os {
    html:not(.is-mac-os) & {
      display: none !important;
    }
  }
  &-checked-sibling-grandchild {
    display: none !important;
    .checked + * > * > & {
      display: initial !important;
    }
  }
}

@each $i in ('', '-2', '-3') {
  .u-show-on-ajax-success#{$i} {
    display: none !important;
    .has-ajax-success#{$i} & {
      display: inline !important;
    }
  }
  .u-hide-on-ajax-success#{$i} {
    .has-ajax-success#{$i} & {
      display: none !important;
    }
  }
  .u-visible-on-ajax-success#{$i} {
    opacity: 0 !important;
    .has-ajax-success#{$i} & {
      opacity: 1 !important;
    }
  }
  .u-invisible-on-ajax-success-#{$i} {
    .has-ajax-success-#{$i} & {
      opacity: 0 !important;
    }
  }
  .u-disable-on-ajax-success#{$i} {
    .has-ajax-success#{$i} &,
    &.has-ajax-success#{$i} {
      color: $state-disabled-text !important;
      background-color: $state-disabled-bg !important;
      border-color: $state-disabled-border !important;
      cursor: default !important;
      pointer-events: none !important;
    }
  }
}

@each $bp, $width in $mq-breakpoints {
  .u-hide\@#{$bp} {
    @include mq(#{$bp}) {
      display: none !important;
    }
  }
}
@each $bp, $width in $mq-breakpoints {
  .u-hide\@l#{$bp} {
    @include mq($until: #{$bp}) {
      display: none !important;
    }
  }
}

.u-pos {
  &_rel {
    position: relative !important;
  }
  &_abs {
    position: absolute !important;
  }
  &_b100 {
    bottom: 100% !important;
  }
  &_l100 {
    left: 100% !important;
  }
  &_t0 {
    top: 0 !important;
  }
  &_r0 {
    right: 0 !important;
  }
}

.u-z {
  @each $i in (0, 1, 2, 3) {
    &_#{$i} {
      z-index: $i !important;
    }
  }
}

.u-centreY {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.u-fade-js {
  @at-root html.js & {
    opacity: 0;
    transition: $fade-transition;
    pointer-events: none;
    &.inish {
      opacity: 0.7;
      pointer-events: auto;
    }
    &.in {
      opacity: 1 !important;
      pointer-events: auto;
    }
  }
}

.u-fl {
  &_r {
    float: right !important;
    &\@md {
      @include mq($from: md) {
        float: right !important;
      }
    }
    &\@lg {
      @include mq($from: lg) {
        float: right !important;
      }
    }
    &\@llg {
      @include mq($until: lg) {
        float: right !important;
      }
    }
  }
  &_l {
    float: left !important;
  }
}

.u-cl {
  &_b {
    clear: both !important;
  }
  &_l {
    clear: left !important;
  }
  &_r {
    clear: right !important;
  }
}

// Screenreaders
.u-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;

  &--focusable {
    &:active,
    &:focus {
      position: static;
      width: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      clip: auto;
    }
  }

}

.u-pointer-disabled {
  pointer-events: none !important;
  &\@md {
    @include mq(md) {
      pointer-events: none !important;
    }
  }
}

.u-label_success {
  color: $color-success !important;
}

.u-label_info {
  color: $color-info !important;
}

.u-label_warning {
  color: $color-warning !important;
}

.u-label_danger {
  color: $color-danger !important;
}

.u-scale {
  &_1\/2 {
    transform: scale(0.5) !important;
  }
  &_2\/3 {
    transform: scale(0.67) !important;
  }
  &_3\/2 {
    transform: scale(1.5) !important;
  }
  &_2 {
    transform: scale(2) !important;
  }
}

.u-d {
  &_i {
    display: inline !important;
  }
  &_ib {
    display: inline-block !important;
  }
  &_b {
    display: block !important;
  }
}

.u-ta {
  &_c {
    text-align: center !important;
  }
  &_r {
    text-align: right !important;
  }
  &_l {
    text-align: left !important;
  }
  &_l\@sm {
    @include mq(sm) {
      text-align: left !important;
    }
  }
  &_l\@md {
    @include mq(md) {
      text-align: left !important;
    }
  }
  &_r\@md {
    @include mq(md) {
      text-align: right !important;
    }
  }
}

.u-fs {
  &_tiny {
    font-size: $font-size-12 !important;
    &\@lsm {
      @include mq($until: sm) {
        font-size: $font-size-12 !important;
      }
    }
  }
  &_smaller {
    font-size: $font-size-14 !important;
    &\@sm {
      @include mq(sm) {
        font-size: $font-size-14 !important;
      }
    }
  }
  &_small {
    font-size: $font-size-15 !important;
    &\@lmd {
      @include mq($until: md) {
        font-size: $font-size-15 !important;
      }
    }
  }
  &_large {
    font-size: $font-size-18 !important;
    &\@md {
      @include mq(md) {
        font-size: $font-size-18 !important;
      }
    }
  }
  &_0 {
    font-size: 0 !important;
  }
  &_xs {
    font-size: 0.6em !important;
  }
  &_s {
    font-size: 0.8em !important;
  }
  &_d {
    font-size: 15px !important;
  }
  &_l {
    font-size: 1.2em !important;
  }
  &_xl {
    font-size: 1.5em !important;
  }
  &_xxl {
    font-size: 2em !important;
  }
}

.u-fv {
  &_sc {
    font-variant: small-caps;
  }
}

.u-va {
  &_t {
    vertical-align: top !important;
  }
  &_m {
    vertical-align: middle !important;
  }
  &_a {
    vertical-align: baseline !important;
  }
  &_b {
    vertical-align: bottom !important;
  }
  &_tb {
    vertical-align: text-bottom !important;
  }
}

.u-bw {
  &_r {
    border-right: 1px solid white;
  }
}

.u-border {
  &-none {
    border: none !important;
  }
  &-pseudo-surround {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: - $spacing-unit-small;
      right: - $spacing-unit-small;
      border: 1px solid;
      @include setPropFromVar(border-color, $var-color-border);
    }
  }
}

.u-tc {
  &_w {
    color: white !important;
  }
  &_b {
    color: $color-blue !important;
  }
  &_bl {
    color: $color-blue-light !important;
  }
  &_df {
    @include setPropFromVar(color, $var-color-text-base);
  }
  &_g {
    color: $color-green !important;
  }
  &_r {
    color: $color-red  !important;
  }
  &_gray {
    color: $color-gray-70  !important;
  }
}

.u-bg {
  &_w {
    background-color: white !important;
  }
  &_w {
    background-color: white !important;
  }
  &_g87 {
    background-color: $color-gray-87-blue-tint;
  }
  &_g93 {
    background-color: $color-gray-93-blue-tint;
  }
  &_g95 {
    background-color: $color-gray-95;
  }
  &_g96 {
    @include setPropFromVar(background-color, $var-color-bg-2);
  }
  &_g98 {
    @include setPropFromVar(background-color, $var-color-bg-3);
  }
  &_bl {
    background-color: $color-blue-light !important;
  }
  &_g {
    background-color: $color-green-bright !important;
  }
}

.u-lh {
  &_2\@md {
    @include mq(md) {
      line-height: 2;
    }
  }
  &_2\.5\@md {
    @include mq(md) {
      line-height: 2.5;
    }
  }
}

// Needed so as not to cause validation error in IE conditional element
.u-deprecated-box {
  @extend .o-box--large;
  @include setPropFromVar(background-color, $var-color-bg-3);
  @include setPropFromVar(font-family, $var-font-family-bold);
  @include setPropFromVar(font-weight, $var-font-weight-bold);
}

.u-inherit-ff {
  font-family: inherit !important;
}

.u-mirror-x {
  transform: scaleX(-1) !important;
}

.u-reflect {
  &--x {
    transform: scaleX(-1);
  }
  &--y {
    transform: scaleY(-1);
  }
}

// Animations
.u-anim {
  &_ellipsis {
    @include anim-ellipsis;
  }
}

// AJAX
.u-ajax-fade {
  transition: $fast-transition-all;
  .is-progress-disabled &,
  &.is-progress-disabled,
  &.is-ajax-updating {
    opacity: $opacity-updating;
  }
}

.has-ajax-update {
  box-shadow: 0 0px 10px 0 $color-green-bright;
}

.u-hr_b {
  position: relative;
  @include hr-bottom;
}

// Mousetrap
.u-mousetrap-hint {
  display: none;
  font-size: $font-size-12;
  html[data-show-hints="1"] & {
    display: block;
  }
}

// List style
.u-ls_none {
  list-style: none;
}

// Linked elements
.u-linked {
  &--hide-when-filled {
    .has-linked-filled & {
      display: none;
    }
  }
  &--show-when-filled {
    display: none;
    .has-linked-filled & {
      display: inline-block;
    }
  }
}

// Highlight on 'in'
.u-highlight {
  &.in,
  [data-live-id].in & {
    @include anim-highlight();
  }
  &--bg {
    &.in,
    [data-live-id].in & {
      @include anim-highlight-bg();
    }
  }
}

// Track changes
.u-tc {
  @each $variant in ('highlight', 'highlight-bg') {
    &--#{$variant} {
      &.has-change,
      &.is-changed {
        @if $variant == 'highlight' {
          @include anim-highlight();
        }
        @else if $variant == 'highlight-bg' {
          @include anim-highlight-bg();
        }
      }
      @each $status, $color in $status_colors {
        $attr-selector: '';
        @if $status != '' {
          $attr-selector: '[data-status="#{$status}"]';
        }
        @at-root #{$attr-selector}#{&},
        #{$attr-selector} &,
        [data-status] #{$attr-selector} & { // Nested [data-status] elements are based on the closer parent
          &.has-change,
          &.is-changed {
            @if $variant == 'highlight' {
              @include anim-highlight($color);
            }
            @else if $variant == 'highlight-bg' {
              @include anim-highlight-bg($color);
            }
          }
        }
      }
    }
  }
}

.u-nowrap {
  white-space: nowrap !important;
}

.u-td_lt {
  font-style: normal;
  text-decoration: line-through;
}

.u-columns {
  @each $bp, $width in $mq-breakpoints {
    &_2\@#{$bp} {
      @include mq($from: #{$bp}) {
        text-align: left;
        columns: 2;
        column-gap: $spacing-unit-large;
      }
    }
  }
}















