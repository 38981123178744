/*------------------------------------*\
    #NAV
\*------------------------------------*/

$main-menu-link-top-margin-xs: 8px;
$main-menu-link-top-margin-md: 39px;
$main-menu-link-top-margin-mlg: 42px;

.c-nav {
  font-size: 0;
  @include use-default-fonts;

  // Main nav
  &--main {
    display: flex;
    justify-content: space-between;
  }

  // Composite nav
  &--composite {
    padding-top: $spacing-unit-med-large;
    @include mq(md) {
      display: none;
    }
  }

  // Horizontal tabs
  &--tabs_horizontal {
    position: relative;
  }

  // Vertical tabs
  &--tabs_vertical {
    margin-bottom: $spacing-unit;
    @include o-sticky;
    @include mq(md) {
      margin: 0;
      padding: $spacing-unit 0;

      &.c-nav--auction-item-settings {
        padding-top: $spacing-unit;
        padding-bottom: $spacing-unit;
      }

    }
    @include mq(lg) {
      padding: $spacing-unit-med-large 0;
    }
  }

  // Collapsing tabs
  &--collapse {
    @include mq($until: md) {
      @include font-size($font-size-12);
      overflow: hidden;
    }
  }

}


// Nav wraps
.c-nav__wrap {
  &--info {
    display: none;
    @include mq(md) {
      display: block;
    }
  }
  &--admin,
  &--personal {
    margin-left: auto;
  }
}

// Nav lists
.c-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;

  &--admin {
    @include mq(md) {
      margin-right: -$spacing-unit-small;
    }
  }

  &--tabs {
    @include clearfix;
  }

  // Dropdown menus. See also bootstrap/_dropdowns.scss
  &--dropdown-menu {
    @include mq($until: md) {
      position: relative !important;
      .c-nav--collapse &,
      .c-nav--main & {
        right: 0;
        left: auto;
        top: 100%;
        width: 100%;
        float: none;
      }
      .c-nav--composite & {
        width: 100% !important;
        &.open {
          display: block;
        }
      }
      .c-nav--collapse & {
        @include setPropFromVar(background-color, $var-color-bg-2);
      }
    }
    @include mq(md) {
      .c-nav--collapse & {
        display: none;
      }
    }
  }
  .c-nav--collapse:not(.is-toggled) &--tier_2,
  .c-nav--collapse:not(.is-toggled) &--tier_3 {
    display: none;
  }

  .c-nav--collapse > & {
    @include mq($until: md) {
      overflow: hidden;
      @include setPropFromVar(background-color, $var-color-bg-3);
      position: relative;
      max-height: 42px;
      transition: all 0.2s ease;
      &.is-toggled {
        background-color: transparent;
        max-height: 1000px;
        margin-top: 42px;
      }
    }
  }

  &--tier_2 {
    position: relative;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;

    .c-nav--main & {
      text-align: right;
      right: 0;
      min-width: 180px;
      @include mq(md) {
        text-align: left;
        position: absolute;
        z-index: -1 !important;
        top: $main-menu-link-top-margin-md;
        left: -$spacing-unit-small;
        right: auto;
        @include setPropFromVar(background-color, $var-color-bg-2);
        border: 1px solid;
        @include setPropFromVar(border-color, $var-color-border);;
        padding: $spacing-unit-med-huge 0 $spacing-unit-tiny;
        margin-top: -$spacing-unit-small;
        max-width: 320px;
      }
      @include mq($from: mlg) {
        top: $main-menu-link-top-margin-mlg;
      };
    }

    .c-nav--collapse & {
      position: static;
      padding: $spacing-unit-tiny 0 $spacing-unit-tiny $spacing-unit-large;
    }

    .c-nav--composite & {
      float: none;
    }

  }

  &--tier_3 {
    .c-nav--collapse & {
      padding: $spacing-unit-tiny 0 $spacing-unit-tiny $spacing-unit;
    }
  }

  // Footer menu nav list
  &--footer {
    display: none;
    @include mq(md) {
      display: block;
    }
  }

}


// Nav items
.c-nav__item {
  display: block;
  position: relative;
  direction: ltr;

  &--tab_horizontal {
    @include mq(md) {
      padding: $spacing-unit-tiny $spacing-unit-small 0;
      float: left;
      text-align: center;
    }
    @include mq(lg) {
      padding: $spacing-unit-small $spacing-unit 0;
    }
  }

  &--tab_vertical.c-nav__item--with-cta {
    @include setPropFromVar(background-color, $var-color-bg-3);
    @include mq(md) {
      position: relative;
    }
  }

  .c-nav--main & {
    display: inline-block;
    &:not(.c-nav__item--log-in):not(.c-nav__item--my-home) {
      @include mq($until: md) {
        display: none;
      }
    }
  }

  .c-nav--collapse & {
    @include mq($until: md) {
      position: static;
    }
  }

  .c-nav--main & {
    @include mq(md) {
      position: relative;
      z-index: 12;
      &.dropdown.open {
        z-index: 13;
      }
    }
    @include mq(lg) {
      margin-left: $spacing-unit-small;
    }
  }

  .c-nav--main .c-nav__list--tier_2 > & {
    display: block;
    @include mq(md) {
      margin: 0 $spacing-unit-small;
    }
  }

  &--cta {
    margin-top: -$spacing-unit-small;
    .c-nav--info & {
      float: right;
    }
  }

  &--home {
    .c-nav--main .c-nav__list:not(.c-nav__list--exit-example) & {
      display: none;
    }
  }

}

// Nav links
.c-nav__link {
  @include setPropFromVar(color, $var-color-nav-inactive);
  @include setPropFromVar(font-family, $var-font-family-bolder);
  @include setPropFromVar(font-weight, $var-font-weight-bolder);
  @include font-size($font-size-15);
  @include attention() {
    text-decoration: none;
  }

  .c-nav--main & {
    display: inline-block;
    margin-top: $main-menu-link-top-margin-xs;
    @include setPropFromVar(color, $var-color-nav);
    @include mq(md) {
      @include setPropFromVar(color, $var-color-nav-inactive);
      margin-top: $main-menu-link-top-margin-md;
      padding: $spacing-unit-small $spacing-unit-tiny;
      &:hover,
      &:focus {
        @include setPropFromVar(color, $var-color-nav);
      }
      &.is-active {
        @include setPropFromVar(color, $var-color-nav);
      }
    }
    @include mq(mdl) {
      padding: $spacing-unit-small;
    };
    @include mq(mlg) {
      margin-top: $main-menu-link-top-margin-mlg;
    };
  }

  .c-nav__list--main-menu & {
    margin-top: $main-menu-link-top-margin-xs;
    @include mq($from: md) {
      margin-top: $main-menu-link-top-margin-md;
    }
    @include mq($from: mlg) {
      margin-top: $main-menu-link-top-margin-mlg;
    };
  }

  .c-nav__item--log-in &,
  .c-nav__item--my-home & {
    .c-nav--main & {
      @include mq($until: sm) {
        padding: $spacing-unit-tiny $spacing-unit-small;
        margin: 0 -#{$spacing-unit-small} 0 0;
        @include font-size($font-size-18);
      }
      @include mq($until: md) {
        &.is-active {
          display: none;
        }
      }
    }
  }

  .c-nav__list--composite.c-nav__list--tier_1 > .c-nav__item > & {
    &.is-active-trail {
      @include setPropFromVar(color, $var-color-nav);
    }
  }

  @include mq($until: md) {
    .c-nav--collapse.is-toggled .c-nav__list--tier_3 &,
    .c-nav--collapse.is-toggled .c-nav__list--tier_2 & {
      @include attention {
        color: $color-green;
      }
      &.is-active {
        color: $color-green;
      }
    }
  }

  .c-nav__item--tab_horizontal & {
    position: relative;
  }

  &--dropdown-toggle {
    display: inline-block;
    position: relative;
    .c-nav__icon--toggle {
      margin-left: 5px;
      vertical-align: middle;
    }
    .c-nav__icon--close {
      display: none;
    }
    @at-root .dropdown.open > & {
      .c-nav__icon--toggle {
        transform: scaleY(-1);
      }
      .c-nav__icon--close {
        display: inline-block;
      }
    }
    @include mq(md) {
      .dropdown.open & {
        position: relative;
        &:before {
          content: '';
          display: block;
          position: absolute;
          bottom: -1px;
          right: -1px;
          width: 1px;
          height: 1px;
          border-left: 1px solid;
          @include setPropFromVar(border-left-color, $var-color-bg-2);
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: -1px;
          left: -1px;
          width: 1px;
          height: 1px;
          border-left: 1px solid;
          @include setPropFromVar(border-left-color, $var-color-bg-2);
        }
        .c-nav__icon--toggle {
          transform: scaleY(-1);
        }
        .c-nav__icon--close {
          display: none;
        }
      }
      &.is-active-trail {
        @include setPropFromVar(color, $var-color-nav);
      }
    }

    .c-nav__list--admin.c-nav__list--tier_1 .dropdown.open & {
      @include setPropFromVar(background-color, $var-color-bg-3);
      @include mq(md) {
        background-color: transparent;
      }
    }

    .c-nav__list--composite & {
      position: relative;
      &:after {
        position: absolute;
        right: $spacing-unit;
        top: 50%;
        margin-top: -0.5em;
      }
      @include mq(md) {
        &:after {
          position: static;
          margin-left: 5px;
        }
      }
    }
  }

  &--cta {
    &,
    .c-nav--composite &.c-nav__link {
      color: white;
      @extend .c-button;
      @extend .c-button--small;
    }

    &.is-active,
    &.is-active-trail {
      color: white;
    }

    .c-nav--main & {
      vertical-align: baseline;
      // button--small top padding-top = 13px
      // Normal menu link padding-top = $spacing-unit-small
      margin-top: $main-menu-link-top-margin-md - (13px - $spacing-unit-small);
      margin-left: $spacing-unit-small;
      @include mq($from: mlg) {
        margin-top: $main-menu-link-top-margin-mlg - (13px - $spacing-unit-small);
      };
    }

    .c-nav__list--composite & {
      margin: $spacing-unit-small 0 0 $spacing-unit-large;
    }

    .c-nav__item--tab_vertical & {
      @include mq(md) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .c-nav--main .c-nav__list--tier_1 > .c-nav__item > & {
      margin-top: 36px;
    }

  }

  .c-nav--footer & {
    display: inline-block;
    padding: 0 0 $spacing-unit-small;
    @include setPropFromVar(color, $var-color-text-base);
    @include font-size(15px, 1.6);
    &:hover,
    &:focus {
      @include setPropFromVar(color, $var-color-nav);
    }
  }

  &--quiet {
    @include setPropFromVar(background-color, $var-color-bg-2);
  }

  &--tab_horizontal {
    display: block;
    padding: $spacing-unit-small $spacing-unit-tiny;
    @include font-size($font-size-15, 1.6);
    border-bottom: 2px solid transparent;
    &.is-active,
    &.is-active-trail {
      @include setPropFromVar(color, $var-color-nav);
      @include setPropFromVar(border-color, $var-color-nav);
    }
    &:hover,
    &:focus {
      @include setPropFromVar(color, $var-color-nav);
    }

    @include mq(md) {
      padding: $spacing-unit $spacing-unit-small;
    }

  }

  &--tab_vertical {
    display: block;

    @include mq(md) {
      padding: $spacing-unit-small $spacing-unit;
      &.is-active,
      &.is-active-trail {
        @include setPropFromVar(color, $var-color-ui);
      }
      &:hover,
      &:focus {
        @include setPropFromVar(color, $var-color-ui);
      }
      .c-nav__item--tab_vertical.c-nav__item--with-cta & {
        padding-bottom: 80px;
      }

    }
    @include mq(lg) {
      padding: $spacing-unit-small $spacing-unit-large;
    }

  }

  .c-nav--collapse & {
    // Tabs are placed into a select-like element on lmd
    @include mq($until: md) {
      @include font-size($font-size-12, 1.5);
      opacity: 0;
      transition: $fade-transition;
      padding: $spacing-unit-small + 2px $spacing-unit $spacing-unit-small - 2px;
      border: none;
      @include setPropFromVar(color, $var-color-nav-inactive);
      @include setPropFromVar(background-color, $var-color-bg-3);
      &.is-active,
      &.is-active-trail {
        opacity: 1;
        position: absolute;
        top: ($spacing-unit - 2 * $spacing-unit-small) / 2;
        left: 0;
        width: 100%;
        z-index: 1;
        @include setPropFromVar(background-color, $var-color-bg-2);
      }
      @at-root .is-toggled#{&} {
        opacity: 1;
        position: relative;
        @include setPropFromVar(background-color, $var-color-bg-3);
        &.is-active,
        &.is-active-trail {
          @include setPropFromVar(color, $var-color-nav);
        }
        &:hover {
          @include setPropFromVar(color, $var-color-nav);
          @include setPropFromVar(background-color, $var-color-bg-2);
        }
      }
    }
  }
  @at-root .c-nav--collapse > .c-nav__list > .c-nav__item:first-child > & {
    @include mq($until: md) {
      border-top: none;
    }
  }

  .c-nav--composite &,
  .c-nav--main .c-nav__list--tier_2 & {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &.is-active {
      @include setPropFromVar(color, $var-color-nav);
      &.c-nav__link--cta {
        @include setPropFromVar(background-color, $var-color-nav);
        color: white;
      }
    }
    @include attention() {
      @include setPropFromVar(color, $var-color-nav);
    }
  }

  .c-nav--main .c-nav__list--tier_2 & {
    margin-top: 0;
  }

  .c-nav--composite & {
    padding: $spacing-unit-small $spacing-unit;
  }

  .c-nav__list--composite .c-nav__list--tier_2 & {
    @include setPropFromVar(font-family, $var-font-family-normal);
    padding: $spacing-unit-tiny $spacing-unit;
  }

  .c-nav__list--composite .c-nav__list--tier_2 .c-nav__item:last-child & {
    padding-bottom: $spacing-unit-med-small;
  }

  &--back-to-list {
    .c-nav--tabs_vertical & {
      display: inline-block;
      @extend .c-button--small;
      @extend .c-button--quiet;
      margin: $spacing-unit-small $spacing-unit $spacing-unit;
      @include mq(lg) {
        margin-left: $spacing-unit-large;
      }
    }
  }

  .c-nav--collapse .c-nav__list--tier_2 & {
    background-color: transparent;
    white-space: normal;
    padding: 0 $spacing-unit 0 $spacing-unit-tiny;
    line-height: 2.5;
    &--back-to-list {
      display: block;
      position: relative;
      left: -$spacing-unit;
      &.is-active-trail {
        @include setPropFromVar(color, $var-color-nav-inactive);
      }
    }
  }

  .c-nav--collapse .c-nav__list--tier_2 &--dropdown-toggle {
    padding: 0;
    @include attention {
      text-underline: none;
    }
  }
}

// Composite menu & horiz. tabs toggle (displayed as dropdown on lmd)
.c-nav__toggle {

  @include mq(md) {
    display: none;
  }

  &--composite {
    @include font-size(24px, 1);
    z-index: 1;
    padding: $spacing-unit-tiny $spacing-unit-small;
    margin-left: -$spacing-unit-small;
    &.is-toggled {
      @include font-size(16px, 1);
    }
    @include mq(md) {
      display: none;
    }
  }

  .c-nav--collapse & {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    text-align: right;
    padding: 0 1.25em;
    @include setPropFromVar(color, $var-color-nav-inactive);
    line-height: 42px;
    height: 42px;
    &.is-toggled {
      width: auto;
      left: auto;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        height: 100%;
        width: 42px;
        right: 0;
        top: 0;
        @include setPropFromVar(background-color, $var-color-bg-3);
      }
    }
    @include attention {
      @include setPropFromVar(color, $var-color-nav);
    }
    @include mq(md) {
      display: none;
    }

  }

}

// Titles for tab groups
.c-nav__tab-group-title {
  display: block;
  @include setPropFromVar(font-family, $var-font-family-lighter);
  @include setPropFromVar(color, $var-color-text-light);
  padding: $spacing-unit $spacing-unit-small;

  @include mq(md) {
    @include font-size(15px);
    padding: $spacing-unit-med-large $spacing-unit $spacing-unit-small;
    margin-top: $spacing-unit;
    border-top: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);
    .c-nav__item:first-child & {
      margin-top: 0;
      padding-top: 0;
      border-top: none;
    }
  }
  @include mq(lg) {
    padding: $spacing-unit-med-large $spacing-unit-large $spacing-unit-small;
  }

  .c-nav--collapse .c-nav__list--tier_2 & {
    padding: 0 0 0 $spacing-unit-tiny;
  }

}

// Icons within nav link text
.c-nav__icon {
  position: absolute;
  left: 0;

  .c-nav--composite .c-nav__list--tier_2 & {
    display: none;
  }

  .c-nav--main &--toggle,
  .c-nav--composite &--toggle {
    font-size: 0.75em;
    margin-left: $spacing-unit-small;
    vertical-align: middle;
    margin-right: 1px;
    transition: $rotate-transition;
    right: $spacing-unit-small;
    left: auto;
    top: 50%;
    margin-top: -1em;
    @include mq(md) {
      position: static;
      margin-top: 0;
    }
  }

  .c-nav__toggle--composite & {
    position: relative;
    vertical-align: middle;
  }

  .c-nav--collapse > .c-nav__toggle &--toggle {
    right: $spacing-unit-small;
    font-size: 16px;
    left: auto;
  }
  .c-nav--collapse.is-toggled > .c-nav__toggle &--toggle {
    font-size: 12px;
  }

  .c-nav--collapse > .c-nav__toggle.is-toggled &--toggle {
    @include setPropFromVar(color, $var-color-nav);
  }

  .c-nav--collapse .c-nav__link--dropdown-toggle &--toggle {
    display: none;
  }

  .c-nav--collapse.is-toggled .c-nav__link--dropdown-toggle &--toggle {
    display: inline-block;
    right: $spacing-unit-small;
    top: 50%;
    margin-top: -0.2em;
    left: auto;
  }

  .c-nav__list--tier_2 &--toggle {
    font-size: 0.75em;
  }

  .c-nav__toggle--composite &--cross {
    display: none;
    body.has-viewport-side & {
      display: inline;
    }
  }

  .c-nav__toggle--composite &--menu {
    body.has-viewport-side & {
      display: none;
    }
  }

  .c-nav--collapse & {
    &--cross {
      display: none;
    }
    &--toggle {
      @include mq(md) {
        display: none;
      }
    }
    @at-root .c-nav--collapse.is-toggled#{&} {
      &--cross {
        display: inline;
      }
      &--menu {
        display: none;
      }
    }
  }

  .c-nav--main .c-nav__item--log-in &,
  .c-nav--main .c-nav__item--my-home & {
    @include mq($until: sm) {
      position: static;
    }
  }

}

// Link content (text and/or icon)
.c-nav__title {
  &--with-icon {
    display: inline-block;
    position: relative;
    padding-left: 2em;
  }
  .c-nav__list--composite .c-nav__list--tier_2 & {
    padding-left: 2em;
  }
}

// Link text
.c-nav__text {
  .c-nav--main .c-nav__item--log-in &,
  .c-nav--main .c-nav__item--my-home & {
    @include mq($until: sm) {
      display: none;
    }
  }
}

// Remove button
.c-nav__remove {
  display: none;
  html.js & {
    display: block;
    position: absolute;
    z-index: 1;
    right: $spacing-unit-small;
    top: 50%;
    margin-top: -0.5em;
    cursor: pointer;
    @include font-size(10px, 1);
    @include setPropFromVar(color, $var-color-nav-inactive);
    .c-nav__item:hover & {
      color: #fff;
    }
  }
}

.c-nav__remove-icon {
  transition: $global-transition-all;
  .c-nav__remove:hover & {
    transform: scale(1.5);
  }
}

// Mousetrap hint
.c-nav__mousetrap-hint {
  position: absolute;
  right: 0;
  top: 6px;
  .c-nav--auction-settings & {
    right: 6px;
  }
}

























































