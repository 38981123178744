/*------------------------------------*\
    #ICONS
\*------------------------------------*/

.c-icon {
  display: inline-block;
  position: relative;
  vertical-align: baseline;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;

  &--button-label {
    vertical-align: middle;
  }

  &--tablesort {
    font-size: 0.75em;
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    margin-left: $spacing-unit-tiny;
    opacity: 1;
    transition: $fade-transition;
    .is-progress-disabled & {
      opacity: 0;
    }
  }

  &--ajax-spinner {
    @include spinning(cw);
    animation-play-state: paused;
    opacity: 1;
    .is-progress-disabled & {
      opacity: 0.5;
      animation-play-state: running;
    }
  }

  &--chevron {
    &_small {
      font-size: 0.35em;
    }
  }

  &--home,
  &--person {
    &.c-icon--nav {
      transform: translateY(0.0625em);
    }
  }

  &--gavel,
  &--dollar {
    &.c-icon--nav {
      transform: translateY(0.125em);
    }
  }

}
