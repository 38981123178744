/*------------------------------------*\
    #TEXT ELEMENTS
\*------------------------------------*/

p {
  @include font-size($font-size-14, 1.5);
  @include mq($from: sm) {
    @include font-size($font-size-15, 1.5);
  }
  @include mq(md) {
    @include font-size($font-size-16, 1.5);
  }
}

b,
strong {
  @include setPropFromVar(font-family, $var-font-family-bold);
  @include setPropFromVar(font-weight, $var-font-weight-bold);
}

small {
  @include font-size($font-size-12);
}
