/*------------------------------------*\
    #FILE
\*------------------------------------*/

.c-file {
  @include mq($until: md) {
    @include font-size($font-size-12, 1.5);
  }
}

.c-file__icon {
  margin-right: $spacing-unit-small;
}

.c-file__link {
  word-break: break-word;
}
