/*------------------------------------*\
    #HOVERABLE CONTENT
\*------------------------------------*/

.c-hoverable {
  position: relative;
}

.c-hoverable__default-content {
  .c-hoverable:hover &,
  .c-hoverable:focus & {
    display: none;
  }
}

.c-hoverable__hover-content {
  display: none;
  .c-hoverable:hover &,
  .c-hoverable:focus & {
    display: initial;
  }
}
