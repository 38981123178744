/*------------------------------------*\
    #SLIDERS
\*------------------------------------*/

.c-slider {
  position: relative;
  direction: ltr;

  &--ai-main {
    //@include setPropFromVar(background-color, $var-color-bg-2);
  }
  &--ai-thumbs {
    margin: -$spacing-unit-med-tiny 0 $spacing-unit-small;
  }
}

.c-slider__slides {
  overflow: hidden;
  white-space: nowrap;
  line-height: 0;
}

.c-slider__slide {
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  &,
  html.js &.u-fade-js.inish {
    @include attention {
      opacity: 1;
      outline: none;
    }
  }
  html.js .c-slider--ai-main & {
    height: 0;
    &:first-child {
      height: auto;
    }
  }
  .c-slider--ai-thumbs & {
    cursor: pointer;
    margin-right: 4px;
    transition: $fade-transition;
    > img {
      max-width: 62px;
      max-height: 62px;
    }
    &.is-active {
      opacity: 1;
    }
  }
}

.c-slider__image {
  img {
    margin: 0 auto;
  }
}

.c-slider__full-size-link {
  display: block;
  cursor: zoom-in;
}

.c-slider__controls {
  display: none;
  @include mq($from: md) {
    display: block;
    opacity: 0;
    transition: $fade-transition;
    .c-slider:hover & {
      opacity: 1;
    }
    .c-slider--ai-thumbs & {
      font-size: 0.75em;
    }
  }
}

.c-slider__control {
  display: none; // Slick.js will display when ready
  z-index: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 15%;
  cursor: pointer;
  opacity: 0.25;
  transition: $global-transition-all;
  font-size: 1.25em;
  @include attention {
    opacity: 0.7;
    font-size: 1.5em;
  }
  &--prev {
    left: 0;
  }
  &--next {
    right: 0;
  }

  .c-slider--ai-thumbs & {
    width: 1.5em;
  }

}

.c-slider__control-label {
  position: absolute;
  top: 50%;
  margin-top: -1em;
  padding: 0.5em;
  background-color: $color-gray-30;
  color: white;
  display: flex;
  flex-direction: column;
  .c-slider__control--prev & {
    left: -$spacing-unit-tiny;
  }
  .c-slider__control--next & {
    right: -$spacing-unit-tiny;
  }
}
