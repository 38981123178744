/*------------------------------------*\
    #HELP TIPS
\*------------------------------------*/

.c-tip {
  @extend .o-box;
  @extend .o-box--bordered;
  position: relative;
  margin-bottom: $spacing-unit-med-large;
  clear: both;
  @include mq($until: sm) {
    padding: $spacing-unit-med-small;
  }
  &--auction-item {
    @include mq($until: md) {
        margin-bottom: $spacing-unit;
      background-color: #fff;
    }
  }
}

.c-tip__wrapper {
  position: relative;
  @include font-size($font-size-12, 1.5);
  @include setPropFromVar(color, $var-color-text-base);
  @include mq(sm) {
    padding-left: $font-size-12 * 2 + $spacing-unit;
    &:before {
      font-size: 2em;
      content: '';
      @include svg-icon('info', $fill: $color-green);
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.c-tip__bottom {
  margin-top: $spacing-unit-small;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-tip__do-not-repeat-label {
  @include font-size($font-size-12, 1);
}

