/*------------------------------------*\
    #STATISTICS
\*------------------------------------*/

.c-stats {
  margin-bottom: $spacing-unit;
  @include mq($from: mmd) {
    display: flex;
  }
}

.c-stats__stat {
  display: block;
  margin-bottom: $spacing-unit-small;
  color: inherit;
  @include mq($from: mmd) {
    flex: 1 0 25%;
    display: flex;
    flex-direction: column;
    max-width: 200px;
    padding-right: $spacing-unit;
  }
  &--link {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.c-stats__stat-title {
  display: inline;
  &::after {
    content: ': '
  }
  @include mq($from: mmd) {
    display: block;
    @include font-size($font-size-18);
    &::after {
      display: none;
    }
    .c-stats__stat--link:hover &,
    .c-stats__stat--link:focus & {
      text-decoration: none;
      //@include setPropFromVar(font-family, $var-font-family-bolder);
      //@include setPropFromVar(font-weight, $var-font-weight-bolder);
    }
  }
}

.c-stats__stat-value {
  display: inline;
  @include mq(mmd) {
    display: block;
    @include font-size($font-size-30);
    @include setPropFromVar(font-family, $var-font-family-bold);
    @include setPropFromVar(font-weight, $var-font-weight-bold);
    @include mq($from: mmd) {
      order: -1;
    }
    .c-stats__stat--link:hover &,
    .c-stats__stat--link:focus & {
      text-decoration: none;
    }
  }
}
