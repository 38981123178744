/* ==========================================================================
   #TABLES
   ========================================================================== */

/**
 * A simple object for manipulating the structure of HTML `table`s.
 */

.o-table {
  width: 100%;

  &,
  &:not(.o-table--small):not(.o-table--large):not(.o-table--rows),
  &--small,
  &--large,
  &--rows {
    > thead,
    > tbody {
      > tr {
        > th {
          vertical-align: bottom;
          @include setPropFromVar(background-color, $var-color-bg-3);
          a.is-active {
            position: relative;
          }
        }
      }
    }
  }

  &:not(.not-hoverable) > tbody > tr {
    &:hover,
    &:focus {
      @include setPropFromVar(background-color, $var-color-bg-2);
      &.is-clickable {
        cursor: pointer;
      }
    }
  }

  &:not(.o-table--small):not(.o-table--large):not(.o-table--huge):not(.o-table--rows) {
    @include table-defaults(default, false, false);
  }
  &--small:not(.o-table--rows) {
    @include table-defaults(small, false, false);
  }
  &--large:not(.o-table--rows) {
    @include table-defaults(large, false, false);
  }
  &--huge:not(.o-table--rows) {
    @include table-defaults(huge, false, false);
  }
  &--rows {
    @include table-defaults(default, rows, false);
  }
  &--small.o-table--rows {
    @include table-defaults(small, rows, false);
  }
  &--large.o-table--rows {
    @include table-defaults(large, rows, false);
  }
  &--huge.o-table--rows {
    @include table-defaults(huge, rows, false);
  }

  &--striped {
    > tbody > tr:nth-child(even) {
      background-color: $color-gray-98-blue-tint;
    }
  }

  &--unpadded {
    > thead,
    > tbody {
      > tr {
        > th,
        > td {
          padding: 0 !important;
        }
      }
    }
  }

  &--fixed {
    table-layout: fixed;
  }

  &--rows {
    > tbody {
      > tr {
        > td {
          border-bottom: 1px solid;
          @include setPropFromVar(border-color, $var-color-border);
        }
        &:nth-last-child(1) > td {
          border-bottom: none;
        }
      }
    }
  }

  &--centered {
    th, td {
      text-align: center;
    }
  }

}

.o-table__caption {
  text-align: left;
  margin-bottom: $spacing-unit-small;
}




