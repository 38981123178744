/*------------------------------------*\
    #VIEW OPTIONS
\*------------------------------------*/

.c-view-options {
  line-height: 28px;
  @include font-size($font-size-12, 'none');
  @include mq(md) {
    @include font-size($font-size-15, 'none');
    margin-left: $spacing-unit;
  }
  &--sort-mode {
    @include mq($until: md) {
      display: none;
    }
  }
}

.c-view-options__title {
  display: inline-block;
  margin-right: $spacing-unit-small;
  vertical-align: bottom;
}

.c-view-options__list {
  display: inline-block;
  margin: 0;
  list-style: none;
  position: relative;
  z-index: 1;
  font-size: 0;
}

.c-view-options__item {
  display: inline-block;
}

.c-view-options__link {
  display: inline-block;
  vertical-align: middle;
  padding: 0 6px;

  &:focus,
  html.can-hover &:hover,
  &.is-toggled,
  &.is-active {
    @include setPropFromVar(background-color, $var-color-highlight-contrast);
    @include setPropFromVar(color, $var-color-link);
    text-decoration: none;
  }

  &--view-mode {
    @include font-size($font-size-15, 'none');
  }

  &--sort-mode {
    @include font-size($font-size-12, 'none');
  }

  &--download {
    @include font-size($font-size-15);
    svg {
      vertical-align: text-bottom;
    }
  }

  @include mq($until: md) {
    .c-view-options--admin & {
      border: 1px solid transparent;
      &.is-active,
      &.is-toggled {
        @include setPropFromVar(border-color, $var-color-ui);
      }
    }
  }

  &--filter-toggle {
    @include mq(md) {
      display: none;
    }
  }

  &--disabled {
    pointer-events: none;
  }

}

.c-view-options__link-mousetrap-hint {
  position: absolute;
  top: 100%;
}

.c-view-options__select {
  @include mq(md) {
    display: none;
  }
}

.c-view-options__icon {
  vertical-align: middle;
}
