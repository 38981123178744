/*------------------------------------*\
    #VIEWS
\*------------------------------------*/

.c-view {
  &--air-line-items,
  &--air-commerce-cart-summary {
    @include mq($from: sm) {
      padding: $spacing-unit;
      border: 1px solid;
      @include setPropFromVar(border-color, $var-color-border);
      @include setPropFromVar(background-color, $var-color-bg-3);
      margin-bottom: $spacing-unit-med-large;
    }
    .c-form--commerce-checkout-form-checkout & {
      @include mq(md) {
        margin-bottom: $spacing-unit-large;
      }
    }
  }
}

.c-view__filters {
  padding: $spacing-unit-tiny 0;
  font-size: 0;
  z-index: 2;
  clear: both;
  &.is-stuck {
    background-color: #fff;
    @include mq($until: md) {
      border-bottom: 1px solid;
      @include setPropFromVar(border-color, $var-color-border);
      @include setPropFromVar(background-color, $var-color-bg-2);
      @include undo-wrapper();
    }
  }
  .c-view--display_auction-bid-history & {
    &.is-stuck {
      @include setPropFromVar(background-color, $var-color-bg-3);
    }
  }
}

.c-view__links {
  float: right;
  padding-top: $spacing-unit-tiny;
  line-height: 1;
  display: flex;
  align-items: center;
  @include mq(md) {
    float: none;
    position: absolute;
    top: $spacing-unit-small;
    right: $spacing-unit-small;
    padding-top: 0;
  }
}

.c-view--aa-admin .c-view__header {
  float: right;
  position: relative;
  z-index: 99;
  > p {
    @include font-size($font-size-14);
    margin-bottom: 0;
  }
}

.c-view__link {
  display: inline-block;
}

.c-view__link-mousetrap-hint {
  position: absolute;
  top: 100%;
  margin-top: 3px;
}

.c-view__exposed-form {
  .c-form--view_air-auction-items & {
    @include mq($until: md) {
      margin-bottom: $spacing-unit-small;
    }
  }
}

.c-view__widgets {
  display: flex;
  flex-flow: row wrap;
  margin-right: -$spacing-unit-small;
}

.c-view__widget {
  display: inline-block;
  vertical-align: top;
  margin-right: $spacing-unit-small;
  flex: 1 1 auto;
  min-width: 200px;
  @include mq(md) {
    max-width: 260px;
  }
  .c-view--air-auction-items.c-view--display_table &--sort-by {
    @include mq($from: md) {
      display: none;
    }
  }
  &--submit-button,
  &--reset-button {
    min-width: 0;
    flex-grow: 0;
  }
  &--submit-button {
    margin-right: $spacing-unit-small;
    html.js & {
      @extend .u-invisible;
    }
  }
}

.c-view__vbo-select-info {
  display: none;
  @include mq(md) {
    display: block;
    @include setPropFromVar(background-color, $var-color-bg-3);
    @include setPropFromVar(font-family, $var-font-family-default);
    padding: $spacing-unit-tiny $spacing-unit-tiny 0;
    @include font-size($font-size-14);
  }
  @include mq(lg) {
    padding: $spacing-unit-small $spacing-unit-small 0;
  }
}

.c-view__item {
  .c-view--air-auction-items & {
    &--teaser,
    &--small-teaser,
    &--simple-teaser {
      height: 100%;
      // @see https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items
      min-width: 0;
    }
  }
}

.c-view__content {
  .c-view--air-auction-items.c-view--display_admin-view & {
    @include mq($until: md) {
      margin: 0 -20px;
    }
  }
  .c-view--air-auction-items:not(.c-view--display_table) & {
    @include mq(md) {
      padding-top: $spacing-unit;
    }
  }

  .c-view--air-auction-items.c-view--display_teaser & {
    max-width: 630px;
    margin: 0 auto;
    @include mq($from: md) {
      max-width: none;
    }
    $grid-gap: $spacing-unit;
    // Floating positioning for non-grid enabled browsers
    > * {
      float: left;
      .is-ie & {
        padding: $spacing-unit-small;
      }
      @include mq($from: mmd) {
        width: 50%;
      }
      @include mq(md) {
        width: 33%;
      }
      @include mq($from: lg) {
        width: 25%;
      }
      @include mq($from: xl) {
        width: 20%;
      }
    }
    @supports (display: grid) {
      display: grid;
      grid-column-gap: $grid-gap;
      grid-row-gap: $grid-gap;
      > * {
        width: auto;
      }
      @include mq($from: mmd) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include mq(md) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include mq($from: lg) {
        grid-template-columns: repeat(4, 1fr);
      }
      @include mq($from: xl) {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }

  .c-view--air-auction-items.c-view--display_small-teaser & {
    $grid-gap: $spacing-unit-small;
    > * {
      float: left;
      width: 50%;
      .is-ie & {
        padding: $spacing-unit-tiny;
      }
      @include mq($from: sm) {
        width: 33%;
      }
      @include mq($from: mmd) {
        width: 25%;
      }
      @include mq(md) {
        width: 20%;
      }
      @include mq(mlg) {
        width: 16%;
      }
      @include mq(lg) {
        width: 14%;
      }
      @include mq(xl) {
        width: 12%;
      }
    }
    @supports (display: grid) {
      display: grid;
      grid-column-gap: $grid-gap;
      grid-row-gap: $grid-gap;
      > * {
        width: auto;
      }
      grid-template-columns: repeat(2, 1fr);
      @include mq($from: sm) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include mq(mmd) {
        grid-template-columns: repeat(4, 1fr);
      }
      @include mq(md) {
        grid-template-columns: repeat(5, 1fr);
      }
      @include mq(mlg) {
        grid-template-columns: repeat(6, 1fr);
      }
      @include mq($from: lg) {
        grid-template-columns: repeat(7, 1fr);
      }
      @include mq($from: xl) {
        grid-template-columns: repeat(8, 1fr);
      }
    }
  }

  .c-view--air-auction-items.c-view--display_table & {
    @include setPropFromVar(background-color, $var-color-bg-1);
    padding: 0 $spacing-unit-small;
    @include mq($from: md) {
      padding: $spacing-unit $spacing-unit $spacing-unit-tiny $spacing-unit;
    }
  }

  .c-view--style_default & {
    @at-root .is-ajax-updating#{&} {
      opacity: 0.5;
    }
  }
  .c-view--style_list & {
    @at-root .is-ajax-updating#{&} {
      opacity: 0.5;
    }
  }
  .c-view--style_table & {
    tbody {
      opacity: 1;
      @at-root .is-ajax-updating#{&} {
        opacity: 0.5;
      }
    }
  }
}

.c-view__message {
  @include mq($until: md) {
    border-bottom: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);
    padding-bottom: $spacing-unit-small;
  }
  .c-view--air-auction-items-sorting & {
    @include font-size($font-size-12, 1.5);
    @include mq(md) {
      padding: $spacing-unit-small $spacing-unit-small 0;
    }
  }
}

.c-view__views-form-actions {
  padding: $spacing-unit-small 0;
  @include mq(md) {
    padding: $spacing-unit-small;
  }
}

.c-view__pager {
  @include font-size($font-size-15, 1);
  &--before {
    background-color: white;
    padding-bottom: $spacing-unit-tiny;
  }
  &--after {
    margin: $spacing-unit 0;
    @include font-size($font-size-15, 1);
  }
  &--load-more {
    margin: 0;
  }
  .c-view--tiny & {
    @include font-size($font-size-12, 1);
    @include mq(md) {
      @include font-size($font-size-14, 1);
    }
  }
  .c-view--air-auction-items-admin & {
    margin: 0;
    @include mq($until: md) {
      // Doesn't work with the collapsible filter bar when sticky
      position: static !important;
    }
    @include mq(md) {
      @include setPropFromVar(background-color, $var-color-bg-3);
    }
  }
  .c-view--display_auction-bid-history & {
    @include mq(md) {
      @include setPropFromVar(background-color, $var-color-bg-3);
    }
  }
  .c-view--air-auction-pages & {
    @include setPropFromVar(background-color, $var-color-bg-2);
    &--before {
      padding-bottom: $spacing-unit-small;
    }
  }
}

.c-view__footer {
  padding-top: $spacing-unit-small;
  .c-view--air-credit-bundle-line-items & {
    padding: $spacing-unit-small 0 $spacing-unit;
    @include mq($from: md) {
      padding-bottom: $spacing-unit-large;
    }
  }
  .c-view--air-auction-items-admin & {
    @include mq($from: md) {
      @include setPropFromVar(background-color, $var-color-bg-2);
    }
  }
  .c-view--air-commerce-cart-summary &,
  .c-view--air-line-items & {
    padding-top: 0;
  }
}

.c-view__footer-item {
  @include font-size($font-size-12, 1.5);
}

.c-view__view-empty {
  & > *:last-child {
    margin-bottom: 0 !important;
  }
}
