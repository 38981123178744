/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/

// Buttons use input sizing apart font size and horizontal padding
$button-font-size-tiny:  12px;
$button-font-size-small: 13px;
$button-font-size:       15px;

$button-padding-horizontal:               40px;
$button-padding-horizontal-wide:          60px;
$button-padding-horizontal-narrow:        16px;
$button-padding-horizontal-tiny:          12px;
$button-padding-horizontal-smaller:       20px;
$button-padding-horizontal-small:         24px;
$button-padding-horizontal-small-wide:    36px;

@mixin button-horizontal-padding($size, $breakpoint: all) {
  @if $breakpoint == all {
    @include mq($from: 0px) {
      padding-left: $size;
      padding-right: $size;
    }
    @include mq(sm) {
      padding-left: $size;
      padding-right: $size;
    }
    @include mq(mmd) {
      padding-left: $size;
      padding-right: $size;
    }
    @include mq(md) {
      padding-left: $size;
      padding-right: $size;
    }
    @include mq(mlg) {
      padding-left: $size;
      padding-right: $size;
    }
    @include mq(lg) {
      padding-left: $size;
      padding-right: $size;
    }
    @include mq(xl) {
      padding-left: $size;
      padding-right: $size;
    }
  }
  @else if $breakpoint == lsm {
    @include mq($until: sm) {
      padding-left: $size;
      padding-right: $size;
    }
  }
  @else if $breakpoint == lmd {
    @include mq($until: md) {
      padding-left: $size;
      padding-right: $size;
    }
  }
  @else {
    @include mq($breakpoint) {
      padding-left: $size;
      padding-right: $size;
    }
  }
}

// Button colors
@mixin button-variant($c: white, $bg: $color-blue, $br: $color-blue-light, $ch: $c, $bgh: $bg, $brh: $br) {
  color: $c;
  background-color: $bg;
  border-color: $br;
  &:hover,
  &:focus,
  &:active,
  &.active {
    color: $ch;
    background-color: $bgh;
    border-color: $brh;
  }
}

@mixin button-focus {
  text-decoration: none;
  background-image: none;
  @include setPropFromVar(color, $var-color-button-focus-text);
  @include setPropFromVar(background-color, $var-color-button-focus);
  @include setPropFromVar(border-color, $var-color-button-focus);
}

@mixin button-base {
  @include setPropFromVar(font-family, $var-font-family-bolder);
  @include setPropFromVar(font-weight, $var-font-weight-bolder);
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  user-select: none;
  position: relative;
  border: 1px solid;
  border-radius: $global-radius;
  @include setPropFromVar(color, $var-color-button-text);
  @include setPropFromVar(background-color, $var-color-button-bg);
  @include setPropFromVar(border-color, $var-color-button-focus);
  transition: $fast-transition-all;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  &:hover,
  &:focus,
  &:active,
  &.active {
    @include button-focus;
  }

  &--disabled,
  html.js &.is-disabled-js,
  &.is-disabled,
  &.is-progress-disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      cursor: default;
      pointer-events: none !important;
      @include setPropFromVar(color, $var-state-disabled-text);
      &:not(.c-button--link) {
        @include setPropFromVar(background-color, $var-state-disabled-bg);
        @include setPropFromVar(border-color, $var-state-disabled-br);
      }
    }
  }

  &,
  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    outline: none;
  }
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

// Button sizes
.c-button {
  @include button-base;

  // Font sizes
  @include font-size($button-font-size-tiny);
  @include mq(sm) {
    @include font-size($button-font-size-small);
  }
  @include mq(md) {
    @include font-size($button-font-size);
  }
  @include mq(lg) {
    @include font-size($button-font-size);
  }
  @include mq(xl) {
    @include font-size($button-font-size);
  }

  // Padding and height
  @include input-sizing(normal, $include-font: false);
  @include button-horizontal-padding($button-padding-horizontal-small);
  @include button-horizontal-padding($button-padding-horizontal-small-wide, sm);
  @include button-horizontal-padding($button-padding-horizontal, md);

  &--v-tiny {
    @include apply-style-across-all-breakpoints(font-size, '#{$font-size-12}');
    @include apply-style-across-all-breakpoints(line-height, '#{$input-line-height-tiny}');
    @include apply-style-across-all-breakpoints(padding-top, '#{$input-padding-vertical-v-tiny + 1px}');
    @include apply-style-across-all-breakpoints(padding-bottom, '#{$input-padding-vertical-v-tiny - 1px}');
    @include apply-style-across-all-breakpoints(height, '#{$input-padding-vertical-v-tiny * 2 + $input-line-height-tiny  + 2px}');
    @include button-horizontal-padding($button-padding-horizontal-tiny);
  }
  &--tiny {
    @include apply-style-across-all-breakpoints(font-size, '#{$font-size-12}');
    @include apply-style-across-all-breakpoints(line-height, '#{$input-line-height-tiny}');
    @include apply-style-across-all-breakpoints(padding-top, '#{$input-padding-vertical-tiny + 1px}');
    @include apply-style-across-all-breakpoints(padding-bottom, '#{$input-padding-vertical-tiny - 1px}');
    @include apply-style-across-all-breakpoints(height, '#{$input-padding-vertical-tiny * 2 + $input-line-height-tiny  + 2px}');
    @include button-horizontal-padding($button-padding-horizontal-tiny);
  }
  &--tiny\@lmd {
    @include mq($until: md) {
      @include input-sizing(tiny, $include-font: false);
    }
  }
  &--smaller {
    @include button-horizontal-padding($button-padding-horizontal-smaller);
  }
  &--small,
  &--smaller,
  &--small-wide {
    @include input-sizing(small, $include-font: false);
    @include button-horizontal-padding($button-padding-horizontal-small, sm);
  }
  &--small-wide {
    @include button-horizontal-padding($button-padding-horizontal-small-wide);
  }
  &--small {
    &\@lmd {
      @include mq($until: md) {
        @include input-sizing(small, $include-font: false);
      }
      @include button-horizontal-padding($button-padding-horizontal-smaller, lmd);
    }
  }
  &--large {
    @include mq($from: md) {
      @include input-sizing(large, $include-font: false);
    }
    @include button-horizontal-padding($button-padding-horizontal-small-wide);
    @include button-horizontal-padding($button-padding-horizontal, sm);
  }

  &--huge {
    @include input-sizing(huge, $include-font: false);
    @include button-horizontal-padding($button-padding-horizontal, sm);
  }

  &--wide {
    @include button-horizontal-padding($button-padding-horizontal-wide);
    @include button-horizontal-padding($button-padding-horizontal-wide);
  }

  &--narrow {
    @include button-horizontal-padding($button-padding-horizontal-narrow);
  }
}

// Square button
.c-button--square {
  border-radius: 0;
}

// Fuctional variants
.c-button--primary {
  @include setPropFromVar(background-color, $var-color-button-primary);
  @include setPropFromVar(border-color, $var-color-button-primary);
  &:hover,
  &:focus,
  &:active,
  &.active {
    @include setPropFromVar(background-color, $var-color-button-primary-focus);
    @include setPropFromVar(border-color, $var-color-button-primary-focus);
  }
}

.c-button--cancel,
.c-button--danger {
  @include button-variant($bg: $color-red, $br: $color-red-dark, $bgh: $color-red-bright);
}

.c-button--safe-cancel {
  @include button-variant($bg: $color-blue, $br: $color-blue-light, $bgh: $color-blue-light);
}

@mixin c-button--revert {
  @include button-variant($color-gray-40, white, $color-gray-87-blue-tint, white, $state-danger-bg-hover, $state-danger-border);
}
.c-button--revert {
  @include c-button--revert;
}

.c-button--loud {
  @include button-variant($bg: $color-red-bright, $br: $color-red-rich, $bgh: $color-red-rich);
}

.c-button--back {
  @include button-variant($color-gray-40, white, $color-gray-87-blue-tint, white, $color-gray-35-blue-tint, $color-gray-40);
}

.c-button--quiet {
  @include setPropFromVar(border-color, $var-color-border);
  @include setPropFromVar(color, $var-color-ui);
  @include setPropFromVar(background-color, $var-color-bg-2);
  &:hover,
  &:focus,
  &:active,
  &.active {
    @include setPropFromVar(color, $var-color-button-focus-text);
    @include setPropFromVar(background-color, $var-color-ui);
    @include setPropFromVar(border-color, $var-color-ui);
  }
}

.c-button--quiet-light {
  @include setPropFromVar(border-color, $var-color-border);
  @include setPropFromVar(color, $var-color-ui);
  @include setPropFromVar(background-color, $var-color-bg-2);
  &:hover,
  &:focus,
  &:active,
  &.active {
    @include setPropFromVar(color, $var-color-button-focus-text);
    @include setPropFromVar(background-color, $var-color-ui);
    @include setPropFromVar(border-color, $var-color-ui);
  }
}

.c-button--quiet-green {
  @include setPropFromVar(border-color, $var-color-border);
  @include button-variant($color-green, $color-gray-96-blue-tint, $color-gray-87-blue-tint);
  &:hover,
  &:focus,
  &:active,
  &.active {
    @include setPropFromVar(color, $var-color-button-focus-text);
    border-color: $color-green;
    background-color: $color-green;
  }
}

.c-button--v-quiet {
  @include setPropFromVar(border-color, $var-color-border);
  @include setPropFromVar(color, $var-color-ui);
  @include setPropFromVar(background-color, $var-color-bg-3);
  &:hover,
  &:focus,
  &:active,
  &.active {
    @include setPropFromVar(color, $var-color-button-focus-text);
    @include setPropFromVar(background-color, $var-color-ui);
    @include setPropFromVar(border-color, $var-color-ui);
  }
}

.c-button--vv-quiet {
  @include setPropFromVar(border-color, $var-color-border);
  @include setPropFromVar(color, $var-color-text-base);
  @include setPropFromVar(background-color, $var-color-bg-3);
  &:hover,
  &:focus,
  &:active,
  &.active {
    @include setPropFromVar(color, $var-color-button-focus-text);
    @include setPropFromVar(background-color, $var-color-ui);
    @include setPropFromVar(border-color, $var-color-ui);
  }
}

.c-button--vv-quiet_l {
  @extend .c-button--vv-quiet;
  @include mq(md) {
    @include font-size($font-size-18, 1);
  }
}

.c-button--remove,
.c-button--delete {
  @extend .c-button--small;
  @extend .c-button--revert;
}

.c-button--delete-tiny {
  @extend .c-button--tiny;
  @extend .c-button--revert;
}

.c-button--submenu-cta {
  display: none;
  @include mq(md) {
    display: block;
    margin: $spacing-unit;
  }
}

.c-button--back-to-list {
  @include mq(md) {
    margin: 0 $spacing-unit;
    width: calc(100% - 2 * #{$spacing-unit});
  }
  @include mq(lg) {
    margin: 0 $spacing-unit;
    width: calc(100% - 4 * #{$spacing-unit});
  }
}

.c-button__label {
  max-width: 100%;

  &--icon {
    position: relative;
    display: inline-block;
    &_left {
      @extend .c-button__label--icon;
      padding-left: 2em;
    }
    &_right {
      @extend .c-button__label--icon;
      padding-right: 2em;
    }
    .c-button--section-toggle & {
      display: block;
    }

    @mixin properties {
      position: static;
      padding: 0 0.5em;
    }
    .c-button--icon-only & {
      @include properties;
    }
    @each $bp in (sm, mmd, md, lg) {
      .c-button--icon-only\@l#{$bp} & {
        @include mq($until: $bp) {
          @include properties;
        }
      }
    }
  }
  .c-button--reset.is-disabled & {
    display: none;
  }

  .c-button--collapse-toggle & {
    display: block;
  }

}


.c-button__text {
  display: inline-block;
  max-width: 100%;

  @mixin properties {
    display: none;
  }
  .c-button--icon-only & {
    @include properties;
  }
  @each $bp in (sm, mmd, md, lg) {
    .c-button--icon-only\@l#{$bp} & {
      @include mq($until: $bp) {
        @include properties;
      }
    }
  }
  &--show-on-hover {
    display: none;
    .c-button:focus &,
    .c-button:hover & {
      display: inline;
    }
  }
  &--hide-on-hover {
    .c-button:focus &,
    .c-button:hover & {
      display: none;
    }
  }
}

@mixin c-button__icon--mid {
  margin-top: 0;
  left: 50%;
  right: auto;
  top: 50%;
  bottom: auto;
  transform: translate(-50%, -50%);
}

.c-button__icon {
  position: absolute;
  margin-top: -1px;
  @include font-size($font-size-15, 1);
  &--left {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &--right {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &--mid {
    @include c-button__icon--mid;
  }
  &--top {
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }

  .c-button--icon-only &,
  .c-button__label--icon-only & {
    @include c-button__icon--mid;
  }
  @each $bp in (sm, mmd, md, lg) {
    .c-button--icon-only\@l#{$bp} & {
      @include mq($until: $bp) {
        @include c-button__icon--mid;
      }
    }
  }

  .c-button--top-link & {
    font-size: 0.85em;
    top: 3px;
  }
  .c-button--help-tip-toggle & {
    position: static;
    transform: none;
  }
}

.c-button--load-more {
  @extend .c-button--link;
}

// Make a button look and behave like a link
@mixin c-button--link {
  @include setPropFromVar(color, $var-color-link);
  font-family: inherit;
  font-size: inherit;
  text-decoration: none;
  display: inline;
  padding: 0;
  border: none;
  height: auto;
  line-height: inherit;
  width: auto;
  margin: 0;
  border-radius: 0;
  font-weight: normal;
  -webkit-appearance: none;

  @include attention() {
    text-decoration: underline;
  }

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
  }

  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }

  &:hover,
  &:focus {
    @include setPropFromVar(color, $var-color-link-focus);
    text-decoration: underline;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      @include setPropFromVar(color, $var-color-link);
      text-decoration: none;
      background-color: transparent;
    }
  }
}
.c-button--link {
  @include c-button--link;
}

.c-button--top-link {
  @extend .c-button--tiny;
  @extend .c-button--square;
  @extend .c-button--v-quiet;
  @include setPropFromVar(font-family, $var-font-family-normal);
  @include font-size($font-size-10, 1);
  padding: 10px 10px 2px ;
  height: auto !important;
  border: none;
}

@mixin c-button--stretch {
  padding-left: $button-padding-horizontal-narrow;
  padding-right: $button-padding-horizontal-narrow;
  text-align: center;
  width: 100%;
}

.c-button--stretch {
  @include c-button--stretch;
  &\@lsm {
    @include mq($until: sm) {
      @include c-button--stretch;
    }
  }
  &\@lmd {
    @include mq($until: md) {
      @include c-button--stretch;
    }
  }
  &\@mlg {
    @include mq(mlg) {
      @include c-button--stretch;
    }
  }
  &\@lg {
    @include mq(lg) {
      @include c-button--stretch;
    }
  }
}

.c-button--combined {
  &_l {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
  &_m {
    border-radius: 0;
    border-right: none;
  }
  &_r {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.c-button--vbo-toggle {
  border: 1px solid transparent;
  padding: $spacing-unit-tiny;
  height: 27px;
  width: 27px;
  &[disabled] {
    border-color: transparent;
  }
  &:hover,
  &.is-toggled {
    @include setPropFromVar(border-color, $var-color-ui);
  }
}

.c-button--my-home {
  @extend .c-button--vv-quiet;
  @extend .c-button--stretch\@lsm;
  @include mq($until: md) {
    margin-right: $spacing-unit;
  }
  &.c-button--primary {
    &:hover,
    &:focus,
    &:active,
    &.active {
      @include setPropFromVar(background-color, $var-color-button-primary-focus);
    }
  }
}

@mixin c-button--icon-only {
  text-align: center;
}

.c-button--icon-only {
  @include c-button--icon-only;
  @each $bp in (sm, mmd, md, lg) {
    &\@l#{$bp} & {
      @include mq($until: $bp) {
        @include c-button--icon-only;
      }
    }
  }
}

.c-button--admin-options {
  @include c-button--icon-only;
  @extend .c-button--v-quiet;
  height: auto;
  padding: $input-padding-vertical-small $input-padding-horizontal-small;
  @include mq(md) {
    padding: $input-padding-vertical-normal $input-padding-horizontal-normal;
  }
}

.c-button--search {
  &,
  &[disabled] {
    @extend .c-button--link;
    @include setPropFromVar(color, $var-state-disabled-text);
    border-color: transparent;
    display: inline-block;
    height: 56px;
    padding-top: 5px;
    width: 50px;
    text-align: center;
    vertical-align: text-bottom;
    background-color: transparent;
    transition: $global-transition-all;
    .has-focus-within & {
      @include setPropFromVar(color, $var-color-ui);
    }
  }
}

.c-button--view-preview-item {
  @extend .c-button;
  @extend .c-button--quiet-light;
  @extend .c-button--small;
  margin: $spacing-unit-small 0 0 $spacing-unit-small;
}

.c-button--edit-item {
  @extend .c-button;
  @extend .c-button--quiet-light;
}

.c-button--section-toggle {
  @extend .c-button--link;
  width: 100%;
}

.c-button__label-wrapper {
  .c-button--section-toggle & {
    @include mq($until: md) {
      @include setPropFromVar(color, $var-color-text-base);;
    }
    @include setPropFromVar(color, $var-color-ui);
    text-align: left;
    padding: $spacing-unit-small 0;
    transition: $global-transition-all;
    @include mq(md) {
      padding: $spacing-unit 0;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -$spacing-unit-med-large;
      right: -$spacing-unit-med-large;
      border: 1px solid transparent;
    }
    &:focus, &:active {
      outline: none;
    }
  }
  .c-button:focus &:after {
    @include setPropFromVar(border-color, $var-color-ui);
  }
}

.c-button--upgrade-cta {
  @extend .c-button--primary;
  @extend .c-button--small;
}

.c-button__ajax-progress {
  .c-button--tiny & {
    left: 100%;
  }
  .c-button--help-tip-toggle & {
    margin-right: 0;
  }
}

.c-button--collapse-toggle {
  @extend .c-button--link;
  &,
  &:active,
  &:focus,
  &:hover {
    color: inherit;
  }
}

.c-button--tag {
  @include setPropFromVar(color, $var-color-link);
  @include setPropFromVar(background-color, $var-color-highlight-contrast);
  padding: 4px $spacing-unit-tiny 0;
  height: auto;
  @include font-size($font-size-10, 2);
  border: 1px solid transparent;
  font-family: inherit;
  margin: $spacing-unit-tiny $spacing-unit-tiny 0 0;
  border-radius: $small-radius;
  @include attention {
    @include setPropFromVar(color, $var-color-link);
    background-image: none;
    @include setPropFromVar(background-color, $var-color-highlight-contrast);
    text-decoration: none;
    @include setPropFromVar(border-color, $var-color-ui);
    &:after {
      content: 'x';
      position: absolute;
      right: 2px;
      top: 4px;
      font-size: 8px;
      line-height: 0;
    }
  }
  @include mq($from: sm) {
    @include font-size($font-size-12, 2);
  }
  &.c-button--tag--ai-admin {
    @include mq($until: md) {
      @include setPropFromVar(border-color, $var-color-ui);
    }
  }
}

.c-button__mousetrap-hint {
  position: absolute;
  right: 4px;
  top: 0;
  .c-button--help-tip-toggle & {
    top: -12px;
    left: 100%;
  }
}

.c-button--help-tip-toggle {
  @extend .c-button--link;
  font-size: 24px;
  &.c-button--inactive {
    color: $color-gray-80;
    &:hover,
    &:focus {
      @include setPropFromVar(color, $var-color-link-focus);
    }
  }
}

.c-button--search-reset {
  @extend .c-button--small;
  @extend .c-button--vv-quiet;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;

  &.has-linked-focus {
    @include setPropFromVar(border-color, $var-color-ui);
    @include setPropFromVar(box-shadow, $var-input-focus-shadow);
  }
  &:not(.has-linked-filled) {
    @include attention {
      @include button-variant($color-gray-40, $color-gray-98-blue-tint, $color-gray-87-blue-tint);
    }
  }
}

.c-button--line-item-delete {
  @extend .c-button--v-tiny;
  @include mq($until: md) {
    @include c-button--link;
    &,
    &:active,
    &:focus,
    &:hover {
      color: $color-danger;
    }
    .c-button__label {
      padding: 0;
    }
    .c-button__icon {
      position: static;
      margin-left: 0.5em;
      //display: none;
    }
  }

  @include mq($from: md) {
    @include c-button--revert;
  }
}






















