/*------------------------------------*\
    #SPINNER
\*------------------------------------*/

@mixin spinner($size: medium, $style: default, $position: relative) {
  $dim: 24px;
  $border-width: 3px;

  @if $size == tiny {
    $dim: 12px;
    $border-width: 1px;
  }
  @if $size == small {
    $dim: 16px;
    $border-width: 2px;
  }
  @if $size == med-small {
    $dim: 20px;
  }
  @if $size == med-large {
    $dim: 36px;
    $border-width: 4px;
  }
  @if $size == large {
    $dim: 48px;
    $border-width: 6px;
  }

  @if $position == absolute or $position == left or $position == right or $position == middle or $position == top or $position == bottom {
    position: absolute;
    top: 50%;
    margin-top: -0.5 * $dim;
  }
  @else {
    position: relative;
  }
  @if $position == left {
    left: 0;
    right: auto;
  }
  @if $position == right {
    left: auto;
    right: 0;
  }
  @if $position == middle {
    left: 50%;
    right: auto;
    margin-left: -0.5 * $dim;
  }
  @if $position == top {
    top: 0;
    left: 50%;
    margin-top: 0;
    margin-left: -0.5 * $dim;
  }
  @if $position == bottom {
    top: auto;
    bottom: 0;
    left: 50%;
    margin-top: 0;
    margin-left: -0.5 * $dim;
  }

  height: $dim;
  width: $dim;

  &:after {
    content: '';
    display: block;
    @include spinning;
    border-radius: 100%;
    background: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99999;
    height: $dim;
    width: $dim;
    margin-top: -$dim / 2;
    margin-left: -$dim / 2;

    border: $border-width solid rgba(100, 100, 100, 0.4);
    border-top-color: currentColor;
    @if $style == dark {
      border-top-color: rgba(255, 255, 255, 0.6);
    }
    @if $style == light {
      border-color: rgba(200, 200, 200, 0.6);
      border-top-color: rgba(255, 255, 255, 1);
    }
    @if $style == reverse {
      border-color: rgba(200, 200, 200, 0.6);
      @include setPropFromVar(border-top-color, $var-color-ui);
    }
    @if $style == blue {
      border-color: rgba(200, 200, 200, 0.6);
      border-top-color: $color-blue;
    }
    @if $style == ui {
      border-color: rgba(200, 200, 200, 0.6);
      @include setPropFromVar(border-top-color, $var-color-ui);
    }

  }

}
