/*------------------------------------*\
    #BREADCRUMBS
\*------------------------------------*/

.c-breadcrumbs {
}

.c-breadcrumbs__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-breadcrumbs__item {
  display: inline-block;
  padding: 0;
  @include setPropFromVar(font-family, $var-font-family-normal);
  margin-bottom: -1px;
  white-space: nowrap;
  @include font-size($font-size-12, 24px);
  @include mq(md) {
    @include font-size($font-size-14, 54px);
  }
}

.c-breadcrumbs__link,
.c-breadcrumbs__here {
  display: inline-block;
  border-bottom: 1px solid transparent;
  @include setPropFromVar(color, $var-color-text-base);;
  vertical-align: middle;
  padding: 14px 0 12px;
  @include mq($until: sm) {
    max-width: calc(100vw - 180px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @include attention() {
    text-decoration: none;
    @include setPropFromVar(border-color, $var-color-nav);
  }
}

.c-breadcrumbs__separator {
  @include setPropFromVar(fill, $var-color-text-base);
  vertical-align: middle;
  font-size: 8px;
  margin: 0 7px;
  @include mq($until: md) {
    transform: scaleX(-1);
    margin-left: 0;
  }
  @include mq(md) {
    .c-breadcrumbs__item:first-child & {
      display: none;
    }
  }
}

.c-breadcrumbs__here {
  @include setPropFromVar(color, $var-color-nav);
  @include setPropFromVar(border-bottom-color, $var-color-nav);
}
