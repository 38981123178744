/*------------------------------------*\
    #PAGE
\*------------------------------------*/

.c-page {
  &:not(.c-page--print) {
    position: relative;
    min-height: 100%;
  }

  &--auth-register,
  &--get-started,
  &--admin,
  &--checkout,
  &--orders,
  &--bidder-admin,
  &--ai-full:not(.c-page--ai-monitor),
  &--site-contact-form,
  &--donate-confirm,
  &--auction-contact-form,
  &--auction-restricted-access,
  &--bidder-registration-form,
  &--auction-offline,
  &--news,
  &--bundle-admin,
  &--batch {
    background-color: white;
    @include mq(md) {
      @include setPropFromVar(background-color, $var-color-bg-2);
    }
  }
}

.c-page__top {
  @include clearfix;
  position: relative;
  padding-bottom: 25px;
  @include mq($from: mmd) {
    padding-bottom: 28px;
  }
  @include mq($from: md) {
    padding-bottom: 36px;
  }
  @include mq($from: lg) {
    padding-bottom: 36px;
  }
  .c-page--info & {
    @include mq($from: mlg) {
      padding-bottom: 139px;
    }
  }

  .c-page--maintenance & {
    margin: 30px 60px;
    text-align: center;
    @include mq($from: md) {
      width: 33%;
      min-width: 640px;
      margin: 60px auto;
    }
  }
}

.c-page__bottom {
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  .c-page--info & {
    position: static;
  }
}

