/*------------------------------------*\
    #BID FORM
\*------------------------------------*/

.c-bid-form {
  &--fancybox {
    @include mq($from: md) {
    }
  }
  &--review {
  }
}

.c-bid-form__bidding-wrapper {
  @include mq($from: mmd) {
    display: flex;
    justify-content: center;
  }
  @include mq($from: md) {
    flex-wrap: wrap;
  }
  @include mq($from: mlg) {
    flex-wrap: nowrap;
  }
}

.c-bid-form__input-wrapper {
  @include mq($from: mmd) {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @include mq($from: md) {
    flex: 1 0 100%;
  }
  @include mq($from: mlg) {
    flex: 1 0 auto;
  }
}

.c-bid-form__bid-type {
  flex: 1 0 100%;
  margin-top: $spacing-unit-small;
  display: flex;
  justify-content: space-around;
  @include mq($from: mmd) {
    flex: 0 1 auto;
    margin: 0 0 0 $spacing-unit;
    align-items: center;
  }
  @include mq($from: md) {
    flex: 1 0 100%;
    margin: $spacing-unit-small 0 0 0;
    justify-content: center;
  }
  @include mq($from: mlg) {
    flex: 0 1 0;
    margin: 0 $spacing-unit-small;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-content: center;
  }
}

.c-bid-form__title {
  .c-bid-form:not(.c-bid-form--fancybox) & {
    @extend .c-heading--gamma;
    @include setPropFromVar(color, $var-color-ai-header-text);
  }
  @include mq($from: mlg) {
    .c-bid-form--with-bid-type-options & {
      padding-right: 107px;
    }
  }
  .c-bid-form--fancybox & {
    @extend .c-heading--beta;
  }
}

.c-bid-form__button {
  border-radius: 0 0 $global-radius $global-radius;
  width: 100%;
  @include mq(mmd) {
    border-radius: 0 $global-radius $global-radius 0;
    width: auto;
    flex: 0 0 auto;
  }
  @include mq($from: md) {
    flex-grow: 1;
  }
}

.c-bid-form__note {
  padding-top: $spacing-unit-med-tiny;
  @include font-size($font-size-14);
  @include mq(md) {
    @include font-size($font-size-15);
  }
  @include mq($from: mlg) {
    .c-bid-form--with-bid-type-options & {
      padding-right: 107px;
    }
  }
}

.c-bid-form__input-container {
  @include setPropFromVar(color, $var-color-text-placeholder);
  .c-bid-form.is-changed & {
    @include setPropFromVar(color, $var-color-text-dark);
  }
}

.c-bid-form__input {
  border-radius: $global-radius $global-radius 0 0;
  text-align: center;
  @include setPropFromVar(color, $var-color-text-placeholder);
  .c-bid-form.is-changed & {
    @include setPropFromVar(color, $var-color-text-dark);
  }
  @include mq($from: mmd) {
    text-align: left;
    border-radius: $global-radius 0 0 $global-radius;
  }
}

.c-bid-form__messages {
  .c-bid-form--enter-bid & {
    margin: $spacing-unit-small 0 0;
    @include mq(md) {
      position: absolute;
      width: fit-content;
      max-width: 100%;
      z-index: 1000;
    }
  }
}

.c-bid-form__bid-details {
  text-align: left;
  @include mq($from: sm) {
    @include font-size($font-size-16, 1.5);
  }
  .c-bid-form--fancybox & {
    @include setPropFromVar(background-color, $var-color-bg-2);
  }
  border: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  padding: $spacing-unit $spacing-unit-small $spacing-unit-med-tiny;
  margin-bottom: $spacing-unit;
}

.c-bid-form__item-title,
.c-bid-form__cat-no {
  @include setPropFromVar(font-family, $var-font-family-bold);
  @include setPropFromVar(font-weight, $var-font-weight-bold);
  padding: 0;
  @include mq(mmd) {
    @include font-size($font-size-15, 1.5);
    padding: 0 $spacing-unit-small;
  }
  @include mq(md) {
    @include font-size($font-size-16, 1.5);
  }
  @include mq(lg) {
    //@include font-size($font-size-large, 1.5);
  }
}

.c-bid-form__bid-note {
  margin-bottom: $spacing-unit;
}

.c-bid-form__ua-acceptance {
}



















































