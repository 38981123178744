/*------------------------------------*\
    #CONTEXTUAL LINKS
\*------------------------------------*/

.c-contextual-links {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.c-contextual-links__list {
  margin-bottom: 0;
}

.c-contextual-links__button {
  .c-contextual-links.open & {
    z-index: 1001;
    @include setPropFromVar(background-color, $var-color-bg-1);
    border-bottom-color: transparent;
    border-bottom-right-radius: 0;
    // Makes a soft corner
    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 1px;
      position: absolute;
      @include setPropFromVar(background-color, $var-color-bg-4);
      bottom: -1px;
      left: -1px;
    }
    // Covers up a gap in the border
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 1px;
      position: absolute;
      @include setPropFromVar(background-color, $var-color-bg-1);
      bottom: -1px;
      right: -1px;
    }
    @include mq(md) {
      &:before {
        @include setPropFromVar(background-color, $var-color-bg-1);
      }
      &:after {
        @include setPropFromVar(background-color, $var-color-bg-4);
      }
    }
  }
  .c-contextual-links.open & {
    @include setPropFromVar(color, $var-color-ui);
  }
  .c-contextual-links--ai-full & {
    border-color: transparent;
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    @include setPropFromVar(background-color, $var-color-bg-3);
    @include setPropFromVar(color, $var-color-ui);
    padding: $spacing-unit-small $spacing-unit-med-small;
  }
  .c-contextual-links--ai-full.open & {
    @include setPropFromVar(border-color, $var-color-border);
    &:before {
      @include setPropFromVar(background-color, $var-color-bg-1);
    }
    &:after {
      @include setPropFromVar(background-color, $var-color-bg-4);
    }
  }
}

.c-contextual-links__wrapper {
  transform: translateY(-1px);
  @include setPropFromVar(background-color, $var-color-bg-1);
  padding-top: $spacing-unit-tiny;
  border: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);;
  border-radius: 0 $global-radius $global-radius $global-radius;
  position: relative;
  left: 0;
  z-index: 0;
  .c-contextual-links--ai-full & {
    left: auto;
    right: 0;
    border-radius: $global-radius 0 $global-radius $global-radius;
  }
  @include mq(md) {
    border-radius: $global-radius 0 $global-radius $global-radius;
    left: auto;
    right: 0;
  }
}

.c-contextual-links__list {
  @extend .o-list-bare;
  text-align: left;
}

.c-contextual-links__item {
  padding: 0;
}

.c-contextual-links__link {
  display: block;
  min-width: 16em;
  @include setPropFromVar(font-family, $var-font-family-default);
  @include setPropFromVar(color, $var-color-text-base);;
  padding: 0 $spacing-unit-small;
  @include font-size($font-size-12, 2);
  @include mq(md) {
    @include font-size($font-size-15, 2);
  }
  @include attention() {
    @include setPropFromVar(color, $var-color-ui);
    text-decoration: none;
  }
  &.is-progress-disabled {
    position: relative;
  }
}

.c-contextual-links__icon {
  width: 1em;
  margin-right: $spacing-unit-small;
}
