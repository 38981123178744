/* ==========================================================================
   #RESPONSIVE TABLES
   ========================================================================== */

/**
 * Responsive table using display:block and data attributes
 */

.o-table-responsive {
  width: 100%;

  &,
  &:not(.o-table--small):not(.o-table--large):not(.o-table--rows),
  &--small,
  &--large,
  &--rows {
    > tbody,
    > thead {
      > tr {
        > th,
        > td {
          @include mq($until: md) {
            display: block;
            clear: left;
          }
        }
        > th {
          @include mq($until: md) {
            display: none;
          }
          a.is-active {
            position: relative;
          }
        }
        > td {
          @include mq($until: md) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
          }
        }

        > th {
          vertical-align: bottom;
          @include setPropFromVar(background-color, $var-color-bg-3);
        }

        > td:last-child,
        > td.is-last-non-empty {
          @include mq($until: md) {
            position: relative;
            &:after {
              content: '';
              display: block;
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              margin-top: $spacing-unit;
              border-bottom: 1px solid;
              @include setPropFromVar(border-color, $var-color-border);
            }
          }
        }
        &:last-child {
          > td:last-child,
          > td.is-last-non-empty {
            &:after {
              display: none;
            }
          }
        }

        > td {
          @include mq($until: mmd) {
            &:first-child {
              padding-top: $spacing-unit-tiny;
            }
            &:last-child,
            &.is-last-non-empty {
              padding-bottom: $spacing-unit-tiny;
            }
          }
          @include mq($from: mmd, $until: md) {
            &:first-child {
              padding-top: $spacing-unit-small;
            }
            &:last-child,
            &.is-last-non-empty {
              padding-bottom: $spacing-unit-small;
            }
          }
        }

      }
    }
  }

  &:not(.o-table-responsive--small):not(.o-table-responsive--large):not(.o-table-responsive--huge):not(.o-table-responsive--rows) {
    @include table-defaults;
  }
  &--small:not(.o-table-responsive--rows) {
    @include table-defaults(small);
  }
  &--large:not(.o-table-responsive--rows) {
    @include table-defaults(large);
  }
  &--huge:not(.o-table-responsive--rows) {
    @include table-defaults(huge);
  }
  &--rows {
    @include table-defaults(default, rows);
  }
  &--small.o-table-responsive--rows {
    @include table-defaults(small, rows);
  }
  &--large.o-table-responsive--rows {
    @include table-defaults(large, rows);
  }
  &--huge.o-table-responsive--rows {
    @include table-defaults(huge, rows);
  }

  &--striped {
    > tbody > tr:nth-child(even) {
      background-color: $color-gray-98-blue-tint;
    }
  }

  &:not(.not-hoverable) > tbody > tr {
    &:hover,
    &:focus {
      @include mq(md) {
        @include setPropFromVar(background-color, $var-color-bg-2);
        &.is-clickable {
          cursor: pointer;
        }
      }
    }
  }

}

.o-table-responsive--rows {
  > tbody {
    > tr {
      @include mq($from: md) {
        > td {
          border-bottom: 1px solid;
          @include setPropFromVar(border-color, $var-color-border);
        }
        &:nth-last-child(1) > td {
          border-bottom: none;
        }
      }
    }
  }

}

.o-table-responsive__in-cell-header {
  flex: 1 0 50%;
  @include mq(sm) {
    flex-basis: 33%;
  }
  @include mq(md) {
    display: none;
  }
}

.o-table-responsive__cell-wrapper {
  @include mq($until: md) {
    flex: 0 1 67%;
  }
  @include mq($until: sm) {
    flex-basis: 50%;
  }
}


