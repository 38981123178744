///* ========================================================================
//   #REM
//   ======================================================================== */

// Convert any px value into its rem equivalent.

@function inuit-rem($value) {

  @if (type-of($value) == number) {

    @if (unit($value) != "px") {
      @error "`#{$value}` needs to be a pixel value.";
    }

  } @else {
    @error "`#{$value}` needs to be a number.";
  }

  @return $value / $inuit-global-font-size * 1rem;

}


// Mixin to create a declaration whose value is a rem unit. Also provide pixel
// fallback.
//
// .simple-usage {
//   @include inuit-rem(padding, 10px);
// }

@mixin inuit-rem($property, $value) {

  @if (type-of($value) == number) {

    @if (unit($value) != "px") {
      @error "`#{$value}` needs to be a pixel value.";
    }

  } @else {
    @error "`#{$value}` needs to be a number.";
  }

  // We provide a `px` fallback for old IEs not supporting `rem` values.
  #{$property}: $value;

  #{$property}: inuit-rem($value);

}
