/*------------------------------------*\
    #PAGE NAV
\*------------------------------------*/

.c-page-nav {
  transition: $fade-transition;
  &--ai {
    &.c-page-nav--header {
      @include setPropFromVar(background-color, $var-color-ai-header-bg-1);
    }
    &.c-page-nav--footer {
      @include setPropFromVar(background-color, $var-color-ai-header-bg-2);
      @include mq(md) {
        margin-bottom: $spacing-unit;
      }
    }
  }
  &--admin {
    background-color: white;
    padding: $spacing-unit-small 0 $spacing-unit-small;
      border-bottom: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
    margin-bottom: $spacing-unit-small;
    &-ai {
      @include mq(md) {
        @include setPropFromVar(background-color, $var-color-bg-2);
      }
    }
    &-ap {
      margin-bottom: $spacing-unit;
    }
    &.is-stuck {
      border-bottom: none;
      z-index: 99;
      @include hr-bottom($extend: $spacing-unit);
      @include mq(md) {
        @include hr-bottom();
      }
    }
  }
  &--print {
    background-color: transparent !important;
  }
}

.c-page-nav__list {
  margin: 0;
  font-size: 0;
  display: flex;
  .c-page-nav--admin & {
    justify-content: space-between;
  }
}

.c-page-nav__item {
  transition: $fade-transition;
  .c-page-nav--admin-ap & {
    margin: 0 $spacing-unit-tiny;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .c-page-nav--ai & {
    &--next {
      margin-left: auto;
    }
  }
}

.c-page-nav__link {
  display: inline-block;
  position: relative;
  overflow: hidden;

  .c-page-nav--admin-ap & {
    @include mq($until: xl) {
      max-width: 20vw;
    }
    @include mq($until: mlg) {
      max-width: 15vw;
    }
    @include mq($until: md) {
      max-width: 22vw;
    }
    @include mq($until: sm) {
      max-width: 33vw;
    }
  }

  .c-page-nav--ai & {
    @include setPropFromVar(color, $var-page-nav-1);
    @include font-size(12px, 1);
    padding: $spacing-unit-small;
    @include attention {
      @include setPropFromVar(color, $var-page-nav-1);
      @include setPropFromVar(background-color, $var-page-nav-2);
    }

    &--back {
      @include attention {
        @include setPropFromVar(background-color, $var-page-nav-4);
      }
    }
  }

  .c-page-nav--ai.c-page-nav--footer & {
    @include mq(md) {
      @include font-size(16px, 1);
      padding: $spacing-unit $spacing-unit-med-large;
    }
  }

  &.is-ajax-updating {
    cursor: default;
    pointer-events: none !important;

    .c-page-nav--admin & {
      color: $state-disabled-text;
      background-color: $state-disabled-bg;
      border-color: $state-disabled-border;
    }
  }

  .c-page-nav--print & {
    color: $color-gray-44-blue-tint !important;
    background-color: transparent !important;
  }
}

.c-page-nav__link-text {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.c-page-nav__icon {
  vertical-align: middle;
  opacity: 1;
  transition: $fade-transition;
  .c-page-nav__link--back &,
  .c-page-nav__link--prev & {
    transform: scaleX(-1);
    .c-page-nav--ai & {
      margin-right: 2/3 * 1em;
    }
  }
  .c-page-nav__link--next & {
    .c-page-nav--ai & {
      margin-left: 2/3 * 1em;
    }
  }
  .c-page-nav__link.is-ajax-updating &,
  .c-page-nav__link.is-progress-disabled & {
    @include delayed-transparency(0.5s);
  }
}
