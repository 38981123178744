/*------------------------------------*\
    #LINKS
\*------------------------------------*/

a {
  text-decoration: none;
  @include setPropFromVar(color, $var-color-link);
  transition: color $fast-transition;
  @include setPropFromVar(outline-color, $var-color-link);

  &:hover,
  &:focus,
  .is-clickable &.is-hovered {
    text-decoration: underline;
    @include setPropFromVar(color, $var-color-link-focus);
  }

  > svg {
    // cf https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/7998724/
    pointer-events: none;
  }

  &[disabled] {
    pointer-events: none;
  }

}
