/*------------------------------------*\
    #BLOCK LINKS
\*------------------------------------*/

.c-block-links {}

.c-block-links__list {
  @extend .o-list-bare;
  font-size: 0;
}

.c-block-links__item {
  display: inline-block;
  vertical-align: bottom;

  .c-block-links--help & {
    margin-right: $spacing-unit-small;
    box-shadow: -5px 5px 0 $color-blue-shadow;
    &:last-child {
      margin-right: 0;
    }
    @include mq(md) {
      margin-right: $spacing-unit-med-small;
      box-shadow: -8px 8px 0 $color-blue-shadow;
    }
    @include mq(lg) {
      margin-right: $spacing-unit;
      box-shadow: -10px 10px 0 $color-blue-shadow;
    }
  }
}

.c-block-links__link {
  display: inline-block;
  color: white;
  text-align: center;
  background-color: $color-blue-v-light;
  transition: $fast-transition-all;
  @include attention {
    color: white;
    text-decoration: none;
    background-color: $color-blue-dark;
  }

  .c-block-links--help & {
    height: 36px;
    @include mq(md) {
      height: 64px;
    }
    @include mq(lg) {
      height: 86px;
    }
  }
  .c-block-links--help .c-block-links__item:first-child & {
    @include setPropFromVar(font-family, $var-font-family-bolder);
    @include setPropFromVar(font-weight, $var-font-weight-bolder);
    @include font-size(14px, 1.5);
    padding: $spacing-unit-med-tiny $spacing-unit;
    @include mq(md) {
      @include font-size($font-size-18, 1.5);
      padding: $spacing-unit-med-small $spacing-unit-med-large 0;
    }
    @include mq(lg) {
      @include font-size(26px, 1.5);
      padding: $spacing-unit $spacing-unit-large 0;
    }
  }
  .c-block-links--help .c-block-links__item:not(:first-child) & {
    font-size: 14px;
    line-height: 44px;
    width: 36px;
    @include mq(md) {
      font-size: 24px;
      line-height: 72px;
      width: 64px;
    }
    @include mq(lg) {
      font-size: 32px;
      line-height: 100px;
      width: 86px;
    }
  }

  .c-block-links--footer-contact & {
    padding: 0 0 $spacing-unit-small;
    margin-right: $spacing-unit-tiny;
  }

  .c-block-links--footer-contact & {
    font-size: 14px;
    line-height: 34px;
    height: 26px;
    width: 26px;
    margin-right: $spacing-unit-tiny;
  }
}
