///* ========================================================================
//   #TABLE SIZING
//   ======================================================================== */

@mixin table-defaults($size: default, $rows: false, $responsive: true) {
  > thead,
  > tbody {
    > tr {

      > td,
      > th {
        @include font-size($font-size-13, 1.5);
        @if ($responsive == false) {
          @if ($rows)  {
            padding: $spacing-unit-tiny 0;
          }
        }
        @include mq($from: mmd) {
          @include font-size($font-size-14, 1.5);
          padding: $spacing-unit-min $spacing-unit-small;
          @if ($rows and $responsive == false) {
            padding: $spacing-unit-tiny $spacing-unit-small;
          }
        }
        @include mq(md) {
          @include font-size($font-size-15, 1.5);
          padding: $spacing-unit-tiny $spacing-unit-small;
        }
      }

      @if ($size == small) {
        > th,
        > td {
          @include mq(md) {
            @include font-size($font-size-13, 1.5);
            padding: $spacing-unit-tiny;
          }
          @include mq(lg) {
            @include font-size($font-size-14, 1.5);
          }
        }
      }

      @else if ($size == large) {
        > th,
        > td {
          @if ($responsive == false) {
            padding: $spacing-unit-tiny 0;
          }
          @include mq(mmd) {
            @if ($responsive == false) {
              padding: $spacing-unit-tiny $spacing-unit-small;
            }
          }
          @include mq(mmd) {
            @if ($responsive == false) {
              padding: $spacing-unit-tiny $spacing-unit-small;
            }
          }
          @include mq(mlg) {
            @include font-size($font-size-15, 1.5);
            @if ($rows) {
              padding: $spacing-unit-med-tiny $spacing-unit-small;
            }
          }
          @include mq(lg) {
            @include font-size($font-size-16, 1.5);
          }
        }
      }
      @else if ($size == huge) {
        > th,
        > td {
          @include mq(md) {
            @include font-size($font-size-15);
            padding: $spacing-unit-tiny $spacing-unit-small;
          }
          @include mq(mlg) {
            @include font-size($font-size-18, 1.5);
            @if ($rows) {
              padding: $spacing-unit-small $spacing-unit-tiny;
            }
            @else {
              padding: $spacing-unit-tiny $spacing-unit-tiny;
            }
          }
          @include mq(lg) {
            @include font-size($font-size-20);
          }
        }
      }

      > th {
        @include mq($from: md) {
          padding-top: $spacing-unit-med-small;
        }
      }

      &:last-child > th {
        @include hr-bottom();
        &:not(.is-sticky) {
          position: relative;
        }
      }

    }
  }

  @if ($rows == false) {
    &.not-hoverable {
      > thead,
      > tbody {
        > tr {
          &:first-child > td,
          &.o-table__row--first > td {
            @include mq(md) {
              padding-top: $spacing-unit-small;
            }
          }
          &:last-child > td,
          &.o-table__row--last > td {
            @include mq(md) {
              padding-bottom: $spacing-unit-small;
            }
          }
        }
      }
    }
  }

}
