/*------------------------------------*\
    #DISMISS BUTTON
\*------------------------------------*/

.c-dismiss {
  position: absolute;
  right: 50%;
  top: 50%;
  margin-top: -1.5em;
  margin-right: -1.5em;
  padding: 1em;
  opacity: 0.85;
  transition: all 0.1s ease;
  &:hover,
  &:focus {
    opacity: 1;
  }
}

.c-dismiss__icon {
  transition: all 0.1s ease;
  .c-dismiss:hover & ,
  .c-dismiss:focus & {
    transform: scale(1.25);
  }
}