/*------------------------------------*\
    #Inline links
\*------------------------------------*/

.c-inline-links {}

.c-inline-links__list {
  margin: 0;
  list-style: none;
}

.c-inline-links__item {
  display: inline-block;
}

.c-inline-links__item + .c-inline-links__item {
  &:before {
    content: "\00B7";
    display: inline-block;
    margin: 0 $spacing-unit-tiny;
  }
}

.c-inline-links__link {
  border-bottom: 1px solid transparent;
  @include attention {
    text-decoration: none;
    @include setPropFromVar(border-bottom-color, $var-color-ui);
  }
  &.is-active {
    @include setPropFromVar(border-bottom-color, $var-color-ui);
  }
}
