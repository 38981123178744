/*------------------------------------*\
    #WRAPS
\*------------------------------------*/

.u-wrap {

  &--auction-main {
    padding: $spacing-unit 0;
    @include mq(md) {
      padding-top: $spacing-unit-med-large;
      padding-bottom: $spacing-unit-large;
    }
    @include mq(lg) {
      margin-bottom: 0;
    }
  }

  &--auction-main,
  &--auction-items-admin {
    @include mq(md) {
      min-height: calc(100vh - 92px);
    }
  }

  &--auction-page-node-form,
  &--auction-page-secondary-form {
    height: 100%;
    position: relative;
    @include mq(md) {
      background-color: white;
      padding: $spacing-unit-med-small $spacing-unit-large $spacing-unit-med-large ;
    }
  }

  &--auction-page-secondary-form {
    @include mq($from: md) {
      padding-top: $spacing-unit-large;
    }
  }

  &--bidding-admin,
  &--auction-results,
  &--order-admin {
    padding: 0 0 $spacing-unit-small 0;
    height: 100%;
    position: relative;
    @include mq(md) {
      background-color: white;
      padding: $spacing-unit-med-large $spacing-unit-large;
    }
  }

  &--cart {
    @extend .u-3\/4\@mlg;
    @extend .u-2\/3\@lg;
    margin-bottom: $spacing-unit-large;
  }

  &--my-order-view {
    margin-top: $spacing-unit;
    @include mq($from: md) {
      max-width: 75%;
    }
  }

  &--bidding-admin,
  &--auction-results {
    @include mq($until: md) {
      padding-top: $spacing-unit-small;
    }
  }

  &--admin-no-sidebar {
    position: relative;
    padding: $spacing-unit-small 0 $spacing-unit;
    @include mq(md) {
      background-color: white;
      padding: $spacing-unit-large;
    }
  }

  &--content-checkout,
  &--bidder-profile,
  &--content-orders {
    @include mq(md) {
      background-color: white;
      padding: $spacing-unit-large;
      margin-bottom: $spacing-unit-huge;
    }
  }

  &--legal {
    @include mq(md) {
      background-color: white;
      padding: $spacing-unit-large;
    }
  }
}
















