/*------------------------------------*\
    #LEAD
\*------------------------------------*/

.c-lead {
  @include setPropFromVar(font-family, $var-font-family-normal);
  font-weight: normal;
  @include font-size(16px, 1.5);
  @include mq(md) {
    @include font-size(20px, 1.5);
  }
  &--light {
    @extend .c-lead;
    @include setPropFromVar(font-family, $var-font-family-lighter);
    margin-bottom: 1em;
    @include mq(md) {
      margin-bottom: $spacing-unit;
    }
  }
  &--sm {
    @extend .c-lead;
    @include mq(md) {
      @include font-size(16px, 1.5);
    }
  }
  &--bold {
    @extend .c-lead;
    @include setPropFromVar(font-family, $var-font-family-bolder);
    @include setPropFromVar(font-weight, $var-font-weight-bolder);
    @include mq(md) {
      @include font-size(22px, 1.5);
    }
  }
  &--bold-l {
    @extend .c-lead;
    @include setPropFromVar(font-family, $var-font-family-bolder);
    @include setPropFromVar(font-weight, $var-font-weight-bolder);
    @include mq(md) {
      @include font-size(22px, 1.5);
    }
    @include mq(lg) {
      @include font-size(26px, 1.6);
    }
  }
}
