/*------------------------------------*\
    #PRICE
\*------------------------------------*/

.c-price {
  &--block {
    max-width: 290px;
    margin: 0 auto;
  }
  &--table {
    width: auto;
    min-width: 300px;
    border-top: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);
    &.c-price--views-total {
      margin-top: $spacing-unit-small;
      @include mq($from: mmd) {
        border-top: 1px solid;
        @include setPropFromVar(border-color, $var-color-border);
      }
    }
    &.c-price--auction-line-item,
    &.c-price--commerce_order {
      min-width: 0px;
      width: 100%;
      border-top: none;
    }
    &.c-price--auction-line-item.c-price--single-component {
      min-width: 0px;
      width: auto;
    }
    &.c-price--auction-line-item:not(.c-price--single-component) {
      //margin-top: $spacing-unit-small;
    }
  }
}

.c-price__title,
.c-price__value {
  //.c-price--auction-line-item .c-price__component:not(.c-price__component--total):first-child &,
  .c-price--commerce_order .c-price__component--total & {
    padding-top: $spacing-unit-small;
    border-top: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);
  }
  .c-price--views-total & {
    @include mq($from: md) {
      padding: $spacing-unit-min $spacing-unit-small;
    }
  }

  .c-price__component--total &,
  .c-price__component--amount &,
  .c-price__component--balance & {
    @include setPropFromVar(font-family, $var-font-family-bold);
    @include setPropFromVar(font-weight, $var-font-weight-bold);
  }

  .c-price--auction-line-item .c-price__component--total:not(:first-child) & {
    padding-top: $spacing-unit-small;
    border-top: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);
  }

  .c-price--auction-line-item .c-price__component--before-total & {
    padding-bottom: $spacing-unit-small;
  }

  .c-price--auction-line-item & {
    //@include setPropFromVar(font-family, $var-font-family-default);
  }

}

.c-price__title {
  margin-bottom: $spacing-unit-small;

  .c-price--block & {
    @extend %c-heading--gamma;
    @include setPropFromVar(color, $var-color-ai-header-text);
  }

  .c-price--small-block &,
  .c-price--inline & {
    margin-bottom: 0;
  }

  .c-price--small-block & {
    text-overflow: clip;
    white-space: nowrap;
  }

  .c-price--small & {
    @include setPropFromVar(color, $var-color-text-dark);
    @include setPropFromVar(font-family, $var-font-family-normal);
    font-weight: normal;
    margin-bottom: 0;
  }

  .c-price--inline & {
    display: inline;
  }

  .c-price--auction-line-item.c-price--single-component & {
    position: relative;
    padding-right: 0;
    &:after {
      content: ": ";
    }
  }

}

.c-price__wrapper {
  .c-price--inline & {
    display: inline;
  }
}

.c-price__value {
  .c-price--inline & {
    display: inline;
  }
  .c-price--block & {
    overflow: hidden;
    @include input-sizing(large);
    @include setPropFromVar(color, $var-color-text-dark);
    @include setPropFromVar(background-color, $var-color-bg-1);
    text-align: center;
    padding-left: 74px;
    padding-right: 74px;
    min-width: 160px;
    border-radius: $global-radius;
    border: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);
    @include mq($from: md) {
      padding-left: 0;
      padding-right: 0;
      flex: 1 0 auto;
    }
    @include mq($from: mlg) {
      min-width: 200px;
    }
  }

  .c-price--small-block & {
    display: inline-block;
    max-width: 100%;
    border-radius: $small-round;
    border: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);;
    @include setPropFromVar(color, $var-color-text-dark);
    padding: 7px $spacing-unit-small 5px;
    margin: $spacing-unit-tiny 0;
    min-width: 6em;
    text-align: center;
    @include font-size($font-size-12);
    @include mq($from: sm) {
      @include font-size($font-size-16);
      line-height: $base-line-height;
    }
  }

  .c-price--table & {
    text-align: right !important;
    padding-bottom: 0;
  }

}

.c-price__bidder-info {
  padding-top: $spacing-unit-med-tiny;
  white-space: nowrap;
  @include font-size($font-size-14);
  @include mq(md) {
    @include font-size($font-size-15);
    white-space: normal;
  }
  @include mq($from: lg) {
    white-space: nowrap;
  }
}

