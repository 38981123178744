/* ==========================================================================
   #GRID
   ========================================================================== */

/**
 * CSS3 grid layout.
 */

@mixin o-grid {
  display: grid;
  grid-column-gap: $spacing-unit;
}

.o-grid {
  @include o-grid;
  @each $breakpoint in (sm, md, lg, xl) {
    &\@#{$breakpoint} {
      @include mq($from: $breakpoint) {
        @include o-grid;
      }
    }
  }
  &--a\/a {
    grid-template-columns: auto auto;
    > * {
      @include grid-ie-calc(2, 2, $spacing-unit);
    }
  }
  &--1\/3 {
    grid-template-columns: 1fr 3fr;
    > * {
      @include grid-ie-calc(3, 3, $spacing-unit);
    }
    &\@md {
      @include mq($from: md) {
        grid-template-columns: 1fr 3fr;
        > * {
          @include grid-ie-calc(3, 3, $spacing-unit);
        }
      }
    }
  }
  &--1\/4 {
    grid-template-columns: 1fr 4fr;
    > * {
      @include grid-ie-calc(4, 4, $spacing-unit);
    }
    &\@md {
      @include mq($from: md) {
        grid-template-columns: 1fr 4fr;
        > * {
          @include grid-ie-calc(4, 4, $spacing-unit);
        }
      }
    }
  }
  &--3\/1 {
    grid-template-columns: 3fr 1fr;
    > * {
      @include grid-ie-calc(3, 3, $spacing-unit);
    }
  }
  &--halves {
    grid-template-columns: repeat(2, 1fr);
    > * {
      @include grid-ie-calc(2, 2, $spacing-unit);
    }
  }
  &--thirds {
    grid-template-columns: repeat(3, 1fr);
    > * {
      @include grid-ie-calc(3, 3, $spacing-unit);
    }
  }
  &--quarters {
    grid-template-columns: repeat(4, 1fr);
    &\@md {
      @include mq($from: md) {
        grid-template-columns: repeat(4, 1fr);
        > * {
          @include grid-ie-calc(4, 4, $spacing-unit);
        }
      }
    }
  }
  &--flush {
    grid-column-gap: 0;
  }
  &--large {
    grid-column-gap: $spacing-unit-large;
  }
  &--huge {
    grid-column-gap: $spacing-unit-huge;
  }

}

