/*------------------------------------*\
    #JQUERY UI - MENU
\*------------------------------------*/


.ui-menu {
  list-style: none;
  padding: 4px 8px 2px;
  margin: 0;
  display: block;
  outline: none;
}

.ui-menu .ui-menu {
  margin-top: -3px;
  position: absolute;
}

.ui-menu .ui-menu-item {
  padding: 0;
  width: 100%;
  margin: 2px 0;
  @include mq(md) {
  }
}

.ui-menu .ui-menu-divider {
  margin: 5px -2px 5px -2px;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-width: 1px 0 0 0;
}

.ui-menu .ui-menu-item a {
  text-decoration: none;
  display: block;
  padding: 4px;
  line-height: 1.5;
  min-height: 0; /* support: IE7 */
}

.ui-menu .ui-menu-item a.ui-state-focus,
.ui-menu .ui-menu-item a.ui-state-active {
}

.ui-menu .ui-state-disabled {
  margin: .4em 0 .2em;
  line-height: 1.5;
}

.ui-menu .ui-state-disabled a {
  cursor: default;
}

/* icon support */
.ui-menu-icons {
  position: relative;
}

.ui-menu-icons .ui-menu-item a {
  position: relative;
  padding-left: 2em;
}

/* left-aligned */
.ui-menu .ui-icon {
  position: absolute;
  top: .2em;
  left: .2em;
}

/* right-aligned */
.ui-menu .ui-menu-icon {
  position: static;
  float: right;
}
