/*------------------------------------*\
    #FORMS
\*------------------------------------*/

/**
 * Structure:
 * Simple inputs: container > item > control = input
 * Radios/checkboxes: container > item > group > muliple control-wrappers > control
 * Textareas: container > item > group > textarea-wrapper > control
 * c-form__control is the actual input/textarea element
 */

$inline-form-label-width: 240px;
$small-inline-form-label-width: 200px;
$inline-form-control-width: 400px;
$narrow-form-width: 400px;

.c-form {

  @include font-size($font-size-15);
  @include mq(md) {
    @include font-size($font-size-16);
  }

  &--air-bid-form {
    position: relative;
  }

  &--air-auction-page-node-form {
    @extend .u-3\/4\@mlg;
    @extend .u-2\/3\@lg;
  }

  &--link {
    display: inline;
  }

  &--inline {
    @extend .u-3\/4\@md;
    @extend .u-2\/3\@mlg;
    @extend .u-1\/2\@lg;
  }

  body > *:not(.fancybox-container) & {
    &--commerce-checkout-form:not(.c-form--commerce-checkout-form-complete),
    &--commerce-checkout-form-review,
    &--commerce-checkout-form-reviews,
    &--commerce-checkout-form-checkout,
    &--air-commerce-stripe-refund-form,
    &--commerce-cop-confirm-payment-form {
      @extend .u-3\/4\@mlg;
      @extend .u-2\/3\@lg;
    }
    &--commerce-payment-order-transaction-add-form {
      @extend .u-2\/3\@mlg;
      @extend .u-1\/2\@lg;
    }
  }

  &--add-product-type_product-to-cart {
    display: inline-block;
  }

  &--commerce-payment-order-transaction-add-form {
    margin-top: $spacing-unit-large;
  }

  &--node-air-auction-page {
    padding-top: $spacing-unit-tiny;
  }

  &--node-air-auction-item {
    padding-top: $spacing-unit-small;
  }

  &--views-form-air-commerce-cart-form-default:not(&--views-form-air-commerce-cart-form-default-empty) {
    @include mq($from: sm) {
      padding: $spacing-unit;
      border: 1px solid;
      @include setPropFromVar(border-color, $var-color-border);
      @include setPropFromVar(background-color, $var-color-bg-3);
    }
  }

  &--commerce-checkout-form-review,
  &--commerce-checkout-form-reviews,
  &--user-profile-form,
  &--air-team-form {
    margin-bottom: $spacing-unit-med-large;
    @include mq($from: md) {
      margin-bottom: $spacing-unit-large;
    }
  }

  &--search {
    position: relative;
    border-bottom: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);
    text-align: center;
    background-color: transparent;
    margin-bottom: $spacing-unit-small;

    &:after {
      content: '';
      position: absolute;
      z-index: 2;
      display: inline-block;
      bottom: -1px;
      left: 50%;
      width: 30%;
      margin-left: -15%;
      border-bottom: 2px solid transparent;
      transition: $global-transition-all;
    }

    &.has-focus-within {
      background-color: white;

      &:after {
        @include setPropFromVar(border-color, $var-color-ui);
        width: 100%;
        margin-left: -50%;
      }
    }
  }

  &--views-exposed-form {
    font-size: 0;
    line-height: 1;
  }

  &--vbo-config {
    @include mq(md) {
      min-width: 350px;
    }
  }

}

.c-form__wrapper {
  .c-form--link & {
    display: inline;
  }
}

.c-form__container {
  position: relative;
  transition: $global-transition-all;

  .c-form--single-row & {
    @include mq(mmd) {
      display: inline-block;
      margin-right: $spacing-unit-small;
    }
  }
  &--clearfix {
    @include clearfix;
  }
  &--text-with-format {
    margin-bottom: $spacing-unit !important;
  }

  .c-form--node-air-auction-item .c-form__wrapper--auction-admin > .c-form__container > & {
    @include mq($from: mlg) {
      width: 75%;
    }
  }

  .c-form--node-air-auction-item .c-form__wrapper--auction-admin > .c-form__container > & {
    @include mq($from: mlg) {
      width: 75%;
    }
  }

  .c-form--node-air-auction-item > .c-form__wrapper > &:not(.c-form__actions) {
    @include mq(md) {
      background-color: #fff;
    }
  }

  &--exposed-search {
    display: flex;
  }

  .c-form--air-team-form &--row {
    display: flex;
    align-items: baseline;
    > *:first-child {
      flex-grow: 1;
    }
  }

  &--multiple-value-item-with-remove {
    display: flex;
    align-items: baseline;
  }

  &--500 {
    max-width: 500px;
  }

}

.c-form__title {
  .c-form--node-air-auction-item & {
    margin: 0 0 $spacing-unit-med-large;
    float: left;
    clear: none;
  }
}

.c-form__group {
  @include clearfix;
  margin-bottom: $spacing-unit;
  position: relative;
  &--type_radio,
  &--type_checkbox {
    margin-bottom: $spacing-unit-small;
    .c-form__radios:not(.c-form__radios--inline) &:last-of-type {
      margin-bottom: 0;
    }
    .c-form--inline & {
      clear: left;
      @include mq(lg) {
        clear: none;
      }
    }
    .c-form__checkboxes--inline & {
      display: inline-block;
      margin-right: $spacing-unit;
    }
    .c-form--views-form:not(.c-form--vbo-config) &,
    .c-form--air-help-form & {
      margin-bottom: 0;
    }
  }
  &--type_password-confirm {
    @include clearfix;
    margin-bottom: 0;
  }
  .c-form--commerce-checkout-form-review &--cart-contents,
  .c-form--commerce-checkout-form-reviews &--cart-contents {
    margin-bottom: $spacing-unit;
    @include mq($from: md) {
      margin-bottom: $spacing-unit-large;
    }
  }
  &--name_bid-type {
    margin-bottom: 0;
  }

  &--customer-profile-billing {
    padding: $spacing-unit;
    border: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);
    @include setPropFromVar(background-color, $var-color-bg-3);
    @extend .u-2\/3\@md;
    @extend .u-1\/2\@lg;
  }

  &--name_field-ap-layout\[und\] {
    margin: 0;
    position: relative;
    height: 100%;
  }

  &--vbo-select-all {
    display: inline-block;
  }

  &--element_field-ap-default-item-expiry {
    margin-bottom: 0;
  }

  &--name_field-ai-expiry-initial\[und\]\[0\]\[timezone\] {
    .c-form__fieldset--admin & {
      display: none;
    }
  }

  .c-form__radios--inline & {
    display: inline-block;
    margin-right: $spacing-unit-tiny;
  }

  .c-form__container--tip &--type_checkbox {
    margin-bottom: 0;
  }

  &--name_bid-type {
    @include mq($from: mmd) {
      margin-right: $spacing-unit;
      &:last-child {
        margin-right: 0;
      }
    }
    @include mq($from: mlg) {
      margin-right: 0;
    }
  }

  &--element_field-ap-color-scheme.is-disabled {
    opacity: $opacity-disabled;
  }

  &--confirm-msg-checkbox {
    margin: $spacing-unit-small 0 0;
  }
}

.c-form__control-wrapper {
  clear: both;

  .c-form--single-row & {
    @include mq(mmd) {
      vertical-align: middle;
      clear: none;
    }
  }

  &:not(.c-form__control-wrapper--icon-only) {
    @include mq(mmd) {
      .c-form--inline & {
        clear: none;
        padding-left: $inline-form-label-width;
      }
      .c-form--inline.c-form--small & {
        padding-left: $small-inline-form-label-width;
      }
      .c-form--inline .c-form__fieldset:not(.c-form__fieldset--quiet) & {
        padding-left: $inline-form-label-width - $spacing-unit;
      }
      .c-form--inline.c-form--small .c-form__fieldset:not(.c-form__fieldset--quiet) & {
        padding-left: $small-inline-form-label-width - $spacing-unit;
      }
      .c-form--inline.c-form--small .c-form__fieldset:not(.c-form__fieldset--quiet) .c-form__item--no-lg-indent &,
      .c-form--inline .c-form__fieldset:not(.c-form__fieldset--quiet) .c-form__group--no-lg-indent &,
      .c-form--inline .c-form__item--no-title &,
      .c-form--inline.c-form--single-row & {
        padding-left: 0;
      }
    }
    &.c-form__control-wrapper--radio,
    &.c-form__control-wrapper--checkbox,
    &.c-form__control-wrapper--color {
      display: flex !important;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: $spacing-unit-small;
      .c-form--inline &,
      .c-form--inline .c-form__fieldset:not(.c-form__fieldset--quiet) &,
      .c-form--inline.c-form--small .c-form__fieldset:not(.c-form__fieldset--quiet) & {
        @include mq(mmd) {
          padding-left: 0;
        }
      }
      .c-form--views-form & {
        display: inline-block;
        margin-bottom: 0;
        @include mq(lg) {
          vertical-align: middle;
        }
      }
      .c-form__container--no-form &,
      .c-form__group--confirm-msg-checkbox &,
      .c-form__group--name_do-not-repeat-checkbox & {
        margin-bottom: 0;
        @include mq($from: md) {
          margin-bottom: 0;
        }
      }
      &.c-form__control-wrapper--radio-sm,
      &.c-form__control-wrapper--checkbox-sm {
        margin-bottom: $spacing-unit-tiny;
        @include mq($until: md) {
          margin-bottom: $spacing-unit-med-tiny;
        }
      }
      .c-form__group--element_field-ap-layout & {
        flex-flow: column nowrap;
      }
      &.c-form__control-wrapper--bef-checkbox {
        align-items: flex-start;
      }
      .c-form__group--element_bid-type & {
        margin: 0;
        flex-wrap: nowrap;
        justify-content: left;
        text-align: left;
      }
      .c-form--air-bid-form .c-form__group--element_ua-acceptance &,
      .c-form--buy-now-form .c-form__group--element_ua-acceptance & {
        @include mq($until: sm) {
          align-items: flex-start;
        }
      }
      .c-form--eck--entity--form-add-air-registration-registration &--name_ua-acceptance ,
      .c-form--eck--entity--form-edit-air-registration-registration &--name_ua-acceptance {
        //max-width:
      }
    }
    .c-form__group--align-flex-start & {
      align-items: flex-start;
    }
  }

  .c-form__item--element_bid-amount & {
    @include mq(md) {
      float: left;
    }
  }

  .c-form__group--element_field-ap-layout & {
    display: block;
    height: 100%;
    @include mq($until: md) {
      margin-bottom: 0;
    }
  }

  .c-form:not(.c-form--inline) .c-form__item--type_select & {
    width: 100%;
    max-width: 400px;
  }

  .c-form__item--lockable & {
    position: relative;
  }

  .c-form__group--name_path\[pathauto\] &--checkbox {
    margin-bottom: 0;
  }

  &--telfield {
    .c-form:not(.c-form--locked-inputs) & {
      max-width: 460px;
    }
  }

  .c-form--views-form .c-form__item--element_edit-quantity & {
    display: flex;
    justify-content: flex-end;
  }
}

.c-form__control {

  // All form controls other than buttons start with the equivalent input font size
  &:not(.c-form__control--button) {
    @include input-sizing(normal, $include-font: only);
    .c-form--tiny & {
      @include input-sizing(tiny, $include-font: only);
    }
    .c-form--small & {
      @include input-sizing(small, $include-font: only);
    }
    .c-form--large & {
      @include input-sizing(large, $include-font: only);
    }
  }

  // Use input sizing for text inputs
  .c-form__item--type_password &,
  .c-form__item--type_emailfield &,
  .c-form__item--type_numberfield &,
  .c-form__item--type_searchfield &,
  .c-form__item--type_telfield &,
  .c-form__item--type_textfield & {
    @include input-sizing(normal, $include-font: false);
    .c-form--tiny & {
      @include input-sizing(tiny, $include-font: false);
    }
    .c-form--small & {
      @include input-sizing(small, $include-font: false);
    }
    .c-form--large & {
      @include input-sizing(large, $include-font: false);
    }
  }

  .c-form__item--type_numberfield &--price {
    &_symbol-1 {
      @include apply-style-across-all-breakpoints(padding-left, #{$input-padding-horizontal-small + 20px});
    }
    &_symbol-3 {
      @include apply-style-across-all-breakpoints(padding-left, #{$input-padding-horizontal-small + 44px});
    }
  }

  // 'Item' types are arbitrary HTML so have a min height to match inputs, but no max
  .c-form__item--type_item &:not(.c-form__control--button) {
    @include input-sizing(normal, $include-font: false, $include-height: false);
    .c-form--tiny & {
      @include input-sizing(tiny, $include-font: false, $include-height: false);
      @include mq($until: sm) {
        padding-top: $spacing-unit-tiny;
      }
    }
    .c-form--small & {
      @include input-sizing(small, $include-font: false, $include-height: false);
      @include mq($until: sm) {
        padding-top: $spacing-unit-tiny;
      }
    }
    .c-form--large & {
      @include input-sizing(large, $include-font: false, $include-height: false);
    }
  }

  .c-form__item--type_color & {
    padding: 0;
    width: 1em;
    height: 1em;
    @include setPropFromVar(background-color, $var-color-bg-1);
    border: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);
    border-radius: $global-radius;
    flex-shrink: 0;
    @include font-size(28px);
    @include mq(md) {
      @include font-size(32px);
    }
  }

  &.is-locked {
    position: relative;
    &:before {
      content: '';
      position: absolute;
    }
    .c-form--air-team-form .c-form__container--row & {
      @include mq($from: md) {
        @include setPropFromVar(background-color, $var-color-bg-3);
      }
    }
    &:not(.error) {
      outline: none;
      border-color: transparent;
      @include apply-style-across-all-breakpoints(padding-left, '0px');
    }
  }

  .c-form--tiny &.is-locked,
  .c-form--small &.is-locked {
    @include mq($until: sm) {
      padding-top: 0;
    }
  }
  .c-form--inline &.is-unlocked {
    @include mq($until: md) {
      margin-top: $spacing-unit-tiny;
    }
  }

  .c-form__actions & {
    margin-right: $spacing-unit-small;
    .c-form--views-form-air-commerce-cart-form-default & {
      margin-right: 0;
    }
  }

  .c-form--single-row &:not(.c-form__control--checkbox-button) {
    @include mq(mmd) {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .c-form__item--name_path\[alias\] & {
    padding-left: 160px;
  }

  // The timezone can never be 'wrong'
  .c-form__item.is-error:not(.c-form__item--name_field-ap-default-item-expiry\[und\]\[0\]\[timezone\]\[timezone\]) &:not(.is-locked) {
    border-color: $color-danger;
  }

  .c-form__item--name_path\[alias\] & {
    padding-left: 160px;
  }

  .c-form__item.is-error .c-form__help-block & {
    @include setPropFromVar(border-color, $var-color-border);
  }

  .c-form__textarea-wrapper & {
    display: block;
    margin: 0;
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  &--checkbox:not(.c-form__control--checkbox-button),
  &--checkbox:not(.c-form__control--checkbox-icon),
  &--radio:not(.c-form__control--radio-button),
  &--radio:not(.c-form__control--radio-image) {
    html.js & {
      // Placeholder for iCheck prior to initialising
      line-height: 1;
      cursor: pointer;
      text-align: center;
      -webkit-appearance: none;
      font-size: 28px;
      padding: 28px 28px 0 0;
      @include mq(md) {
        font-size: 32px;
        padding: 32px 32px 0 0;
      }

    }
  }
  &--checkbox-sm:not(.c-form__control--checkbox-button),
  &--checkbox-sm:not(.c-form__control--checkbox-icon),
  &--radio-sm:not(.c-form__control--radio-button),
  &--radio-sm:not(.c-form__control--radio-image) {
    html.js & {
      font-size: 18px;
      padding: 18px 18px 0 0;
    }
  }

  &--checkbox-button,
  &--checkbox-image,
  &--checkbox-icon,
  &--radio-button,
  &--radio-image,
  &--radio-icon {
    display: none;
  }

  &--select {
    @at-root html.js select#{&}:not(.js-no-selectboxit) {
      // Create space that will be filled by SelectBoxIt
      opacity: 0;
      appearance: none;
      display: inline-block;
      height: 56px;
      border: none;
      background-color: transparent;
      max-width: 100%;
    }
    @at-root html.js .c-view__exposed-form select#{&}:not(.js-no-selectboxit) {
      height: 40px;
    }
    @at-root html.js .c-form--vbo select#{&}:not(.js-no-selectboxit) {
      height: 36px;
    }
    @at-root html.js .c-form--small select#{&}:not(.js-no-selectboxit) {
      height: 40px;
    }
  }

  .c-form--locked-inputs .c-form__item--type_item .c-form__control-wrapper > & {
    padding-left: 0;
  }

  .c-form--air-ai-search-form &--search {
    border-color: transparent;
    background-color: transparent;
    display: inline-block;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &--search-submit {
    position: absolute;
    left: 0;
    z-index: 1;
  }

  &--submit-button.has-ajax-success,
  &--submit-button.has-ajax-success-post {
    .c-form--node-air-auction-page &,
    .c-form--node-air-auction-item & {
      opacity: 1;
    }
  }

  .c-form__container--exposed-search .c-form__item--type_textfield & {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  .c-form--air-donation-donate-now-form & {
    max-width: none;
  }

  .c-form__container--multiple-value-item-with-remove &--submit-button {
    margin-left: $spacing-unit;
  }

  .c-form--views-form .c-form__item--element_edit-quantity & {
    text-align: right;
    max-width: 6em;
  }

  .c-form__item--element_translations .c-form__textarea-wrapper & {
    padding: $spacing-unit-small;
    @include font-size($font-size-14, 1.4);
  }

  .c-form--eck--entity--form-add-air-registration-registration .c-form__item--name_card.is-disabled & {
    border: none;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
  }

  .c-form--eck--entity--form-add-air-registration-registration &.c-form__control--button[name="remove_card"] {
    padding-bottom: $spacing-unit-med-small;
    @include mq($from: md) {
      padding-bottom: $spacing-unit-med-large;
    }
  }

  &.error {
    border-color: $color-form-error;
  }

}

.c-form__label {

  @extend %c-heading--gamma;

  margin-bottom: $spacing-unit-small;

  .c-form--large:not(.c-form--air-bid-form) & {
    @extend %c-heading--beta;
  }
  .c-form--tiny &:not(.c-form__label--after),
  .c-form--small &:not(.c-form__label--after) {
    @extend %c-heading--eta;
    @include mq($until: sm) {
      margin-bottom: 0;
    }
  }

  .c-form--inline .c-form__item > &,
  .c-form--inline .c-form__control-wrapper:not(.c-form__control-wrapper--radio):not(.c-form__control-wrapper--checkbox) & {
    @include mq(mmd) {
      float: left;
      clear: left;
      margin-top: $input-padding-vertical-small + 1px;
    }
    @include mq(md) {
      margin-top: $input-padding-vertical-normal + 1px;
    }
    @include mq(lg) {
      width: $inline-form-label-width;
      padding-right: $spacing-unit;
      &:after {
        content: ':';
      }
    }
  }

  .c-form--tiny.c-form--inline .c-form__item &,
  .c-form--small.c-form--inline .c-form__item &,
  .c-form--tiny.c-form--inline .c-form__control-wrapper:not(.c-form__control-wrapper--radio):not(.c-form__control-wrapper--checkbox) &,
  .c-form--small.c-form--inline .c-form__control-wrapper:not(.c-form__control-wrapper--radio):not(.c-form__control-wrapper--checkbox) & {
    @include mq(mmd) {
      width: $small-inline-form-label-width;
      margin-top: $input-padding-vertical-tiny + 1px;
    }
    @include mq(md) {
      margin-top: $input-padding-vertical-tiny + 1px;
    }
  }

  .c-form--locked-inputs & {
    margin-bottom: 0;
  }

  .c-form--single-row & {
    @include mq(mmd) {
      display: inline-block;
      margin-right: $spacing-unit-small;
    }
  }

  .c-form__fieldset:not(.c-form__fieldset--quiet) &:not(.c-form__label--after),
  .c-form__fieldset.c-form__fieldset--not-quiet &:not(.c-form__label--after) {
    @extend %c-heading--epsilon;
  }

  html.has-date .c-form__fieldset .c-form__item--element_field-ap-default-item-expiry & {
    @extend %c-heading--gamma;
    margin-bottom: $spacing-unit-tiny;
    @include mq(md) {
      margin-bottom: $spacing-unit-small;
    }
  }

  .c-form__item--type_radios > & {
    margin-top: 0;
  }

  &--after {
    @include input-sizing($include-font: only);
    &,
    .c-form--node-air-auction-page &,
    .c-form--node-air-auction-item & {
      float: none;
      clear: none;
      vertical-align: middle;
      line-height: inherit;
      display: inline-block;
      width: auto;
      font-family: inherit;
      color: inherit;
      margin: 8px 0 0;
      font-weight: normal;
    }
    .c-form__control-wrapper--checkbox &,
    .c-form__item--type_checkbox &,
    .c-form__item--type_radios &,
    .c-form__group--confirm-msg-checkbox &,
    .c-form__item--type_radio & {
      margin: 0;
    }
  }
  .c-form .c-form__group--element_do-not-repeat-checkbox &--after {
    @include font-size($font-size-12, 1);
  }

  .c-form--commerce-checkout-form-review .c-form__group--customer-profile-billing &,
  .c-form--commerce-checkout-form-reviews .c-form__group--customer-profile-billing & {
    @include setPropFromVar(font-family, $var-font-family-bold);
    @include setPropFromVar(font-weight, $var-font-weight-bold);
    display: inline-block;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
    &:after {
      content: ': ';
    }
  }

  .c-form__control-wrapper--radio &,
  .c-form__control-wrapper--checkbox &,
  .c-form__control-wrapper--color & {
    flex: 1 0 0;
    padding-left: $spacing-unit-small;
    @include mq(sm) {
      padding-left: $spacing-unit-med-small;
    }
  }
  .c-form__control-wrapper--radio .c-form__control--radio-image + &,
  .c-form__control-wrapper--radio .c-form__control--radio-icon + &,
  .c-form__control-wrapper--checkbox .c-form__control--checkbox-image + &,
  .c-form__control-wrapper--checkbox .c-form__control--checkbox-icon + & {
    padding-left: 0;
    @include mq($from: sm) {
      padding-left: 0;
    }
  }

  .c-form__control-wrapper--radio-sm &,
  .c-form__control-wrapper--checkbox-sm & {
    @include mq(sm) {
      padding-left: $spacing-unit-small;
    }
  }

  .c-form__control-wrapper--bef-checkbox & {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    @include font-size($font-size-12, 1.5);
    @include mq(md) {
      @include font-size($font-size-14);
    }
  }

  &.has-tooltip {
    position: relative;
  }

  &--with-image,
  &--with-image.c-form__label--after {
    font-family: inherit;
    color: inherit;
    height: 100%;
    width: 100%;
    display: block;
    margin: 0;
    text-align: center;
    border: 1px solid;
    @include setPropFromVar(border-color, $var-color-border-heavy);;
    border-radius: $large-radius;
    cursor: pointer;
    padding: $spacing-unit 0 $spacing-unit-small;
    &:hover,
    &:focus,
    .c-form__control--radio:checked + &,
    .c-form__control--checkbox:checked + & {
      border-color: $color-green;
      box-shadow: inset 0 0 0 1px $color-green;
    }
  }

  .c-form__control--radio-button + &,
  .c-form__control--checkbox-button + & {
    font-family: inherit;
    color: inherit;
    //height: 100%;
    //width: 100%;
    display: inline-block;
    margin: 0;
    text-align: center;
    border: 1px solid;
    @include setPropFromVar(border-color, $var-color-border-heavy);
    border-radius: $large-radius;
    cursor: pointer;
    padding: $spacing-unit-tiny $spacing-unit-small;
    &:hover,
    &:focus {
      @include setPropFromVar(box-shadow, $var-inset-shadow);
    }
  }
  .c-form__control--radio-button:checked + &,
  .c-form__control--checkbox-button:checked + & {
    @include setPropFromVar(background-color, $var-color-button-focus);
    @include setPropFromVar(color, $var-color-button-focus-text);

  }

  &--with-icon,
  &--with-icon.c-form__label--after {
    margin: 0;
    cursor: pointer;
    html.no-js .c-form__control--radio:checked + &,
    html.no-js .c-form__control--checkbox:checked + & {
      @include setPropFromVar(box-shadow, $var-outset-shadow);

    }
  }

  .c-form__group--cart-contents & {
    margin-bottom: $spacing-unit-small;
  }

  .c-form__item--element_field-ai-image.is-disabled & {
    display: none !important;
  }

  .c-form--air-online-toggle-form & {
    display: none;
  }

  .c-form__radios--no-label & {
    display: none;
  }

  .c-form__group--element_bid-type & {
    @include font-size($font-size-14);
    margin: 0;
    white-space: nowrap;
    flex: 0 1 auto;
  }

  .c-form--air-credits-manual-credits-form & {
    margin-bottom: $spacing-unit-tiny;
  }

  .c-form__control--radio-icon.is-progress-disabled + & {
    visibility: hidden;
  }

  .c-form--user-profile-form .is-highlighted & {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: -10px;
      top: 0;
      bottom: 0;
      border-left: 4px solid $color-danger;
    }
  }

  .c-form--buy-now-form .c-form__group--element_ua-acceptance &,
  .c-form--air-bid-form .c-form__group--element_ua-acceptance & {
    @include font-size($font-size-12, 1.5);
    @include mq($from: mmd) {
      @include font-size($font-size-14, 1.5);
    }
  }

}

.c-form__label-text {
  .c-form__group--name_field-ap-layout\[und\] & {
    display: inline-block;
    padding: 0 $spacing-unit-small;
    height: 2.4em;  // 3 lines
  }
  .c-form__control-wrapper--bef-checkbox & {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @each $name, $font in $custom-fonts {
    [for^=edit-field-ap-font-und-#{$name}] & {
      font-family: $font;
    }
  }
  .c-form--air-bid-form .c-form__wrapper:not(.c-form__wrapper--fancybox) .c-form__container--bid-type & {
    white-space: nowrap;
  }
}

.c-form__label-image-wrapper {
  opacity: 0.5;
  margin-bottom: $spacing-unit-tiny;
  .c-form__label:hover &,
  .c-form__label:focus &,
  .c-form__control--radio:checked + .c-form__label &,
  .c-form__control--checkbox:checked + .c-form__label & {
    opacity: 1;
  }
}

html.can-hover .c-form__label-icon-wrapper {
  .c-form__container--hover-icon & {
    opacity: 0;
  }
  .c-form__container--hover-icon:hover &,
  .c-form__container--hover-icon:focus &,
  .c-form__control--radio:checked + .c-form__label &,
  .c-form__control--checkbox:checked + .c-form__label & {
    opacity: 1;
  }
}

.c-form__label-image,
.c-form__label-icon {
  display: inline-block;
}

.c-form__element-label {
  margin-bottom: $spacing-unit;
}

.c-form__field-suffix {
  margin-top: $spacing-unit-tiny;
}

.c-form__description {
  @include font-size($font-size-15);
  margin-top: $spacing-unit / 2;
  margin-bottom: $spacing-unit;
}

.c-form__fieldset-wrapper {
  &--vbo {
    display: none;
    @include mq(md) {
      display: block;
      @include setPropFromVar(background-color, $var-color-bg-3);
      z-index: 3;
      padding: $spacing-unit-tiny 0 0 $spacing-unit-tiny;
      @include font-size($font-size-14, 1);
    }
    @include mq(lg) {
      padding: $spacing-unit-small 0 0 $spacing-unit-small;
    }
  }
}

.c-form__fieldset {
  position: relative;
  border: none;
  padding: 0;
  margin: 0;
  transition: opacity $fast-transition;
  opacity: 1;
  &--bordered,
  &--bordered-blue {
    border: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);
    padding: $spacing-unit $spacing-unit $spacing-unit-small $spacing-unit;
    margin-bottom: $spacing-unit;
  }
  &--bordered {
    @include setPropFromVar(border-color, $var-color-border);
  }
  &--bordered-blue {
    border-color: $color-blue;
  }
  &--bordered\@md {
    @include mq(md) {
      border: 1px solid;
      @include setPropFromVar(border-color, $var-color-border);
      padding: $spacing-unit $spacing-unit $spacing-unit-small $spacing-unit;
      margin-bottom: $spacing-unit;
    }
  }
  html.js &.is-disabled-js,
  &.is-disabled {
    opacity: $opacity-disabled;
  }

  &--account,
  &--customer-profile-billing,
  &--commerce-marketplace-payment,
  &--commerce-marketplace-payment-multi,
  &--payment-terminal {
    @extend .c-form__fieldset--bordered;
    @extend .u-bg_g98;
    @include mq($from: md) {
      margin-bottom: $spacing-unit-large;
    }
  }

  &--payment-terminal {
    max-width: 36em;
  }

  &--account,
  &--customer-profile-billing {
    @extend .u-2\/3\@lg;
  }

  &--commerce-marketplace-payment,
  &--commerce-marketplace-payment-multi {
    @extend .u-2\/3\@md;
    @extend .u-1\/2\@lg;
  }

  &--start-time-fieldset {
    padding-bottom: $spacing-unit;
  }

  &--expiry-fieldset {
    margin-bottom: $spacing-unit;
  }

  &--radio-indent {
    @include mq($from: sm) {
      margin-left: 45px;
    }
    @include mq($from: md) {
      margin-left: 55px;
    }
  }

  &--checkbox-group {
    // Mirror .c-form__item--type-radios
    @extend .c-form__fieldset--quiet;
    padding-bottom: $spacing-unit-med-small;
    @include mq(md) {
      padding-bottom: $spacing-unit;
    }
  }

  &--phone-verification {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-unit-tiny;
  }

}

.c-form__fieldset-legend {
  @extend .c-heading--gamma;
  margin-bottom: $spacing-unit-small;

  .c-form__fieldset--bordered & {
    margin-bottom: 0;
  }

  .c-form__fieldset--bordered\@md & {
    @include mq(md) {
      margin-bottom: 0;
    }
  }
  .c-form__fieldset--default-start-time &,
  .c-form__fieldset--default-expiry-fieldset & {
    margin-bottom: 0;
  }

  .c-form__fieldset--bordered.c-form__fieldset--admin & {
    @extend .c-heading--epsilon;
  }
}

.c-form__fieldset-description {
  margin-bottom: $spacing-unit-small;
  @include font-size($font-size-12, 1.5);
  @include setPropFromVar(color, $var-color-text-light);
}

.c-form__textarea-wrapper {
  clear: both;
  @include mq(lg) {
    clear: none;
  }
  .c-form--inline & {
    @include mq(lg) {
      padding-left: $inline-form-label-width;
    }
  }
  .c-form--inline .c-form__fieldset & {
    @include mq(lg) {
      padding-left: $inline-form-label-width - $spacing-unit;
    }
  }
}

.c-form__item {
  position: relative;
  padding-bottom: $spacing-unit-med-small;
  @include mq($from: md) {
    padding-bottom: $spacing-unit-med-large;
  }

  .c-form--tiny &,
  .c-form--small & {
    padding-bottom: $spacing-unit-small;
    @include mq(md) {
      padding-bottom: $spacing-unit;
    }
  }

  &--type_checkbox {
    @include mq(md) {
      padding-bottom: $spacing-unit;
    }
    .c-form__fieldset--start-time-fieldset &,
    .c-form__fieldset--totalizer-fieldset &,
    .c-form__fieldset--donations-monitor-fieldset &,
    .c-form__fieldset--expiry-fieldset & {
      padding-bottom: 0;
    }
  }

  &--type_radios {
    @include mq(md) {
      padding-bottom: $spacing-unit;
      &.c-form__item--element_field-ap-layout {
        padding-bottom: $spacing-unit-med-large;
      }
    }
  }

  &--type_select {
    .c-form--air-admin-view-filter-form & {
      vertical-align: middle;
    }
  }

  &--type_color:not(:last-of-type) {
    padding-bottom: 0;
  }

  &--name_field-ap-privacy\[und\],
  &--name_field-ap-payment\[und\] {
    @include mq(md) {
      padding-bottom: $spacing-unit;
    }
  }

  .c-form--single-row & {
    @include mq(mmd) {
      padding-bottom: 0;
      display: inline-block;
    }
  }
  .c-form--air-manual-upgrade-form &,
  .c-form--air-credits-manual-credits-form & {
    @include mq(mmd) {
      vertical-align: bottom;
    }
  }

  .c-form--air-db-query-form & {
    margin-bottom: $spacing-unit-tiny;
  }

  .c-form--inline & {
    padding-bottom: $spacing-unit-tiny;
    @include mq(mmd) {
      padding-bottom: $spacing-unit-small;
    }
  }

  .c-form--air-bid-form .c-form__wrapper:not(.c-form__wrapper--fancybox) &,
  .c-form--views-form-air-commerce-cart-form-default & {
    padding-bottom: 0 !important;
  }

  .c-form--node-air-auction-page &--name_path\[alias\] {
    padding-bottom: 0;
  }

  &--name_ai-view-urls {
    padding: 0;
  }

  .c-form__container--text-with-format & {
    padding-bottom: $spacing-unit-small;
  }

  &--element_field-ap-default-item-start,
  &--element_field-ap-default-item-start--html5 {
    padding-bottom: $spacing-unit-tiny;
  }

  .c-form__container--suffixed-item &,
  &--element_field-ap-default-deferred-start,
  &--element_field-ap-default-item-start--html5,
  &--element_field-ai-start-time--html5,
  &--element_field-ai-start-time:last-child,
  &--element_field-ai-expiry--html5,
  &--element_field-ai-expiry:last-child {
    padding-bottom: 0;
    @include mq(md) {
      padding-bottom: 0;
    }
  }

  &--name_field-ap-default-item-start\[und\]\[0\]\[value\]\[date\],
  &--name_field-ap-default-item-expiry\[und\]\[0\]\[value\]\[date\],
  &--name_field-ai-start-time\[und\]\[0\]\[value\]\[date\],
  &--name_field-ai-expiry\[und\]\[0\]\[value\]\[date\] {
    padding-bottom: $spacing-unit-small;
  }

  &--name_field-ap-default-item-expiry--html5 {
    padding-bottom: $spacing-unit-small;
    @include mq(md) {
      padding-bottom: $spacing-unit-small;
    }
  }

  &--name_twilio-test-number {
    padding-bottom: $spacing-unit-med-small;
  }

  .c-form__fieldset--path &--type_checkbox {
    padding-bottom: $spacing-unit-small;
  }

  // Padding is on the containing fieldset, which includes additional content
  &--element_field-ap-privacy {
    padding-bottom: 0;
  }
  &--element_field-ap-password {
    padding-bottom: $spacing-unit-small;
    padding-top: $spacing-unit-small;
  }

  .c-form--air-ai-search-form &--type_searchfield {
    padding-left: 28px;
    padding-bottom: 0 !important;
  }

  .c-form__fieldset--inline & {
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 0;
  }

  .c-form--commerce-checkout-form-review &,
  .c-form--commerce-checkout-form-reviews &,
  .c-form--commerce-payment-order-transaction-add-form &.c-form__item--element_payment-details,
  .c-form--eck--entity--form-add-air-registration-registration &.c-form__item--element_credit-card {
    padding-bottom: $spacing-unit-small;
  }

  .c-form--user-login &--name_pass {
    padding-bottom: $spacing-unit-tiny;
  }

  .c-form--tight & {
    padding-bottom: $spacing-unit;
  }

  .c-form--v-tight & {
    padding-bottom: $spacing-unit-tiny;
  }

  .c-form--filters.c-form--single-row & {
    display: block;
  }

  .c-form--filters & {
    display: block;
  }

  &--type_autocomplete-deluxe {
    .c-form--fancybox & {
      @include mq($from: sm) {
        min-width: 500px;
      }
    }
  }

  .c-form__item--type_autocomplete-deluxe &--type_textfield {
    height: 40px !important;
    padding-bottom: 0 !important;
    @include mq(sm) {
      height: 42px !important;
    }
    @include mq(md) {
      height: 54px !important;
    }
  }

  .c-form--view_air-auction-items &--name_sort-by {
    padding-bottom: 0;
  }

  .c-form--air-protected-pages-enter-password &--type_password {
    padding-bottom: $spacing-unit-tiny;
  }

  .c-form__fieldset--checkbox-group > .c-form__container &,
  .c-form__fieldset--checkbox-group > .c-form__fieldset-wrapper--collapsible > .c-form__fieldset > .c-form__container & {
    padding-bottom: 0;
  }

  .c-form--vbo-config & {
    padding-bottom: $spacing-unit-small;
  }

  .c-form--vbo-confirm & {
    padding-bottom: 0;
  }

  .c-form__container--exposed-search &--type_textfield {
    flex: 1 0 auto;
  }

  &--name_field-ai-expiry-initial\[und\]\[0\]\[value\] {
    padding-bottom: 0;
  }

  &--element_field-ap-color-scheme {
    .c-form__container.is-disabled & {
      opacity: $opacity-disabled;
    }
  }

  &--element_field-ap-donations-amounts {
    padding-bottom: $spacing-unit-small;
  }

  &--confirm-msg-checkbox {
    padding: 0;
  }

  &--name_currency-select {
    .c-form--air-credits-pricing-currency-form &,
    .c-form--air-credits-add-to-cart-form & {
      flex-grow: 1;
    }
  }

  .c-form--views-form &--element_edit-quantity {
    padding: 0;
    @include mq(md) {
      padding: 0;
    }
  }

  .c-form--air-language-select-form & {
    padding-bottom: 0 !important;
  }

  .c-form--eck--entity--form-add-air-registration-registration &--element_field-reg-card,
  .c-form--eck--entity--form-edit-air-registration-registration &--element_field-reg-card {
    padding-bottom: $spacing-unit-tiny;
    @include mq($from: md) {
      padding-bottom: $spacing-unit-tiny;
    }
  }

  .c-form__fieldset--phone-verification & {
    padding-bottom: 0;
    @include mq($from: md) {
      padding-bottom: 0;
    }
  }

  .c-form__container--suffixed-item + & {
    margin-top: $spacing-unit-tiny;
  }

  .c-form__fieldset--usertxt.in &--name_field-ap-register-usertxt-label\[und\]\[0\]\[value\],
  .c-form__fieldset--usertxt.collapsing &--name_field-ap-register-usertxt-label\[und\]\[0\]\[value\] {
    margin-bottom: $spacing-unit-med-small;
  }
  &--type_date-popup {
    html.has-date & {
      display: none;
    }
  }
}

.c-form__radios {
  clear: left;
  .c-form--inline & {
    @include mq(lg) {
      clear: none;
      padding-left: $inline-form-label-width;
    }
  }
  .c-form--inline .c-form__fieldset:not(.c-form__fieldset--quiet) & {
    @include mq(lg) {
      padding-left: $inline-form-label-width - $spacing-unit;
    }
  }
  .c-form__item--element_field-ap-layout & {
    display: grid;
    grid-column-gap: $spacing-unit;
    grid-row-gap: $spacing-unit;
    grid-template-columns: repeat(2, 1fr);
    > * {
      @include grid-ie-calc(6, 2, $spacing-unit);
    }
    @include mq(md) {
      grid-template-columns: repeat(3, 1fr);
      > * {
        @include grid-ie-calc(6, 3, $spacing-unit);
      }
    }
  }
}

.c-form__actions {
  clear: both;
  margin: $spacing-unit 0;
  &:last-child {
    margin-bottom: 0;
  }

  .c-form--user-pass &,
  .c-form--password-reset & {
    margin: 0;
  }

  .c-form--views-form-air-commerce-cart-form-default & {
    margin: $spacing-unit-small 0 0;
    text-align: right;
    @include mq(md) {
      margin-top: $spacing-unit;
    }
  }
  .c-form--images & {
    margin-left: 0;
  }
  .c-form--user-profile-form &,
  .c-form--contact-site-form &,
  .c-form--air-auction-contact-form &,
  .c-form--commerce-payment-order-transaction-add-form &,
  .c-form--commerce-checkout-form-review &,
  .c-form--commerce-checkout-form-reviews & {
    @include mq($from: md) {
      margin-top: $spacing-unit-large;
    }
  }

  .c-form--commerce-payment-order-transaction-add-form &,
  .c-form--commerce-checkout-form-review &,
  .c-form--commerce-checkout-form-reviews & {
    margin-top: 0;
  }
  .c-form--air-manual-upgrade-form.c-form--small & {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: bottom;
  }

  .c-form--air-credits-manual-credits-form.c-form--small & {
    display: block;
  }

  .c-form--commerce-payment-order-transaction-add-form & {
    margin-bottom: 0;
  }

  .c-form--fancybox &,
  .c-form__wrapper--fancybox & {
    margin: 0;
  }

  .c-form--air-auction-contact-form.c-form--fancybox & {
    margin-top: $spacing-unit-large;
  }

  .c-form--node-air-auction-item & {
    margin: 0;
    padding: $spacing-unit 0;
  }

  .c-form--small & {
    margin: $spacing-unit-small 0;
  }
}

.c-form__actions-separator {
  display: inline-block;
  margin: 0 $spacing-unit;
  text-align: center;
}

.c-form__prefixed-input {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  > input {
    flex: 1;
  }

  .c-form--single-row & {
    @include mq(mmd) {
      display: inline-block;
      clear: none;
    }
  }

  @include mq(md) {
    clear: none;
  }
  .c-form__item--element_bid-amount & {
    clear: none;
    @include font-size($font-size-16);
    line-height: $base-line-height;
    //background-color: white;
    @include mq(md) {
      @include font-size($font-size-18);
      //float: left;
    }
  }
}

.c-form__input-prefix {
  @include input-sizing($include-font: only);
  padding-right: $spacing-unit-small;
  .c-form__item--name_path\[alias\] & {
    @include setPropFromVar(color, $var-color-text-light);;
  }
}

.c-form__prefixed-input-js {
  position: relative;
  clear: both;

  .c-form--single-row & {
    @include mq(mmd) {
      display: inline-block;
      clear: none;
    }
  }

  @include mq(md) {
    clear: none;
  }
  .c-form__item--element_bid-amount & {
    clear: none;
    @include font-size($font-size-16);
    line-height: $base-line-height;
    @include mq(md) {
      @include font-size($font-size-18);
      float: left;
    }
  }
}

.c-form__input-prefix-js {
  html.no-js & {
    display: none;
  }

  position: absolute;
  left: 0;
  top: 1px;
  display: block;
  @include setPropFromVar(color, $var-color-text-dark);
  pointer-events: none;
  text-align: center;

  @include input-sizing();
  .c-form--tiny & {
    @include input-sizing(tiny);
  }
  .c-form--small & {
    @include input-sizing(small);
  }
  .c-form--large & {
    @include input-sizing(large);
  }

  padding-right: 1px !important;
  @include mq(sm) {
    padding-right: 2px !important;
  }

  .c-form__item--name_path\[alias\] & {
    @include setPropFromVar(color, $var-color-text-light);;
  }

}

.c-form__help-block {
  clear: both;
  margin: $spacing-unit-small 0;
  @include font-size($font-size-12, 1.5);

  .c-form--no-tips &:not(.c-form__help-block--show-always),
  .is-disabled &:not(.c-form__help-block--show-always) {
    display: none;
  }

  &.c-form__help-block--static {
    margin-bottom: $spacing-unit-small;
    @include mq($from: md) {
      margin-bottom: $spacing-unit;
    }
  }

  // Timezone note in AI admin
  .c-form__date-combo &:last-child {
    @include mq($from: md) {
      margin-bottom: $spacing-unit-small;
    }
  }

  .c-form--inline &.c-form__help-block--static {
    @include mq(mlg) {
      padding-left: $inline-form-label-width;
    }
  }
  .c-form--inline.c-form--tiny &.c-form__help-block--static,
  .c-form--inline.c-form--small &.c-form__help-block--static {
    @include mq(mmd) {
      padding-left: 0;
    }
    @include mq(mmd) {
      padding-left: $small-inline-form-label-width;
    }
  }

  &--med-large {
    @include font-size($font-size-15, 1.2);
  }

  &--large {
    @include font-size($font-size-18, 1.2);
  }

  .c-form__fieldset-legend + & {
    margin-top: 0;
    margin-bottom: $spacing-unit-small;
  }

  @include mq(mlg) {
    &:not(.c-form__help-block--static) {
      html.js body > :not(.fancybox-container) & {
        position: absolute;
        z-index: 1;
        left: 100%;
        top: $spacing-unit-med-small;
        width: calc(33% - #{$spacing-unit});
        margin-left: $spacing-unit-med-large;
      }
      html.js body > :not(.fancybox-container) .c-form--air-auction-page-node-form &,
      html.js body > :not(.fancybox-container) .c-form--air-auction-item-node-form & {
        width: calc(50% - #{$spacing-unit-med-large});
      }
      html.js body > :not(.fancybox-container) .c-form__fieldset--bordered & {
        margin-left: $spacing-unit-large + $spacing-unit-small;
      }
      html.js body > :not(.fancybox-container) .c-form__fieldset-legend + & {
        top: -$spacing-unit;
      }
      html.js body > :not(.fancybox-container) .c-form__item--name_path\[alias\] & {
        top: -$spacing-unit-med-small;
      }
      html.js body > :not(.fancybox-container) .c-form__item--type_checkbox & {
        top: -$spacing-unit-med-large;
      }
      html.has-date > :not(.fancybox-container) .c-form__fieldset--default-start-time &,
      html.has-date > :not(.fancybox-container) .c-form__fieldset--default-expiry-fieldset & {
        top: -70px;
      }
      html.has-date > :not(.fancybox-container) .c-form__fieldset--start-time &,
      html.has-date > :not(.fancybox-container) .c-form__fieldset--timeout-fieldset & {
        top: -$spacing-unit-med-small;
      }
      html.has-date > :not(.fancybox-container) .c-form__item--element_field-ap-default-item-start &,
      html.has-date > :not(.fancybox-container) .c-form__item--element_field-ap-default-item-expiry &,
      html.has-date > :not(.fancybox-container) .c-form__item--element_field-ai-start-time &,
      html.has-date > :not(.fancybox-container) .c-form__item--element_field-ai-expiry & {
        top: $spacing-unit;
      }
    }
  }
  @include mq(lg) {
    &:not(.c-form__help-block--static) {
      html.js body > :not(.fancybox-container) .c-form--user-profile-form & {
        width: calc(50% - #{$spacing-unit});
      }
    }
  }

  html.js body > :not(.fancybox-container) .c-form--inline.c-form--tiny &,
  html.js body > :not(.fancybox-container) .c-form--inline.c-form--small & {
    @include mq($from: mmd, $until: mlg) {
      width: 100%;
      padding-left: $small-inline-form-label-width;
    }
    @include mq($from: mlg) {
      width: calc(50% - #{$spacing-unit-med-large});
      top: -$spacing-unit;
    }
    @include mq($from: lg) {
      width: calc(75% - #{$spacing-unit-med-large});
    }
  }

}

.c-form__help-link {
  &.c-form__help-link-ctc {
    @include font-size($font-size-12);
    display: block;
    margin: $spacing-unit-tiny 0 $spacing-unit;
  }
}

.c-form__label-help {
  display: block;
  clear: both;
  margin: 0 0 $spacing-unit-small 0;
  @include font-size($font-size-15, 1.5);
  @include setPropFromVar(color, $var-color-text-base);
  .c-form--inline & {
    @include mq(lg) {
      clear: none;
      padding: $spacing-unit-tiny 0 0 $inline-form-label-width;
    }
  }
  .c-form--inline .c-form__fieldset:not(.c-form__fieldset--quiet) & {
    @include mq(lg) {
      padding: 0;
    }
  }
}

.c-form__messages {
  clear: both;
  margin-bottom: $spacing-unit;
  @include mq(md) {
    margin-bottom: $spacing-unit-med-large;
  }

  .c-form--node-air-auction-page &,
  .c-form--node-air-auction-item & {
    padding: $spacing-unit-small 0;
    margin-bottom: 0;
    @include mq(md) {
      margin-bottom: $spacing-unit-small;
    }
    @include mq(lg) {
      padding: $spacing-unit 0;
    }
  }
  .c-form--air-donation-donate-now-form &,
  .c-form--commerce-payment-order-transaction-add-form &,
  .c-form--views-form-air-auction-items-admin-view & {
    margin: $spacing-unit 0 0;
  }
  &--views-form {
    @extend .o-wrapper--tight-4\@md;
    padding-top: $spacing-unit;
    margin-bottom: 0;
  }
  .c-form__actions & {
    margin-top: $spacing-unit;
  }
  .c-form--air-manual-upgrade-form &,
  .c-form--air-credits-manual-credits-form & {
    margin: $spacing-unit-small 0;
    @include mq($from: md) {
      margin-bottom: 0;
    }
  }
}

.c-form__section-title {
  display: none;
  @include mq(md) {
    display: block;
  }
}

.c-form__section-intro {
  margin-bottom: $spacing-unit-med-large;
}

.c-form__input-lock-toggle {
  position: absolute;
  top: $spacing-unit-tiny;
  right: 8px;
  cursor: pointer;
  @include setPropFromVar(color, $var-color-link);
  &:before {
    content: '';
    font-size: $font-size-18;
    .c-form--tiny &,
    .c-form--small & {
      font-size: $font-size-12;
      @include mq(sm) {
        font-size: $font-size-15;
      }
    }
    @include svg-icon(pen, $fill: getVarSass(color-link));
  }
  &.is-unlocked,
  &:hover,
  &:focus {
    &:before {
      @include svg-icon(pen, $fill: getVarSass(color-link-hover));
    }
  }
  &.is-unlocked {
    &:before {
      margin-right: $spacing-unit-tiny;
      @include svg-icon(pen-paper, $fill: getVarSass(color-link-hover));
    }
  }
}

.c-form__input-lock-toggle-text {
  @include font-size($font-size-15);
  .c-form--tiny &,
  .c-form--small & {
    font-size: $font-size-12;
  }
  .is-unlocked > &,
  :hover > &,
  :focus > & {
    @include setPropFromVar(color, $var-color-link-focus);
  }
  :hover > &,
  :focus > & {
    text-decoration: underline;
  }
  .is-unlocked > & {
    text-decoration: none;
  }
}

.c-form__input-mousetrap-hint {
  position: absolute;
  right: 4px;
  top: 4px;
  @include setPropFromVar(color, $var-color-text-dark);
}

.c-form__label--error,
label.error {
  color: $color-form-error;
  display: block;
  clear: both;
  margin: $spacing-unit-tiny 0 0;
  flex: 1 0 100%;

  @include font-size($font-size-12, 1.5);
  @include mq(sm) {
    @include font-size($font-size-15, 1.5);
  }
  @include mq(lg) {
    .c-form--inline .c-form__fieldset:not(.c-form__fieldset--quiet) & {
      padding-left: $inline-form-label-width - $spacing-unit;
    }
    .c-form--inline .c-form__fieldset:not(.c-form__fieldset--quiet) .c-form__group--no-lg-indent & {
      padding-left: 0;
    }
  }
}


















































