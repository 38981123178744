/*------------------------------------*\
    #VIEWPORT
\*------------------------------------*/

$viewport-side-width: 275px;
$viewport-transition: left 0.3s ease-out;

.c-viewport {
  &:not(.c-viewport--print) {
    width: 100%;
    min-height: 100%;
    height: 1px;
  }
}

.c-viewport__main,
.c-viewport__side {
  .c-viewport--with-admin-menu & {
    padding-top: 40px;
    @include mq($from: mmd) {
      padding-top: 20px;
    }
  }
}

.c-viewport__side {
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  left: -$viewport-side-width;
  top: 0;
  bottom: 0;
  width: $viewport-side-width;
  border-right: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  transition: $viewport-transition;
  @include setPropFromVar(background-color, $var-color-bg-1);
  @include mq($until: md) {
    .c-viewport.has-viewport-side & {
      left: 0;
    }
  }
}

.c-viewport__main {
  .c-viewport:not(.c-viewport--print) & {
    position: relative;
    z-index: 0;
    min-height: 100%;
    transition: $viewport-transition;
    left: 0;
    &:before {
      content: '';
      position: absolute;
      left: -1px;
      top: 0;
      bottom: 0;
      border-left: 1px solid;
      @include setPropFromVar(border-color, $var-color-border);
    }
  }
  @include mq($until: md) {
    @include setPropFromVar(background-color, $var-color-bg-1);
    .c-viewport:not(.c-viewport--print).has-viewport-side & {
      left: $viewport-side-width;
    }
  }
}
