/* ==========================================================================
   #DATE
   ========================================================================== */

.c-date {
}

.c-date__text-element {
  html.has-date & {
    display: none;
  }
}

.c-date__html5-element {
  html:not(.has-date) & {
    display: none;
  }
}
