///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// Standardise some UI treatments.
$small-radius:                     2px;
$global-radius:                    4px;
$large-radius:                     6px;

$font-family-serif:                Georgia, serif;
$font-family-mail:                 -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;

$font-size-10:                     10px;
$font-size-12:                     12px;
$font-size-13:                     13px;
$font-size-14:                     14px;
$font-size-15:                     15px;
$font-size-16:                     16px;
$font-size-18:                     18px;
$font-size-20:                     20px;
$font-size-30:                     30px;

$default-fonts: (
  default: AvenirLTPro-Book,
  lighter: AvenirLTPro-Book,
  normal: AvenirLTPro-Medium,
  bold: AvenirLTPro-Heavy,
  bolder: AvenirLTPro-Black
);
$default-font-weights: (
  default: normal,
  lighter: normal,
  normal: normal,
  bold: normal,
  bolder: normal
);

$small-round:                      3px;

$random:                           random(9999);
$image_path:                       '/assets/images/';

$focus-shadow-px:                  0 0 5px 0;
$input-shadow-px:                  0 1px 10px -3px;
$outset-shadow-px:                 0 0 0 2px;
$inset-shadow-px:                  inset 0 0 0 2px;

// Transitions
$global-transition:                0.2s;
$global-transition-all:            all $global-transition;
$fade-transition:                  opacity $global-transition;
$slide-transition:                 max-height $global-transition;
$rotate-transition:                transform $global-transition;
$fast-transition:                  0.1s;
$fast-transition-all:              all $fast-transition;
$slow-transition:                  1s;
$slow-transition-all:              all $slow-transition;

// Opacity
$opacity-updating:                 0.7;
$opacity-disabled:                 0.5;

// Breakpoints and wrappers
$mq-breakpoints: (
        xs:     0px,
        sm:   480px,
        mmd:  640px,
        md:   960px,
        mdl: 1024px,
        mlg: 1200px,
        lg:  1440px,
        xl:  1808px, // =  (420 (max teaser) + 2 * 1px border) * 4 columns + 3 * 24 spacing + 2 * 24 gutter
        vxl:  2260px
);
$wrapper-width: map-get($mq-breakpoints, xl);
$tight-wrappers: (
        7: 480px,
        6: 600px,
        5: 760px,
        4: 960px,
        3: 1050px,
        2: 1200px,
        1: 1360px
);

