/*------------------------------------*\
    #AJAX-PROGRESS
\*------------------------------------*/

.c-ajax-progress {
  opacity: 0;
  transition: $fade-transition;
  &:not(.in) {
    &:after {
      animation-play-state: paused;
      animation-iteration-count: 0;
    }
  }
  &.in {
    opacity: 1;
    &:after {
      animation-play-state: running;
    }
  }

  &--radio {
    @include spinner(small, ui, middle);
    margin-left: -8px;
  }

  &--spinner-small {
    @include spinner(small, ui, right);
    margin-right: $spacing-unit-tiny;
  }
  &--submit {
    margin-right: 4px;
    @include spinner(small, light, right);
    @include mq(md) {
      margin-right: $spacing-unit-tiny;
      @include spinner(medium, light, right);
    }
    &.c-ajax-progress--small {
      margin-right: $spacing-unit-tiny / 2;
      @include mq(md) {
        @include spinner(small, light, right);
      }
    }
    :disabled &,
    .is-progress-disabled &,
    .is-disabled & {
      @include spinner(small, reverse, right);
      @include mq(md) {
        @include spinner(medium, reverse, right);
      }
      &.c-ajax-progress--small {
        margin-right: $spacing-unit-tiny / 2;
        @include mq(md) {
          @include spinner(small, reverse, right);
        }
      }
    }
  }
  &--alternate-submit {
    .is-progress-disabled & {
      @include spinner(small, reverse, middle);
      @include mq(sm) {
        margin-left: $spacing-unit-med-small;
        @include spinner(med-small, reverse, left);
      }
    }
  }
  &--remove {
    margin-right: 4px;
    @include spinner(small, light, right);
    @include mq(md) {
      margin-right: $spacing-unit-tiny / 2;
      @include spinner(small, light, right);
    }
  }
  &--reset {
    @include spinner(small, white, middle);
    @include mq($from: sm) {
      @include spinner(medium, white, middle);
    }
    :disabled &,
    .is-progress-disabled &,
    .is-disabled & {
      @include spinner(small, reverse, middle);
      @include mq($from: sm) {
        @include spinner(medium, reverse, middle);
      }
    }
  }
  &--submit-outside {
    @include spinner(small, ui, absolute);
    left: 100%;
    margin-left: $spacing-unit-small;
  }
  &--link {
    @include spinner(small, ui, relative);
    //left: 100%;
    margin-left: $spacing-unit-small;
  }

  &--contextual {
    @include spinner(small, default, right);
    margin-right: $spacing-unit-small;
  }

  &--tablehead {
    @include spinner(small, ui, absolute);
    left: 100%;
    margin-left: $spacing-unit-small;
  }

  &--view-widget {
    @include spinner(medium, ui, left);
    margin-left: $spacing-unit-small;
  }

  &--view-pager {
    @include spinner(small, ui);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &--view-pager-load-more {
    @include spinner(small, ui, right);
    margin-right: - $spacing-unit;
  }

  &--ajax-nav {
    @include spinner(small, ui);
    margin-left: $spacing-unit;
    &.c-ajax-progress--ai-search-bar {
      @include spinner(small, ui, right);
      margin-left: 0;
      margin-right: $spacing-unit-small;
    }
  }

  &--page-nav {
    @include spinner(small, ui, middle);
    &.c-ajax-progress--prev {
      @include mq(sm) {
        margin-left: 1em;
        @include spinner(med-small, ui, left);
      }
    }
    &.c-ajax-progress--next {
      @include mq(sm) {
        margin-right: 1em;
        @include spinner(med-small, ui, right);
      }
    }
  }
  &--page-nav-ai-full {
    &.c-ajax-progress--prev {
      @include spinner(small, light, left);
      margin-left: 0.5em;
    }
    &.c-ajax-progress--next {
      @include spinner(small, light, right);
      margin-right: 0.5em;
    }
  }
  &--page-nav-ai-full-footer {
    &.c-ajax-progress--prev {
      @include spinner(medium, light, left);
      margin-left: 1.5em;
    }
    &.c-ajax-progress--next {
      @include spinner(medium, light, right);
      margin-right: 1.5em;
    }
  }

  &--page-nav-ai-teaser {
    @include spinner(med-large, ui);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &--page-nav-ai-table-cell {
    @include spinner(small, ui);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
  }
}

