/*------------------------------------*\
    #BANNER
\*------------------------------------*/

.c-banner {
  padding: $spacing-unit-tiny $spacing-unit-small;
  background-color: $color-yellow-light;
}

.c-banner__content {
  position: relative;
  color: $color-gray-20;
  @include font-size($font-size-12, 1.5);
  padding-right: $spacing-unit;
  @include mq($from: md) {
    @include font-size($font-size-14, 1.5);
  }
}

.c-banner__list {
  margin-bottom: $spacing-unit-tiny;
}

.c-banner__linked-title {
  &,
  &:hover,
  &:active,
  &:focus {
    @include setPropFromVar(font-family, $var-font-family-bold);
    @include setPropFromVar(font-weight, $var-font-weight-bold);
    color: $color-gray-20;
    margin-right: 0.33em;
  }
}

.c-banner__do-not-show-again {
  &,
  &:hover,
  &:active,
  &:focus {
    @include setPropFromVar(font-family, $var-font-family-bold);
    @include setPropFromVar(font-weight, $var-font-weight-bold);
  }
}


.c-banner__dismiss {
  position: absolute;
  right: $spacing-unit-small;
  top: 0;
  bottom: 0;
}

.c-banner__dismiss-button {
  font-size: 0.67em;
  &,
  &:hover,
  &:active,
  &:focus {
    color: $color-gray-20;
  }
}
