/*------------------------------------*\
    #SPINNERS
\*------------------------------------*/

.c-spinner {
  &--slider {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -0.5em;
    margin-left: -0.5em;
  }
}

.c-spinner__icon {
  @include spinning(ccw);
  fill: $color-blue;
}