/*------------------------------------*\
    #JQUERY UI - AUTOCOMPLETE
\*------------------------------------*/

.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
  max-height: 240px;
  overflow-x: hidden;
  overflow-y: scroll;
  @include font-size($font-size-14);
  @include mq(md) {
    padding: 3px;
    @include font-size($font-size-15);
  }
}
