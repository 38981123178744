///*------------------------------------*\
//    #GRID CALCULATION FOR IE
//\*------------------------------------*/

/*

    This mixin allows us use CSS grid without having to think about
    what -ms-grid-row/-ms-grid-column we have to assign to a grid element
    for it to properly work on Internet Explorer and Edge.

    It takes three arguments, the last one of which is optional. Specify the
    maximum amount of items you want to have in your grid, when they should
    break to the next line and, if you like, a grid-gap of some sort.

    Make sure to use the same amount of arguments in -ms-grid-columns as in
    $wrap-on, so it correctly breaks the line, eg. '1fr 2fr 1fr 2fr 1fr' and
    '5'.

    Usage:
    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;

        display: -ms-grid;
        -ms-grid-columns: 1fr 1fr 1fr;

        &-item {
            @include grid-ie-calc(15, 3, 10px);
        }
    }

    See it in action here:
    https://rbnlffl.synology.me/grid-ie-calc

    Tested on:
    IE 11, Edge 38, Chrome 58, Firefox 53, Safari 10, Opera 45

*/

@mixin grid-ie-calc($items: 30, $wrap-on: 3, $grid-gap: 0) {
  $current-row: 1;
  $current-column: 1;

  @if $grid-gap > 0 {
    & {
      margin: $grid-gap / 2;

      @supports (grid-gap: $grid-gap) {
        margin: 0;
      }
    }
  }

  @for $i from 1 through $items {
    @if $current-column > $wrap-on {
      $current-column: 1;
      $current-row: $current-row + 1;
    }

    &:nth-child(#{$i}) {
      -ms-grid-row: $current-row;
      -ms-grid-column: $current-column;
    }

    $current-column: $current-column + 1;
  }
}