/*------------------------------------*\
    #MESSAGES
\*------------------------------------*/

$message-success-bg:            $state-success-bg;
$message-success-text:          $state-success-text;
$message-success-border:        $state-success-border;

$message-info-bg:               $state-info-bg;
$message-info-text:             $state-info-text;
$message-info-border:           $state-info-border;

$message-warning-bg:            $state-warning-bg;
$message-warning-text:          $state-warning-text;
$message-warning-border:        $state-warning-border;

$message-danger-bg:             $state-danger-bg;
$message-danger-text:           $state-danger-text;
$message-danger-border:         $state-danger-border;


@mixin message-variant($background, $border, $text-color) {
  background-color: $background;
  border-color: $border;
  color: $text-color;

  hr {
    border-top-color: darken($border, 5%);
  }

  .c-messages__group-dismiss-button {
    color: $text-color;
    text-shadow: 0 1px 0 $text-color;
  }

  a {
    color: $text-color;
    @include setPropFromVar(font-family, $var-font-family-bold);
    @include setPropFromVar(font-weight, $var-font-weight-bold);
  }
}

.c-messages {
  &--ai-edit {
    margin: $spacing-unit 0 $spacing-unit-small;
  }
  &--card-payment {
    margin: $spacing-unit-tiny 0;
    @include message-variant($message-danger-bg, $message-danger-border, $message-danger-text);
  }
  &--default-font {
    @include setPropFromVar(font-family, $var-font-family-default);
    @include use-default-fonts;
  }
}

.c-messages__message-group {
  position: relative;
  clear: both;
  border: 1px solid transparent;
  margin-bottom: $spacing-unit-small;
  padding: $spacing-unit-med-small $spacing-unit-large  $spacing-unit-med-small $spacing-unit;
  @include mq(md) {
    margin-bottom: $spacing-unit;
  }
  @include mq(lg) {
    padding: $spacing-unit $spacing-unit-large $spacing-unit $spacing-unit;
  }
  &:last-child {
    margin-bottom: 0;
  }

  .c-messages--small & {
    float: left;
    min-width: 50%;
    padding: $spacing-unit-small $spacing-unit-large  $spacing-unit-small $spacing-unit;
    margin: 0 0 $spacing-unit-tiny 0;
  }

  .c-messages--simple & {
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
  }
}

.c-messages__group-dismiss {
  position: absolute;
  height: 100%;
  top: 0;
  right: $spacing-unit;
  .c-messages--simple & {
    display: none;
  }
}

.c-messages__group-dismiss-button {
  font-size: 12px;
  .c-messages--small & {
    font-size: 10px;
  }
}

.c-messages__message-group--status {
  @include message-variant($message-success-bg, $message-success-border, $message-success-text);
}
.c-messages__message-group--warning {
  @include message-variant($message-warning-bg, $message-warning-border, $message-warning-text);
}
.c-messages__message-group--error {
  @include message-variant($message-danger-bg, $message-danger-border, $message-danger-text);
}

.c-messages__message-list {
  margin-bottom: $spacing-unit-small;
  &:last-child {
    margin-bottom: 0;
  }
}

.c-messages__message-item {
  .c-messages__message-list & {
    text-align: left;
  }
  @include font-size($font-size-14, 1.5);
  .c-messages:not(.c-messages--small) & {
    @include mq(sm) {
      @include font-size($font-size-15, 1.5);
    }
    @include mq(md) {
      @include font-size($font-size-16, 1.5);
    }
  }
}
