/*------------------------------------*\
    #ONETRUST COOKIE BANNER
\*------------------------------------*/

.optanon-alert-box-wrapper {
  border-top: 2px solid $color-blue !important;
  .c-page--user-login & {
    display: none !important;
  }
}

#alert-box-message  .banner-policy-link {
  text-decoration: none !important;
  @include attention {
    text-decoration: underline !important;
  }
}

#optanon {
  #optanon-menu {
    margin-bottom: $spacing-unit-med-large;
  }
  #optanon-popup-bottom-logo {
    display: none;
  }
}

