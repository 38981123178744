/* ==========================================================================
   #WRAPPERS
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

/* stylelint-disable */
@if (type-of($wrapper-width) != number) {
  @error "`#{$wrapper-width}` needs to be a number."
}
/* stylelint-enable */

@mixin o-wrapper {
  position: relative;
  @include inuit-clearfix();
  padding-right: $spacing-unit;
  padding-left:  $spacing-unit;
  margin-right: auto;
  margin-left:  auto;
  max-width: $wrapper-width;
  html[data-aa-admin] & {
    max-width: none;
  }
}

.o-wrapper {
  @include o-wrapper;
  &\@sm {
    @include mq(sm) {
      @include o-wrapper;
    }
  }
  &\@md {
    @include mq(md) {
      @include o-wrapper;
    }
  }
}

.o-wrapper--flush\@lmd {
  @include mq($until: md) {
    padding-left: 0;
    padding-right: 0;
  }
}


/* Size variants.
   ========================================================================== */

.o-wrapper--tiny {
  padding-right: $spacing-unit-tiny;
  padding-left:  $spacing-unit-tiny;
}

.o-wrapper--small {
  padding-right: $spacing-unit-small;
  padding-left:  $spacing-unit-small;
}

.o-wrapper--large {
  padding-right: $spacing-unit-large;
  padding-left:  $spacing-unit-large;
}

.o-wrapper--huge {
  padding-right: $spacing-unit-huge;
  padding-left:  $spacing-unit-huge;
}

@each $i, $tight-wrapper in $tight-wrappers {
  .o-wrapper--tight-#{$i} {
    @include o-wrapper;
    max-width: mq-px2em($tight-wrapper);
    &\@sm {
      @include mq(sm) {
        @include o-wrapper;
        max-width: mq-px2em($tight-wrapper);
      }
    }
    &\@md {
      @include mq(md) {
        @include o-wrapper;
        max-width: mq-px2em($tight-wrapper);
      }
    }
    &\@lg {
      @include mq(lg) {
        @include o-wrapper;
        max-width: mq-px2em($tight-wrapper);
      }
    }
  }
}

.o-wrapper--wide\@vxl {
  @include mq(vxl) {
    @include o-wrapper;
    max-width: 2200px;
  }
}
