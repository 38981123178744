/*------------------------------------*\
    #CTAs
\*------------------------------------*/

.c-cta {
  @extend .o-box--large\@md;
  text-align: center;
  @include mq($from: md) {
    @include setPropFromVar(background-color, $var-color-bg-1);
  }
}

.c-cta__icon-wrap {
  @include font-size(74px, 1);
}

.c-cta__title {
  @include setPropFromVar(color, $var-color-text-dark);
}

.c-cta__text {
  margin-bottom: $spacing-unit;
}
