// NOTE: by using on all variables, we're saying only declare the variable if it doesn't
// already exist, which allows devs to declare these variables themselves and assign them any value
// they want before importing this file

// rgba is needed for the selected flag hover state to blend in with
// the border-highlighting some browsers give the input on focus
$hoverColor: rgba(0, 0, 0, 0.05);
$greyText: #999;
$greyBorder: #CCC;

$flagHeight: 15px;
$flagWidth: 20px;
$flagPadding: 8px;
$flagPaddingMd: 14px;
// this border width is used for the popup and divider, but it is also
// assumed to be the border width of the input, which we do not control
$borderWidth: 1px;

$arrowHeight: 4px;
$arrowWidth: 6px;
$triangleBorder: 3px;
$arrowPadding: 6px;
$arrowPaddingMd: 10px;
$arrowColor: #555;

$inputPadding: $input-padding-horizontal-tiny;
$inputPaddingMd: $input-padding-horizontal-small;
$selectedFlagWidth: $flagWidth + (2 * $flagPadding);
$selectedFlagWidthMd: $flagWidth + (2 * $flagPaddingMd);
$selectedFlagArrowWidth: $flagWidth + $flagPadding + $arrowWidth + (2 * $arrowPadding);
$selectedFlagArrowWidthMd: $flagWidth + $flagPaddingMd + $arrowWidth + (2 * $arrowPaddingMd);

// image related variables
$flagsImagePath: "#{$image_path}flags/";
$flagsImageName: "flags";
$flagsImageExtension: "png";

// enough space for them to click off to close
$mobilePopupMargin: 30px;

.iti {
  // need position on the container so the selected flag can be
  // absolutely positioned over the input
  position: relative;
  // keep the input's default inline properties
  display: inline-block;

  // paul irish says this is ok
  // http://www.paulirish.com/2012/box-sizing-border-box-ftw/
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  &__hide {
    display: none;
  }
  // need this during init, to get the height of the dropdown
  &__v-hide {
    visibility: hidden;
  }

  // specify types to increase specificity e.g. to override bootstrap v2.3
  input, input[type=text], input[type=tel] {
    position: relative;
    // input is bottom level, below selected flag and dropdown
    z-index: 0;

    // any vertical margin the user has on their inputs would no longer work as expected
    // because we wrap everything in a container div. i justify the use of !important
    // here because i don't think the user should ever have vertical margin here - when
    // the input is wrapped in a container, vertical margin messes up alignment with other
    // inline elements (e.g. an adjacent button) in firefox, and probably other browsers.
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    // make space for the selected flag on right of input (if disabled allowDropdown)
    // Note: no !important here, as the user may want to tweak this so that the
    // perceived input padding matches their existing styles
    padding-right: $selectedFlagWidth;

    // any margin-right here will push the selected-flag away
    margin-right: 0;
  }

  &__flag-container {
    // positioned over the top of the input
    position: absolute;
    // full height
    top: 0;
    //bottom: 0;
    right: 0;
    // prevent the highlighted child from overlapping the input border
    padding: $borderWidth;

    height: 34px;
    @include mq($from: sm) {
      height: 40px;
    }
    @include mq($from: md) {
      height: 56px;
      &.is-locked,
      &.is-unlocked {
        height: 40px;
      }
    }

    @include mq($until: sm) {
      &.is-locked {
        top: -5px;
      }
      &.is-unlocked {
        top: 0;
      }
    }
    &.is-locked,
    &.is-unlocked {
      @include mq($from: sm) {
        top: -3px;
      }
      @include mq($from: md) {
        top: 0;
      }
    }

  }

  &__selected-flag {
    // render above the input
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    // this must be full-height both for the hover highlight, and to push down the
    // dropdown so it appears below the input
    height: 100%;
    padding: 0 $arrowPadding 0 $flagPadding;
    .iti__flag-container.is-locked & {
      padding: 0;
    }
    @include mq($from: md) {
      .iti__flag-container:not(.is-locked) & {
        padding: 0 $arrowPaddingMd 0 $flagPaddingMd;
      }
      .iti__flag-container.is-unlocked & {
        padding: 0 $arrowPadding 0 $flagPadding;
      }
    }
  }

  &__arrow {
    .iti__flag-container.is-locked & {
      display: none;
    }
    margin-left: $arrowPadding;
    @include mq($from: md) {
      margin-left: $arrowPaddingMd;
      .iti__flag-container.is-unlocked & {
        margin-left: $arrowPadding;
      }
    }

    // css triangle
    width: 0;
    height: 0;
    border-left: $triangleBorder solid transparent;
    border-right: $triangleBorder solid transparent;
    border-top: $arrowHeight solid $arrowColor;

    &--up {
      border-top: none;
      border-bottom: $arrowHeight solid $arrowColor;
    }
  }

  // the dropdown
  &__country-list {
    position: absolute;
    // popup so render above everything else
    z-index: 2;

    // override default list styles
    list-style: none;
    // in case any container has text-align:center
    text-align: left;

    // place menu above the input element
    &--dropup {
      bottom: 100%;
      margin-bottom: (-$borderWidth);
    }

    padding: 0;
    // margin-left to compensate for the padding on the parent
    margin: -1px 0 0 0-($borderWidth);

    @include mq(sm) {
      @include setPropFromVar(box-shadow, $var-input-focus-shadow);
      clip-path: inset(0 -10px -10px -10px);
    }

    //background-color: white;
    //border: $borderWidth solid $greyBorder;

    @include setPropFromVar(background-color, $var-color-bg-1);
    border: 1px solid;
    @include setPropFromVar(border-color, $var-color-ui);
    @include setPropFromVar(border-top-color, $var-color-bg-1);
    border-bottom-left-radius: $global-radius;
    border-bottom-right-radius: $global-radius;

    // don't let the contents wrap AKA the container will be as wide as the contents
    white-space: nowrap;
    // except on small screens, where we force the dropdown width to match the input
    @media (max-width: 500px) {
      white-space: normal;
    }

    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;

    // Fixes https://github.com/jackocnr/intl-tel-input/issues/765
    // Apple still hasn't fixed the issue where setting overflow: scroll on a div element does not use inertia scrolling
    // If this is not set, then the country list scroll stops moving after rasing a finger, and users report that scroll is slow
    // Stackoverflow question about it: https://stackoverflow.com/questions/33601165/scrolling-slow-on-mobile-ios-when-using-overflowscroll
    -webkit-overflow-scrolling: touch;

    .iti__flag-container.is-locked &,
    .iti__flag-container.is-unlocked & {
      max-width: 408px;
    }
  }

  // dropdown flags need consistent width, so wrap in a container
  &__flag-box {
    display: inline-block;
    width: $flagWidth;
  }

  // the divider below the preferred countries
  &__divider {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: $borderWidth solid $greyBorder;
  }

  // each country item in dropdown (we must have separate class to differentiate from dividers)
  &__country {
    // Note: decided not to use line-height here for alignment because it causes issues e.g. large font-sizes will overlap, and also looks bad if one country overflows onto 2 lines
    //padding: 5px 10px;
    outline: none;

    @include input-sizing(small);
    .iti__flag-container.is-unlocked & {
      @include input-sizing(smaller);
    }
  }

  // the dial codes after the country names are greyed out
  &__dial-code {
    color: $greyText;
  }
  &__country.iti__highlight {
    background-color: $hoverColor;
  }

  // spacing between country flag, name and dial code
  &__flag-box, &__country-name, &__dial-code {
    vertical-align: middle;
  }
  &__flag-box, &__country-name {
    margin-right: 6px;
  }

  // these settings are independent of each other, but both move selected flag to left of input
  &--allow-dropdown, &--separate-dial-code {
    input, input[type=text], input[type=tel] {
      margin-left: 0;
      &, &.is-unlocked {
        padding-left: $selectedFlagArrowWidth + $inputPadding !important;
      }
      &.is-locked {
        padding-left: $flagWidth + $flagPadding !important;
      }
      @include mq($from: md) {
        padding-left: $selectedFlagArrowWidthMd + $inputPaddingMd !important;
        &.is-locked {
          padding-left: $flagWidth + $flagPaddingMd !important;
        }
        &.is-unlocked {
          padding-left: $selectedFlagArrowWidth + $inputPadding !important;
        }
      }
    }
    .iti__flag-container {
      right: auto;
      left: 0;
    }
  }

  &--allow-dropdown {
    // hover state - show flag is clickable
    .iti__flag-container:not(.is-locked):hover {
      cursor: pointer;
      .iti__selected-flag {
        background-color: $hoverColor;
      }
    }
    // disable hover state when input is disabled
    input[disabled] + .iti__flag-container:hover,
    input[readonly] + .iti__flag-container:hover {
      cursor: default;
      .iti__selected-flag {
        background-color: transparent;
      }
    }
  }

  &--separate-dial-code {
    .iti__selected-flag {
      // now that we have digits in this section, it needs this visual separation
      background-color: $hoverColor;
    }
    .iti__selected-dial-code {
      margin-left: $arrowPadding;
      @include mq($from: md) {
        margin-left: $arrowPaddingMd;
        .iti__flag-container.is-unlocked & {
          margin-left: $arrowPadding;
        }
      }
    }
  }

  // if dropdownContainer option is set, increase z-index to prevent display issues
  &--container {
    position: absolute;
    top: -1000px;
    left: -1000px;
    // higher than default Bootstrap modal z-index of 1050
    z-index: 1060;
    // to keep styling consistent with .flag-container
    padding: $borderWidth;
    &:hover {
      cursor: pointer;
    }
  }
}

// overrides for mobile popup (note: .iti-mobile class is applied on body)
.iti-mobile .iti {
  &--container {
    top: $mobilePopupMargin;
    bottom: $mobilePopupMargin;
    left: $mobilePopupMargin;
    right: $mobilePopupMargin;
    position: fixed;
  }
  &__country-list {
    max-height: 100%;
    width: 100%;
  }
  &__country {
    padding: 10px 10px;
    // increase line height because dropdown copy is v likely to overflow on mobile and when it does it needs to be well spaced
    line-height: 1.5em;
  }
}






.iti__flag {
  height: $flagHeight;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("#{$flagsImagePath}#{$flagsImageName}.#{$flagsImageExtension}");
  background-repeat: no-repeat;
  // empty state
  background-color: #DBDBDB;
  background-position: $flagWidth 0;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url("#{$flagsImagePath}#{$flagsImageName}@2x.#{$flagsImageExtension}");
  }
}



// hack for Nepal which is the only flag that is not square/rectangle, so it has transparency, so you can see the default grey behind it
.iti__flag.iti__np {
  background-color: transparent;
}
