/*------------------------------------*\
    #VIEWS TABLES
\*------------------------------------*/


.c-views-table {
  &--view_air-auction-items-admin,
  &--view_air-auction-items-sorting {
    thead {
      @include setPropFromVar(background-color, $var-color-bg-3);
    }
    tbody {
      background-color: white;
    }
  }
  &--view_air-auction-items:not(.sticky-header) {
    margin-bottom: $spacing-unit;
  }
  &--line-items {
    table-layout: fixed;
  }
}

.c-views-table__header,
.c-views-table__field {

  .c-views-table--line-items & {
    &--amount,
    &--commerce-stock,
    &--quantity,
    &--edit-quantity,
    &[class*="--commerce-total"] {
      text-align: right;
    }
  }
  .c-views-table--line-items & {
    @include mq($until: md) {
      &--amount,
      &--commerce-stock,
      &--quantity,
      &--edit-quantity,
      &[class*="--commerce-total"] {
        text-align: inherit;
      }
    }
  }

  @include mq(md) {
    .c-views-table--view_air-order-payments & {
      &--delete-transaction,
      &--status {
        text-align: center;
      }
    }
    .c-views-table--view_air-credit-bundle-line-items &,
    .c-views-table--view_air-auction-donations.c-views-table--display_donation-line-items &,
    .c-views-table--view_air-order-payments & {
      &--amount,
      &[class*="--commerce-total"] {
        text-align: right !important;
      }
    }
  }

  &--title,
  &--title-1,
  &--commerce-store {
    @include mq(md) {
      .c-views-table:not(.c-views-table--aa-admin):not(.c-views-table--view_air-auction-bids.c-views-table--display_monitor) & {
        & > *:not(.o-table-responsive__in-cell-header) {
          max-width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .c-views-table--view_air-auction-leaders.c-views-table--display_auction-leaders & {
        & > *:not(.o-table-responsive__in-cell-header) {
          max-width: 160px !important;
        }
      }
      .c-views-table--view_air-auction-leaders.c-views-table--display_lec-leaders & {
        & > *:not(.o-table-responsive__in-cell-header) {
          max-width: 300px !important;
        }
      }
      .c-views-table--view_air-auction-bids.c-views-table--display_auction-bids & {
        & > *:not(.o-table-responsive__in-cell-header) {
          max-width: 30vw !important;
        }
      }
      .c-views-table--view_air-user-bids.c-views-table--display_bidder-auction-recent-bids &,
      .c-views-table--view_air-user-bids.c-views-table--display_bid-history & {
        & > *:not(.o-table-responsive__in-cell-header) {
          max-width: 50vw !important;
        }
      }
      .c-views-table--view_air-auction-items-sorting &,
      .c-views-table--view_air-auction-items-admin & {
        & > *:not(.o-table-responsive__in-cell-header) {
          max-width: 15vw !important;
        }
      }
    }
    @include mq(mlg) {
      .c-views-table--view_air-auction-items-sorting &,
      .c-views-table--view_air-auction-items-admin & {
        & > *:not(.o-table-responsive__in-cell-header) {
          max-width: 25vw !important;
        }
      }
    }
    @include mq(lg) {
      .c-views-table--view_air-auction-items-sorting &,
      .c-views-table--view_air-auction-items-admin & {
        & > *:not(.o-table-responsive__in-cell-header) {
          max-width: 400px !important;
        }
      }
    }
  }

  &--name {
    > *:not(.o-table-responsive__in-cell-header) {
      @include mq(md) {
        max-width: 160px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &.c-views-table__header--views-bulk-operations,
  &.c-views-table__field--views-bulk-operations {
    line-height: 1;
  }

  &--weight {
    html.js .c-views-table--view_air-auction-items-sorting & {
      display: none;
    }
  }

  .c-views-table--view_air-auction-bids.c-views-table--display_auction-item-bids-list & {
    @include mq($until: md) {
      @include font-size($font-size-12, 1.5);
    }
  }

}

.c-views-table__header {
  &--field-ai-image {
    .c-views-table--view_air-auction-items-admin &,
    .c-views-table--view_air-auction-items-sorting & {
      color: $color-gray-98-blue-tint;
    }
  }
  &--title {
    .c-views-table--view_air-auction-items-sorting & {
      color: $color-gray-98-blue-tint;
    }
  }
  .c-views-table--view_air-auction-items & {
    background-color: white;
  }
  .c-views-table--view_air-auction-items-admin & {
    border-bottom: none;
    &:after {
      border-bottom: none;
    }
  }
  .c-views-table--line-items tr:first-child &:first-child {
    @for $i from 2 through 5 {
      @at-root .cols-#{$i}#{&} {
        width: 100% - $i * 10%;
      }
    }
  }
}

.c-views-table__field {
  .c-views-table--view_air-auction-items-admin &,
  .c-views-table--view_air-auction-items-sorting & {
    &--draggable {
      padding-left: $spacing-unit;
      padding-right: $spacing-unit-small;
      position: relative;
    }
  }
  &.c-views-table__field--field-ai-image {
    width: 100px;
  }
  .c-views-table--view_air-auction-items-admin &--views-bulk-operations {
    @include mq($until: md) {
      display: none;
    }
  }
  .c-views-table--view_air-auction-items-admin.c-views-table--display_listing &--field-ai-image {
    @include mq($until: md) {
      padding-top: $spacing-unit-small;
    }
  }
  .c-views-table--view_air-auction-items-admin &--title {
    position: relative;
  }

  .c-views-table--responsive &--empty {
    @include mq($until: md) {
      display: none !important;
    }
  }
}

.c-views-table__header > a {
  position: relative;
  @include attention {
    text-decoration: none;
  }
}

.c-views-table__vbo-toggle {
  display: inline-block;
  position: absolute;
  z-index: 1;
  left: $spacing-unit-med-large;
  top: 50%;
  transform: translateY(-50%);
  @include mq(lg) {
    left: $spacing-unit-med-large + 2px;
  }
  .c-views-table--view_air-auction-items-admin.sticky-header.sticky-enabled & {
    display: none;
  }
}

.c-views-table__in-cell-header {
  &--field_ai_image {
    display: none;
  }
  &--amount,
  &[class*="--commerce-total"] {
    text-align: left;
  }
  .c-views-table--line-items &--nothing {
    display: none;
  }
}

.c-views-table__cell-wrapper {
  @include mq($until: md) {
    text-align: right;
    .c-views-table--line-items &--nothing {
      text-align: left;
      flex: 1 1 auto;
    }
  }
  &--field_ai_image {
    padding-left: 0;
    float: none;
    > a {
      display: inline-block;
    }
    img {
      max-width: 100px;
      max-height: 100px;
    }
    @include mq($until: md) {
      margin-bottom: $spacing-unit-tiny;
    }
  }
  &--admin-options {
    margin-bottom: $spacing-unit-tiny;
    @include mq(md) {
      margin-bottom: 0;
      min-width: 150px;
    }
  }
  .c-views-table--view_air-auction-items &--title {
    position: relative;
  }
}

.c-views-table__mousetrap-hint {
  position: absolute;
  .c-views-table__cell-wrapper--title & {
    top: $spacing-unit-small;
    left: 0;
  }
}

.c-views-table__me {
  @include setPropFromVar(color, $var-color-link);
}
