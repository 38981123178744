/*------------------------------------*\
    #Donate Now block
\*------------------------------------*/

.c-donate-now {
  @include setPropFromVar(background-color, $var-color-bg-2);
  border: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);;
  text-align: center;
  padding: $spacing-unit-med-small;
  &--top,
  &--bottom {
    @include setPropFromVar(background-color, $var-color-bg-2);
    @include setPropFromVar(border-color, $var-color-border);;
    border: 1px solid;
  }
  @include mq(mmd) {
    padding: $spacing-unit;
  }
  &--sidebar {
    @include mq($from: md) {
      padding: $spacing-unit-med-large;
    }
  }
}

.c-donate-now__form {
  display: flex;
  justify-content: space-around;
}

.c-donate-now__wrapper {
  .c-donate-now--inline & {
    @include mq(mmd) {
      display: flex;
      justify-content: space-around;
      align-items: baseline;
    }
  }
}

.c-donate-now__input-wrapper {
  .c-donate-now--inline & {
    @include mq($from: mmd) {
      padding-bottom: 0;
    }
  }
  .c-donate-now--sidebar & {
    @include mq($from: mmd) {
      padding-bottom: $spacing-unit-small;
    }
  }
}

.c-donate-now__icon {
  display: block;
  transform: scale(2);
  margin: $spacing-unit-small 0;
  @include setPropFromVar(color, $var-color-ui);
  .c-donate-now--inline & {
    @include mq(mmd) {
      margin: 0 $spacing-unit-small 0;
      transform: translateY(20%) scale(2);
    }
  }

  .c-donate-now--sidebar & {
    transform: scale(3);
    margin: $spacing-unit-small 0 $spacing-unit;
  }

}

.c-donate-now__title {
  @extend .c-heading--delta-l;
  margin-bottom: $spacing-unit-med-small;
  .c-donate-now--inline & {
    @include mq(mmd) {
      padding: 0 $spacing-unit;
      margin: 0;
    }
  }
}

.c-donate-now__submit {
  width: 100%;
  @include mq($from: sm) {
    width: auto;
  }
  .c-donate-now--sidebar & {
    display: block;
    width: 100%;
    margin-top: $spacing-unit-tiny;
  }
  .c-donate-now--inline & {
    @include mq(mmd) {
      margin-left: $spacing-unit-small;
    }
  }
}

.c-donate-now__buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > * {
    flex: 1 0 auto;
  }
  //margin: $spacing-unit-small -#{$spacing-unit-tiny} $spacing-unit-tiny;
  margin: 0 -#{$spacing-unit-tiny};
  .c-donate-now--inline & {
    @include mq($from: md) {
      margin: 0;
    }
  }
}

.c-donate-now__button {
  padding: 0 $spacing-unit-tiny;
  margin: 0;
}

.c-donate-now__amount-wrapper {
  .c-donate-now--inline & {
    @include mq($from: sm) {
      display: flex;
      justify-content: space-around;
    }
  }
}

label.error {
  .c-donate-now--top & ,
  .c-donate-now--bottom & {
    position: absolute;
  }
}
