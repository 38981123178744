/*------------------------------------*\
    #FOOTER
\*------------------------------------*/

.c-footer {
  position: relative;
  text-align: center;
  padding: $spacing-unit-tiny 0 $spacing-unit-min;
  @include setPropFromVar(font-family, $var-font-family-normal);
  @include setPropFromVar(color, $var-color-text-dark);
  border-top: 1px solid;
  @include setPropFromVar(border-color, $var-color-border-light);
  @include setPropFromVar(background-color, $var-color-bg-1);
  &--info {
    @include setPropFromVar(background-color, $var-color-bg-2);
    @include mq(mlg) {
      text-align: left;
      padding-bottom: $spacing-unit-med-small;
    }
  }
}

.c-footer__block-links {
  margin-top: $spacing-unit-small;
  position: relative;
  @include mq(sm) {
    margin-top: 0;
  }
}

.c-footer__wrap {
  .c-footer--info & {
    &--outer {
      @extend .o-layout;
      @extend .o-layout--large;
    }
    &--main-nav {
      @extend .o-layout__item;
      @extend .u-2\/6;
      @extend .u-2\/5\@lg;
    }
    &--personal-nav {
      @extend .o-layout__item;
      @extend .u-1\/6;
      @extend .u-1\/5\@lg;
    }
    &--info {
      @extend .o-layout__item;
      @extend .u-3\/6\@mlg;
      @extend .u-2\/5\@lg;
    }
  }
}

.c-footer__nav {
  display: none;
  @include mq(mlg) {
    display: block;
    margin: $spacing-unit-med-large 0 0;
  }
}

.c-footer__nav--main {
  @include mq(mlg) {
    position: relative;
    columns: 2;
  }
}

.c-footer__info {
  @include mq(md) {
    display: flex;
    align-items: center;
    justify-content: left;
  }
  .c-footer--info & {
    @include mq($from: mlg) {
      display: block;
    }
  }
}

.c-footer__branding {
  display: none;
  @include mq($from: md) {
    display: block;
  }
  .c-footer--info & {
    @include mq($from: mlg) {
      margin: $spacing-unit 0 $spacing-unit-small;
    }
  }
}

.c-footer__legal {
  direction: ltr;
  @include setPropFromVar(font-family, $var-font-family-lighter);
  @include setPropFromVar(color, $var-color-text-base);
  @include font-size(10px, 1.5);
  @include mq($from: mmd) {
    @include font-size(12px, 1.5);
  }
  @include mq(md) {
    margin: $spacing-unit-min 0 0 $spacing-unit-small;
  }
  .c-footer--info & {
    @include mq($from: mlg) {
      margin: 0;
    }
  }
}

.c-footer__links {
  margin: 0;
  list-style-type: none;
  .c-footer--info & {
    @include mq(mlg) {
      display: block;
    }
  }
}

.c-footer__link {
  display: inline-block;
  padding: 0;
  &--copyright {
    .c-footer--info & {
      @include mq(mlg) {
        display: block;
      }
    }
    @include mq($from: md) {
      a {
        pointer-events: none;
      }
    }
  }
  &--abn {
    @include mq($until: mmd) {
      display: none;
    }
  }
  &--language-select {
    position: relative;
    padding-left: $spacing-unit-min;
    width: 10px;
  }
}

.c-footer__legal-link {
  color: inherit;
  @include attention {
    color: $color-blue-dark;
  }
  &:before {
    content: '|';
    display: inline-block;
    position: relative;
    top: -2px;
    padding: 0 3px 0 0px;
    @include font-size(8px, 1);
  }
  .c-footer__link--copyright &:before {
    display: none;
  }
  .c-footer--info .c-footer__link--abn &:before {
    @include mq($from: mlg) {
      display: none;
    }
  }
}

.c-footer__top-link {
  display: none;
  @include mq($from: mlg) {
    display: block;
  }
}

.c-footer__monitor-view-link {
  display: none;
  @include mq($from: mlg) {
    display: block;
    margin-left: auto;
    @include font-size(12px, 1.5);
  }
}
