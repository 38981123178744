///* ========================================================================
//   #INPUT SIZING
//   ======================================================================== */

// Used in c-input, c-button and c-form__control

$input-line-height-tiny:          12px;
$input-line-height-smaller:       16px;
$input-line-height-small:         18px;
$input-line-height-normal:        22px;
$input-line-height-large:         26px;

$input-padding-vertical-v-tiny:   6px;
$input-padding-vertical-tiny:     8px;
$input-padding-vertical-smaller:  10px;
$input-padding-vertical-small:    15px;
$input-padding-vertical-normal:   16px;
$input-padding-vertical-large:    18px;

$input-padding-horizontal-tiny:   10px;
$input-padding-horizontal-small:  15px;
$input-padding-horizontal-normal: 24px;

$input-padding-xs-vertical:       $input-padding-vertical-smaller;
$input-padding-md-vertical:       $input-padding-vertical-normal;
$input-padding-xs-horizontal:     $input-padding-horizontal-small;
$input-padding-md-horizontal:     $input-padding-horizontal-normal;

@mixin input-sizing($variant: normal, $include-font: true, $borders: true, $include-height: true) {

  // Normal
  $padding-vertical: (
          xs: $input-padding-vertical-smaller,
          sm: $input-padding-vertical-smaller,
          md: $input-padding-vertical-normal,
          lg: $input-padding-vertical-normal
  );
  $padding-horizontal: (
          xs: $input-padding-horizontal-small,
          sm: $input-padding-horizontal-small,
          md: $input-padding-horizontal-normal,
          lg: $input-padding-horizontal-normal
  );
  $font-size: (
          xs: $font-size-12,
          sm: $font-size-14,
          md: $font-size-15,
          lg: $font-size-16
  );
  $line-height: (
          xs: $input-line-height-tiny,
          sm: $input-line-height-small,
          md: $input-line-height-normal,
          lg: $input-line-height-normal
  );

  @if $variant == tiny {
    $padding-vertical: (
            xs: $input-padding-vertical-tiny,
            sm: $input-padding-vertical-tiny,
            md: $input-padding-vertical-tiny,
            lg: $input-padding-vertical-tiny
    );
    $padding-horizontal: (
            xs: $input-padding-horizontal-tiny,
            sm: $input-padding-horizontal-tiny,
            md: $input-padding-horizontal-tiny,
            lg: $input-padding-horizontal-tiny
    );
    $font-size: (
            xs: $font-size-12,
            sm: $font-size-12,
            md: $font-size-12,
            lg: $font-size-12
    );
    $line-height: (
            xs: $input-line-height-tiny,
            sm: $input-line-height-tiny,
            md: $input-line-height-tiny,
            lg: $input-line-height-tiny
    );
  }

  @if $variant == smaller {
    $padding-vertical: (
            xs: $input-padding-vertical-tiny,
            sm: $input-padding-vertical-tiny,
            md: $input-padding-vertical-smaller,
            lg: $input-padding-vertical-smaller
    );
    $padding-horizontal: (
            xs: $input-padding-horizontal-small,
            sm: $input-padding-horizontal-small,
            md: $input-padding-horizontal-small,
            lg: $input-padding-horizontal-small
    );
    $font-size: (
            xs: $font-size-12,
            sm: $font-size-14,
            md: $font-size-14,
            lg: $font-size-14
    );
    $line-height: (
            xs: $input-line-height-smaller,
            sm: $input-line-height-smaller,
            md: $input-line-height-smaller,
            lg: $input-line-height-smaller
    );
  }

  @if $variant == small {
    $padding-vertical: (
            xs: $input-padding-vertical-tiny,
            sm: $input-padding-vertical-tiny,
            md: $input-padding-vertical-smaller,
            lg: $input-padding-vertical-smaller
    );
    $padding-horizontal: (
            xs: $input-padding-horizontal-small,
            sm: $input-padding-horizontal-small,
            md: $input-padding-horizontal-small,
            lg: $input-padding-horizontal-small
    );
    $font-size: (
            xs: $font-size-12,
            sm: $font-size-14,
            md: $font-size-15,
            lg: $font-size-15
    );
    $line-height: (
            xs: $input-line-height-smaller,
            sm: $input-line-height-small,
            md: $input-line-height-small,
            lg: $input-line-height-small
    );
  }

  @if $variant == normal {
    // Set out above
  }

  @if $variant == large {
    $padding-vertical: (
            xs: $input-padding-vertical-tiny,
            sm: $input-padding-vertical-smaller,
            md: $input-padding-vertical-normal,
            lg: $input-padding-vertical-normal
    );
    $padding-horizontal: (
            xs: $input-padding-horizontal-tiny,
            sm: $input-padding-horizontal-small,
            md: $input-padding-horizontal-normal,
            lg: $input-padding-horizontal-normal
    );
    $font-size: (
            xs: $font-size-14,
            sm: $font-size-15,
            md: $font-size-16,
            lg: $font-size-18
    );
    $line-height: (
            xs: $input-line-height-smaller,
            sm: $input-line-height-small,
            md: $input-line-height-normal,
            lg: $input-line-height-large
    );
  }

  @if $variant == huge {
    $padding-vertical: (
            xs: $input-padding-vertical-small,
            sm: $input-padding-vertical-small,
            md: $input-padding-vertical-normal,
            lg: $input-padding-vertical-large
    );
    $padding-horizontal: (
            xs: $input-padding-horizontal-normal,
            sm: $input-padding-horizontal-normal,
            md: $input-padding-horizontal-normal,
            lg: $input-padding-horizontal-normal
    );
    $font-size: (
            xs: $font-size-15,
            sm: $font-size-15,
            md: $font-size-16,
            lg: $font-size-18
    );
    $line-height: (
            xs: $input-line-height-small,
            sm: $input-line-height-small,
            md: $input-line-height-normal,
            lg: $input-line-height-large
    );
  }

  @each $breakpoint in (xs, sm, md, lg) {
    $key: $breakpoint;
    $pv: map-get($padding-vertical, $key);
    $ph: map-get($padding-horizontal, $key);
    $fs: map-get($font-size, $key);
    $lh: map-get($line-height, $key);
    $border-width: 0px;
    @if $borders {
      $border-width: 1px;
    }
    @include mq($from: $breakpoint) {
      @if $include-font == true or $include-font == only {
        @include font-size($fs);
      }
      @if $include-font != only {
        line-height: $lh;
        padding: $pv + 1px $ph $pv - 1px;
        @if $include-height {
          height: $lh + 2 * $pv + 2 * $border-width;
        }
      }
    }
  }

}



































