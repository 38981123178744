/*------------------------------------*\
    #LISTING HEADERS
\*------------------------------------*/

.c-listing-header {
  padding-top: $spacing-unit-tiny;
  padding-bottom: $spacing-unit-tiny;
  @include mq(md) {
    padding-top: $spacing-unit-small;
    padding-bottom: $spacing-unit-small;
    text-align: right;
  }
}

.c-listing-header__title {
  margin: $spacing-unit-tiny 0;
  @include mq(md) {
    float: left;
    margin: 0;
  }
}

.c-listing-header__title-heading {
  @include setPropFromVar(color, $var-color-text-v-dark);
  display: inline;
}

.c-listing-header__title-next-expiry {
  display: none;
  @include mq(md) {
    @include font-size($font-size-14, 2);
    display: inline;
    padding-left: $spacing-unit-small;
  }
}

.c-listing-header__cta {
  float: right;
  margin: $spacing-unit-tiny 0 0 $spacing-unit-small;
  @include mq($until: sm) {
    height: 2.5em;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: $spacing-unit-med-large;
  }
}

.c-listing-header__filters {
  text-align: left;
  margin-top: $spacing-unit-small;
  @include mq($from: md) {
    display: block !important;
    clear: left;
  }
}

.c-listing-header__view-options {
  margin-bottom: $spacing-unit-tiny;
  @include mq(sm) {
    display: inline-block;
  }
  @include mq($from: md) {
    margin-bottom: 0;
  }
}

