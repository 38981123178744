/*------------------------------------*\
    #TABLE TOTAL
\*------------------------------------*/

.c-table-total {
  border-top: 1px solid;
  border-bottom: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  @include setPropFromVar(border-color, $var-color-border-heavy);;
  @include setPropFromVar(font-family, $var-font-family-bold);
  @include setPropFromVar(font-weight, $var-font-weight-bold);
  width: auto;
  margin: $spacing-unit 0;
  min-width: 200px;
}

.c-table-total__title {
  padding: $spacing-unit $spacing-unit-tiny $spacing-unit-tiny;
}

.c-table-total__value {
  padding: $spacing-unit $spacing-unit-tiny $spacing-unit-tiny;
  text-align: right;
}
