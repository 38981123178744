/*------------------------------------*\
    #FIELDS
\*------------------------------------*/

.c-field {
  @include mq($until: sm) {
    @include font-size($font-size-14, 1.5);
  }
  &--commerce_customer_shipping {
    margin-top: $spacing-unit;
  }
}

.c-field__label {
  @extend %c-heading--gamma;
}
