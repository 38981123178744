/*------------------------------------*\
    #FILE UPLOAD
\*------------------------------------*/

.c-file-upload {
}

.c-file-upload__data {
  clear: both;
}

.c-file-upload__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.c-file-upload__label {
  @extend .c-button;
  @extend .c-button--small;
  cursor: pointer;

  .c-file-upload.has-file & {
    @extend .c-button--link;
    @include setPropFromVar(color, $var-color-text-base);;
  }
  .is-disabled & {
    @extend .c-button--quiet;
    @include setPropFromVar(color, $var-color-text-light);;
    cursor: default;
    pointer-events: none;
  }
}

.c-file-upload__wrapper {
  margin-bottom: $spacing-unit-small;
}

.c-file-upload:not(.has-file) .c-file-upload__input:focus + .c-file-upload__label,
.c-file-upload:not(.has-file) .c-file-upload__input.has-focus + .c-file-upload__label {
  @include button-focus;
}

.c-file-upload__label-text {
  padding-left: $spacing-unit-small;
  word-break: break-word;
  white-space: normal;
}

.c-file-upload__info {
  margin-bottom: $spacing-unit-small;
}

.c-file-upload__name {
  padding-right: $spacing-unit;
}

.c-file-upload__file-mime,
.c-file-upload__file-size {
  margin-right: $spacing-unit-small;
  font-size: 0.67em;
  color: $color-gray-60;
}

.c-file-upload__upload-button {
  html.js & {
    display: none;
  }
  .c-file-upload.has-file & {
    display: block;
  }
}

