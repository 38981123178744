/*------------------------------------*\
    #ARTICLE NODES
\*------------------------------------*/

.c-node-article {

}

.c-node-article__title {
  @extend .c-heading--alpha;
  margin-bottom: $spacing-unit-small;
  .c-node-article--teaser & {
    @extend .c-heading--gamma-l;
    margin-bottom: $spacing-unit-tiny;
  }
}

.c-node-article__submitted {
  .c-node-article--full & {
    @extend .c-heading--eta;
    margin-bottom: $spacing-unit-large;
  }
  .c-node-article--teaser & {
    @include font-size($font-size-14);
    margin-bottom: $spacing-unit-small;
  }
}

.c-node-article__subheading {
  @extend .c-heading--beta;
  margin-top: $spacing-unit-large;
}
