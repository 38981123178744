///*------------------------------------*\
//    #MIXINS
//\*------------------------------------*/

// Apply ‘attention’ styles in one go.
@mixin attention($self: false, $focus: true, $active: true) {
  @if($self != false and $focus != true) {
    &,
    &:hover,
    &:active {
      @content;
    }
  }
  @elseif($self != false) {
    &,
    &:active,
    &:hover,
    &:focus {
      @content;
    }
  }
  @elseif($focus != true) {
    &:hover,
    &:active {
      @content;
    }
  }
  @elseif($active != true) {
    &:hover,
    &:focus {
      @content;
    }
  }
  @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

// Create all specified heading styles in one go.
@mixin headings($from: 1, $to: 6) {

  %base-heading {
    @content;
  }

  @if $from >= 1 and $to <= 6{

    @for $i from $from through $to{

      h#{$i} {
        @extend %base-heading;
      }

    }

  }

}

// Text overflow
@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover,
  &:focus {
    overflow: visible;
  }
}

@mixin tab-focus {
  // Default
  outline: thin dotted;
  // WebKit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

/**
 * Force overly long spans of text to truncate, e.g.:
 *
   `@include truncate(100%);`
 *
 * Where `$truncation-boundary` is a united measurement.
 */
@mixin truncate($truncation-boundary){
  max-width:$truncation-boundary;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}

@mixin circle($width, $color) {
  width: $width;
  height: $width;
  background: $color;
  border-radius: $width/2;
}

@mixin triangle($size, $color) {
  display: block;
  width: 0;
  height: 0;
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-bottom: $size solid $color;
}

@mixin triangle-eq($size, $color) {
  display: block;
  width: 0;
  height: 0;
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-bottom: 1.732 * $size solid $color; // 2 * cos(30deg)
}

// Shadows
@mixin hard-shadow($size: 5px, $color: $color-gray-91-blue-tint, $dir: left) {
  @if $dir == left {
    box-shadow: -$size $size 0 $color;
  }
  @if $dir == right {
    box-shadow: $size $size 0 $color;
  }
}

// Apply a style across all min-width media queries. Useful for overriding without using !important
@mixin apply-style-across-all-breakpoints($property, $value) {
  $breakpoints: map-keys($mq-breakpoints);
  @each $breakpoint in ($breakpoints) {
    @include mq($from: $breakpoint) {
      #{$property}: unquote($value);
    }
  }
}

// HR using absolutely-positioned pseudo element
@mixin hr-bottom($extend: 0) {
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: -$extend;
    right: -$extend;
    border-bottom: 1px solid;
    @include setPropFromVar(border-color, $var-color-border);
  }
}

// Hide a top or bottom border
@mixin hide-border($which, $position: relative, $color: false) {
  position: $position;
  &:before, &:after {
    content: '';
    position: absolute;
    display: block;
    width: 1px;
    height: 1px;
    @if $color {
      background-color: $color;
    }
    @else {
      @include setPropFromVar(background-color, $var-color-ui);
    }
  }
  &:before {
    left: -1px;
  }
  &:after {
    right: -1px;
  }
  border-#{$which}-left-radius: 0;
  border-#{$which}-right-radius: 0;
  border-#{$which}-color: transparent !important;
  &:before, &:after {
    #{$which}: -1px;
  }
}
.with-border-bottom-hidden {
  @include hide-border(bottom);
}
.with-border-top-hidden {
  @include hide-border(top);
}

// Undo the effect of a parent wrapper
@mixin undo-wrapper($padding: $spacing-unit) {
  margin-left: -$padding;
  margin-right: -$padding;
  padding-left: $padding;
  padding-right: $padding;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}
