/*------------------------------------*\
    #LINE ITEM SUMMARY
\*------------------------------------*/

.c-line-item-summary {
  @include setPropFromVar(font-family, $var-font-family-bold);
  @include setPropFromVar(font-weight, $var-font-weight-bold);
  border-top: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  text-align: right;
  @include font-size($font-size-15, 1.5);
  @include mq(md) {
    @include font-size($font-size-16, 1.5);
    border-top: none;
    @include clearfix;
  }
}

.c-line-item-summary__total {
  padding-top: $spacing-unit;
  @include mq(md) {
      border-bottom: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
    padding: $spacing-unit $spacing-unit-small $spacing-unit-small $spacing-unit-large;
    float: right;
  }
}
