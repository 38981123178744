/*------------------------------------*\
    #HEADINGS
\*------------------------------------*/

.c-heading--alpha,
%c-heading--alpha {
  @include setPropFromVar(color, $var-color-heading-alpha);
  @include setPropFromVar(font-family, $var-font-family-bolder);
  @include setPropFromVar(font-weight, $var-font-weight-bolder);
  display: block;
  margin-bottom: $spacing-unit;
  @include font-size(24px, 1.4);
  @include mq(md) {
    @include font-size(30px, 1.4);
    margin-bottom: $spacing-unit-med-large;
  }
  @include mq(lg) {
    @include font-size(44px, 1.4);
  }

  &-xl {
    @extend %c-heading--alpha;
    @include font-size(34px, 1.4);
    @include mq(md) {
      @include font-size(80px, 1.2);
    }
  }

  &-l {
    @extend %c-heading--alpha;
    @include font-size(34px, 1.4);
    @include mq(md) {
      @include font-size(60px, 1.2);
    }
  }

  &-s {
    @extend %c-heading--alpha;
    @include font-size(22px, 1.4);
    @include mq(md) {
      @include font-size(30px, 1.4);
    }
  }

  &-xs {
    @extend %c-heading--alpha;
    @include font-size(20px, 1.4);
    @include mq(md) {
      @include font-size(24px, 1.4);
    }
  }

  &-xxs {
    @extend %c-heading--alpha;
    @include font-size($font-size-18, 1.4);
    @include mq(md) {
      @include font-size(20px, 1.4);
    }
  }

  &.c-heading--boxed {
    margin-bottom: $spacing-unit;
  }
}


.c-heading--beta,
%c-heading--beta {
  @include setPropFromVar(color, $var-color-heading-beta);
  @include setPropFromVar(font-family, $var-font-family-bolder);
  @include setPropFromVar(font-weight, $var-font-weight-bolder);
  display: block;
  margin-bottom: $spacing-unit;
  clear: both;
  @include font-size(20px, 1.4);
  @include mq(sm) {
    @include font-size(24px, 1.4);
  }
  @include mq(md) {
    @include font-size(28px, 1.4);
  }

  &-xl {
    @extend %c-heading--beta;
    @include font-size(32px, 1.2);
    @include mq(sm) {
      @include font-size(48px, 1.2);
    }
    @include mq(md) {
      @include font-size(60px, 1.2);
    }
  }

  &-l {
    @extend %c-heading--beta;
    @include font-size(24px, 1.2);
    @include mq(sm) {
      @include font-size(36px, 1.2);
    }
    @include mq(md) {
      @include font-size(48px, 1.2);
    }
  }

  &-s {
    @extend %c-heading--beta;
    @include font-size($font-size-18, 1.4);
    @include mq(sm) {
      @include font-size(20px, 1.4);
    }
    @include mq(md) {
      @include font-size(26px, 1.4);
    }
  }

  &-xs {
    @extend %c-heading--beta;
    @include font-size(16px, 1.2);
    @include mq(md) {
      @include font-size(22px, 1.2);
    }
  }

  &-xxs {
    @extend %c-heading--beta;
    @include font-size(14px, 1.2);
    @include mq(md) {
      @include font-size(20px, 1.2);
    }
  }

}

.c-heading--gamma,
%c-heading--gamma {
  @include setPropFromVar(color, $var-color-heading-gamma);
  @include setPropFromVar(font-family, $var-font-family-bold);
  @include setPropFromVar(font-weight, $var-font-weight-bold);
  display: block;
  margin-bottom: $spacing-unit-small;
  @include font-size($font-size-16, 1.5);
  @include mq(sm) {
    @include font-size($font-size-18, 1.4);
  }
  @include mq(md) {
    @include font-size(20px, 1.2);
  }

  &-xl {
    @extend %c-heading--beta;
    @include font-size($font-size-18, 1.2);
    @include mq(sm) {
      @include font-size(36px, 1.2);
    }
    @include mq(md) {
      @include font-size(48px, 1.2);
    }
  }

  &-l {
    @extend %c-heading--gamma;
    @include font-size($font-size-18, 1.2);
    @include mq(md) {
      @include font-size(24px, 1.2);
    }
  }

  &-s {
    @extend %c-heading--gamma;
    @include font-size(14px, 1.4);
    @include mq(md) {
      @include font-size(20px, 1.2);
    }
  }

  &-xs {
    @extend %c-heading--gamma;
    @include font-size(13px, 1.4);
    @include mq(md) {
      @include font-size($font-size-18, 1.3);
    }
  }

  &-xxs {
    @extend %c-heading--gamma;
    @include font-size(12px, 1.4);
    @include mq(md) {
      @include font-size($font-size-16, 1.5);
    }
  }

}

.c-heading--delta,
%c-heading--delta {
  @include setPropFromVar(color, $var-color-heading-delta);
  @include setPropFromVar(font-family, $var-font-family-bold);
  @include setPropFromVar(font-weight, $var-font-weight-bold);
  display: block;
  margin-bottom: $spacing-unit;
  @include font-size($font-size-16, 1.5);

  &-l {
    @extend %c-heading--delta;
    @include font-size($font-size-18, 1.2);
    @include mq(md) {
      @include font-size(20px, 1.2);
    }
  }
}

.c-heading--epsilon,
%c-heading--epsilon {
  @include setPropFromVar(color, $var-color-text-base);
  @include setPropFromVar(font-family, $var-font-family-bold);
  @include setPropFromVar(font-weight, $var-font-weight-bold);
  display: block;
  margin-bottom: $spacing-unit-tiny;
  @include font-size($font-size-14, 1.5);
  @include mq($from: sm) {
    @include font-size($font-size-15, 1.5);
  }
  @include mq(md) {
    margin-bottom: $spacing-unit-small;
    @include font-size($font-size-18, 1.4);
  }
}

.c-heading--zeta,
%c-heading--zeta {
  color: $color-blue;
  @include setPropFromVar(font-family, $var-font-family-bold);
  @include setPropFromVar(font-weight, $var-font-weight-bold);
  display: block;
  @include font-size(14px);
}

.c-heading--eta,
%c-heading--eta {
  @include setPropFromVar(font-family, $var-font-family-bold);
  @include setPropFromVar(font-weight, $var-font-weight-bold);
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

@mixin heading--theta {
  @include setPropFromVar(font-family, $var-font-family-bold);
  @include setPropFromVar(font-weight, $var-font-weight-bold);
}
.c-heading--theta {
  @include heading--theta;
}

.c-heading--form-title,
%c-heading--form-title {
  @include setPropFromVar(color, $var-color-ui);
  @include setPropFromVar(font-family, $var-font-family-lighter);
  @include setPropFromVar(font-weight, $var-font-weight-lighter);
  display: block;
  padding-bottom: $spacing-unit;
  margin-bottom: $spacing-unit-med-large;
  border-bottom: 1px solid;
  @include setPropFromVar(border-color, $var-color-border-heavy);;
  @include font-size(24px, 1.2);
}

.c-heading__quietened {
  @include setPropFromVar(font-family, $var-font-family-default);
  @include setPropFromVar(font-weight, $var-font-weight-normal);
}

@mixin heading-bg($bg-color: $color-gray-40, $inc-lmd: true) {
  $shadow-color: $color-blue-gray-2;
  $text-color: white;
  @if $bg-color == $color-blue-v-light {
    $shadow-color: $color-blue-v-dark;
    $text-color: white;
  }
  @if $bg-color == white {
    $shadow-color: $color-gray-91-blue-tint;
    $text-color: $color-blue;
  }
  @if $bg-color == $color-red-bright {
    $shadow-color: $color-red-shadow;
    $text-color: white;
  }
  @if $bg-color == $color-green-dark {
    $shadow-color: $color-green-shadow;
    $text-color: white;
  }

  @if $inc-lmd == true {
    display: inline-block;
    padding: $spacing-unit $spacing-unit-large;
    color: $text-color;
    background-color: $bg-color;
    @include hard-shadow(5px, $shadow-color);
  }
  @include mq(md) {
    display: inline-block;
    padding: $spacing-unit $spacing-unit-med-huge;
    color: $text-color;
    background-color: $bg-color;
    @include hard-shadow(8px, $shadow-color);
  }
  @include mq(lg) {
    @include hard-shadow(12px, $shadow-color);
  }
}

.c-heading__bg {
  &--red {
    @include heading-bg($color-red-bright);
  }
  &--green {
    @include heading-bg($color-green-dark);
  }
}

.c-heading--table-row-break {
  @extend %c-heading--delta;
  margin-bottom: 0;
  padding-top: $spacing-unit-small;
}
