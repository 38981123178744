/*------------------------------------*\
    #CARDS
\*------------------------------------*/

.c-card {
  position: relative;

  &--plain {
    text-align: left;
    @include font-size($font-size-12, 1.5);
    @include hard-shadow(5px);
    @include mq(sm) {
      @include font-size($font-size-16, 1.5);
      @include hard-shadow(8px);
    }
    @include mq(md) {
      margin-bottom: $spacing-unit-large;
      @include font-size($font-size-20, 1.5);
      @include hard-shadow(12px);
    }
  }

  &--why {
    @include o-box;
    @include setPropFromVar(background-color, $var-color-bg-2);
    padding: $spacing-unit;
    box-shadow: 5px 5px 0 $color-gray-87-blue-tint;
    margin-bottom: $spacing-unit;
    clear: both;
    @include mq(sm) {
      box-shadow: 10px 10px 0 $color-gray-87-blue-tint;
      max-width: 66%;
    }
    @include mq(md) {
      padding: $spacing-unit-med-large;
      box-shadow: 15px 15px 0 $color-gray-87-blue-tint;
    }
    @include mq(lg) {
      padding: $spacing-unit-large;
    }
  }

  &.c-card--why.c-card--1 {
    @include mq(sm) {
      padding: $spacing-unit;
    }
    @include mq(md) {
      position: relative;
      z-index: 2;
      top: -$spacing-unit-large;
      width: 50%;
    }
    @include mq(lg) {
      width: 45%;
      left: 10%;
    }
    @include mq(xl) {
      width: 40%;
      left: 20%;
    }
  }

  &.c-card--why.c-card--2 {
    @include setPropFromVar(background-color, $var-color-bg-3);
    box-shadow: 5px 5px 0 $color-gray-96-blue-tint;
    @include mq(sm) {
      box-shadow: 10px 10px 0 $color-gray-96-blue-tint;
      float: right;
      text-align: right;
    }
    @include mq(md) {
      box-shadow: 15px 15px 0 $color-gray-96-blue-tint;
      position: absolute;
      top: $spacing-unit-large;
      z-index: 1;
      right: $spacing-unit;
      width: 55%;
    }
    @include mq(lg) {
      width: 42%;
      right: 8%;
    }
    @include mq(xl) {
      top: $spacing-unit-huge;
    }
  }

  &.c-card--why.c-card--3 {
    @include mq(sm) {
      max-width: none;
    }
    @include mq(md) {
      max-width: 66%;
      margin-bottom: -$spacing-unit-large;
    }
    @include mq(lg) {
      width: 55%;
    }
    @include mq(xl) {
      width: 50%;
      left: 10%;
    }
  }

  &--who {
    background-color: $color-blue-gray-2;
    @extend .o-flag;
  }

  &--pricing {
    @include o-box;
    @include setPropFromVar(background-color, $var-color-bg-2);
    padding: $spacing-unit;
    box-shadow: 5px 5px 0 $color-gray-87-blue-tint;
    margin-bottom: $spacing-unit;
    clear: both;
    text-align: left;
    max-width: 600px;
    @include font-size($font-size-12);
    @include mq(sm) {
      box-shadow: -10px 10px 0 $color-gray-87-blue-tint;
    }
    @include mq(mmd) {
      margin: 0 auto $spacing-unit;
    }
    @include mq(md) {
      padding: $spacing-unit-med-large;
      margin: $spacing-unit auto $spacing-unit-large;
      box-shadow: -15px 15px 0 $color-gray-87-blue-tint;
    }
    @include mq(lg) {
      padding: $spacing-unit-large;
    }
  }

  &.c-card--payment-collection {
    background-color: #fff;
    padding: $spacing-unit;
    display: flex;
    @include mq(md) {
      padding: $spacing-unit-med-large;
    }
  }

  &--feature {
    text-align: center;
    @include hard-shadow(5px);
    margin: 0;
    @include mq(sm) {
      @include hard-shadow(10px);
      display: block;
    }
    @include mq(md) {
      @include hard-shadow(15px);
    }
  }

}

.c-card__inner {
  .c-card--plain & {
    break-inside: avoid;
    background-color: #fff;
    padding: $spacing-unit;
    margin-bottom: $spacing-unit;
    @include mq(md) {
      padding: $spacing-unit $spacing-unit-med-large;
    }
    @include mq(lg) {
      padding: $spacing-unit-med-large $spacing-unit-large;
    }
  }
}

.c-card__front {
  .c-card--who & {
    text-align: center;
    background-color: $color-blue-gray-4;
    padding: ($spacing-unit-small + 10px) $spacing-unit-med-small ($spacing-unit-small - 10px);
    @extend .o-flag__img;
    position: relative;
    top: -10px;
    left: -15px;
  }

  .c-card--feature & {
    background-color: white;
    position: relative;
    z-index: 1;
    height: 100%;
    padding: $spacing-unit-small;
    opacity: 1;
    transition: $fade-transition;
    @include mq(sm) {
      padding: $spacing-unit;
    }
    @include mq(md) {
      padding: $spacing-unit-med-large;
    }
    @include mq(lg) {
      padding: $spacing-unit-large;
    }
  }
  .c-card--feature:hover & {
    opacity: 0;
  }
}

.c-card__back {
  @include font-size($font-size-12, 1.5);
  @include mq(md) {
    @include font-size($font-size-14, 1.5);
  }
  @include mq(lg) {
    @include font-size($font-size-16, 1.5);
  }

  .c-card--who & {
    padding: $spacing-unit-small $spacing-unit-med-small $spacing-unit-small ($spacing-unit-med-small - 15px);
    @extend .o-flag__body;
    color: white;
    text-align: left;
    position: relative;
    @include font-size($font-size-12, 1.5);
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: -15px;
      right: 0;
      bottom: 0;
      border-bottom: 1px solid $color-blue-gray-4;
      .c-card:last-child & {
        border: none;
      }
    }
    @include mq(mmd) {
      @include font-size($font-size-14, 1.5);
    }
    @include mq(lg) {
      @include font-size($font-size-16, 1.5);
    }
  }
  .c-card--feature & {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-gray-91-blue-tint;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    padding: $spacing-unit;
    @include setPropFromVar(font-family, $var-font-family-bold);
    @include setPropFromVar(font-weight, $var-font-weight-bold);
    @include mq(md) {
      padding: $spacing-unit-med-large;
    }
    @include mq(lg) {
      padding: $spacing-unit-large;

    }
  }
}

.c-card__back-note {
  .c-card--feature & {
    @include setPropFromVar(font-family, $var-font-family-default);
  }
}

.c-card__image {

  .c-card--why.c-card--1 & {
    float: left;
    width: 33%;
    margin-left: -$spacing-unit-small;
    margin-top: $spacing-unit-small;
    @include mq(sm) {
      margin: 0;
    }
  }

  .c-card--why.c-card--2 & {
    width: 33%;
    float: left;
    @include mq(sm) {
      width: 40%;
      padding: 0 $spacing-unit;
    }
    @include mq(md) {
      width: 50%;
      padding-left: 15%;
    }
    @include mq(lg) {
      padding-left: 17%;
      margin-top: -$spacing-unit;
    }
  }

  .c-card--why.c-card--3 & {
    position: absolute;
    left: $spacing-unit-small;
    top: $spacing-unit-small;
    width: 50%;
    @include mq(sm) {
      position: static;
      float: left;
      width: 45%;
    }
    @include mq(lg) {
      margin-top: -$spacing-unit-small;
    }
    @include mq(xl) {
      width: 40%;
    }
  }

  .c-card--pricing & {
    width: 70px;
    margin-right: $spacing-unit;
    flex: 0 0 auto;
  }

}

.c-card__title {
  .c-card--plain & {
    @extend .c-heading--gamma-l;
  }

  .c-card--why & {
    @extend .c-heading--gamma-xl;
    color: $color-red-bright;
    clear: none;
  }

  .c-card--why.c-card--1 & {
    padding-left: calc(33% + #{$spacing-unit-tiny});
    @include mq(sm) {
      padding-left: calc(33% + #{$spacing-unit});
    }
    @include mq(md) {
      margin-top: $spacing-unit-large;
      padding-left: calc(33% + #{$spacing-unit-large});
    }
    @include mq(lg) {
      margin-top: $spacing-unit-med-huge;
    }
  }

  .c-card--why.c-card--2 & {
    @include mq(md) {
      float: right;
      width: 50%;
    }
    @include mq(lg) {
      //width: 45%;
    }
  }

  .c-card--why.c-card--3 & {
    text-align: right;
    float: right;
    width: 75%;
    max-width: 180px;
    margin-top: $spacing-unit;
    position: relative;
    z-index: 1;
    @include mq(sm) {
      text-align: left;
      float: none;
      padding-left: calc(45% + #{$spacing-unit});
    }
    @include mq(xl) {
      padding-left: calc(40% + #{$spacing-unit});
      width: 40%;
    }
  }

  .c-card--who & {
    @extend .c-heading--gamma-l;
    @include mq($until: md) {
      @include font-size($font-size-16);
    }
    color: white;
  }

  .c-card--feature & {
    @extend .c-heading--gamma-l;
    margin-bottom: 0;
    color: $color-blue-gray-3;
  }

  .c-card--pricing & {
    @include setPropFromVar(font-family, $var-font-family-bolder);
    @include setPropFromVar(font-weight, $var-font-weight-bolder);
    @include font-size(26px, 1.6);
    color: $color-blue;
  }

}

.c-card__description {
  @include font-size($font-size-12, 1.5);
  color: $color-gray-35-blue-tint;
  @include mq(sm) {
    @include font-size($font-size-15, 1.5);
  }
  @include mq(md) {
    @include font-size($font-size-20, 1.5);
    color: $color-gray-60;
  }

  .c-card--why.c-card--1 & {
    padding-left: calc(33% + #{$spacing-unit-tiny});
    @include mq(sm) {
      padding-left: calc(33% + #{$spacing-unit});
    }
    @include mq(md) {
      padding-left: calc(33% + #{$spacing-unit-large});
    }
  }

  .c-card--why.c-card--2 & {
    padding-left: calc(33% + #{$spacing-unit});
    @include mq(md) {
      clear: both;
      padding: 0 0 0 10%;
    }
  }

  .c-card--why.c-card--3 & {
    clear: both;
    text-align: right;
    @include mq(sm) {
      text-align: left;
    }
  }
}

.c-card__icon {
  .c-card--who & {
    display: inline-block;
    color: white;
    @include font-size(40px, 1);
    width: 80px;
    margin-top: 10px;
    @include mq(sm) {
      width: 140px;
    }
    @include mq(md) {

    }
  }

  .c-card--feature & {
    color: $color-red-bright;
    @include font-size(32px, 1);
    @include mq(sm) {
      @include font-size(48px, 1);
    }
    @include mq(md) {
      @include font-size(60px, 1);
      margin-bottom: $spacing-unit-small;
    }
  }
}

.c-card__headline {
  color: $color-blue-gray-3;
  @include setPropFromVar(font-family, $var-font-family-bolder);
  @include setPropFromVar(font-weight, $var-font-weight-bolder);
  @include font-size(38px, 1.5);
  margin-bottom: $spacing-unit;
}

.c-card__subheadline {
  color: $color-blue-gray-3;
  @include setPropFromVar(font-family, $var-font-family-bolder);
  @include setPropFromVar(font-weight, $var-font-weight-bolder);
  @include font-size(26px, 1.25);
}

.c-card__text {
  color: $color-blue-gray-3;
  @include font-size(13px, 1.5);
  @include mq($from: md) {
    @include font-size(14px, 1.5);
  }
  @include mq($from: lg) {
    @include font-size(15px, 1.5);
  }
}

.c-card__link {
  .c-card--why & {
    color: inherit;
    @include setPropFromVar(font-family, $var-font-family-normal);
  }
}

.c-card__footnote {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding-top: $spacing-unit-med-large;
  @include font-size($font-size-12, 1.5);
}

@supports (display: grid) {
  @include mq(md) {
    .c-card--who {
      display: flex;
      align-items: center;
      padding: $spacing-unit;
    }
    .c-card__front {
      .c-card--who & {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 1;
        left: 15px;
        top: -10px;
        width: 100%;
        height: 100%;
        padding: 0;
        opacity: 1;
        transition: $fade-transition;
      }
      .c-card--who:hover & {
        opacity: 0;
      }
    }
    .c-card__back {
      .c-card--who & {
        display: block;
        text-align: center;
        padding: 0 0 10px 15px;
        &:after {
          display: none;
        }
      }
    }
    .c-card__icon {
      .c-card--who & {
      }
    }
  }

  .c-card--feature {
    width: auto;
  }
}


