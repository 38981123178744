/*------------------------------------*\
    #ALIASES
\*------------------------------------*/

$spacing-unit:             $inuit-global-spacing-unit; // 24px
$spacing-unit-min:         $inuit-global-spacing-unit-tiny / 2; // 3px
$spacing-unit-tiny:        $inuit-global-spacing-unit-tiny; // 6px
$spacing-unit-med-tiny:    $inuit-global-spacing-unit / 3; // 8px
$spacing-unit-small:       $inuit-global-spacing-unit-small; // 12px
$spacing-unit-med-small:   $inuit-global-spacing-unit * 0.75; // 18px
$spacing-unit-med-large:   $inuit-global-spacing-unit * 1.5; // 36px
$spacing-unit-large:       $inuit-global-spacing-unit-large; // 48px
$spacing-unit-med-huge:    $inuit-global-spacing-unit * 2.5; // 60px
$spacing-unit-huge:        $inuit-global-spacing-unit-huge; // 72px
$spacing-unit-v-huge:      $inuit-global-spacing-unit-huge * 1.5; // 108px

$base-line-height:         $inuit-global-line-height;

@mixin font-size($args...) {
    @include inuit-font-size($args...);
}

@mixin clearfix {
    @include inuit-clearfix;
}




