/*------------------------------------*\
    #SELECTBOXIT
\*------------------------------------*/

/**
 * Styles output from SelectBoxIt jQuery plugin
 */

$selectboxit-button-width-xs: 32px;
$selectboxit-button-width-sm: 42px;
$selectboxit-button-width-md: 56px;


/* SelectBoxIt container */
.selectboxit-container {
  position: relative;
  display: block;
  vertical-align: top;
  height: auto;
  @include setPropFromVar(font-family, $var-font-family-default);

  /* Styles that apply to all SelectBoxIt elements */
  * {
    /* Prevents text selection */
    user-select: none;
    outline: none;
    white-space: nowrap;
  }

  // Allow fixing the height via a container's data attribute, which is the number of rows visible
  // in the selectbox dropdown. We approximate the additional height needed for the dropdown button as 3.5em
  @for $i from 1 through 10 {
    [role="dialog"] [data-selectbox-height-open-in-fancybox="#{$i}"] &[aria-expanded="true"] {
      height: 3.5em + $i * 2.5em;
    }
    [role="dialog"] .selectboxit-sm [data-selectbox-height-open-in-fancybox="#{$i}"] &[aria-expanded="true"] {
      height: 40px + $i * 35px;
    }
  }
}

/* Button */
.selectboxit {
  cursor: pointer;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  display: block;
  position: relative;
  height: auto;
  width: 100%;
  max-width: 400px;
  @include mq(sm) {
    min-width: 190px;
  }
  @include mq(mlg) {
    min-width: 240px;
  }
  &.is-locked {
    pointer-events: none;
  }
  .selectboxit-no-max-width & {
    max-width: none;
  }
}

.selectboxit-options a {
  display: inline-block;
  width: 100%;
  @include input-sizing(normal);
  padding-top: 0.55em !important;
  padding-bottom: 0.45em !important;
  line-height: 1.5em !important;
  height: 2.5em !important;
  .selectboxit-tiny & {
    @include input-sizing(tiny);
  }
  .selectboxit-sm & {
    @include input-sizing(smaller);
  }
}

/* Focus pseudo selector */
.selectboxit:focus {
  outline: 0;
}

/* Options List */
.selectboxit-options {
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 $spacing-unit 0;
  padding: 0;
  list-style: none;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
  display: none;
  z-index: 99999;
  text-align: left;

}

.selectboxit-option-icon {
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

/* Disabled Mouse Interaction */
.selectboxit.selectboxit-disabled,
.selectboxit-options .selectboxit-disabled {
  box-shadow: none;
  cursor: not-allowed;
  @at-root .selectboxit:not(.selectboxit-locked-inputs) .selectboxit-disabled {
    @include setPropFromVar(color, $var-state-disabled-text);
    @include setPropFromVar(background-color, $var-state-disabled-bg);
  }
}

/* Button Text */
.selectboxit-text {
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  @include setPropFromVar(color, $var-color-text-base);

  .selectboxit-open & {
    @include setPropFromVar(color, $var-color-text-dark);
  }

  .selectboxit:not(.selectboxit-locked-inputs) .selectboxit-disabled &,
  .selectboxit-options .selectboxit-disabled & {
    @include setPropFromVar(color, $var-state-disabled-text);
  }

  .selectboxit-btn > & {
    @include input-sizing($borders: false);
    .selectboxit-tiny & {
      @include input-sizing(tiny, $borders: false);
    }
    .selectboxit-sm & {
      @include input-sizing(small, $borders: false);
    }
    .selectboxit-lg & {
      @include input-sizing(large, $borders: false);
    }
  }

  &#edit-sort-bySelectBoxItText {
    &:before {
      content: '';
      @include svg-icon(up-down, 0.75em, $fill: getVarSass(color-text-base));
      margin-right: $spacing-unit-tiny;
      html[data-theme="dark"] {
        filter: url(#color-gray-87-blue-tint);
      }
    }
    &[data-val="_dummy"]:before {
      display: none;
    }

  }

  .selectboxit.is-locked & {
    padding-left: 0 !important;
  }

}

.selectboxit-option-icon-container {
  display: flex;
  align-items: center;
}


/* Individual options */
.selectboxit-option {

  .selectboxit-option-anchor {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* Individual Option Hover Action */
  .selectboxit-option-anchor:hover {
    text-decoration: none;
  }

  /* Individual Option Optgroup Header */
  .selectboxit-optgroup-header {
    margin: 0;
    list-style-type: none;
  }

  /* The first Drop Down option */
  &-first {
  }

  /* The last Drop Down option */
  &-last {
  }

}

/* Drop Down optgroup headers */
.selectboxit-optgroup-header {
  font-weight: bold;

  /* The first Drop Down option optgroup */
  & + .selectboxit-option-first {
  }

  /* Drop Down optgroup header hover psuedo class */
  &:hover {
    cursor: default;
  }

}

/* Drop Down down arrow container */
.selectboxit-arrow-container {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  font-size: $font-size-15;
  width: $selectboxit-button-width-md;

  .selectboxit-disabled &,
  .selectboxit.is-lockable & {
    visibility: hidden;
  }

  &:before {
    @include svg-icon(arrow-down, $fill: getVarSass(color-text-base));
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .selectboxit-open & {
      @include svg-icon(arrow-up, $fill: getVarSass(color-text-base));
    }
    .selectboxit-disabled & {
      @include svg-icon(arrow-down, $fill: getVarSass(state-disabled-text));
    }
  }
  // Have to declare media queries outside-in so that the JS sets
  // the container's width attribute based on the largest possible
  // (the dropdown is first appended to the body for sizing, outside
  // the form element it will eventually sit within).
  form:not(.selectboxit-sm):not(.selectboxit-tiny) &,
  .selectboxit-btn:not(.selectboxit-sm):not(.selectboxit-tiny) & {
    @include mq($until: sm) {
      font-size: $font-size-10;
      width: $selectboxit-button-width-xs;
    }
    @include mq($until: md) {
      font-size: $font-size-14;
      width: $selectboxit-button-width-sm;
    }
  }

  .selectboxit-arrow {
    display: none;
  }

  /* Drop Down down arrow for jQueryUI and jQuery Mobile */
  .selectboxit-arrow.ui-icon {
    top: 30%;
  }

  .selectboxit-tiny &,
  .selectboxit-sm & {
    width: auto;
    background-color: transparent;
    &:before {
      left: 50%;
      @include mq($from: sm) {
        transform: translate(-100%, -50%);
      }
    }
  }

  .selectboxit-tiny & {
    @include input-sizing(tiny);
  }

  .selectboxit-sm & {
    @include input-sizing(small);
  }
}

/* Drop Down individual option icon positioning */
.selectboxit-option-icon-container {
}


/* Drop Down individual option icon positioning */
.selectboxit-option-icon-url {
  width: 18px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  height: 100%;
  background-position: center;
  float: left;
}

.selectboxit-rendering {
  display: inline-block !important;
  *display: inline !important;
  zoom: 1 !important;
  visibility: visible !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

/* jQueryUI and jQuery Mobile compatability fix - Feel free to remove this style if you are not using jQuery Mobile */
.jqueryui .ui-icon {
  background-color: inherit;
}

/* Another jQueryUI and jQuery Mobile compatability fix - Feel free to remove this style if you are not using jQuery Mobile */
.jqueryui .ui-icon-triangle-1-s {
  background-position: -64px -16px;
}

.selectboxit-btn {
  @include setPropFromVar(background-color, $var-color-bg-1);
  border: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);
  position: relative;
  border-radius: $global-radius;
  .view-air-auction-items & {
    background-color: $color-gray-20;
  }
   &.selectboxit-tiny,
   &.selectboxit-sm {
    @include setPropFromVar(border-color, $var-color-border);
  }
  .is-error &:not([name="field_ap_default_item_expiry[und][0][timezone][timezone]"]) {
    border-color: $color-form-error;
  }
  &.is-locked {
    border-color: transparent;
  }
  &.selectboxit-enabled:not(.is-locked) {
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      background-position: 0 -15px;
      @include setPropFromVar(color, $var-color-highlight);
      border: 1px solid;
      @include setPropFromVar(border-color, $var-color-ui);
    }
  }

  &.selectboxit-open {

    @include setPropFromVar(border-color, $var-color-ui);
    &.selectboxit-open {
      @include hide-border(bottom);
    }
    &.selectboxit-open-up {
      @include hide-border(top);
    }
  }

}

.selectboxit-default-arrow {
  display: none;
}

.selectboxit-list {
  @include setPropFromVar(background-color, $var-color-bg-1);
  border: 1px solid;
  @include setPropFromVar(border-color, $var-color-ui);

  .selectboxit-open:not(.selectboxit-open-up) + & {
    margin-top: -1px;
    border-bottom-left-radius: $global-radius;
    border-bottom-right-radius: $global-radius;
    border-top: none;
  }
  .selectboxit-open-up + & {
    margin-top: 1px;
    border-top-left-radius: $global-radius;
    border-top-right-radius: $global-radius;
    border-bottom: none;
  }

  .selectboxit-option-anchor {
    display: flex;
    align-items: center;
    line-height: 1;
    @include setPropFromVar(color, $var-color-text-base);
  }
  .selectboxit-selected .selectboxit-option-anchor {
    @include setPropFromVar(color, $var-color-text-dark);
  }

  > .selectboxit-focus > .selectboxit-option-anchor {
    @include setPropFromVar(background-color, $var-color-bg-2);
  }

  > .selectboxit-disabled > .selectboxit-option-anchor {
    @include setPropFromVar(color, $var-state-disabled-text);
  }

}

// Language selection
// Flag position given for the high-res version
$flags: (
  'en': 'us',
  'en-gb': 'gb',
  'fr': 'fr',
  'de': 'de',
  'es': 'es'
);
@each $lang, $flag in $flags {

  [data-selectboxit-flags="1"] .selectboxit-container[role="combobox"] {
    .selectboxit-option[data-val="#{$lang}"] .selectboxit-option-icon,
    .selectboxit-btn .selectboxit-text[data-val="#{$lang}"] {
      @extend .iti__flag;
      @extend .iti__#{$flag};
      vertical-align: middle;
    }
    .selectboxit-btn {
      transform: scale(0.75);
    }
  }
}
[data-selectboxit-flags="1"] .selectboxit-container[role="combobox"] {
  position: absolute;
  left: 0;
  bottom: -2px;
  .selectboxit-arrow-container {
    display: none;
  }
  .selectboxit-btn {
    background-color: transparent;
    &:not(.selectboxit-open) {
      border: none !important;
      width: 20px !important;
      min-width: unset !important;
      overflow: hidden;
      background-color: transparent;
      border-radius: 0;
    }
    &.selectboxit-open {
      min-width: 0 !important;
      width: 20px !important;
      border: none;
    }
    .selectboxit-text {
      text-indent: -1000px;
      padding: 0;
      margin: 0;
    }
  }
  .selectboxit-list {
    border: 1px solid;
    @include setPropFromVar(border-color, $var-color-ui);
    border-radius: $global-radius !important;
    margin-top: -$spacing-unit-small !important;
    min-width: 190px;
  }
  .selectboxit-options {
    right: 0;
    @include mq(mmd) {
      transform: translateX(50%);
    }
  }
  .selectboxit-options a {
    padding: $spacing-unit-tiny !important;
    height: auto !important;
    @include font-size($font-size-14, 1);
  }
  .selectboxit-option-icon-container {
    padding: 0 $spacing-unit-small 0 $spacing-unit-tiny;
  }
}
