/*------------------------------------*\
    #REGISTRATION
\*------------------------------------*/

.c-registration {
}

.c-registration__item {
  margin-bottom: $spacing-unit-small;
}

.c-registration__label {
  display: inline-block;
  &::after {
    //content: ':\00a0';
  }
}

.c-registration__value {
  display: inline-block;
}



















