/*------------------------------------*\
    #Tooltip
\*------------------------------------*/

/*!
 * Thanks to http://primercss.io/docs.css
 */


.c-tooltip {
  position: relative;
}

@include mq(mmd) {
  .c-tooltip::after {
    position: absolute;
    z-index: 1000000;
    padding: 5px 8px;
    font: normal normal 11px/1.5 Helvetica, arial, nimbussansl, liberationsans, freesans, clean, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    -webkit-font-smoothing: subpixel-antialiased;
    color: #fff;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: break-word;
    white-space: pre;
    pointer-events: none;
    content: attr(aria-label);
    background: rgba(0, 0, 0, 0.8);
    border-radius: 3px;
    opacity: 0;
  }

  .c-tooltip::before {
    position: absolute;
    z-index: 1000001;
    width: 0;
    height: 0;
    color: rgba(0,0,0,0.8);
    pointer-events: none;
    content: "";
    border: 5px solid transparent;
    opacity: 0;
  }

  .c-tooltip--s::after,
  .c-tooltip--se::after,
  .c-tooltip--sw::after {
    top: 100%;
    right: 50%;
    margin-top: 5px;
  }

  .c-tooltip--s::before,
  .c-tooltip--se::before,
  .c-tooltip--sw::before {
    top: auto;
    right: 50%;
    bottom: -5px;
    margin-right: -5px;
    border-bottom-color: rgba(0,0,0,0.8);
  }

  .c-tooltip--se::after {
    right: auto;
    left: 50%;
    margin-left: -15px;
  }

  .c-tooltip--sw::after {
    margin-right: -15px;
  }

  .c-tooltip--n::after,
  .c-tooltip--ne::after,
  .c-tooltip--nw::after {
    right: 50%;
    bottom: 100%;
    margin-bottom: 10px;
  }

  .c-tooltip--n::before,
  .c-tooltip--ne::before,
  .c-tooltip--nw::before {
    top: -10px;
    right: 50%;
    bottom: auto;
    margin-right: -5px;
    border-top-color: rgba(0,0,0,0.8);
  }

  .c-tooltip--ne::after {
    right: auto;
    left: 50%;
    margin-left: -15px;
  }

  .c-tooltip--nw::after {
    margin-right: -15px;
  }

  .c-tooltip--s::after,
  .c-tooltip--n::after {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }

  .c-tooltip--w::after {
    right: 100%;
    bottom: 50%;
    margin-right: 5px;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
  }

  .c-tooltip--w::before {
    top: 50%;
    bottom: 50%;
    left: -5px;
    margin-top: -5px;
    border-left-color: rgba(0,0,0,0.8);
  }

  .c-tooltip--e::after {
    bottom: 50%;
    left: 100%;
    margin-left: 5px;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
  }

  .c-tooltip--numberfield::before {
    left: 200px;
  }
  .c-tooltip--numberfield::after {
    left: 205px;
  }

  .c-tooltip--e::before {
    top: 50%;
    right: -5px;
    bottom: 50%;
    margin-top: -5px;
    border-right-color: rgba(0,0,0,0.8);
  }

  .c-tooltip--multiline::after {
    width: 350px;
    overflow-wrap: break-word;
    word-wrap: normal;
    white-space: pre-line;
    border-collapse: separate;
  }

  .c-tooltip--multiline.c-tooltip--s::after,
  .c-tooltip--multiline.c-tooltip--n::after {
    right: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .c-tooltip--multiline.c-tooltip--w::after,
  .c-tooltip--multiline.c-tooltip--e::after {
    right: 100%;
  }

  .c-tooltip--ta_l::after {
    text-align: left;
  }
  .c-tooltip--ta_r::after {
    text-align: right;
  }
}

@-webkit-keyframes tooltip-appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes tooltip-appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


@mixin tooltip-anim {
  display: inline-block;
  text-decoration: none;
  animation-name: tooltip-appear;
  animation-duration: .1s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

.c-tooltip {
  html.can-hover &:hover {
    &::before,
    &::after {
      @include tooltip-anim;
    }
  }
}

.has-tooltip {
  html.can-hover &:hover {
    > .c-tooltip:before,
    > .c-tooltip:after {
      @include tooltip-anim;
    }
  }
}

html.can-hover .c-tooltip--no-delay:hover::before,
html.can-hover .c-tooltip--no-delay:hover::after {
  opacity: 1;
  -webkit-animation: none;
  animation: none;
}

.c-tooltip--sticky::before,
.c-tooltip--sticky::after {
  display: inline-block;
}

.c-tooltip--label {
  display: inline-block;
  padding: 0 2px;
  text-align: center;
  vertical-align: top;
  @include font-size(9px, 1);
  @include mq($until: md) {
    display: none;
  }
}

.c-tooltip__icon {
  transition: $global-transition-all;
  html.can-hover .c-tooltip:hover &,
  html.can-hover .has-tooltip:hover & {
    transform: scale(1.5);
  }
}
