/* ==========================================================================
   #TABLES
   ========================================================================== */

/**
 * 1. Ensure tables fill up as much space as possible.
 */

table {
  width: 100%; /* [1] */
  margin-bottom: 0;
}

th {
  @include setPropFromVar(font-family, $var-font-family-bold);
  @include setPropFromVar(font-weight, $var-font-weight-bold);
  text-align: left;
}

