/*------------------------------------*\
    #STANDALONE
\*------------------------------------*/

/*
  Single-use components
 */

.s--auction-admin-page-content-top {
  @extend .o-grid\@md;
  @extend .o-grid--a\/a;
  @include mq($from: md) {
    position: relative;
    @include hr-bottom;
    & > * {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.s--page-header-add-item-link-wrapper {
  display: none;
  @include mq($from: md) {
    display: block;
    text-align: right;
    padding-top: $spacing-unit-med-small;
  }
  @include mq($from: lg) {
    padding-top: $spacing-unit;
  }
}

.s--sticky-header-bg {
  @include setPropFromVar(background-color, $var-color-bg-3);
    border-bottom: 1px solid;
  @include setPropFromVar(border-color, $var-color-border);;
}

.s--ai-search-bar {
  display: none;
  @include mq($from: md) {
    display: block;
    @include o-sticky;
    top: $spacing-unit-small;
  }
}

.s--ai-search-list {
  background-color: transparent;
  overflow-y: auto;
  padding-bottom: 1px;
  margin-top: $spacing-unit-small;
  max-height: calc(100vh - 260px);
  @include mq($from: lg) {
    max-height: calc(100vh - 230px);

  }
}

.s--ui-feedback {
  @include setPropFromVar(color, $var-color-ui);
  @include font-size($font-size-12, 1);
  @include setPropFromVar(font-family, $var-font-family-default);
  position: absolute;
  right: 100%;
  top: 50%;
  padding-right: $spacing-unit-tiny;
}

.s--skip-link {
  &:focus {
    @include font-size($font-size-15);
    position: absolute;
    top: $spacing-unit-med-tiny;
    outline: none;
  }
}

.s--monitor-view-top-links {
  display: flex;
  justify-content: space-between;
  padding: $spacing-unit-small 0;
  @include font-size($font-size-14);
}

.s--tablesort-indicator {
  font-size: 0.5em;
  position: absolute;
  right: -1.25em;
}
